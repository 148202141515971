import { Component, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ConfBarSignature } from '@atline-shared/components/conf-bar-websign-verify/conf-bar-websign-verify.component';
import { WebSignVerifierRequest } from '@atline/core/models/api/requests/webSignVerifier.request';
import { WebSignVerifierResponse } from '@atline/core/models/api/responses/webSignVerifier.response';
import { UtilsService } from '@atline/core/services/utils.service';
import { WebSignVerifier } from '@atline/core/services/webSignVerfifier.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-signature-websign',
  templateUrl: './signature-websign.component.html',
  styleUrls: ['./signature-websign.component.scss'],
})
export class SignatureWebsignComponent implements OnInit {

  confBarData!: ConfBarSignature;
  hasError = false;
  signTokenConform = true;
  isConnected = this.utilsService.isLoggedWithAuthorizationCode;

  private file: { file: File | null, token?: File | null } = { file: null, token: null };

  constructor(
    private readonly webSignVerifier: WebSignVerifier,
    private readonly utilsService: UtilsService,
    readonly mediaObserver: MediaObserver,
  ) { }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }

  response: any;

  isLoading = false;
  selectedFileName!: string;

  get fileFileName(): string | null {
    return this.file.file?.name ?? null
  }

  get tokenFileName(): string | null {
    return this.file.token?.name ?? null
  }

  get pdfReport(): string {
    return this.response
  }

  get disabledVerifyButton(): boolean {
    return this.isLoading || (this.confBarData.hasAttachedFile ? (_.isNil(this.file.file) || _.isNil(this.file.token)) : _.isNil(this.file.file));
  }

  // get checkCoherenceTokenAndFile(): boolean {
  //   if (this.file?.token && this.file?.file) {
  //     return this.file.token.name.search(this.file.file.name) > -1
  //   }
  //   return true;
  // }


  public uploadFile(f: any, type: 'file' | 'token'): void {
    this.response = null;
    if (f[0]) {
      this.file[type] = f[0].file;
    } else {
      this.file[type] = f.files[0];
    }
  }

  callWsForVerification(): void {
    if (this.file.file) {
      this.hasError = false;
      const request = new WebSignVerifierRequest();
      request.cle_etab = this.utilsService.cleEtab || '';
      request.cle_pa = this.utilsService.paListFromToken[0].cle_pa;
      request.processStateAife = '';
      request.repKey = '';
      request.locale = '';
      request.reportType = this.confBarData.pdf ? 'PDF' : this.confBarData.xml ? 'XML' : 'ALL';
      this.isLoading = true;

      if (this.file.token && this.confBarData.hasAttachedFile) {
        request.attached_document = this.file.file;
        request.signed_document = this.file.token;
      } else {
        request.signed_document = this.file.file;
      }


      this.webSignVerifier.webSignVerifier(request).subscribe(
        (response: WebSignVerifierResponse) => {
          this.isLoading = false;
          this.response = response;
        },
        (_err) => {
          this.hasError = true;
          this.isLoading = false;
        }
      );
    }
  }
}
