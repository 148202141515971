import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockStatusComponent } from './block-status.component';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@atline/core/material/material.module';



@NgModule({
  declarations: [BlockStatusComponent],
  exports: [BlockStatusComponent],
  imports: [
    CommonModule,
    TranslateModule,
    LayoutModule,
    FlexLayoutModule,
    MaterialModule
  ]
})
export class BlockStatusModule { }
