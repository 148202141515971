import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalDataService } from 'src/app/core/services/data-service.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ms-nav',
  templateUrl: './ms-nav.component.html',
  styleUrls: ['./ms-nav.component.scss'],
})
export class MsNavComponent implements OnInit {
  public isLogged = false;

  currentRoute = '';

  public navElement = [{ url: 'marches-securises' }];

  isPa!: Observable<boolean>;

  activePa = environment.activation.pa;


  constructor(
    private readonly router: Router,
    private readonly globalData: GlobalDataService,
    private readonly utilsService: UtilsService
  ) {
    this.isPa = globalData.isPa;
    this.router.events
      .pipe<any>(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        event.url.split('/')[2] === undefined ||
        event.url.split('/')[2] === 'consultation'
          ? (this.currentRoute = '')
          : (this.currentRoute = event.url.split('/')[2]);
      });
  }

  ngOnInit(): void {
    this.globalData.isLogged.subscribe((isLogged) => {
      this.isLogged = isLogged;
    });
  }

  get isConnected(): boolean {
    return this.utilsService.isLoggedWithAuthorizationCode;
  }
}
