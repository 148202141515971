import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  Input,
  HostBinding,
  HostListener,
  Output,
  EventEmitter
} from '@angular/core';


const colorSelected = '#FF6700' as const;
const colorDefault = '#1C3761' as const;

const styles = {
  color: colorDefault,
  transition: 'all 500ms ease',
  cursor: 'pointer',
} as const;
type KeysStyles = keyof typeof styles;

@Directive({
  selector: 'li[sub-btn-side-bar]'
})
export class SubButtonSideBarDirective implements OnInit {

  @Input()
  selected = false;

  private hover = false;

  @Output()
  readonly buttonClicked = new EventEmitter<MouseEvent>();

  constructor(
    private readonly elementRef: ElementRef<HTMLLIElement>,
    private readonly renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    for (const key of Object.keys(styles)) {
      this.renderer.setStyle(this.elementRef.nativeElement, key, styles[key as KeysStyles]);
    }
  }

  @HostBinding('style.color')
  get color(): string {
    return this.selected || this.hover ? colorSelected : colorDefault;
  }

  @HostListener('mouseover', ['$event'])
  onMouseEnter(): void {
    this.hover = true;
  }

  @HostListener('mouseout', ['$event'])
  onMouseOver(): void {
    this.hover = false;
  }

  @HostListener('click', ['$event'])
  click(event: MouseEvent): void {
    this.buttonClicked.emit(event);
  }

}
