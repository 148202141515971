import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'block-status',
  templateUrl: './block-status.component.html',
  styleUrls: ['./block-status.component.scss']
})
export class BlockStatusComponent implements OnChanges {

  @Input('align-content')
  public align: 'row' | 'column' = 'column';

  @Input('position-content')
  public position: 'left' | 'right' | 'center' = 'center';

  public layout!: string;
  public layoutAlign!: string;

  constructor() {
    this.setLayout(this.align);
    this.setlayoutAlign(this.position);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.align) this.setLayout(changes.align.currentValue);
    if (changes.position) this.setlayoutAlign(changes.position.currentValue);
  }

  back(): void {
    history.back();
  }

  public setLayout(align: 'row' | 'column'): void {
    if (align !== this.align) this.align = align;
    this.setlayoutAlign(this.position);
    this.layout = align + ' wrap'
  }

  public setlayoutAlign(position: 'left' | 'right' | 'center'): void {
    if (position !== this.position) this.position = position;

    let alignement = ((): string => {
      if (position === 'center') return 'center';
      else if (position === 'left') return 'start';
      else if (position === 'right') return 'end';
      return '';
    })();

    switch (this.align) {
      case 'row':
        this.layoutAlign = `${alignement} stretch`;
        break;
      case 'column':
        this.layoutAlign = `start ${alignement}`;
        break;
    }
  }

}
