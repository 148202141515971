import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsefulLinkComponent } from './useful-link.component';
import { SoonAvalaibleModule } from '@atline-shared/components/soon-avalaible/soon-avalaible.module';



@NgModule({
  declarations: [UsefulLinkComponent],
  imports: [
    CommonModule,
    SoonAvalaibleModule
  ],
  exports: [UsefulLinkComponent]
})
export class UsefulLinkModule { }
