import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticateService } from '@atline/core/services/authenticate.service';
import { DialogsService } from '@atline/core/services/dialogs.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {

  constructor(
    private readonly authService: AuthenticateService,
    private readonly dialogService: DialogsService,
    private readonly matDialog: MatDialogRef<SessionExpiredComponent>
  ) { }

  ngOnInit(): void {
  }

  removeSession(): void {
    this.authService.logout();
    this.matDialog.close();
  }

  login(): void {
    this.matDialog.close();
    this.dialogService.openLoginDialog();
  }

}
