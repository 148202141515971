import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StatistiqueDowloadRequest, StatistiqueRequest } from '../models/api/requests/statistique.request';
import { Observable } from 'rxjs';
import { StatistiqueResponse } from '../models/api/responses/statistique.response';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MethodRequest } from '@atline-shared/enums/method-request.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatistiqueService {

  constructor(
    private readonly apiService: ApiService,
    private readonly http: HttpClient
  ) { }

  getStats(params: StatistiqueRequest): Observable<StatistiqueResponse> {
    return this.apiService.post(StatistiqueResponse, params, 'statistique');
  }

  getCSV(params: StatistiqueDowloadRequest): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.api + 'download_statistics_csv',
      params,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.POST)
          .headers,
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
}
