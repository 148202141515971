import { AvisAttributionItem } from "../../avis-attributions.model";
import { Pagination } from "../../pagination.model";


export class AvisAttributionsResponse {
  attributions!: AvisAttributionItem[];
  nb_total!: number;
  pagination = new Pagination();

  }

