import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {UtilsService} from '../services/utils.service';
import {SessionStorage} from '@atline-shared/enums/session-storage.enum';

import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { TranslateLoader, TranslateModule as NgxTranslateModule, TranslateService } from '@ngx-translate/core';

export const DEFAULT_LANG = 'fr';

export function HttpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/', suffix: '.json'},
    {prefix: './assets/i18n/annonces/', suffix: '.json'},
  ]);
}

function InitializerFactory(translate: TranslateService, utilsService: UtilsService, injector: Injector) {
  return async (): Promise<void> => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    let lang: string = utilsService.getSessionStorage(SessionStorage.LANG);
    const browserLang = utilsService.getBrowserLanguage().substring(0, 2);
    if (!lang || lang === 'undefined') lang = browserLang;
    // this language will be used as a fallback when a translation isn't found in the current language
    if (DEFAULT_LANG !== lang) translate.setDefaultLang(DEFAULT_LANG);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    try { await translate.use(lang).toPromise(); }
    catch (e) { console.error(e); }
  };
}

@NgModule({
  imports: [HttpClientModule,  NgxTranslateModule],
  exports: [NgxTranslateModule],
})
export class TranslateModule {

  static forRoot(): ModuleWithProviders<TranslateModule> {
    const { providers } = NgxTranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient], },
      isolate: false
    });

    return { ngModule: TranslateModule, providers: [
      ...(providers || []),
      {
        provide: APP_INITIALIZER,
        useFactory: InitializerFactory,
        deps: [TranslateService, UtilsService, Injector],
        multi: true
      },
    ]};
  }
}
