export interface OptionFormule {
  id: string;
  value: string;
}

export interface JalItem {
  id_jal: string;
  cle_jal: string;
  entreprise: string;
  type_jal: string;
  offre_jal?: string | null;
  date_creation: string;
  denomination: string;
  description: string;
  email_envoi: string;
  url_envoi: string;
  url_suivi: string;
  partenaire_interbat: string;
  login: string;
  pass: string;
  logo_ent: string;
  option_lrar_available: boolean;
  option_logo_available: boolean;
  option_formule_available: boolean;
  option_formule_list: OptionFormule[];
  jal_actif: string;
  option_lrar_actif: boolean;
  option_logo_actif: boolean;
  option_formule_actif: boolean;
  option_formule_select: string;
}

export class SupportListResponse {
  boamp = '';
  joue = '';
  jals: JalItem[] = [];
}
