import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from '@atline/core/models/services.model';

@Component({
  selector: 'app-presentation-service-item',
  templateUrl: './presentation-service-item.component.html',
  styleUrls: ['./presentation-service-item.component.scss']
})
export class PresentationServiceItemComponent implements OnInit {

  @Input()
  service!: Service;

  constructor(
    private readonly router: Router
  ) { }

  ngOnInit(): void {
  }

  goTo(): void {
    if (!this.service.disabled) {
      try {
        new URL(this.service.route);
        window.open(this.service.route, '_blank');
      } catch (error) {
        this.router.navigate([this.service.route]);
      }
    }
  }

  isDisabled(serviceLink: Service): boolean {
    return serviceLink.disabled;
  }

}
