<div class="container" fxFlex="100">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <h2 *ngIf="!rights" class="text-bl" fxFlex="45">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TITLE' | translate: { nbUsers: nbUsers } }}
    </h2>

    <h2 *ngIf="rights" class="text-bl" fxFlex="45">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.RIGHTS_OF' | translate  : { user: user.firstName } | uppercase}}
    </h2>

    <div class="right" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="55">
      <div fxLayout="row" fxLayoutAlign="start center">
        <label class="search label-input">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.SEARCH_USERS' |
          translate }}</label>
        <input mat-input type="search" class="form-control" aria-label="Recherche" aria-describedby="search-addon"
          #searchInput (input)="searchUsers(searchInput.value)">

        <button mat-icon-button (click)="searchUsers(searchInput.value)">
          <mat-icon svgIcon="loupe" class="search-icon"></mat-icon>
        </button>
      </div>

      <div fxLayout="row" class="add-user" fxLayoutAlign="start center">
        <label class="label-input">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.ADD_USER_LABEL' |
          translate }}</label>
        <app-button class="btn btn-primary" (click)="addUser()" class="add-user-btn">+</app-button>
      </div>
    </div>
  </div>

  <app-add-user *ngIf="show"></app-add-user>
  <app-legend-user-admin [cross]="true" *ngIf="legendVisible" [legendType]="legendType"></app-legend-user-admin>

  
  <app-rights-procedure-dialog *ngIf="rights"></app-rights-procedure-dialog>
  
  <app-pa-user *ngIf="paVisible"></app-pa-user>

  <div *ngIf="added" class="container-success">
    <div mat-dialog-title fxLayout="row-reverse" fxLayoutAlign="space-between center" class="header-success">
      <a *ngIf="cross" atlineLink (click)="close()" class="close"></a>
    </div>
    <p>
      {{
        'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_ADD_SUCCESS'
          | translate
      }}
    </p>
  </div>

  <div *ngIf="!legendVisible && !paVisible && !rights" class="body">
    <header fxLayoutAlign="space-between center">
      <label class="label-list">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.LIST' | translate }}</label>
      <div fxLayout="row" fxLayoutAlign="start center" mat-icon-button>
        <button mat-icon-button (click)="legendVisibility()">
          <mat-icon svgIcon="info" class="mat-icon-legend"></mat-icon>
          <label class="legend">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.LEGEND' | translate }}</label>
        </button>
      </div>
      <label>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.NB_USER_LABEL' | translate: { nbUsers: nbUsers,
          plurial: getPlurial(nbUsers) } }}</label>
    </header>

    <div *ngIf="isLoading" class="loading-spinner">
      <mat-spinner [diameter]="80" color="primary"></mat-spinner>
    </div>

    <div *ngIf="!isLoading">
      <table class="table">
        <thead>
          <tr>
            <th fxFlex="20">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TABLE.FIRST_NAME' | translate }}</th>
            <th fxFlex="20">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TABLE.LAST_NAME' | translate }}</th>
            <th fxFlex="30.5">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TABLE.LOGIN' | translate }}</th>
            <th fxFlex="9.5">
              <div class="icon-number">
                <!-- <mat-icon svgIcon="eye"></mat-icon> -->
                <div>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.DROITS' | translate }}</div>
              </div>
            </th>
            <th fxFlex="12">
              <div class="icon-number">
                <div>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.PA' | translate }}</div>
                <!-- <div class="button-header">
                  <mat-icon>PA</mat-icon>
                </div>
                <span class="number">{{paList?.length}}</span> -->
            </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="filteredUsers.length === 0">
            <td colspan="4" class="no-users-found">
              <div class="no-users-message">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.EMPTY_USR' | translate }}</div>
            </td>
          </tr>
          <tr *ngFor="let user of filteredUsers" [ngClass]="{'admin': user.isAdmin}">
            <td class="user-label" fxFlex="70">{{ user.firstName }}</td>
            <td class="user-label" fxFlex="70">{{ user.lastName }}</td>
            <td class="user-label" fxFlex="70">{{ user.login }}</td>
            <td fxFlex="10">
            <td fxFlex="10">
              <div class="icon-number">
                <button mat-icon-button>
                  <mat-icon svgIcon="eye" (click)="openUserRights(user)"></mat-icon>
                </button>
                <span class="number"></span>
              </div>
            </td>
            <td fxFlex="10">
              <div class="icon-number">
                <button class="button" (click)="paPart(user)">
                  <mat-icon>PA</mat-icon>
                </button>
                <span class="number">{{ countPa(user) }}</span>
              </div>
            </td>
            <td fxFlex="10">
              <button mat-icon-button>
                <mat-icon svgIcon="editpencil"></mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon svgIcon="bin"></mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
