import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-circle-checkbox',
  templateUrl: './circle-checkbox.component.html',
  styleUrls: ['./circle-checkbox.component.scss']
})
export class CircleCheckboxComponent {

  @Input()
  public name: string;

  @Input()
  public code: string;

  @Input()
  public checked = false;

  @Input()
  title!: string;

  @Output()
  public checkedChange = new EventEmitter<boolean>();

  @Input()
  public img?: string;

  @Output()
  public changeState = new EventEmitter<boolean>();


  constructor() {
    this.code = '';
    this.name = '';
  }

  onChange(): void {
    this.checked = !this.checked;
    this.changeState.emit(this.checked);
  }

}
