import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfBarWebsignVerifyComponent } from './conf-bar-websign-verify.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { SwitchModule } from '@atline-shared/design/switch/switch.module';
import { RoundCheckboxModule } from '@atline-shared/design/round-checkbox/round-checkbox.module';
import { SearchBarContainerModule } from '@atline-shared/design/search-bar-container/search-bar-container.module';



@NgModule({
  declarations: [ConfBarWebsignVerifyComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    FlexLayoutModule,
    SwitchModule,
    RoundCheckboxModule,
    SearchBarContainerModule
  ],
  exports: [
    ConfBarWebsignVerifyComponent
  ]
})
export class ConfBarWebsignVerifyModule { }
