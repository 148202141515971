export class WebsendGetdocumentResponse {

  // tslint:disable:variable-name

  response!: WebSendDocumentReponseItem[];
  files!: WebSendDocumentItem[];
  nb_total = '';

  // tslint:enable:variable-name

}

export class WebSendDocumentItem {
  // tslint:disable:variable-name
  id_up_rep!: number;
  id_liste_rep!: number;
  nom_fich!: string;
  size_fich!: string;
  dir_fich!: string;
  path_fich!: string;
  type_fich!: string;
  date_up!: string;
  statut!: number;
  cle_str_env!: string;
  cle_rep_str!: string;
  file_path!: string;
  // tslint:enable:variable-name
}

export class WebSendDocumentReponseItem {
  // tslint:disable:variable-name
  id_l_rep!: number;
  tok_rep!: string;
  cle_rep_ms!: string;
  identifiant_dce_ms!: string;
  cle_pa!: string;
  identifiant_pa!: string;
  cle_ent_ms!: string;
  date_rep!: string;
  depose!: number;
  login_user!: string;
  cle_ident_user!: string;
  anne_dep!: number;
  origin_plateforme!: number;
  timezone!: string;
  // tslint:enable:variable-name
}
