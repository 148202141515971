export class DepotFindResponse {
  // tslint:disable:variable-name
  cle!: string;
  cle_dce!: string;
  date_depot!: number;
  date_depot_f!: string;
  etat_depot!: number;
  etat_depot_lbl!: string;
  entreprise!: EntrepriseDepotFindItem;
  // tslint:enable:variable-name
}

export class EntrepriseDepotFindItem {
  // tslint:disable:variable-name
  cle!: string;
  cle_annuaire!: string;
  denomination!: string;
  nom!: string;
  adresse_1!: string;
  adresse_2!: string;
  code_postal!: string;
  commune!: string;
  pays!: string;
  tel!: string;
  fax!: string;
  siret!: string;
  siren!: string;
  code_naf!: string;
  url!: string;
  contact!: string;
  email_contact!: string;
  // tslint:enable:variable-name
}
