import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServicesModule } from './services/services.module';
import { TranslateModule } from './translate/translate.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    ServicesModule
  ],
})
export class CoreModule { }
