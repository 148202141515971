<div class="marche-container" [ngClass]="{'side-pa-type': isPaSide, 'remove-pb': displayFooter}">

  <span class="corner-pa-color-status" [ngClass]="statusColorClass" *ngIf="isPaSide">
    <ng-container *ngIf="printClock">
      <mat-icon svgIcon="clock"></mat-icon>
    </ng-container>
  </span>

  <header class="card" id="header">
    <ng-content select="[header]"></ng-content>
  </header>

  <div class="divider" [ngClass]="{'side-pa-type': isPaSide}">
    <mat-divider></mat-divider>
    <span class="shape-color-status" [ngClass]="statusColorClass" *ngIf="displayHeaderShape"></span>
  </div>

  <section class="card" id="body">
    <ng-content select="[body]"></ng-content>
  </section>

  <div #footer class="card" id="footer" [ngClass]="{'footer-hidden': !displayFooter}">
    <ng-content select="[footer]"></ng-content>
  </div>

  <div #customContent class="card" id="custom-content" [ngClass]="{'custom-content-hidden': !displayCustomContent}">
    <ng-content select="[customContent]"></ng-content>
  </div>

</div>
