import { Component, Input, OnInit } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';

@Component({
  selector: 'app-hotline',
  templateUrl: './hotline.component.html',
  styleUrls: ['./hotline.component.scss']
})
export class HotlineComponent implements OnInit {

  @Input()
  isSmall = false;

  public Icons = Icons;

  constructor() { }

  ngOnInit(): void {
  }

}
