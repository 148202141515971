export enum ConsultationState {
  SEARCH = 'search',
  LIST = 'list',
  DETAILS = 'viewConsultation',
  QUESTIONS = 'qandanwser',
  PIECE = 'viewPieces',
  DEPOT = 'depot'
}


export enum ConsultationFinaliteMarche {
  MARCHE = 'marche',
  ACCORD = 'accord',
  CONCESSION = 'dsp',
  AUTRE = 'autre',
  SEM = 'sem'
}

export enum ConsultationTypeMarche {
  PUBLIC = 'public',
  ORDONNANCE = 'oradonnace2005',
  PRIVE = 'prive'
}

export enum ConsultationTypePrestation {
  TRAVAUX = 'travaux',
  SERVICES = 'services',
  FOURNITURES = 'fournitures',
  AUTRE = 'autre'
}

export enum ConsultationTypePa {
  PA = 'pa',
  EA = 'ea'
}

export enum ConsultationOptionReponse {
  NO = 0,
  AUTHORISE = 1,
  MANDATORY = 2
}

export enum ConsultationFormatReponse {
  UNIQUE = 'unique',
  TWO_ENV = '2env',
  ENV_LOT = '1env_par_lot',
  CONCOURS_2_ENV = '2env_concours',
  CONCOURS_3_ENV = '3env_concours',
  CONCOURS_4_ENV = '4env_concours_i'
}

export enum ConsultationFormatSignature {
  CAdES_ATTACHE = 1,
  CAdES_DEATACHE = 2,
  XAdES_DETACHE = 5
}

export enum ConsultationStructureInterne {
  WITHOUT = 'sans',
  TWO_REP = '2rep',
  REPC_AND_LOT = '1repc_1rep_lot',
  REP_LOT = '1rep_par_lot'
}


