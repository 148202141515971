import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarchesSecurisesComponent } from './marches-securises.component';
import { HeaderModule } from '../shared/components/header/header.module';
import { FooterModule } from '../shared/components/footer/footer.module';
import { MsRoutingModule } from './ms-routing.module';
import { HotlineFooterModule } from '../shared/components/hotline-footer/hotline-footer.module';
import { ConnectedAnonymousModule } from './connected-anonymous/connected-anonymous.module';
import { DonnesEssenciellesModule } from './donnes-essencielles/donnes-essencielles.module';
import { AvisAttributionModule } from './avis-attribution/avis-attribution.module';
import { FormInscriptionModule } from './form-inscription/form-inscription.module';
import { UsefulLinkModule } from './useful-link/useful-link.module';
import { WhoAreWeModule } from './who-are-we/who-are-we.module';
import { PartnersModule } from './partners/partners.module';
import { SearchModule } from './search/search.module';
import { MsNavModule } from './ms-nav/ms-nav.module';
import { StoryModule } from '@atline/home/story/story.module';
import { CguModule } from '@atline-shared/components/dialog/cgu/cgu.module';
import { CertificatSignatureModule } from '@atline-shared/components/certificat-signature/certificat-signature.module';
import { MsNavMenuModule } from './ms-nav-menu/ms-nav-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    MarchesSecurisesComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    HotlineFooterModule,
    MsRoutingModule,
    DonnesEssenciellesModule,
    AvisAttributionModule,
    CertificatSignatureModule,
    FormInscriptionModule,
    UsefulLinkModule,
    WhoAreWeModule,
    PartnersModule,
    ConnectedAnonymousModule,
    CguModule,
    SearchModule,
    MsNavModule,
    StoryModule,
    TranslateModule,
    MsNavMenuModule,
    FlexLayoutModule
  ]
})
export class MarchesSecurisesModule { }
