// Find all special characters
export const SPE_CARAC_REGEX = /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\/]/gi;
// Find all accents
export const ACCENT_REGEX = /\p{Diacritic}/gu;
// Find all spaces
export const SPACE_REGEX = /\s/g;
// Find all zipcode
export const ZIPCODE_REGEX = /\d{5}/g;
// Check if a code cpv
export const CODE_CPV_REGEX = /^(\d{8})$|^([a-zA-Z]{2}\d{2})$/;
// Check if a email format
export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
// Check format price
export const PRICE_REGEX = /^(\d{1,3}(\s\d{3})*|(\d+))(\.\d{2})?$/g;
// Check if a URL format
export const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
// Check if a phone format
export const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
