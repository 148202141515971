import { Component, Input, OnInit } from '@angular/core';
import { Service, servicesHeader } from '@atline/core/models/services.model';

@Component({
  selector: 'app-services-menu',
  templateUrl: './services-menu.component.html',
  styleUrls: ['./services-menu.component.scss']
})
export class ServicesMenuComponent implements OnInit {

  @Input() isOpen = false;

  servicesList: Service[] = servicesHeader;

  constructor() { }

  ngOnInit(): void {
  }

  isDisabled(serviceLink: Service): boolean {
    return serviceLink.disabled;
  }

}
