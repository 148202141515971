<div class="annuraire-container">
  <!-- <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="header">
    <div fxLayout="column">
      <h2 class="title">{{'APP.MARCHES_SECURISES.CONSULTATION.ALIAS_DIALOG.HEADER' | translate }}</h2>
    </div>
    <button mat-button mat-dialog-close class="close"></button>
  </div>
  <div mat-dialog-content class="body">
    <div *ngFor="let alias of aliasArr">
      <mat-checkbox [(ngModel)]="alias.isSelected" [value]="alias.cle_alias" (change)="toggleSelectAlias($event)"
        color="primary">{{alias.libelle}}</mat-checkbox>
    </div>
  </div> -->

  <app-dialog [dialogRef]="dialogRef">
    <ng-container header>
      <h2 class="title">{{'APP.MARCHES_SECURISES.CONSULTATION.ALIAS_DIALOG.HEADER' | translate }}</h2>
    </ng-container>

    <ng-container body>
      <div *ngFor="let alias of aliasArr">
        <mat-checkbox [(ngModel)]="alias.isSelected" [value]="alias.cle_alias" (change)="toggleSelectAlias()"
          color="primary">{{alias.libelle}}</mat-checkbox>
      </div>
    </ng-container>

    <ng-container footer>
      <app-button [size]="'auto'">{{'APP.MARCHES_SECURISES.CONSULTATION.ALIAS_DIALOG.CLOSE_ANNUAIRE' | translate }}</app-button>
    </ng-container>


  </app-dialog>

</div>
