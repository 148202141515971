import * as _ from 'lodash';

// tslint:disable:variable-name
export class FindStructureReponse {
  annuaire?: Annuaire[] | AnnuaireAdd = undefined;
  client_api?: ClientAPI = undefined;
}

export class Annuaire {
  id_etab_ident = '';
  id_etab = '';
  cle_pa = '';
  ident_type = '';
  valeur = '';
  cle_etab_asupprimer = '';
  siege_social = '';
  date_crea_etab = '';
  ident = '';
  type = '';
  nom_etab = '';
  enseigne = '';
  code_naf = '';
  forme_juridique = '';
  effectif = '';
  date_fermeture = '';
  statut = '';
  origine = '';
  is_modifiable: any;
  date_update = '';
  id_etab_adresse = '';
  numero_voie = '';
  type_voie = '';
  nom_voie = '';
  complement_adresse = '';
  code_postal = '';
  localite = '';
  code_insee_localite = '';
  cedex = '';
  pays_code = '';
  pays_value = '';
  description = '';
}

export class AnnuaireAdd {
  id_etab = '';
  creation = 0;
}

export class ClientAPI {
  etablissement = new EtablissementReponse();
  gateway_error = false;
  annuaire = {
    entreprise: new EntrepriseReponse(),
    etablissement_siege: new EtablissementReponse(),
    gateway_error: false,
  };
  entreprise = new EntrepriseReponse();
  etablissement_siege = new EtablissementReponse();
}

export function isAnnuaireAdd(arg: any): arg is AnnuaireAdd {
  return arg instanceof AnnuaireAdd;
}

export class EtablissementReponse {
  siege_social = false;
  siret = '';
  naf = '';
  libelle_naf = '';
  date_mise_a_jour = 0;
  tranche_effectif_salaire_etablissement: any;
  date_creation_etablissement = 0;
  region_implantation: any;
  commune_implantation: any;
  pays_implantation = { code: '', value: '' };
  diffusable_commercialement = false;
  enseigne: any;
  adresse = {
    l1: '',
    l2: '',
    l3: '',
    l4: '',
    l5: '',
    l6: '',
    l7: '',
    numero_voie: '',
    type_voie: '',
    nom_voie: '',
    complement_adresse: '',
    code_postal: '',
    localite: '',
    code_insee_localite: '',
    cedex: {},
  };
  etat_administratif: any;
  gateway_error = false;
}

export class EntrepriseReponse {
  siren = '';
  capital_social = 0;
  numero_tva_intracommunautaire = '';
  forme_juridique = '';
  forme_juridique_code = '';
  nom_commercial = '';
  procedure_collective = false;
  enseigne: any;
  libelle_naf_entreprise = '';
  naf_entreprise = '';
  raison_sociale = '';
  siret_siege_social = '';
  code_effectif = '';
  date_creation = 0;
  nom: any;
  prenom: any;
  date_radiation: any;
  categorie_entreprise = '';
  tranche_effectif_salarie_entreprise: any;
  mandataires_sociaux: any[] = [];
  etat_adiministratif: any;
}
// tslint:enable:variable-name
