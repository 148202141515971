export class AcceptElrarItem {
  // tslint:disable:variable-name
  id!: number;
  cle_cu!: string;
  cle_utilisateur!: string;
  etat_cu!: number;
  date_action!: number;
  date_action_f!: string;
  // tslint:enable:variable-name
}
