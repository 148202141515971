import { Component, OnInit } from '@angular/core';
import { ImgHelper } from '@atline/core/helpers/img.helper';
import { PaService } from '@atline/core/services/pa.service';
import { UtilsService } from '@atline/core/services/utils.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-pa-logo',
  templateUrl: './pa-logo.component.html',
  styleUrls: ['./pa-logo.component.scss']
})
export class PaLogoComponent implements OnInit {

  private cleEtab!: string | null;

  public base64Img!: string;

  constructor(
    private readonly paService: PaService,
    private readonly utilsService: UtilsService,
    private readonly imgHelper: ImgHelper
  ) { }

  ngOnInit(): void {
    this.cleEtab = this.utilsService.cleEtab;

    if (this.cleEtab) {
      this.paService.getLogo({
        cle_etab: this.cleEtab
      }).subscribe(res => {
        this.imgHelper.blobToBase64(res.body).then(resBase64 => {
          this.base64Img = `${resBase64}`;
        });
      });
    }
  }

}
