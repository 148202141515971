import { Component, Input, OnInit } from '@angular/core';
import { MimeTypeEnum } from '@atline-shared/enums/MimeType.enum';
import { Icons } from '@atline-shared/enums/icons.enum';
import { SignVerifierEnum } from '@atline-shared/enums/signVerifierError.enum';
import { SignatureFileItem } from '@atline/core/models/signatureItem.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-file-signtaure',
  templateUrl: './file-signtaure.component.html',
  styleUrls: ['./file-signtaure.component.scss']
})
export class FileSigntaureComponent implements OnInit {

  private mimeType = MimeTypeEnum;

  icons = Icons;

  @Input()
  file!: SignatureFileItem

  constructor() { }

  ngOnInit(): void {
  }

  get pdfReport(): string | null {
    return this.file.reports?.pdf
  }

  get xmlReport(): string | null {
    return this.file.reports?.xml
  }

  get hasErrorCode(): boolean {
    return this.file.errorCode !== SignVerifierEnum.NO_ERROR_FOUND
  }

  get hasSignatures(): boolean {
    return this.file.signatures.length > 0;
  }

  downloadReport(type: 'PDF' | 'XML'): void {
    const linkSource = `data:${type === 'PDF' ? this.mimeType.PDF : this.mimeType.XML};base64,${type === 'PDF' ? this.pdfReport : this.xmlReport}`;
    const downloadLink = document.createElement('a');
    const fileName = `rapport_verification_signature_${this.file.file.name}_.${_.lowerCase(type)}`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

}
