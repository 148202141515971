import { Component, Inject, OnInit, AfterContentChecked } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-consultation-derivation',
  templateUrl: './consultation-derivation.component.html',
  styleUrls: ['./consultation-derivation.component.scss']
})
export class ConsultationDerivationComponent implements OnInit, AfterContentChecked {

  dce = false;
  lots = false;
  groups = false;

  public options!: Array<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
                                            hasLot: boolean,
                                            isRestreinte: boolean,
                                            id: string,
                                            title: string,
                                            hasDce: boolean,
                                            hasGroups: boolean
    },
  ) { }

  ngOnInit(): void {
    this.options = [
      { key: 'DCE', visibility: this.data.hasDce, value: this.dce },
      { key: 'LOTS', visibility: this.data.hasLot, value: this.lots },
      { key: 'GROUPS', visibility: this.data.hasGroups, value: this.groups }
    ];
  }

  ngAfterContentChecked(): void {
  }

  get consultationId(): string {
    return this.data.id;
  }

  get objet(): string {
    return this.data.title;
  }

  get hasLot(): boolean {
    return this.data.hasLot;
  }

  get hasGroups(): boolean {
    return this.data.hasGroups;
  }

  get hasDce(): boolean {
    return this.data.hasDce;
  }

}
