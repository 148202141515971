import { NgIfContext } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AffectedUserItem } from '@atline/core/models/userItem.model';

@Component({
  selector: 'app-users-dialog',
  templateUrl: './users-dialog.component.html',
  styleUrls: ['./users-dialog.component.scss']
})
export class UsersDialogComponent implements OnInit {
  users: AffectedUserItem[];
  isDeleteDisabled: boolean = false;
  emptyList: TemplateRef<NgIfContext<boolean>> | null = null;
  displayedColumns: string[] = ['firstName', 'lastName', 'actions'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, readonly dialogRef: MatDialogRef<UsersDialogComponent>) {
    this.users = data.users;
  }

  ngOnInit(): void {
    this.updateDeleteDisabledState();
  }

  private updateDeleteDisabledState(): void {
    this.isDeleteDisabled = this.users.length === 0;
  }

  deleteUser(): void {
   
  }
}

