<div class="modal-container">

  <h1>
    {{'APP.MARCHES_SECURISES.SESSION_EXPIRED.DESCRIPTION' | translate}}
  </h1>


  <div fxLayout="row" fxLayoutAlign="space-around">
    <app-button [size]="'auto'" (click)="login()">
      {{'APP.MARCHES_SECURISES.SESSION_EXPIRED.BUTTON.CONTINIOUS' | translate}}
    </app-button>
    <app-button [size]="'auto'" (click)="removeSession()">
      {{'APP.MARCHES_SECURISES.SESSION_EXPIRED.BUTTON.REMOVE_SESSION' | translate}}
    </app-button>
  </div>
</div>
