export enum TypeDume {
  DUME_ALPHA = '0010',
  DUME_S = '0100',
  DUME_FULL = '0001',
  NO_DUME = '1000'
}

export enum MsgDUME {
  CREATED = 'APP.MARCHES_SECURISES.PA.CREATE.TABS.INFOS.DUME.MSG.CREATED',
  IN_PROGRESS = 'APP.MARCHES_SECURISES.PA.CREATE.TABS.INFOS.DUME.MSG.IN_PROGRESS',
  NO_CREAT = 'APP.MARCHES_SECURISES.PA.CREATE.TABS.INFOS.DUME.MSG.NO_CREAT',
  IS = 'APP.MARCHES_SECURISES.PA.CREATE.TABS.INFOS.DUME.MSG.IS',
  ERROR = 'APP.MARCHES_SECURISES.PA.CREATE.TABS.INFOS.DUME.MSG.ERROR'
}
