import { Pagination } from './../../pagination.model';

// tslint:disable:variable-name

export class EnveloppeDeep {
  public id = 0;
  public cle_rep_env = '';
  public type_env = '';
  public libelle = '';
  public date_creation = '';
  public date_creation_f = '';
  public date_depot = '';
  public date_depot_f = '';
  public etat = 0;
  public md5 = '';
  public md5_chiffre = '';
  public emplacement = '';
  public nom_fichier = '';
  public cers = '';
  public backstatus ? = 0;
  public backerror ? = '';
}

export class EnveloppeInfo {
  public cle_rep = '';
  public cle_dce = '';
  public cle_entreprise_ms = '';
  public cle_entreprise = '';
  public denomination_ent = '';
  public date_validation_cu = '';
  public date_validation_cu_f = '';
  public date_acces_reponse = '';
  public date_acces_reponse_f = '';
  public date_depot = '';
  public date_depot_f = '';
  public etat = 0;
  public hd = 0;
  public taille_reponse_total = 0;
  public taille_reponse_total_formatte = '';
  public etat_dl_openpp = 0;
  public contact = '';
  public email_contact = '';
  public utilisateur_id_ident = '';
  public nb_enveloppe = 0;
  public JAVA_installed = '';
  public JAVA_jceerror = '';
  public JAVA_version = '';
  public JAVA_spec = '';
  public JAVA_home = '';
  public OS = '';
  public OS_version = '';
  public langage = '';
  public mps_candidature_id = 0;
  public mps_validation = 0;
  public mps_validation_error = '';
  public archive = 0;
  public dume = 0;
  public is_saas_found = 0;
  public enveloppes = [new EnveloppeDeep()];
  public count_envelopes = 0;
}

export class EnveloppeResponse {
  enveloppes = [new EnveloppeInfo()];
  count = 0;
  count_in_waiting_state = 0;
  pagination: Pagination | null = new Pagination();
}

// tslint:enable:variable-name
