import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import {
  SearchStructureService,
} from '@atline/core/services/search-structure.service';
import { MailService } from '@atline/core/services/mail.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  providers: [SearchStructureService, MailService],
})
export class FaqComponent implements OnInit {
  @ViewChild(MatAccordion)
  accordion!: MatAccordion;

  constructor(
    private router: Router
  ) { }

  onNavigate(): void {
    this.router.navigate([], {
        fragment: 'formulaire',
        skipLocationChange: true
    }).then(() => {
        const element = document.querySelector('.formulaire_faq');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }).catch(error => {
        console.error('Navigation error:', error);
    });
}

  ngOnInit(): void {
  }

}
