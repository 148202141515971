import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { WebSignVerifierRequest } from '../models/api/requests/webSignVerifier.request';
import { WebSignVerifierResponse } from '../models/api/responses/webSignVerifier.response';

@Injectable({
  providedIn: 'root'
})
export class WebSignVerifier {
  constructor(private readonly apiService: ApiService) {}

  webSignVerifier(request: WebSignVerifierRequest): Observable<WebSignVerifierResponse> {
    const formData = new FormData();
    if (request.signed_document) {
      formData.append('signed_document', request.signed_document, request.signed_document.name);
    }

    formData.append('cle_etab', request.cle_etab);
    formData.append('cle_pa', request.cle_pa);
    formData.append('processStateAife', request.processStateAife);
    formData.append('repKey', request.repKey);
    formData.append('attached_document', request.attached_document);
    formData.append('locale', request.locale);
    formData.append('reportType', request.reportType);


    return this.apiService.post<WebSignVerifierResponse>(WebSignVerifierResponse, formData, 'verify_signature', true);
  }
}
