import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DepotsRetraitListRequest } from '@atline/core/models/api/requests/depotsRetraitList.request';
import { RetraitService } from '@atline/core/services/retrait.service';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface PeriodicElement {
  operateur_economiques: string;
  siret_siren: string;
  emails: string;
  villes: string;
}

interface RetraitsPapierListResponse {
  retraits: any[];
  nb_total: number;
}

interface RetraitsListResponse {
  retraits: any[];
  nb_total: number;
  pagination: any[];
}

const elRetraitDefault: PeriodicElement = {
  operateur_economiques: '',
  siret_siren: '',
  emails: '',
  villes: '',
};

@Component({
  selector: 'app-entreprise-retrait',
  templateUrl: './entreprise-retrait.component.html',
  styleUrls: ['./entreprise-retrait.component.scss'],
})
export class EntrepriseRetraitComponent implements OnInit {
  readonly table = new MatTableDataSource<PeriodicElement>();
  readonly columns = [...Object.keys(elRetraitDefault), 'button'];

  private retraitsLists: PeriodicElement[][] = [];

  page = 0;
  length = 0;
  nbParPage = 5;
  readonly itemsPerPage = [5, 10, 20, 30];

  response: any;

  displayedColumns: string[] = [
    'operateur_economiques',
    'siret_siren',
    'emails',
    'villes',
    'actions',
  ];
  clickedRows = new Set<PeriodicElement>();

  noData = false;

  isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(
    readonly dialogRef: MatDialogRef<EntrepriseRetraitComponent>,
    private retraitService: RetraitService,
    @Inject(MAT_DIALOG_DATA)
    public data: { cleDce: string; codeRestreint?: string, cle_etab: string }
  ) { }

  ngOnInit(): void {
    this.table.paginator = this.paginator;

    const retraitsListRequest: DepotsRetraitListRequest = {
      cle_etab: this.data.cle_etab,
      cle_dce: this.data.cleDce,
      code_restreinte: this.data.codeRestreint,
    };

    const retraitsList$ = this.retraitService
      .retraitsList(retraitsListRequest)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );

    const retraitsPapierList$ = this.retraitService
      .retraitsPapierList(retraitsListRequest)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );

    forkJoin([
      retraitsList$.pipe(
        map((response) => response || []),
        catchError(() => of([]))
      ),
      retraitsPapierList$.pipe(
        map((response) => response || []),
        catchError(() => of([]))
      ),
    ]).subscribe(([retraitsListResponse, retraitsPapierListResponse]) => {
      if (
        retraitsListResponse.length === 0 &&
        retraitsPapierListResponse.length === 0
      ) {
        this.noData = true;
        this.isLoading = false;
      } else {
        this.noData = false;
        this.isLoading = false;

        let allData: PeriodicElement[] = [];

        if (
          (<RetraitsListResponse>(<unknown>retraitsListResponse)).retraits &&
          (<RetraitsListResponse>(<unknown>retraitsListResponse)).retraits
            .length !== 0
        ) {
          const transformedData: PeriodicElement[] = (<RetraitsListResponse>(
            (<unknown>retraitsListResponse)
          )).retraits.map((retrait: any) => ({
            operateur_economiques: retrait.entreprise.nom || '',
            siret_siren:
              retrait.entreprise.siret || retrait.entreprise.siren || '',
            emails: retrait.personne.email || '',
            villes: retrait.entreprise.commune || '',
            code_postal: retrait.entreprise.code_postal || '',
            adresse_1: retrait.entreprise.adresse_1 || '',
            adresse_2: retrait.entreprise.adresse_2 || '',
            pays: retrait.entreprise.pays || '',
            tel: retrait.entreprise.tel || '',
            fax: retrait.entreprise.fax || '',
          }));
          allData = [...allData, ...transformedData];

          this.retraitsLists = this.createRetraitsLists(
            allData,
            this.nbParPage
          );
          this.length = allData.length;
          this.setTableData();
        }

        if (
          (<RetraitsPapierListResponse>retraitsPapierListResponse).retraits &&
          (<RetraitsPapierListResponse>retraitsPapierListResponse).retraits
            .length !== 0
        ) {
          const transformedData: PeriodicElement[] = (<
            RetraitsPapierListResponse
          >retraitsPapierListResponse).retraits.map((retrait: any) => ({
            operateur_economiques: retrait.entreprise.nom || '',
            siret_siren:
              retrait.entreprise.siret || retrait.entreprise.siren || '',
            emails: '-',
            villes: retrait.entreprise.commune || '',
            adresse_1: retrait.entreprise.adresse_1,
            code_postal: retrait.entreprise.code_postal || '',
            adresse_2: retrait.entreprise.adresse_2 || '',
            pays: retrait.entreprise.pays,
            tel: retrait.entreprise.tel || '',
            fax: retrait.entreprise.fax || '',
          }));
          allData = [...allData, ...transformedData];
        }
        this.isLoading = false;
        this.retraitsLists = this.createRetraitsLists(allData, this.nbParPage);
        this.length = allData.length;
        this.setTableData();
      }
    });
  }

  onAdd(element: PeriodicElement): void {
    this.dialogRef.close(element);
  }

  private setTableData(): void {
    const flattenedRetraitsLists: PeriodicElement[] =
      this.getFlatRetraitsLists();
    const startIndex = this.page * this.nbParPage;
    const endIndex = startIndex + this.nbParPage;
    this.table.data = flattenedRetraitsLists.slice(startIndex, endIndex);
  }

  private getFlatRetraitsLists(): PeriodicElement[] {
    return this.retraitsLists.reduce(
      (accumulator, currentValue) => accumulator.concat(currentValue),
      []
    );
  }

  private createRetraitsLists(
    retraits: PeriodicElement[],
    retraitParPage: number
  ): PeriodicElement[][] {
    const list: PeriodicElement[][] = [];
    const nbPage = Math.ceil(retraits.length / retraitParPage);
    for (let n = 0; n < nbPage; n++) {
      list.push(retraits.slice(n * retraitParPage, (n + 1) * retraitParPage));
    }
    return list;
  }

  paginatorEvent(pageEvent: PageEvent): void {
    this.page = pageEvent.pageIndex;

    if (this.nbParPage !== pageEvent.pageSize) {
      const elRetraits: PeriodicElement[] = this.getFlatRetraitsLists();
      this.nbParPage = pageEvent.pageSize;
      this.retraitsLists = this.createRetraitsLists(elRetraits, this.nbParPage);
    }
    this.setTableData();
  }
}
