import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MailRequest } from './../models/api/requests/SendMail.request';
import { Observable } from 'rxjs';
import { MessageResponse } from '../models/api/responses/message.response';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private readonly api: ApiService) {}

  sendMail(sendMailRequest: MailRequest): Observable<MessageResponse> {
    return this.api.post(
      MessageResponse,
      sendMailRequest,
      'FaqFormController_faq_mail'
    );
  }
}
