import { LotDictionnary } from '@atline/core/models/dictionnaryLotItem.model';
import { Pagination } from '@atline/core/models/pagination.model';

export class DictionnaryLotResponse {
  // tslint:disable:variable-name
  dictionnaires: LotDictionnary[] = [];
  nb_total = 0;
  pagination = new Pagination();

  constructor();
  constructor(datas: { [Key in keyof DictionnaryLotResponse]?: DictionnaryLotResponse[Key] });
  constructor(arg?: { [Key in keyof DictionnaryLotResponse]?: DictionnaryLotResponse[Key] }) {
    if (arg) Object.assign(this, arg);
  }
  // tslint:enable:variable-name
}
