import { AwardNotificationItem } from '../../AwardNotificationItem.model';
import { Pagination } from '../../pagination.model';

export class AwardNotificationResponse {
  // tslint:disable:variable-name
  attributions!: Array<AwardNotificationItem>;
  nb_total!: number;
  pagination!: Pagination;
  // tslint:enable:variable-name
}
