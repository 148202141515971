<mat-card class="why-us mat-elevation-z4" ngClass.xs ="why-us-responsive">
  <mat-card-title [innerHTML]="'APP.HOME.LANDING.WHY_US.TITLE-HTML' | translate | uppercase"></mat-card-title>
  <mat-card-content>
    <div fxLayout="row wrap">
      <h1 class="tender" fxFlex.lt-lg="100"> {{'APP.HOME.LANDING.WHY_US.FIRST.NUM' | translate }} </h1>
      <span fxFlex="75" fxFlex.lt-lg="100"> <strong> {{ 'APP.HOME.LANDING.WHY_US.FIRST.TITLE' | translate }} </strong><br> {{'APP.HOME.LANDING.WHY_US.FIRST.TEXT' | translate }} </span>
    </div>

    <div fxLayout="row wrap">
      <h1 fxFlex.lt-md="100" fxFlex.md="100" class="secure" fxFlexOrder.gt-lg="2" fxFlexOrder.lg="2"  fxFlexOrder="1"> {{'APP.HOME.LANDING.WHY_US.SECOND.NUM' | translate }} </h1>
      <span fxFlex="75" fxFlex.lt-lg="100" fxFlexOrder.gt-lg="1" fxFlexOrder.lg="1" fxFlexOrder="2"> <strong> {{ 'APP.HOME.LANDING.WHY_US.SECOND.TITLE' | translate }} </strong><br>  {{'APP.HOME.LANDING.WHY_US.SECOND.TEXT' | translate }} </span>
    </div>

    <div fxLayout="row wrap">
      <h1 class="training" fxFlex.lt-lg="100"> {{'APP.HOME.LANDING.WHY_US.THRID.NUM' | translate }} </h1>
      <span fxFlex="75" fxFlex.lt-lg="100"> <strong> {{ 'APP.HOME.LANDING.WHY_US.THRID.TITLE' | translate }} </strong><br>  {{'APP.HOME.LANDING.WHY_US.THRID.TEXT' | translate }} </span>
    </div>
  </mat-card-content>
</mat-card>
