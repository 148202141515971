import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


class StructurePrint {
  name = '';
  siret = '';
  country = '';
  town = '';
  codePostal = '';
  adress = '';
  codeNAF = '';
}

@Component({
  selector: 'app-print-structure',
  templateUrl: './print-structure.component.html',
  styleUrls: ['./print-structure.component.scss']
})
export class PrintStructureComponent implements OnInit {

  print = false;

  // tslint:disable-next-line
  private _structure: StructurePrint;

  @Output()
  printed = new EventEmitter<boolean>();

  constructor() {
    this._structure = new StructurePrint();
  }

  ngOnInit(): void { }

  get structure(): StructurePrint | undefined | null {
    return this._structure;
  }

  @Input()
  set structure(struc: StructurePrint | undefined | null) {
    this._structure = struc ?? new StructurePrint();
    this.print = !!struc;
    this.printed.emit(this.print);
  }
}
