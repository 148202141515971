import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CookiesComponent } from './cookies.component';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [CookiesComponent],
  exports: [CookiesComponent],
  imports: [
    CommonModule,
    LayoutModule,
    FlexLayoutModule,
    TranslateModule,
  ]
})
export class CookiesModule { }
