import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Icons } from '@atline-shared/enums/icons.enum';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { Pagination } from 'src/app/core/models/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input()
  pagination: Pagination = new Pagination();

  @Input() fixedPageSize: boolean = false;

  @Input()
  nbConsultation!: number;

  @Input()
  hideNbParPage = false;

  @Output()
  changePage = new EventEmitter<number>();

  @Input() appearance: 'default' | 'square' = 'default';

  @Output()
  changeNbParPage = new EventEmitter<number>();

  public numberNgFor: number[] = [];
  public nbPage = 0;
  public pageCourante = 1;
  public nbParPageList: Array<string> = [];
  public displayLastPageNumber = false;
  public displayFirstPageNumber = false;
  public isListAffairesUrl: boolean = false;
  public isListGroupsUrl: boolean = false;
  public Icons = Icons;

  constructor(private router: Router) {
    this.isListAffairesUrl = this.router.url.includes(RoutingEnum.LIST_AFFAIRES);
    this.isListGroupsUrl = this.router.url.includes(RoutingEnum.ADM_GROUPS);
  }

  ngOnChanges(): void {
    this.calculNbPage();
    this.initNbParpage();
  }

  ngOnInit(): void {

    this.calculNbPage();
    this.initNbParpage();
  }

  get isOnFirstPage(): boolean {
    return this.pagination.page === 1;
  }

  get isOnLastPage(): boolean {
    return this.pagination.page === this.nbPage;
  }

  private initNbParpage(): void {
    if (this.fixedPageSize) {
      this.nbParPageList = ['10'];
    } else {
      switch (true) {
        case this.nbConsultation >= 40:
          this.nbParPageList = ['10', '20', '30', '50'];
          break;

        case this.nbConsultation <= 40 && this.nbConsultation > 20:
          this.nbParPageList = ['10', '20', '30'];
          break;

        case this.nbConsultation <= 20 && this.nbConsultation > 10:
          this.nbParPageList = ['10', '20'];
          break;

        case this.nbConsultation <= 10:
          this.nbParPageList = [];
          break;
        default:
          break;
      }
    }
  }

  gotToPage(pageNumber: number): void {
    this.pageCourante = pageNumber;
    this.changePage.emit(pageNumber);
    // this.updateNbParPage("10");
    this.reloadNumberPageToPrint();
    window.scrollTo({top:0, behavior: 'smooth'});
  }

  updateNbParPage(n: string): void {
    this.pagination.nbr_par_page = parseInt(n, 10);
    this.calculNbPage();
    this.pageCourante = 1;
    this.changeNbParPage.emit(parseInt(n, 10));
    window.scrollTo({top:0, behavior: 'smooth'});
  }

  calculNbPage(): void {
    this.nbPage = Math.ceil(this.nbConsultation / this.pagination.nbr_par_page);
    this.reloadNumberPageToPrint();
  }

  goToFollowingPage(navigateTo: string): void {
    switch (navigateTo) {
      case 'foward':
        this.gotToPage((this.pagination.page ?? 1) + 1);
        break;

      case 'back':
        this.gotToPage((this.pagination.page ?? 1) - 1);
        break;

      default:
        break;
    }
  }

  reloadNumberPageToPrint(): void {
    this.numberNgFor = [];
    const pageSelect = this.pagination.page;
    const delta = 4;
    let firstPage = 1;
    let lastPage = 1;

    if (pageSelect !== undefined && pageSelect <= this.nbPage) {
      if (this.isOnFirstPage || pageSelect === 2) {
        firstPage = 1;
        lastPage = this.nbPage < 5 ? this.nbPage : 5;
      } else if (this.isOnLastPage || pageSelect === this.nbPage - 1) {
        firstPage = this.nbPage - delta > 0 ? this.nbPage - delta : 1;
        lastPage = this.nbPage;
      } else {
        firstPage = pageSelect - 2;
        lastPage = pageSelect + 2;
      }
    }

    for (let p = firstPage; p <= lastPage; p++) {
      this.numberNgFor.push(p);
    }

    this.displayLastPageNumber = this.nbPage !== lastPage && !this.isOnLastPage;
    this.displayFirstPageNumber = !this.isOnFirstPage && firstPage !== 1;
  }
}
