import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { environment } from 'src/environments/environment';
import { DemandeExecutionRequest } from '../../models/api/requests/history/demande-execution.request';
import { WebsendPieceDownloadRequest } from '../../models/api/requests/history/websendPieceDownload.request';
import { DemandeExecutionResponse } from '../../models/api/responses/history/demande.response';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class DemandeExecutionService {
  private static endPoint = 'demand_exec_oe_list';

  constructor(
    private readonly apiService: ApiService,
    private httpClient: HttpClient
  ) {}

  getDemandeExecutionList(request: DemandeExecutionRequest): Observable<DemandeExecutionResponse> {
    return this.apiService.post(
      DemandeExecutionResponse,
      request,
      DemandeExecutionService.endPoint
    );
  }

  websendDemandePiece(websendPieceDownloadRequest: WebsendPieceDownloadRequest): Observable<HttpResponse<any>> {
    return this.httpClient.post(
      environment.api + 'demande_comp_oe/demande_piece_get',
      websendPieceDownloadRequest,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

}
