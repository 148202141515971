import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, iif, merge, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { FindStructureService } from './find-structure.service';
import { Adresse, Commune, isStructure, Structure } from '../models/structure.model';
import * as _ from 'lodash';
import { ApplicationsFindStructure } from '../models/api/requests/find-structure.request';
import { Country } from '../models/country/countrys.model';

const MAXLENGTH = 6;
const APP_DEFAULT: ApplicationsFindStructure | undefined = undefined;

export type structure = { value: string; name: string; element: Structure, readonly origin?: 'client' | 'annuaire' };

export function isTypeStructure(o: any): o is structure {
  return (
    !!o &&
    _.isString(o.value) &&
    _.isString(o.name) &&
    isStructure(o.element)
  );
}

@Injectable({
  providedIn: 'root',
})
export class SearchStructureService implements OnDestroy {

  private typeChange = new BehaviorSubject<string>('');
  private valueChange = new BehaviorSubject<string>('');
  private applicationChange = new BehaviorSubject<ApplicationsFindStructure | undefined>(APP_DEFAULT);

  private readonly subs: Subscription[] = [];

  public MAX_LENGTH = MAXLENGTH;

  // tslint:disable-next-line: variable-name
  private _structures = merge(this.typeChange, this.valueChange, this.applicationChange).pipe(
    mergeMap(() => iif(() => this.valid, this.callWebService(this.type, this.value, this.application), of([]))),
  );

  constructor(private readonly findStructure: FindStructureService) {}

  ngOnDestroy(): void {
    this.clearParameters();
  }

  get value(): string {
    return this.valueChange.value;
  }

  set value(arg: string) {
    this.valueChange.next(arg);
  }

  get type(): string {
    return this.typeChange.value;
  }

  set type(arg: string) {
    this.typeChange.next(arg);
  }

  get application(): ApplicationsFindStructure | undefined {
    return this.applicationChange.value;
  }

  set application(arg: ApplicationsFindStructure | undefined) {
    this.applicationChange.next(arg);
  }

  get structures(): Observable<structure[]> {
    return this._structures;
  }

  get valid(): boolean {
    return this.value?.length >= this.MAX_LENGTH && !_.isEmpty(this.type);
  }

  sync(
    typeObservable: Observable<string>,
    valueObservable: Observable<string>,
    options?: {
      applicationObservable?: Observable<ApplicationsFindStructure | undefined>,
      callback?: () => any,
      pass?: (arg: { value: string, data: 'type' | 'value' }) => boolean,
    }
  ): Observable<structure[]> {
    typeObservable.pipe(
      filter(value => options?.pass ? options.pass({ value, data: 'type' }) : true),
      tap(options?.callback)
    ).subscribe(type => this.type = type);
    valueObservable.pipe(
      filter(value => options?.pass ? options.pass({ value, data: 'value' }) : true),
      tap(options?.callback)
    ).subscribe(value => this.value = value);
    options?.applicationObservable?.pipe(tap(options.callback)).subscribe(application => this.application = application);
    return this._structures;
  }

  clearParameters(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs.length = 0;
    this.value = '';
    this.type = '';
    this.application = APP_DEFAULT;
  }

  /**
   * call web service 'find_structure' and return reponse in array structure
   * @param type type structure searching
   * @param value value of structure searching
   * @returns array structure
   */
  private callWebService(type: string, value: string, application?: ApplicationsFindStructure): Observable<structure[]> {
    return this.findStructure.find(type, value, application).pipe(
      map((result) => {

        const instanceOrigine = (struc: structure, origine: string): structure => {
          Object.defineProperty(struc, 'origin', {
            get(): 'client' | 'annuaire' | undefined {
              return origine === '1' || origine === '6' ? 'client' : 'annuaire';
            }
          });
          return struc;
        };

        if (!_.isUndefined(result.annuaire) && result.annuaire.length > 0) {
          return result.annuaire.map<structure>(ann => {
            const country = new Country(ann.pays_code, ann.pays_value);
            const adress = new Adresse(ann.numero_voie, ann.type_voie, ann.nom_voie, ann.complement_adresse);
            const commune = new Commune(ann.code_postal, ann.localite);
            const element = new Structure(
              ann.id_etab_ident,
              ann.ident,
              ann.nom_etab,
              ann.code_naf,
              ann.date_crea_etab,
              ann.cedex,
              country,
              adress,
              commune,
              ann.forme_juridique,
              ann
            );
            return instanceOrigine({ value: ann.valeur, name: ann.nom_etab, element }, ann.origine);
          });
        } else if (!_.isUndefined(result.etablissement)) {
          const etab = result.etablissement;
          const country = new Country(etab.pays_implantation.code, etab.pays_implantation.value);
          const adress = new Adresse(
            etab.adresse.numero_voie,
            etab.adresse.type_voie,
            etab.adresse.nom_voie,
            etab.adresse.complement_adresse
          );
          const commune = new Commune(etab.adresse.code_postal, etab.adresse.localite);
          const element = new Structure('', etab.siret, etab.adresse.l1, etab.naf, '', etab.adresse.cedex, country, adress, commune, '');
          return [ instanceOrigine({ value: etab.siret, name: etab.adresse.l1, element }, '1') ];
        }
        return [];
      }),
      catchError(() => of([])),
    );
  }
}
