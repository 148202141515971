import * as _ from 'lodash';

type StructureObjectType<T> = { [key in keyof T]: any };
type Checker<T> = (value: any) => value is T;

export function createChecker<T extends object = any>(structure: StructureObjectType<T>): Checker<T> {
  return (o: any): o is T => {
      return _.isObjectLike(o) && Object.entries(structure).every(([key, check]) => {
          const valueO = o[key];
          if (_.isFunction(check)) return check(valueO);
          else if (_.isArray(check)) return check.some(checker => checker(valueO));
          return check === valueO;
      });
  };
}

export function isNode(value: any): value is Node {
  if (typeof Node === 'object') return value instanceof Node;
  return value
    && typeof value === 'object'
    && typeof value.nodeType === 'number'
    && typeof value.nodeName === 'string';
}

export function isHTMLElement(value: any): value is HTMLElement {
  if (typeof HTMLElement === 'object') return value instanceof HTMLElement;
  return value
    && typeof value === 'object'
    && value !== null
    && value.nodeType === 1
    && typeof value.nodeName === 'string';
}
