<div class="content">
  <app-marche-container [context]="'attri'">

    <div header>
      <h1>
        {{ avis.objet_dce | uppercase }}
      </h1>
    </div>

    <div body class="detail" fxLayout="column">
      <h2 *ngIf="avis.description_avis" class="deal-description">{{ avis.description_avis }}</h2>
      <div fxLayout="column" class="table">

        <div class="table-content">
          <span class="label">{{"APP.MARCHES_SECURISES.AWARD_CONTRACT.IDENTIFIANT_DCE" | translate }}</span>
          <span class="value">{{ avis.identifiant_dce }}</span>
        </div>
        <div class="table-content">
          <span class="label">
            {{"APP.MARCHES_SECURISES.AWARD_CONTRACT.TYPE_MARCHE" | translate }} /
            {{"APP.MARCHES_SECURISES.AWARD_CONTRACT.TYPE_PRESTATION" | translate }}</span>
          <span class="value">{{ avis.type_marche }} / {{ avis.type_prestation }}</span>
        </div>
        <div class="table-content">
          <span class="label">{{ "APP.MARCHES_SECURISES.AWARD_CONTRACT.LIBELLE_PASSATION" | translate }}</span>
          <span class="value">{{avis.libelle_passation}}</span>
        </div>
        <div class="table-content" *ngIf="avis.nb_lots">
          <span class="label">{{"APP.MARCHES_SECURISES.AWARD_CONTRACT.NB_LOTS" | translate }}</span>
          <span class="value">{{ avis.nb_lots }}</span>
        </div>
        <div class="table-content">
          <span class="label">{{"APP.MARCHES_SECURISES.AWARD_CONTRACT.DEPARTEMENTS_PRESTATION" | translate }}</span>
          <span class="value">{{ avis.departements_prestation }}</span>
        </div>
        <div class="table-content">
          <span class="label">{{"APP.MARCHES_SECURISES.AWARD_CONTRACT.FICHIER_AVIS" | translate }}</span>
          <span class="value">{{ avis.fichier_avis }}</span>
        </div>
        <div class="table-content">
          <span class="label">{{ "APP.MARCHES_SECURISES.AWARD_CONTRACT.DATE.DATE_DEBUT_F" | translate }}</span>
          <span class="value">{{ avis.date_debut_f }}</span>
        </div>
      </div>
    </div>

    <div footer class="card-footer" fxLayout="row" fxLayoutAlign="start center">
      <app-button [icon]="Icons.PDF_TO_FILL" [size]="'medium'"
        [title]="'APP.MARCHES_SECURISES.AWARD_CONTRACT.DOWNLOAD.PDF' | translate " (click)="goTo()"
        [disabled]="false">
      </app-button>
    </div>

  </app-marche-container>

</div>
