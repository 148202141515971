<div class="container-services-menu-hamburger" [class.open]="isOpenHamburger" [ngClass.lt-md]="{'mobile': true}">
    <div class="container-nav-list">
        <div class="nav-list-services" fxLayout="row wrap" fxLayout.lt-md="column nowrap" fxLayoutGap="2vw">
            <div *ngFor="let service of listMenuItems" class="container-btn-service-link">
                <a [routerLink]="service.route"
                    class="btn-service-link"
                    routeLinkActive="active"
                    fxLayout="row nowrap"
                    fxLayoutAlign="center center"
                    fxLayoutGap=".5vw" 
                    (click)="closeMenu()">
                    <div class="container-label-service">
                        <span [ngClass]="service.value">{{ 'APP.HOME.NAVIGATION.' + (service.value | uppercase) | translate | uppercase }}</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
  