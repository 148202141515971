<div class="content" fxLayoutAlign="center">
  <div class="marche">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 #title class="title">
        {{ de.objet_marche | uppercase }}
      </h2>
    </div>

    <hr class="seperator" />

    <div fxLayout="column" class="details">
      <h4 class="subtitle">
        {{ de.nom_pa }}
      </h4>
      <div fxLayout="row wrap">
        <div class="description" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" *ngIf="de.ref_fonct_aife">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.REFERENCE" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.ref_fonct_aife }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.NUIMP" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.niump | uppercase }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description" *ngIf="de.nom_pa">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.NOM_PA" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.nom_pa }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.MARKET_TYPE" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.nature_marche }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description" *ngIf="de.siret_pa">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.SIRET_PA" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.siret_pa }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description" >
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.PRESTATION" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.prestation }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.PROCEDURE" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.procedure }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.PLACE" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.nom_lieux }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.CPV_CODE" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.code_cpv }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.DURATION" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.duree }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.PLACE_CODE" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.code_lieux }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description" *ngIf="de.lieux">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.PLACE_NAME" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.lieux }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.PRICE" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.forme_prix }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.AMOUNT" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.mtt_estime }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.DATE.PUBLICATION"
            | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.date_publi_donnee }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.DATE.NOTIFICATION"
            | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.date_notif }}</span>
        </div>

        <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description">
          <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
            "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.LOTS" | translate
            }}</span>
          <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.num_lot }}</span>
        </div>

        <div fxFlex="row" fxFlex="100">
          <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description" *ngIf="de.titulaireSiret">
            <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
              "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.TITULAIRE_SIRET"
              | translate
              }}</span>
            <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">{{ de.titulaireSiret }}</span>
          </div>

          <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxFlex.lt-md="100" fxFlex.gt-sm="50" class="description" *ngIf="de.titulaireName">
            <span class="label" fxFlex.lt-md="100" fxFlex.gt-sm="40">{{
              "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.TITULAIRE_NANE"
              | translate
              }}</span>
            <span class="value" fxFlex.lt-md="100" fxFlex.gt-sm="60">
              <a atlineLink [href]="annuaireLink" target="_blank" rel="noopener noreferrer" class="link">{{
                de.titulaireName }}</a></span>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!plateformPES && !hideActions">
      <hr />
      <div class="actions" fxLayout="row" fxLayoutGap="10px">
        <app-button [icon]="Icons.Attributaires" [size]="'medium'" [title]="
            'APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.ATTRIBUTAIRE'
              | translate
          " [disabled]="!hasTitulaires" (click)="goTo(actions.ATTRIBUTAIRE)"></app-button>

        <app-button [icon]="Icons.PDF" [size]="'medium'" [title]="
            'APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.DOWNLOAD.PDF'
              | translate
          " (click)="goTo(actions.PDF)" [disabled]="true"></app-button>
        <app-button [icon]="Icons.AnyFile" [size]="'medium'" [title]="
            'APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.DOWNLOAD.JSON'
              | translate
          " (click)="goTo(actions.JSON)" [disabled]="true"></app-button>
      </div>
    </ng-container>
  </div>

  <div class="error" *ngIf="error">
    {{ "APP.MARCHES_SECURISES.ESSENTIALS_DATA.ITEM.NOT_FOUND" | translate }}
  </div>
</div>