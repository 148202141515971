export class PaReponse {

    // tslint:disable:variable-name

    pas = [
        {
          cle: '',
          denomination: '',
          identifiant: ''
        }
      ];

      nb_total = 0;

    // tslint:enable:variable-name

}
