import { NgModule } from '@angular/core';
import { LocaleMomentPipe } from './locale-moment.pipe';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import {
  AddPipe,
  CalendarPipe,
  DateFormatPipe,
  DifferencePipe,
  DurationPipe,
  FromUnixPipe,
  FromUtcPipe,
  IsAfterPipe,
  IsBeforePipe,
  LocalePipe,
  LocalTimePipe,
  MomentModule,
  ParsePipe,
  ParseZonePipe,
  SubtractPipe,
  TimeAgoPipe,
  UtcPipe,
} from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from './capitalize.pipe';
import { CamelcasePipe } from './camelcase.pipe';
import { KebabcasePipe } from './kebabcase.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { ReplacePipe } from './replace.pipe';
import { ReplaceAllPipe } from './replace-all.pipe';
import { SortByPipe } from './sort-by.pipe';
import { OrderByPipe } from './order-by.pipe';
import { StartcasePipe } from './startcase.pipe';

@NgModule({
  declarations: [
    CapitalizePipe,
    LocaleMomentPipe,
    CamelcasePipe,
    KebabcasePipe,
    IsEmptyPipe,
    ReplacePipe,
    ReplaceAllPipe,
    SortByPipe,
    OrderByPipe,
    StartcasePipe,
  ],
  exports: [
    CapitalizePipe,
    LocaleMomentPipe,
    CamelcasePipe,
    KebabcasePipe,
    IsEmptyPipe,
    MomentModule,
    ReplacePipe,
    ReplaceAllPipe,
    SortByPipe,
    OrderByPipe,
    StartcasePipe,
  ],
  imports: [CommonModule, NgxTranslateModule, MomentModule],
  providers: [
    TimeAgoPipe,
    CalendarPipe,
    DateFormatPipe,
    ParsePipe,
    LocalePipe,
    LocalTimePipe,
    FromUnixPipe,
    DurationPipe,
    DifferencePipe,
    AddPipe,
    SubtractPipe,
    FromUtcPipe,
    UtcPipe,
    ParseZonePipe,
    IsBeforePipe,
    IsAfterPipe,
  ],
})
export class PipeModule {}
