import { Component, OnInit } from '@angular/core';
import { ShareDeData } from '../../core/services/share-de-data.service';


@Component({
  selector: 'app-donnes-essencielles',
  templateUrl: './donnes-essencielles.component.html',
  styleUrls: ['./donnes-essencielles.component.scss'],
})
export class DonnesEssenciellesComponent implements OnInit {

  constructor(
    private readonly shareDeData: ShareDeData
  ) { }

  ngOnInit(): void {}

  get nbEssentialData(): number {
    return this.shareDeData.numberEssentialData;
  }

}
