import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/core/services/scroll.service';

@Component({
  selector: 'app-section-engagement-rse',
  templateUrl: './section-engagement-rse.component.html',
  styleUrls: ['./section-engagement-rse.component.scss']
})
export class SectionEngagementRseComponent implements OnInit {

  constructor(private readonly scroll: ScrollService) { }

  ngOnInit(): void {
  }

  goTop(): void {
    this.scroll.windowsScrollTo({top: 0, smooth: true});
  }
}
