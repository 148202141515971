import { Component, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  @Output()
  submit = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
