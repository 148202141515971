<app-dialog [dialogRef]="dialogRef">
  <div header class="header full-width" fxLayout="row" fxLayoutAlign="start start">
    <div class="title text-bl" mat-dialog-title>
      {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.HEADER' | translate | uppercase}}
    </div>
  </div>
  <div body mat-dialog-content class="mat-content full-width" fxLayout="column">
    <div class="confirmation-recap">
      <div class="cons-id conf-elt text-bl">
        {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.CONS_ID' | translate:{consultationId: data.consId} }}
      </div>

      <div class="cons-id conf-elt"
        [innerHTML]="'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OBJECT' | translate:{object: data.object}">

      </div>

      <div class="cons-id conf-elt"
        [innerHTML]="'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.PROCEDURE' | translate:{procedure: data.prodedure}">
      </div>

      <div class="cons-id conf-elt"
        [innerHTML]="'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.PUBLICATION_DATE' | translate: { date: datePublicationWithoutHours}">
      </div>

      <div class="cons-id conf-elt"
        [innerHTML]="'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.CLOSURE_DATE' | translate: { date: data.closureDate }">
      </div>
    </div>

    <div class="inform-oe" *ngIf="data.alreadyPublished">
      <form [formGroup]="form">
        <div class="checkbox-oe">
          <mat-checkbox color="primary" formControlName="informOe">
            {{
              'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.INFORM_OE' | translate: { nbOe: data.nbDceRetrieved }
            }}
          </mat-checkbox>
        </div>

        <mat-divider class="mat-divider-h" horizontal *ngIf="informOe.value"></mat-divider>

        <div class="form-oe" *ngIf="informOe.value">
          <div class="title" fxLayout="column">
            <div>
              {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.TITLE' | translate}}
            </div>
            <div>
              {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.NB_OE' | translate:{nbOe:nbRetrait} }}
            </div>
          </div>

          <div class="form">

            <div class="inputs-oe-form" fxLayout="row">
              <div class="label" fxFlex="10">
                {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.SUBJECT' | translate}}
              </div>

              <div class="input" fxFlex="70">
                <mat-form-field appearance="outline" class="full-width">
                  <input matInput formControlName="subject">
                </mat-form-field>
              </div>

            </div>

            <div class="inputs-oe-form" fxLayout="row">
              <div class="label" fxFlex="10">
                {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.SENDER' | translate}}
              </div>

              <div class="input" fxFlex="70">
                {{data.reciever}}
              </div>

            </div>

            <div class="inputs-oe-form" fxLayout="row">
              <div class="label" fxFlex="10">
                {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.MESSAGE' | translate}}
              </div>

              <div class="input" fxFlex="70">
                <mat-form-field appearance="outline" class="mat-ang-textarea full-width textarea">
                  <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="10"
                    cdkAutosizeMaxRows="40" formControlName="emailContent"></textarea>
                </mat-form-field>
              </div>

            </div>

            <div class="inputs-oe-form" fxLayout="row">
              <div class="label" fxFlex="10"></div>

              <div class="input" fxFlex="70">
                <mat-checkbox color="primary" formControlName="shareOnQR">
                  {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.ADD_TO_QR' | translate}}
                </mat-checkbox>
              </div>

            </div>

          </div>
        </div>

        <div class="info-oe-sent">
          {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.INFO1' | translate}}
          {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.OE.INFO2' | translate}}
        </div>
      </form>
    </div>
  </div>
  <div footer class="footer" mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button color="primary" (click)="confirm()">
      {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.BUTTON.CONFIRMATION' | translate}}
    </button>
    <button mat-stroked-button color="warn" (click)="close()">
      {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.BUTTON.CANCEL' | translate}}
    </button>
    <button mat-stroked-button cdkFocusInitial (click)="goToList()">
      {{'APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.BUTTON.BACK' | translate}}
    </button>
  </div>
</app-dialog>
