import { UtilsService } from '@atline/core/services/utils.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent {

  displayBanner : boolean = false;
  
  constructor(public utilsService : UtilsService) { }

  setCookies(response : string): void {
    this.utilsService.setLocalStorage('cookies', response);
    this.displayBanner = true;
  }
}
