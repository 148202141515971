import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CustomOptionComponent } from './custom-option/custom-option.component';
import { Option } from './custom-select.models';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true,
    }
  ],
  animations: [
    trigger('optionsBlock', [
      state('open', style({
        height: '100px',
        'overflow-y': 'auto',
        opacity: '1',
      })),
      state('close', style({
        height: '0px',
        'overflow-y': 'hidden',
        opacity: '0',
      })),
      transition('open => close', animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
      transition('close => open', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ])
  ]
})
export class CustomSelectComponent implements OnInit, ControlValueAccessor, AfterContentInit, OnDestroy, AfterViewInit {

  @ContentChildren(CustomOptionComponent)
  customOptions!: QueryList<CustomOptionComponent>;

  @ViewChild('selectHTML', { static: false })
  selectHTML?: ElementRef<HTMLElement>;

  @ViewChild('options')
  htmlOptions!: ElementRef<any>;

  // tslint:disable:variable-name
  private _value: any = '';
  private _text = '';

  @Input()
  required = false;

  @Output()
  selectChange = new EventEmitter<string>();

  public touched = false;

  open = false;

  readonly listOption: CustomOptionComponent[] = [];

  private subsOption: Subscription[] = [];

  public onChange: (arg: any) => any = () => { /**/ };
  public onTouched: (arg?: any) => any = () => { /**/ };

  constructor(private readonly ref: ElementRef) { }

  ngOnInit(): void { }

  ngAfterContentInit(): void {
    this.customOptions.forEach(customOption => {
      this.listOption.push(customOption);
      this.subsOption.push(customOption.selectOption.subscribe((option: Option) => {
        this.value = option.value;
        this.customOptions.forEach(custOption => custOption.selected = false);
        customOption.selected = true;
      }));
    });
    const timeOut = setTimeout(() => {
      this._text = this.getText();
      clearTimeout(timeOut);
    });
  }

  ngAfterViewInit(): void { /*This normal */ }

  ngOnDestroy(): void {
    this.subsOption.forEach(sub => sub.unsubscribe());
  }

  get value(): any {
    return this._value;
  }

  @Input()
  set value(val: any) {
    if (val) {
      this._value = val;
    }
    else {
      this._value = '';
    }
    this.onChange(this._value);
    this.selectChange.emit(this._value);
    this._text = this.getText();
  }

  get text(): string {
    return this._text;
  }

  get spaceBar(): boolean {
    return !!document.querySelector('.container-icon > *');
  }

  private getText(): string {
    for (const option of this.listOption) {
      if (option.value === this._value) {
        option.selected = true;
        return option.text;
      }
    }
    return '';
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.value = '';
      return;
    }

    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    const self = this;
    this.onTouched = (arg: any): void => {
      self.touched = true;
      fn(arg);
    };
  }

  openCloseOptions(): void {
    const indexTimeOut = setTimeout(() => {
      this.open = !this.open;
      const select = this.selectHTML?.nativeElement;
      if (!_.isUndefined(select)) {
        if (this.open) { select.focus(); }
        else { select.blur(); }
      }
      clearTimeout(indexTimeOut);
    }, 100);
  }

  optionSelect(option: Option): void {
    this.value = option.value;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: MouseEvent): void {
    if (this.open && !this.ref.nativeElement.contains(event.target)) {
      this.openCloseOptions();
    }
  }

  clearClassOptions(): void {
    document.querySelectorAll('option').forEach(opt => opt.classList.remove('selected'));
  }
}
