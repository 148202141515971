import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionTitleComponent } from './section-title.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    SectionTitleComponent
  ],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule
  ],
  exports: [
    SectionTitleComponent
  ]
})
export class SectionTitleModule { }
