import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { UserType } from '../../shared/enums/api.enum';
import { NameValueItem } from '../../core/models/special-types.model';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogsService } from 'src/app/core/services/dialogs.service';
import { CreateAccountService } from 'src/app/core/services/create-account.service';
import { Account } from 'src/app/core/models/api/requests/create-account.request';
import { catchError, debounceTime, map, mergeMap, tap } from 'rxjs/operators';
import { ApiErrorResponse } from 'src/app/core/models/api/responses/api.response';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { ResultFindStructureComponent } from './result-find-structure/result-find-structure.component';
import {
  containsType,
  typesStructure,
} from 'src/app/core/models/typesStructure.model';
import { SearchStructureService } from 'src/app/core/services/search-structure.service';
import { Structure } from 'src/app/core/models/structure.model';

export enum SignupSteps {
  step1 = 'login',
  step2 = 'contact',
  step3 = 'services',
}

enum Part {
  createAccount,
  resultFindStructure,
  registrationForm,
}

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  @ViewChild(ResultFindStructureComponent)
  resultFindStructure!: ResultFindStructureComponent;

  @ViewChild(MatHorizontalStepper)
  stepper!: MatHorizontalStepper;

  part = Part.createAccount;
  parts = Part;

  public signupSteps = SignupSteps;
  public newUserType = UserType;
  public selectedStep = SignupSteps.step1;
  public signUpForm!: FormGroup;

  hide = true;
  hideConfirm = true;
  recaptha = false;
  checkboxPosition: 'before' | 'after' = 'before';

  readonly passwordFC = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
    ),
  ]);
  readonly usernameFC = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(255),
  ]);
  readonly emailFC = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.maxLength(54),
  ]);
  readonly confirmPasswordFC = new FormControl('', [
    Validators.required,
    CustomValidators.equalTo(this.passwordFC),
  ]);

  typesStructure = typesStructure;
  private structureTypesDefault = typesStructure[0];
  userTypes = Object.values(UserType);

  readonly userTypeFC = new FormControl('', [Validators.required]);
  readonly structureTypeFC = new FormControl(this.structureTypesDefault.value, [
    Validators.required,
  ]);
  private typeStructureChange!: Subscription;
  readonly structureFC = new FormControl(
    '',
    this.structureTypesDefault.pattern
      ? [
        Validators.required,
        Validators.pattern(this.structureTypesDefault.pattern),
      ]
      : [Validators.required]
  );

  readonly servicesFC = new FormControl([], [Validators.required]);
  filteredStructures!: Observable<
    { value: string; name: string; element: Structure }[]
  >;

  readonly showLoaderStructure = new BehaviorSubject(false);
  noResult = false;
  structureSelect: Structure | undefined;

  /**
   * List of most valued services
   */
  servicesList: { nameValueItem: NameValueItem; selected: boolean }[] = [
    {
      nameValueItem: { name: 'Atline Tender', value: 'tender' },
      selected: true,
    },
    {
      nameValueItem: { name: 'Atline training', value: 'training' },
      selected: false,
    },
    {
      nameValueItem: { name: 'Atline vault', value: 'secure' },
      selected: false,
    },
    {
      nameValueItem: { name: 'Atline sign off', value: 'sign' },
      selected: false,
    },
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly serviceDialog: DialogsService,
    private readonly creaAccountService: CreateAccountService,
    private readonly searchStructures: SearchStructureService,
    public singUpDialogRef: MatDialogRef<CreateAccountComponent>
  ) { }

  ngOnInit(): void {
    this.searchStructures.type = this.structureTypesDefault.value;

    this.signUpForm = this.formBuilder.group({
      password: this.passwordFC,
      username: this.usernameFC,
      email: this.emailFC,
      confirmPassword: this.confirmPasswordFC,
      recaptcha: this.recaptha,
      structureType: this.structureTypeFC,
      structure: this.structureFC,
      userType: this.userTypeFC,
      services: this.servicesFC,
    });

    this.filteredStructures = this.structureFC.valueChanges.pipe(
      tap((_) => {
        this.showLoaderStructure.next(true);
        this.noResult = false;
      }),
      debounceTime(500),
      mergeMap((value) => {
        this.searchStructures.value = value;
        return this.searchStructures.structures;
      }),
      catchError((err) => {
        this.showLoaderStructure.next(false);
        return throwError(err);
      }),
      map((structures) => structures.splice(0, 5)),
      tap((result) => {
        this.showLoaderStructure.next(false);
        this.noResult = !result.length && this.structureFC.valid;
      })
    );

    this.typeStructureChange = this.structureTypeFC.valueChanges.subscribe(
      (value) => {
        const type = containsType(value);
        if (type) {
          this.structureFC.setValidators(
            type.pattern
              ? [Validators.required, Validators.pattern(type.pattern)]
              : [Validators.required]
          );
          this.structureFC.setValue(this.structureFC.value);
          this.searchStructures.type = value;
        } else {
          this.structureFC.setValidators([]);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.typeStructureChange.unsubscribe();
  }

  /**
   * Permet de blocker les copie, coller et couper sur les champs 'password' et 'confirmPassword'
   * @param e evenement clavier
   */
  @HostListener('paste', ['$event'])
  @HostListener('copy', ['$event'])
  @HostListener('cut', ['$event'])
  cancelCopyPasteCut(e: KeyboardEvent): void {
    const target = e.target as HTMLElement;
    if (
      target.tagName.toLocaleLowerCase() === 'input' &&
      ((target as any).name === 'password' ||
        (target as any).name === 'confirmPassword')
    ) {
      e.preventDefault();
    }
  }

  onSubmit(): void {
    if (this.signUpForm.valid) {
      // TODO submit the form here
      const loaderRef: MatDialogRef<LoaderComponent> =
        this.serviceDialog.openLoader('APP.ACTION.LOADING');

      const newAccount = new Account();
      newAccount.email = this.emailFC.value;
      newAccount.password = this.passwordFC.value;
      newAccount.username = this.usernameFC.value;
      newAccount.role = this.userTypeFC.value;
      newAccount.structure_type = this.structureTypeFC.value;
      newAccount.structure_value = this.structureFC.value;
      newAccount.apps = this.servicesFC.value;
      this.creaAccountService
        .create(newAccount)
        .pipe(
          catchError((err) => {
            loaderRef.close();
            const apiError = err.error as ApiErrorResponse;
            const errorMsg = apiError
              ? apiError.error_description
              : err.errorMessage;
            this.serviceDialog.createInfoDialog(
              'APP.SIGNUP.ERROR.FAILED',
              errorMsg,
              'OK'
            );
            return throwError(err);
          })
        )
        .subscribe((result) => {
          loaderRef.close();
          if (result) {
            this.serviceDialog.createInfoDialog(
              'APP.SIGNUP.SUCCEES.TITLE',
              'APP.SIGNUP.SUCCEES.MSG',
              'OK'
            );
            this.singUpDialogRef.close();
          } else {
            this.serviceDialog.createInfoDialog(
              'APP.SIGNUP.SUCCEES',
              'APP.SIGNUP.ERROR.DEFAULT',
              'OK'
            );
          }
        });
    }
  }

  /**
   * Verify if all input form of step 1 is completed
   * @returns boolean
   */
  step1Completed(): boolean {
    return (
      this.usernameFC.valid &&
      this.emailFC.valid &&
      this.passwordFC.valid &&
      this.confirmPasswordFC.valid
    );
  }

  /**
   * Verify if all input form of step 2 is completed
   * @returns boolean
   */
  step2Completed(): boolean {
    return (
      this.userTypeFC.valid &&
      this.structureTypeFC.valid &&
      this.structureFC.valid
    );
  }

  step3Completed(): boolean {
    return this.servicesFC.valid;
  }

  /**
   * Change the step by the step selected
   * @param event stepper selected
   */
  stepsSelectionChange(event: StepperSelectionEvent): void {
    this.selectedStep = event.selectedStep.label as SignupSteps;
    if (event.previouslySelectedStep.label === SignupSteps.step2) {
      this.userTypeFC.markAsTouched();
    }
  }

  /**
   * Mathod verify if the formControl is required.
   * @param fc formControl if he is required
   * @returns true if required. false if not required.
   */
  required(fc: FormControl): boolean {
    if (fc && fc.validator) {
      const val = fc.validator({} as AbstractControl);
      return !!(val?.required);
    }
    return false;
  }

  /**
   * change the step by the previous step
   * @param stepper stepper of UI Framework 'Angular Material'
   */
  goBack(): void {
    if (this.part === Part.createAccount) {
      this.stepper.previous();
    } else {
      this.part = Part.createAccount;
      this.structureSelect = undefined;
    }
  }

  /**
   * change he step (1 & 2) by the next step if all input form is completed
   */
  goNext(): void {
    let next = false;
    switch (this.selectedStep) {
      case this.signupSteps.step1:
        next = this.step1Completed();
        break;
      case this.signupSteps.step2:
        next = this.step2Completed();
        break;
    }
    if (next) {
      this.stepper.next();
    }
  }

  /**
   * change state 'selected' of a service enter in parameter
   */
  serviceClicked(service: {
    nameValueItem: NameValueItem;
    selected: boolean;
  }): void {
    service.selected = !service.selected;
  }

  selectStructure(structure: Structure): void {
    this.structureSelect = structure;
    this.part = Part.resultFindStructure;
  }

  validSearch(arg: string): void {
    this.part = Part.createAccount;
    if (!arg.length) {
      this.structureSelect = undefined;
    }
    this.goNext();
  }
}
