import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoonAvalaibleComponent } from './soon-avalaible.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/core/material/material.module';
import { BlockStatusModule } from '../block-status/block-status.module';



@NgModule({
  declarations: [SoonAvalaibleComponent],
  exports: [SoonAvalaibleComponent],
  imports: [
    CommonModule,
    NgxTranslateModule,
    FlexLayoutModule,
    MaterialModule,
    BlockStatusModule
  ],
})
export class SoonAvalaibleModule { }
