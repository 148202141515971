import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, from } from 'rxjs';
import { UtilsService } from './utils.service';
import { ConsultationRequest } from '../models/api/requests/consultation.request';
import { find, map, mergeMap } from 'rxjs/operators';
import { SessionStorage } from 'src/app/shared/enums/session-storage.enum';
import { LotItemCreation } from '../models/lotItemCreation.model';
import { CartoucheData } from '../models/cartouche.model';
import { ROLE, ROLE_OE, ROLE_PA } from '../models/constant-variable/role.model';
import { PaToken, ProfileReponse } from '../models/api/responses/authenticate.response';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  private tokenBS = new BehaviorSubject('');
  token = this.tokenBS.asObservable();

  private isLoggedBS = new BehaviorSubject<boolean>(this.utilService.isLoggedWithAuthorizationCode);
  isLogged = this.isLoggedBS.asObservable();

  private formSeachBS = new BehaviorSubject<ConsultationRequest>(new ConsultationRequest());
  formSeach = this.formSeachBS.asObservable();

  private numberOfLotBS = new BehaviorSubject<number>(0);
  numberOfLot = this.numberOfLotBS.asObservable();

  private isInitializedBS = new BehaviorSubject<boolean>(false);
  isInitialized = this.isInitializedBS.asObservable();

  private lotBS = new BehaviorSubject<Array<LotItemCreation>>([]);
  lot = this.lotBS.asObservable();

  private deleteLotBS = new BehaviorSubject<string>('');
  deleteLot = this.deleteLotBS.asObservable();

  private memorizedDateBs = new BehaviorSubject<string>('');
  memorizedDate = this.memorizedDateBs.asObservable();

  private takeDefaultValueResponseBS = new BehaviorSubject<boolean>(false);
  takeDefaultValueResponse = this.takeDefaultValueResponseBS.asObservable();

  private saveConsTriggerBs = new Subject<boolean>();
  saveConsTrigger = this.saveConsTriggerBs.asObservable();

  private attributaireParentIdBs = new BehaviorSubject<string>('0');
  attributaireParentId = this.attributaireParentIdBs.asObservable();

  private announceCartoucheDataBs = new BehaviorSubject<CartoucheData>({});
  announceCartoucheData = this.announceCartoucheDataBs.asObservable();

  private formSealKeyBS = new BehaviorSubject<string>('');
  formSealKey = this.formSealKeyBS.asObservable();

  private sealKeyBS = new BehaviorSubject<string>('');
  sealKey = this.sealKeyBS.asObservable();


  private procedureValueResponseBS = new BehaviorSubject<string>('');
  procedureValueResponse = this.procedureValueResponseBS.asObservable();

  constructor(private readonly utilService: UtilsService) { }

  /**
   * save token on an observble
   * @param t token
   */
  setToken(t: string): void {
    this.tokenBS.next(t);
  }

  /**
   * get the save token as an observable<string>
   * @returns observable with token
   */
  getToken(): Observable<string> {
    return this.token;
  }

  /**
   * save token on an observble
   * @param t token
   */
  setLotsArr(l: Array<LotItemCreation>): void {
    this.lotBS.next(l);
  }

  /**
   * get the save token as an observable<string>
   * @returns observable with token
   */
  getLotsArr(): Observable<Array<LotItemCreation>> {
    return this.lot;
  }


  /**
   * save if user is logged on an observble
   * @param b boolean
   */
  setIsLogged(b: boolean): void {
    this.isLoggedBS.next(b);
  }

  /**
   * get the save token as an observable<string>
   * @returns observable with token
   */
  getNumberOfLot(): Observable<number> {
    return this.numberOfLot;
  }

  /**
   * save if user is logged on an observble
   * @param b boolean
   */
  setNumberOfLot(n: number): void {
    this.numberOfLotBS.next(n);
  }

  /**
   * get the save delete lot as an observable<boolean>
   * @returns observable with delete lot
   */
  getDeleteLot(): Observable<string> {
    return this.deleteLot;
  }

  /**
   * save delete lot
   * @param passation string
   */
  setDeleteLot(passation: string): void {
    this.deleteLotBS.next(passation);
  }


  getTakeDefaultValueResponse(): Observable<boolean> {
    return this.takeDefaultValueResponse;
  }

  setTakeDefaultValueResponse(b: boolean): void {
    this.takeDefaultValueResponseBS.next(b);
  }

  getProcedureValueResponse(): Observable<string> {
    return this.procedureValueResponse;
  }

  setProcedureValueResponse(b: string): void {
    this.procedureValueResponseBS.next(b);
  }

  getIsInitialized(): Observable<boolean> {
    return this.isInitialized;
  }

  setIsInitialized(b: boolean): void {
    this.isInitializedBS.next(b);
  }

  /**
   * get the save token as an observable<string>
   * @returns observable with boolean
   */
  getIsLogged(): Observable<boolean> {
    if (this.utilService.isLoggedWithAuthorizationCode) {
      return of(true);
    } else {
      return this.isLogged;
    }
  }

  /**
   * get form seach data
   * @returns observable with form data
   */
  getformData(): Observable<ConsultationRequest> {
    return this.formSeach;
  }

  /**
   *
   * @param f consultation form data
   */
  setFormData(f: ConsultationRequest): void {
    this.formSeachBS.next(f);
  }

  getMemorizedDate(): Observable<string> {
    return this.memorizedDate;
  }

  setMemorizedDate(date: string): void {
    this.memorizedDateBs.next(date);
  }

  saveConsultationTrigger(): Observable<boolean> {
    return this.saveConsTrigger;
  }

  setSaveConsultationTrigger(b: boolean): void {
    this.saveConsTriggerBs.next(b);
  }

  /**
   * get the attributaire parrent id
   * @returns observable with parent id
   */
  getAttributaireParentId(): Observable<string> {
    return this.attributaireParentId;
  }

  /**
   * save if user is logged on an observble
   * @param b boolean
   */
  setAttributaireParentId(n: string): void {
    this.attributaireParentIdBs.next(n);
  }


  /**
   * get data for announce cartouche (data from announce edit)
   * @returns observable
   */
  getAnnounceDataForcartouche(): Observable<CartoucheData> {
    return this.announceCartoucheData;
  }

  /**
   * set cartouche data
   * @param d data
   */
  setAnnounceDataForcartouche(d: CartoucheData): void {
    this.announceCartoucheDataBs.next(d);
  }



  getFormSealKey(): Observable<string> {
    return this.formSealKey;
  }

  setFormSealKey(s: string): void {
    this.formSealKeyBS.next(s);
  }


  getSealKey(): Observable<string> {
    return this.sealKey;
  }

  setSealKey(s: string): void {
    this.sealKeyBS.next(s);
  }

  getRole(): Observable<ROLE | undefined> {
    return this.isLogged.pipe(map(logged => logged ? this.utilService.getRole() : undefined));
  }

  get isPa(): Observable<boolean> {
    return this.getRole().pipe(map(role => role === ROLE_PA));
  }

  get listPa(): Observable<PaToken[]> {
    return this.isPa.pipe(
      map(bool => {
        if (!bool) { return []; }
        const { profile } = this.utilService.getSessionStorage(SessionStorage.USER_SESSION, true);
        return (profile as ProfileReponse | undefined)?.pa_list ?? [];
      })
    );
  }

  paInList(clePa: string): Observable<PaToken | undefined> {
    return this.listPa.pipe(
      mergeMap(list => from(list)),
      find(pa => pa.cle_pa === clePa),
    );
  }

  get isOe(): Observable<boolean> {
    return this.getRole().pipe(map(role => role === ROLE_OE));
  }
}
