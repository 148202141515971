import { Observable } from 'rxjs';
import { PaReponse } from '../models/api/responses/pa.reponse';
import { PaLogoRequest, PaRequest } from './../models/api/requests/pa-request';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MethodRequest } from '@atline-shared/enums/method-request.enum';

@Injectable({
  providedIn: 'root'
})
export class PaService {

  constructor(
    private readonly apiService: ApiService,
    private readonly http: HttpClient
    ) { }

  getPAs(paRequest: PaRequest): Observable<PaReponse>{
    return this.apiService.post(PaReponse, paRequest, 'pa_list');
  }

  getLogo(req: PaLogoRequest): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}logo_pa`, req,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
}
