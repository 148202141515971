<div class="container-stepper" fxLayout="column">
    <div class="container-section">
        <section fxLayout="column" fxLayout="column">
            <header class="head-stepper" fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutGap.xs="5px">
                <span class="head-title">{{ titre }}</span>
                <div class="tab-step" fxLayout="row" fxLayoutGap="10px">
                    <button *ngFor="let step of steps; let i = index;"
                    class="btn-step"
                    [class.select]="selectedIndex === i"
                    (click)="goStep(i)"
                    #buttonStep>
                    <span>{{ i + 1 }}</span>
                    </button>
                </div>
            </header>
            <div class="stepper-body">
                <div *ngFor="let step of steps, let i = index"
                [classList]="['step', _getStepLabelId(i)]"
                [@stepTransition]="_getAnimationDirection(i)" 
                [attr.tabindex]="selectedIndex === i ? 0 : null"
                [id]="_getStepContentId(i)"
                [attr.aria-labelledby]="_getStepLabelId(i)"
                [attr.aria-expanded]="selectedIndex === i"
                >
                    <ng-container *ngIf="step === selected" [ngTemplateOutlet]="step.content"></ng-container>
                </div>
            </div>
        </section>
    </div>
    <div class="container-btn">
        <button type="button"
            id="buttonNext"
            class="btn next"
            (click)="goNext()"
            [class.invisible]="selectedIndex >= (steps.length - 1)"
            [class.cliquable]="selected.completed"
            #buttonNext
        >
            <img src="../../../../assets/icons/svg/arrow_white.svg">
        </button>
        <button type="submit"
            id="buttonSubmit"
            class="btn valid"
            [class.invisible]="selectedIndex >= 0 && selectedIndex < (steps.length - 1)"
            [class.cliquable]="selected.completed"
            #buttonSubmit
        >
            <span style="color: white;">
                {{ 'APP.CUSTOM-STEPPER.BTN-OK' | translate }}
            </span>
        </button>
    </div>
</div>
