/**
 *
 * SERVICE MENU - HAMBURGER FOR MOBILE & TABLET DEVICES
 *
 * Declaration of routes to display menu
 *
 */

export class ServiceHamburger {
  constructor(
    public route: string,
    readonly name: string,
    readonly value: string
  ) {}
}

export const menuItems: ServiceHamburger[] = [
  new ServiceHamburger('/home/ourSolutions', 'Nos solutions', 'ourSolutions'),
  new ServiceHamburger('/home/story', 'Qui sommes-nous', 'story'),
  new ServiceHamburger('/home/certificat', 'Certificat', 'certificat'),
  new ServiceHamburger('/home/faq', 'FAQ', 'faq'),
  new ServiceHamburger('/home/contactUs', 'Contactez-nous', 'contactUs'),
];
