<div>
  <div class="header" fxLayout="column nowrap" fxLayoutGap="25px">
    <img fxHide.lt-md src="/assets/images/home-landing/FAQ/hautPage.png" alt="" />
  </div>
  <div class="container">
    <div class="container-content-center">
      <div class="msg_img">
        <img src="/assets/images/home-landing/FAQ/question.png" alt="" id="ImgQuestionsLanding" />

        <div class="bvn">
          <span class="bvn_msg" [innerHTML]="'APP.HOME.LANDING.FAQ.TEXTE_BVN_FAQ_PART1' | translate"></span>
          <!-- <a (click)="onNavigate()" [innerHTML]="'APP.HOME.LANDING.FAQ.TEXTE_BVN_FAQ_CLICK' | translate"></a> -->
          <!-- </div>

        <div class="bvn"> -->
          <span class="bvn_msg" [innerHTML]="'APP.HOME.LANDING.FAQ.TEXTE_BVN_FAQ_PART1' | translate"></span>
          <span class="bvn_msg" [innerHTML]="'APP.HOME.LANDING.FAQ.TEXTE_BVN_FAQ_PART2' | translate"></span>
          <a class="clickfaqform" (click)="onNavigate()" href="/home/contactUs">
            {{ 'APP.HOME.LANDING.FAQ.TEXTE_BVN_FAQ_CLICK' | translate }}
          </a>
        </div>
      </div>

      <div class="demande-information-faq">
        <mat-accordion>
          <h1 class="margin faq-section-title" [innerHTML]="
            'APP.HOME.LANDING.FAQ.TITRE_DEMANDE_INFO' | translate | uppercase
          "></h1>

          <mat-expansion-panel #expanse1 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_1" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse1.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_1' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse2 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_2" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse2.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_2' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse3 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_3" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse3.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_3' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse4 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_4" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse4.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_4' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse5 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_5" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse5.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_5' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse6 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_6" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse6.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_6' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <h1 class="margin faq-section-title" [innerHTML]="
            'APP.HOME.LANDING.FAQ.TITRE_DEMANDE_INFO2' | translate | uppercase
          "></h1>

          <mat-expansion-panel #expanse7 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_7" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse7.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_7' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse8 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_8" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse8.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_8' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse9 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_9" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse9.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_9' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse10 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_10" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse10.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_10' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse11 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_11" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse11.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_11' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse12 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_12" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse12.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_12' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>

          <mat-expansion-panel #expanse13 hideToggle="true">
            <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
              <mat-panel-title>{{
                "APP.HOME.LANDING.FAQ.DEMANDE_INFO_13" | translate
                }}</mat-panel-title>
              <mat-icon svgIcon="chevron" class="icon" [class.rotate]="expanse13.expanded"></mat-icon>
            </mat-expansion-panel-header>
            <mat-panel-description>
              <span [innerHTML]="'APP.HOME.LANDING.FAQ.REPONSE_INFO_13' | translate"></span>
            </mat-panel-description>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <app-contact-form></app-contact-form>
    </div>
  </div>

  <app-newsletter></app-newsletter>

</div>