import { ApiErrorResponse } from './api.response';


export class QuestionsAndAnwsersResponse extends ApiErrorResponse {
  // tslint:disable:variable-name
  consultation!: QandAConsultation;
  messages!: Array<QandAMessage>;
  nb_total!: number;
}

export class QandAMessage {
  cle_mail!: string;
  sujet!: string;
  texte!: string;
  date!: string;
  expediteur_type_code!: number;
  expediteur_type_lbl!: string;
  visibilite_code!: number;
  visibilite_lbl!: string;
  avec_accuse_reception!: number;
  piece_jointe!: string;
  expediteur!: QandAExpediteur;
  destinataires!: Array<QandRDestinataire>;
  reponses!: Array<QandAResponse>;
}

export class QandAResponse {
  cle_mail!: string;
  sujet!: string;
  texte!: string;
  date!: string;
  expediteur_type_code!: number;
  expediteur_type_lbl!: string;
  visibilite_code!: number;
  visibilite_lbl!: string;
  avec_accuse_reception!: number;
  piece_jointe!: string;
  expediteur!: QandAExpediteur;
  destinataires!: Array<QandRDestinataire>;
}

class QandAExpediteur {
  nom!: string;
  prenom!: string;
  email!: string;
}

class QandAConsultation {
  cle!: string;
  identifiant!: string;
  objet!: string;
  cle_pa!: string;
}

export class QandRDestinataire {
  etat!: number;
  etat_lbl!: string;
  reception_accuse!: number;
  date_reception_accuse!: number;
  date_reception_accuse_f!: string;
  email!: string;
  nom!: string;
  alias!: string;
  entreprise!: string;
  email_origine!: string;
  email_origine_lbl!: string;
}

    // tslint:enable:variable-name
