import { DefaultResponse } from '../default.response';
import { DemandeCompItem } from '../../../dceDemCompItem.model';

export class GetDemandeComplementaireResponse {

    // tslint:disable:variable-name

    public is_user_certified?: boolean;

    public certification_date_accept?: string;

    public dce_dem_comp: DemandeCompItem[] = [new DemandeCompItem()];

    public nb_total = 0;

    // tslint:enable:variable-name

}

export class DeleteDemandeCompResponse extends DefaultResponse { }
