import { Component, OnInit } from '@angular/core';
import { UtilsService } from './core/services/utils.service';
import { SessionStorage } from './shared/enums/session-storage.enum';
import { IconService } from './core/services/icon.service';
import { AuthenticateService } from './core/services/authenticate.service';
import { GlobalDataService } from './core/services/data-service.service';
import { TokenService } from './core/services/token.service';
import * as _ from 'lodash';
import { forkJoin, of, iif } from 'rxjs';
import { map, mergeMap, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isInitialized = false;
  // private interval!: any;

  // clear localstorage and delete de token when ATS is closing

  constructor(
    private readonly utilsService: UtilsService,
    private readonly authService: AuthenticateService,
    private readonly iconService: IconService,
    private readonly globalDataService: GlobalDataService,
    private readonly tokenService: TokenService,
  ) {
    globalDataService.setIsInitialized(false);
    globalDataService.getIsInitialized().subscribe(res => this.isInitialized = res);
    this.initializeApp();
  }

  initializeApp(): void {
    this.iconService.registerIcons();
    this.initializeToken();
  }

  private initializeToken(): void {
    forkJoin({
      token: of(this.utilsService.token),
      session: of(this.utilsService.getSessionStorage(SessionStorage.USER_SESSION)),
    }).pipe(
      mergeMap(({ token, session }) => iif(
        () => !!token && _.isNil(session),
        this.authService.getTokenInfo(token as string).pipe(
          tap(res => {
            this.authService.setSessionData(res);
            this.utilsService.clearLocalStrorage();
            this.authService.setLocalData(res);
            this.globalDataService.setToken(res.access_token);
            this.globalDataService.setIsLogged(true);
            this.globalDataService.setIsInitialized(true);
          }),
          map(res => ({ token, session: res })),
          catchError(() => of({ token: undefined, session: undefined }))
        ),
        of({ token, session })
      )),
      mergeMap(({ token, session }) => iif(
        () => _.isNil(token) && _.isNil(session),
        this.authService.generateTokenForClient().pipe(tap(res => {
          this.authService.setSessionData(res);
          this.authService.setLocalData(res);
          this.globalDataService.setToken(res.access_token);
          this.globalDataService.setIsInitialized(true);
        }), map(res => ({ token: res.access_token, session: res }))),
        of({ token, session })
      )),
      tap(({ token, session }) => {
        if (_.isNil(token) && session) {
          this.utilsService.setLocalStorage('session', JSON.stringify({ t: session.access_token }));
          if (this.utilsService.isLoggedWithAuthorizationCode) {
            this.globalDataService.setIsLogged(true);
          }
          this.authService.setLocalData(this.utilsService.getSessionStorage(SessionStorage.USER_SESSION, true));
          this.globalDataService.setIsInitialized(true);
        }
      }),
      tap(({ token, session }) => {
        if (token && this.utilsService.isTokenValid() && !_.isNil(session)) {
          this.globalDataService.setToken(token);
          if (this.utilsService.isLoggedWithAuthorizationCode) {
            this.globalDataService.setIsLogged(true);
            this.globalDataService.setIsInitialized(true);
          }
          this.globalDataService.setIsInitialized(true);
        }
      }),
      tap(({ token }) => {
        if (!this.utilsService.isTokenValid() && token) {
          this.globalDataService.setIsInitialized(true);
        }
      })
    ).subscribe(() => this.tokenService.verifyTokenValidity());
  }

  displayCookiesBanner : boolean = false;
  ngOnInit(): void {
    const cookies =  this.utilsService.getCookies();

    if(cookies === '' || cookies === null || cookies === undefined) {
      this.displayCookiesBanner = true;
    }

  }

  onActivate(): void {
    window.scroll(0, 0);
  }
}
