<div class="form-container">
  <div class="content">
    <ng-content #searchTemplate></ng-content>
  </div>


  <button class="search-btn btn-blue" (click)="submit.emit()">
    <mat-icon svgIcon="loupe" [title]="'APP.MARCHES_SECURISES.CONSULTATION.SEARCH.ALT.LOUPE' | translate"></mat-icon>
  </button>
</div>
