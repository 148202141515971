import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginFormModule } from '@atline-shared/components/login-form/login-form.module';
import { MaterialModule } from '@atline/core/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotlineModule } from '@atline-shared/components/hotline/hotline.module';
import { FlexModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LoginFormModule,
    HotlineModule,
    FlexModule
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }
