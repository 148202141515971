<app-search-bar-container class="conf-bar-websing-verifier">

  <div fxFlex="100" fxLayout="row" fxLayoutGap="50px" fxLAyoutAlign="space-between start">
    <section class="checkboxs" fxLayout="column">
      <h4 class="label">
        {{'APP.MARCHES_SECURISES.CONF_SIGN_PAR.LABEL.TYPE' | translate }}
      </h4>
      <div fxLayout="row" fxLayoutGap="20px" fxLAyoutAlign="start start">
        <app-round-checkbox (changeState)="changeFormValue('pdf', $event)">
          {{'APP.MARCHES_SECURISES.CONF_SIGN_PAR.REPORT.PDF' | translate }}
        </app-round-checkbox>
        <app-round-checkbox (changeState)="changeFormValue('xml', $event)">
          {{'APP.MARCHES_SECURISES.CONF_SIGN_PAR.REPORT.XML' | translate }}
        </app-round-checkbox>
      </div>
    </section>

    <section class="container-switch" fxLayout="column">
      <h4 class="label">
        {{'APP.MARCHES_SECURISES.CONF_SIGN_PAR.LABEL.TOKEN' | translate }}
      </h4>
      <div fxLayout="row" fxLayoutGap="15px" fxLAyoutAlign="start start">
        <app-switch [isActivate]="confData.hasAttachedFile" (isActivated)="changeFormValue('hasAttachedFile', $event)"></app-switch>
        <span fxLayout="row" fxLAyoutAlign="start center">{{'APP.MARCHES_SECURISES.CONF_SIGN_PAR.SWTICH_LABEL' | translate }}</span>
      </div>
    </section>
  </div>


</app-search-bar-container>
