import { Component, Inject, OnInit, Optional  } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdministrationGroupItem } from '@atline/core/models/administrationGroupItem.model';
import { UsersListComponent } from '@atline/marches-securises/pa/pa-administrations/users/users-list/users-list.component';
import { Icons } from '@atline-shared/enums/icons.enum';
import { GroupsListComponent } from '@atline/marches-securises/pa/pa-administrations/groups/groups-list/groups-list.component';

@Component({
  selector: 'app-rights-procedure-dialog',
  templateUrl: './rights-procedure-dialog.component.html',
  styleUrls: ['./rights-procedure-dialog.component.scss']
})
export class RightsProcedureDialogComponent implements OnInit {
  public showRights: boolean = true;
  public showProcedures: boolean = false; 

  plus= Icons.Plus;

  public forUser: boolean = false; 
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AdministrationGroupItem,
    @Optional() public UsersList: UsersListComponent,
    @Optional() public GroupsList: GroupsListComponent
  ) { 
  }

  ngOnInit(): void { 
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    if(this.UsersList.user.idIdent) {
      this.showRights = false;
      this.forUser = this.showProcedures = true;
    }
  }
  
  close(): void {
    if(this.UsersList) {
      this.UsersList.rights = false;
      this.UsersList.callToWs();
    }

    else {
      this.GroupsList.rights = false;
      this.GroupsList.callToWs();
    }
    
  }

}
