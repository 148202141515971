import * as _ from 'lodash';

type DecoratorDebounce = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => PropertyDescriptor;

export function Debounce(ms = 0, options?: _.DebounceSettings): DecoratorDebounce {
  // tslint:disable-next-line: variable-name
  return (_target: any, _propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
    const original = descriptor.value;
    descriptor.value = _.debounce(original, ms, options);
    return descriptor;
  };
}
