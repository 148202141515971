import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { PAListRequest } from "../models/api/requests/search_pa_list.request";
import { PaListResponse } from "../models/api/responses/search_pa_list.response";

@Injectable({
  providedIn: 'root'
})

export class SearchPaListSercice {
  constructor(private readonly apiService: ApiService) {
  }


   getPaList(request: PAListRequest): Observable<PaListResponse> {
    return this.apiService.post<PaListResponse>(PaListResponse, request, 'search_pa_list');

  }

}

