import { DepotRetraitEntrepriseItem } from '../../depotRetraitEntrepriseItem.model';

export class RetraitPapierResponse {
  // tslint:disable:variable-name
  cle!: string;
  cle_dce!: string;
  nom_document!: string;
  date_retrait!: string;
  date_retrait_f!: string;
  lot!: string;
  entreprise!: DepotRetraitEntrepriseItem;
  // tslint:enable:variable-name
}
