import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AcceptElrarCguRequest, ElrarActionRequest } from '../models/api/requests/elrarAction.request';
import { ElrarResponse, ElRarValidityAndCheckResponse } from '../models/api/responses/elrar.response';
import { AcceptElrarItem } from '../models/acceptElrarItem.model';

@Injectable({
  providedIn: 'root'
})
export class ElrarService {

  constructor(
    private readonly api: ApiService
  ) { }

    elrarAction(req: ElrarActionRequest): Observable<ElrarResponse> {
      return this.api.post(ElrarResponse, req, 'lrar_action');
    }

    checkELRARvalidityAndStates(req: ElrarActionRequest): Observable<ElRarValidityAndCheckResponse> {
      return this.api.post(ElRarValidityAndCheckResponse, req, 'check_lrar_action');
    }

    acceptElrarCgu(req: AcceptElrarCguRequest): Observable<AcceptElrarItem> {
      return this.api.post(AcceptElrarItem, req, 'accept_lrar_cgu');
    }

}
