import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInscriptionComponent } from './form-inscription.component';
import { SoonAvalaibleModule } from '@atline-shared/components/soon-avalaible/soon-avalaible.module';



@NgModule({
  declarations: [FormInscriptionComponent],
  imports: [
    CommonModule,
    SoonAvalaibleModule
  ],
  exports: [FormInscriptionComponent]
})
export class FormInscriptionModule { }
