
export class EditGroupAdminRequest {
    public cle_etab: string;
    public nom_groupe: string;
    public group_label: string;
    public cle_groupe: string;

    constructor(
        cleEtab: string,
        nomGroupe: string,
        groupLabel: string,
        cleGroupe: string

    ) {
        this.cle_etab = cleEtab;
        this.nom_groupe = nomGroupe;
        this.group_label = groupLabel;
        this.cle_groupe = cleGroupe;
    }
  }
