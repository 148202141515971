import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewTalentComponent } from './new-talent.component';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/core/material/material.module';

@NgModule({
  declarations: [NewTalentComponent],
  imports: [
    CommonModule,
    NgxTranslateModule,
    FlexLayoutModule,
    MaterialModule
  ],
  exports: [NewTalentComponent],
})
export class NewTalentModule { }
