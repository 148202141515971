import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ElrarService } from '@atline/core/services/elrar.service';
import { UtilsService } from '@atline/core/services/utils.service';

@Component({
  selector: 'app-accept-elrar-cgu',
  templateUrl: './accept-elrar-cgu.component.html',
  styleUrls: ['./accept-elrar-cgu.component.scss']
})
export class AcceptElrarCguComponent implements OnInit {

  acceptCgu = false;

  constructor(
    public readonly dialogRef: MatDialogRef<AcceptElrarCguComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {clePa: string},
    private readonly elrarService: ElrarService,
    private readonly utilsService: UtilsService
  ) { }

  ngOnInit(): void {
  }

  getAcceptCGU(event: any): void {
    this.acceptCgu = event;
  }

  validateCgu(): void {
    this.elrarService.acceptElrarCgu({
      cle_etab: this.utilsService.cleEtab ?? '',
      cle_pa: this.utilsService.getPaList()[0].cle_pa,
      cle_utilisateur: this.utilsService.cleUtilisateur ?? ''
    }).subscribe(res => {
      this.dialogRef.close(res);
    });
  }

}
