import { ConsultationOeItem } from '../../consultation/ConsultationOeItem.model';
import { Pagination } from '../../pagination.model';

export class FavorisResponse {

    // tslint:disable:variable-name


    public consultations_favorites!: ConsultationOeItem[];
    nb_total = 0;
    pagination = new Pagination();

    // tslint:enable:variable-name

}
