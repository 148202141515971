import { AdministrationGroupItem } from '../../administrationGroupItem.model';
import { Pagination } from '../../pagination.model';

export class AdministrationGroupResponse {

  groups: AdministrationGroupItem[] = [];
  nb_total: number = 0;
  pagination!: Pagination;
  
}
