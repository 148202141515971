<app-dialog [dialogRef]="dialogRef">

    <h1 mat-dialog-title header>{{ title | translate }}</h1>
  
    <form mat-dialog-content body [formGroup]="editGroup">
  
      <div>
        <label class="nameGroup text-bl">{{'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.NEW_NAME' | translate}}</label>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput type="text" formControlName="nomGr" />
        </mat-form-field>
      </div>
  
      <div class="bouton-modify" fxLayoutAlign="center center">
        <button color="primary" mat-raised-button mat-dialog-close [mat-dialog-close]="false"
          (click)="onConfirm()">{{'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.BUTTON_MODIFIER' | translate |
          uppercase}}</button>
      </div>
  
      <div class="text" mat-dialog-content body>
        <p>{{ description | translate }}</p>
      </div>
  
    </form>
  
    <div mat-dialog-actions footer fxLayout="row" fxLayoutAlign="end center" class="info-dialog-actions"
      class="full-witdh">
      <button color="primary" mat-raised-button mat-dialog-close [mat-dialog-close]="false"
        (click)="onDismiss()">{{'APP.CLOSE' | translate | uppercase}}</button>
    </div>
  
  </app-dialog>
  