<div class="partners-references" fxLayout="column nowrap" fxLayoutGap="25px">

  <!-- Principal img -->
  <img fxHide.lt-md src="/assets/images/home-landing/accueil/5-PARTNERS.png" alt=""/>

  <!-- TEXTES -->
  <section class="section-textes" ngClass.lt-md="mobile">
    <h1>{{ 'APP.HOME.LANDING.PARTNER.RELATIONSHIP-TRUST' | translate | uppercase }}</h1>
    <div class="container-textes" ngClass.lt-md="mobile">
      <p>
      </p>
      <p>
      </p>
      <p>
      </p>
    </div>
  </section>

  <!-- PARTNERS -->
  <app-partner title="APP.HOME.LANDING.PARTNER.PARTNERS" [groupsPartners]="partners"></app-partner>

  <!-- DEMANDES -->
  <div class="demandes" fxLayout="column nowrap" fxLayoutGap="30px">
    <span class="demandes-textes">{{ 'APP.HOME.LANDING.PARTNER.YOU-TO-BECOME' | translate }}</span>
    <div class="demandes-container-btn" fxLayoutAlign="center center">
      <button mat-button (click)="redirectToEmail(userEmail)">{{ 'APP.HOME.LANDING.PARTNER.BTN-BECOME' | translate }}</button>
    </div>
  </div>

  <!-- ETUDE DE CAS -->
  <div class="study-cases" fxLayout="row wrap" fxLayoutAlign="center none">
    <!--    why atline services -->
    <app-block-why-atline></app-block-why-atline>

    <!--study case -->
    <app-study-case></app-study-case>
  </div>

  <!-- CLIENTS -->
  <app-partner title="APP.HOME.LANDING.PARTNER.CLIENTS" [groupsPartners]="clients"></app-partner>

  <!-- NEWSLETTER -->
  <app-newsletter></app-newsletter>

</div>
