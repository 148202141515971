import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '@atline-shared/components/dialog/login/login.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Input()
  incorrectCredentials!: boolean;

  @Output()
  formData = new EventEmitter();

  public signInForm!: FormGroup;
  public hide = true;
  // public incorrectCredentials = false;

  // TODO add the correct validators for the id/password
  readonly passwordFC = new FormControl('', [Validators.required, Validators.minLength(3)]);
  readonly usernameFC = new FormControl('', [Validators.required, Validators.minLength(3)]);

  constructor(
    private readonly formBuilder: FormBuilder,
    public loginDialogRef: MatDialogRef<LoginComponent>,
  ) { }

  ngOnInit(): void {
    this.signInForm = this.formBuilder.group({
      username: this.usernameFC,
      password: this.passwordFC,
      rememberMe: false,
    });
  }


  /**
   * Get the error message of the password form control validation
   */
  getPasswordErrorMessage(): string {
    if (this.passwordFC.hasError('required')) {
      return 'APP.LOGIN.PASSWORD_ERROR';
    }
    return this.passwordFC.hasError('minlength')
      ? 'APP.LOGIN.PASSWORD_ERROR'
      : '';
  }

    /**
   * Submit the form by logging in the user
   * Redirect to dashboard when is success
   */
    onSubmit(): void {
      this.formData.emit(this.signInForm)
    }

    onSignupClick(): void {
      this.loginDialogRef.close('signup');
    }

}
