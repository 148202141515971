import { Component } from '@angular/core';

@Component({
  selector: 'app-pa',
  templateUrl: './pa.component.html',
  styleUrls: ['./pa.component.scss']
})
export class PaComponent {

  constructor() { }

}
