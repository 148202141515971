const typesLotDictionnary = ['lot', 'piece'] as const;
export type TypeLotDictionnary = typeof typesLotDictionnary[number];

export interface LotDictionnary {
  id: string;
  cle: string;
  libelle: string;
  type: TypeLotDictionnary;
}

export function isTypeLotDictionnary(arg: any): arg is TypeLotDictionnary {
  return typesLotDictionnary.includes(arg);
}
