import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  constructor() {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  submit(): void {
    alert('Le service n\'est pas disponible pour le moment');
  }
}
