<div class="container" fxLayout="row nowrap" (click)="onChange()">
    <div class="container-checkbox">
        <div class="checkbox" [class.checked]="checked">
            <input #input type="checkbox" [name]="name" [id]="code" class="checkbox" (change)="onChange();"
                [(ngModel)]="checked" [alt]="title">
        </div>
    </div>
    <div class="container-description">
        <img src='{{"../../../../assets/icons/png/" + img + ".png"}}' [alt]="title" *ngIf="img">
        <ng-content [select]="'label[for=' + name + ']'" *ngIf="!img" style="white-space: normal;"></ng-content>
    </div>
</div>
