import {ApiRequest} from './api.request';

export class Login {
  username = '';
  password = '';
}

// tslint:disable:variable-name
export class AuthorizeRequest extends ApiRequest {
  response_type?: string;
  client_id?: string;
  state?: string;
  login?: string;
  password?: string;
  redirect_uri?: string;
}

export class TokenRequest extends ApiRequest {
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
  code?: string;
  redirect_uri?: string;
}
// tslint:enable:variable-name

