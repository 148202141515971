import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ConsultationsService } from '@atline/core/services/consultations.service';
import { DefaultResponse } from '@atline/core/models/api/responses/default.response';

@Component({
  selector: 'app-loader-publish',
  templateUrl: './loaderPublish.component.html',
  styleUrls: ['./loaderPublish.component.scss']
})
export class LoaderPublishComponent implements OnInit, OnDestroy {
  public title = '';
  constructor(
    private readonly consultationService: ConsultationsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      data: {
        cleDce: string,
        cleEtab: string,
        clePa: string,
      }

    },
  ) { }

  interval: any;
  publishState!: DefaultResponse;


  ngOnInit(): void {
    const req = {
      cle_etab: this.data.data.cleEtab,
      cle_dce: this.data.data.cleDce,
      cle_pa: this.data.data.clePa
    };

    this.consultationService.getPublishState(req).subscribe(res => {
      this.publishState = res;
    });

    this.interval = setInterval((): void => {
      this.consultationService.getPublishState(req).subscribe(res => {
        this.publishState = res;
      });
    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

}

