import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-bar-container',
  templateUrl: './search-bar-container.component.html',
  styleUrls: ['./search-bar-container.component.scss']
})
export class SearchBarContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
