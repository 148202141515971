
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContentSidebarDirective } from './content-sidebar.directive';
import { ButtonSideBarComponent } from './button-side-bar/button-side-bar.component';
import { SubButtonSideBarDirective } from './sub-button-side-bar.directive';
import { DirectiveModule } from '@atline-shared/directive/directive.module';
import { GroupBtnSideBarComponent } from './group-btn-side-bar/group-btn-side-bar.component';
import { MatButtonModule } from '@angular/material/button';
import { OverlayModule } from '@angular/cdk/overlay';


@NgModule({
  declarations: [
    SidebarComponent,
    ContentSidebarDirective,
    ButtonSideBarComponent,
    SubButtonSideBarDirective,
    GroupBtnSideBarComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    DirectiveModule,
    MatButtonModule,
    OverlayModule
  ],
  exports: [
    SidebarComponent,
    ContentSidebarDirective,
    ButtonSideBarComponent,
    SubButtonSideBarDirective,
    GroupBtnSideBarComponent
  ]
})
export class SidebarModule { }
