<div class="modal-container">
  <div mat-dialog-title class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title text-bl">
      {{'APP.MARCHES_SECURISES.PA.CREATE.SEND_JAL.TITLE' | translate }}
    </div>
    <button mat-button mat-dialog-close class="close icon"></button>
  </div>

  <div mat-dialog-body class="dialog-body">

    <div class="info">
      <div class="info-icon icon"></div>
      <span>{{'APP.MARCHES_SECURISES.PA.CREATE.SEND_JAL.INFO' | translate }}</span>
    </div>

    <form fxLayout="row" fxLayoutAlign="start center" [formGroup]="form">
      {{'APP.MARCHES_SECURISES.PA.CREATE.SEND_JAL.EMAILS' | translate }}
      <mat-form-field appearance="outline" class="input-field">
        <input matInput type="text" class="input" formControlName="mail">
      </mat-form-field>
      <button mat-button (click)="addMail($event)" class="icon add-mail"></button>
    </form>

    <div class="mails">
      <div class="mail" *ngFor="let mail of mailArray" fxLayout="row" fxLayoutAlign="start center">
        <span class="value">{{mail}}</span>
        <button mat-button (click)="removeMail(mail)" class="icon bin"></button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center" class="send-btn">
      <button [disabled]="!canSendMail" mat-stroked-button [mat-dialog-close]="mailArray">{{'APP.MARCHES_SECURISES.PA.CREATE.SEND_JAL.SEND' | translate }}</button>
    </div>


  </div>
</div>
