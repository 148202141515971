<mat-card class="use-case mat-elevation-z4" ngClass.xs ="use-case-responsive">
  <mat-card-title class="tender" fxLayout="row nowrap" fxLayoutAlign="space-between none">
    <h2 fxLayout="row" fxLayoutAlign="center baseline">
      {{'APP.HOME.LANDING.WHY_US.STUDY_CASE.TITLE' | translate }}
    </h2>
    <mat-icon class="icon-tender" svgIcon="tender"></mat-icon>
  </mat-card-title>
  <mat-card-content ngClass.xs ="card-content-responsive" >
    <strong fxFlexOrder.xs="22"><p [innerHTML]="'APP.HOME.LANDING.TEXT' | translate"></p></strong>
    <img src="assets/images/home-landing/use-case.png" alt="Atline services use case">
  </mat-card-content>
  <mat-card-actions  fxLayoutAlign="center center" ngClass.xs ="card-actions-responsive">
    <button mat-button (click)="download()">{{'APP.BUTTON.DOWNLOAD' | translate}}</button>
  </mat-card-actions>
</mat-card>
