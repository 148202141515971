import { Component } from '@angular/core';

@Component({
  selector: 'app-soon-avalaible',
  templateUrl: './soon-avalaible.component.html',
  styleUrls: ['./soon-avalaible.component.scss']
})
export class SoonAvalaibleComponent {

  constructor() { }

  back(): void {
    history.back();
  }

}
