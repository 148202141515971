import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Structure } from 'src/app/core/models/structure.model';

enum StatusSearch {
  progress,
  succees,
}
interface StructureDetail {
  [key: string]: string;
}

type Detail = {label: string, value: string};

@Component({
  selector: 'app-result-find-structure',
  templateUrl: './result-find-structure.component.html',
  styleUrls: ['./result-find-structure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResultFindStructureComponent implements OnInit, OnChanges {

  @Output() reponse = new EventEmitter<string>();

  status: StatusSearch = StatusSearch.progress;
  statusSearch = StatusSearch;

  readonly tableStructure: StructureDetail = {
    name: '',
    siret: '',
    naf: '',
    country: '',
    town: '',
    zip_code: '',
    adress: ''
  };

  constructor() { }

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(): void {
    this.reload();
  }

  get table(): Detail[] {
    return Object.entries(this.tableStructure).map<Detail>((value) => ({label: value[0], value: value[1]}) );
  }

  @Input()
  set structure(structure: Structure | undefined | null) {
    if (structure) {
      this.tableStructure.name = structure.nom;
      this.tableStructure.siret = structure.siret;
      this.tableStructure.naf = structure.code_naf;
      this.tableStructure.country = structure.pays.name;
      this.tableStructure.town = structure.commune.nom;
      this.tableStructure.zip_code = structure.commune.code_postal;
      this.tableStructure.adress = `${structure.adresse.num_voie} ${structure.adresse.type_voie} ${structure.adresse.nom_voie}`;
    }
    else {
      this.tableStructure.name = '';
      this.tableStructure.siret = '';
      this.tableStructure.naf = '';
      this.tableStructure.country = '';
      this.tableStructure.town = '';
      this.tableStructure.zip_code = '';
      this.tableStructure.adress = '';
    }
  }

  reload(): void {
    this.status = StatusSearch.progress;
    const numTimeOut = setTimeout(() => {
      this.status = StatusSearch.succees;
      clearTimeout(numTimeOut);
    }, 1000);
  }

  clickValid(): void {
    this.reponse.emit('valid');
  }

  clickCancel(): void {
    this.reponse.emit('');
  }
}
