import {NgModule} from '@angular/core';
import {Routes, RouterModule, NoPreloading} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MarchesSecurisesComponent} from './marches-securises/marches-securises.component';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent,
    loadChildren: async () => (await import('./home/home.module')).HomeModule
  },
  {
    path: 'marches-securises', component: MarchesSecurisesComponent,
    loadChildren: async () => (await import('./marches-securises/marches-securises.module')).MarchesSecurisesModule
  },
  {path: '', redirectTo: 'marches-securises', pathMatch: 'full'},
  {path: '**', redirectTo: 'marches-securises', pathMatch: 'full'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, anchorScrolling: 'enabled'} ) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
