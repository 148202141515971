import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AdminUserResponse } from '../models/api/responses/adminUser.response';
import { AdminUserRequest } from '../models/api/requests/adminUser.request';
import { AdminUserCreateRequest } from '../models/api/requests/adminUserCreate.request';
import { AssignPaToUserRequest } from '../models/api/requests/AssignPaToUserRequest';
@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(private readonly apiService: ApiService) { }

  /**
   * Get list of favoris
   * @returns array of all consulations
   */
  adminUser(params: AdminUserRequest): Observable<AdminUserResponse> {
    return this.apiService.post(AdminUserResponse, params, 'administration/utilisateurs_list');
  }
  
  addUser(adminUserCreateRequest: AdminUserCreateRequest): Observable<AdminUserResponse> {
    return this.apiService.post(AdminUserResponse, adminUserCreateRequest, 'user_ms_add');
  }

  assignPaToUser(assignPaToUserRequest: AssignPaToUserRequest): Observable<AdminUserResponse> {
    return this.apiService.post(AdminUserResponse, assignPaToUserRequest, 'affecter_utilisateur_pa');
  }
}

