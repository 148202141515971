<div class="container">
    <div fxLayout="row" fxLayoutAlign="start center">
            <div class="header" (click)="showRights = true; showProcedures = false" [ngClass]="{'visible': showRights}">
                <h1 *ngIf="!forUser">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.GROUP_RIGHTS' | translate | uppercase}}</h1>
                <h1 *ngIf="forUser">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_RIGHTS' | translate | uppercase }}</h1>
            </div>
            <div class="header" (click)="showProcedures = true; showRights = false" [ngClass]="{'visible': showProcedures}"  fxLayout="row" fxLayoutAlign="center start" >
                <h1>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.AUTHORIZED_PROC' | translate| uppercase}}</h1>
                <mat-icon (click)="close()" [svgIcon]="plus"></mat-icon>
            </div>
    </div>
    <app-rights *ngIf="showRights"></app-rights>


    <app-procedure *ngIf="showProcedures" ></app-procedure>
</div>