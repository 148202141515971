<div class="sign-modal-dialog signup" fxLayout="column" ngClass.lt-md="mobile-dialog">
  <div class="m-10">
    <div class="head">
      <button id="btn-back"
              [style.opacity]="selectedStep !== signupSteps.step1 ? 1 : 0"
              (click)="goBack()" mat-button
              type="button">{{'APP.BUTTON.BACK' | translate}}
      </button>
      <mat-icon class="logo-ats" svgIcon="logo-ats"></mat-icon>
      <button id="btn-close"
              mat-button
              type="button"
              mat-dialog-close>X</button>
    </div>
    <h1 mat-dialog-title *ngIf="part === parts.createAccount">{{'APP.SIGNUP.TITLE' | translate}}</h1>
  </div>
  <!-- Formulaire -->
  <form [style.display]="part === parts.createAccount ? 'initial' : 'none'" [formGroup]="signUpForm" (ngSubmit)="onSubmit()" class="signupForm">
    <mat-horizontal-stepper labelPosition="bottom" #stepper (selectionChange)="stepsSelectionChange($event)">

      <!---------------- STEP 1: contact login and recaptcha ---------------->
      <mat-step [label]="signupSteps.step1" [stepControl]="signUpForm" [completed]="step1Completed()">

        <ng-template matStepLabel>
          <h2 fxHide.lt-md="true" *ngIf="selectedStep === signupSteps.step1">{{'APP.SIGNUP.STEP_1' | translate}}</h2>
        </ng-template>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center">
          <h2 fxHide.gt-md="true" fxHide.md="true">{{'APP.SIGNUP.STEP_1' | translate}}</h2>

          <!-- Username -->
          <div fxLayout="column" class="signup-form-container-field">
            <!--Field-->
            <mat-form-field fxFlex="40" fxFlex.lt-md="80" class="signup-form-field m-10" appearance="outline">
              <mat-label>
                {{'APP.LOGIN.USERNAME' | translate}} 
                <small class="required" *ngIf="required(usernameFC)">*</small>
              </mat-label>
              <mat-icon matPrefix></mat-icon>
              <input matInput formControlName="username" name="username" placeholder="{{'APP.LOGIN.USERNAME_PLACEHOLDER' | translate }}"/>
              <!--Error Message-->
              <mat-error class="container-error-msg">
                <small *ngIf="usernameFC.invalid" class="form-error-msg">
                  {{ 'APP.SIGNUP.ERROR.USERNAME' | translate }}
                </small>
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Email -->
          <div fxLayout="column" class="signup-form-container-field">
            <!--Field-->
            <mat-form-field fxFlex="40" fxFlex.lt-md="80" class="signup-form-field m-10" appearance="outline">
              <mat-label>
                {{'APP.SIGNUP.EMAIL' | translate}}
                <small class="required" *ngIf="required(emailFC)">*</small>
              </mat-label>
              <mat-icon matPrefix></mat-icon>
              <input
                matInput
                formControlName="email"
                name="email"
                placeholder="{{'APP.SIGNUP.EMAIL' | translate}}"
              />

              <!--Error message-->
              <mat-error class="container-error-msg">
                <small *ngIf="emailFC.invalid" class="form-error-msg">
                  {{ 'APP.SIGNUP.ERROR.EMAIL' | translate}}
                </small>
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Password -->
          <div fxLayout="column" class="signup-form-container-field">
            <!--Field-->
            <mat-form-field fxFlex="40" fxFlex.lt-md="80" class="signup-form-field m-10" appearance="outline">
              <mat-label>
                {{'APP.LOGIN.PASSWORD' | translate}}
                <small class="required" *ngIf="required(passwordFC)">*</small>
              </mat-label>
              <mat-icon matPrefix></mat-icon>
              <input
                matInput
                formControlName="password"
                [type]="hide ? 'password' : 'text'"
                name="password"
                placeholder="{{ 'APP.SIGNUP.PASSWORD_PLACEHOLDER' | translate }}"
              />
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>

              <!--Error Message-->
              <mat-error class="container-error-msg">
                <small *ngIf="passwordFC.invalid" class="form-error-msg">
                  {{ 'APP.SIGNUP.ERROR.PASSWORD' | translate}}
                </small>
              </mat-error>
            </mat-form-field>
          </div>

          <!-- ConfirmPassword -->
          <div fxLayout="column" class="signup-form-container-field">
            <!--Field-->
            <mat-form-field fxFlex="40" fxFlex.lt-md="80" class="signup-form-field m-10" appearance="outline">
              <mat-label>
                {{'APP.SIGNUP.CONFIRM_PASSWORD' | translate}}
                <small class="required" *ngIf="required(confirmPasswordFC)">*</small>
              </mat-label>
              <mat-icon matPrefix></mat-icon>
              <input
                matInput
                formControlName="confirmPassword"
                [type]="hideConfirm ? 'password' : 'text'"
                name="confirmPassword"
                placeholder="confirm password"
              />
              <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirm">
                <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>

              <!--Error Message-->
              <mat-error class="container-error-msg">
                <small *ngIf="confirmPasswordFC.invalid" class="form-error-msg">
                  {{ 'APP.SIGNUP.ERROR.PASSWORD' | translate}}
                </small>
              </mat-error>
            </mat-form-field>
          </div>

        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <!-- CAPTCHA -->
          <mat-checkbox>
            <mat-label>CAPTCHA</mat-label>
          </mat-checkbox>
          <!-- BUTTON NEXT -->
          <button mat-stroked-button (click)="goNext()" color="primary" class="stroked-bn lg-border-radius" type="button">
            {{'APP.BUTTON.NEXT' | translate}}
          </button>
        </div>
      </mat-step>

      <!--------------- STEP 2: contact info ---------------->
      <mat-step [label]="signupSteps.step2" [stepControl]="signUpForm" [completed]="step2Completed()">
        <ng-template matStepLabel>
          <h2 fxHide.lt-md="true" *ngIf="selectedStep === signupSteps.step2">{{'APP.SIGNUP.STEP_2' | translate}}</h2>
        </ng-template>
        <h2 fxHide.gt-md="true" fxHide.md="true">{{'APP.SIGNUP.STEP_2' | translate}}</h2>

        <!-- Radio Button UserType -->
        <div fxLayout="row wrap" fxLayout.lt-md="column">
          <mat-radio-group formControlName="userType">
            <mat-radio-button *ngFor="let userType of userTypes"
            color="primary"
            class="m-10"
            [value]="userType">{{'APP.SIGNUP.' + (userType | uppercase) | translate}}</mat-radio-button>

            <!-- Error Message -->
            <mat-error class="container-error-msg">
              <small *ngIf="userTypeFC.invalid && userTypeFC.touched" class="form-error-msg">
                {{ 'APP.SIGNUP.ERROR.USERTYPE' | translate}}
              </small>
            </mat-error>
          </mat-radio-group>
          
          <!-- siret input with dropdown and button actions -->
          <div class="structure-ident" fxFill fxLayout="row wrap" fxLayoutAlign="space-between">
            <mat-form-field appearance="outline">
              <mat-label></mat-label>
              <mat-select formControlName="structureType">
                <mat-option *ngFor="let structure of typesStructure" [value]="structure.value">
                  {{structure.value| titlecase}}
                </mat-option>
              </mat-select>

              <mat-error class="container-error-msg">
                <small *ngIf="structureTypeFC.invalid" class="form-error-msg">
                  {{ 'APP.SIGNUP.ERROR.STRUCTURETYPE' | translate}}
                </small>
              </mat-error>

            </mat-form-field>

            <div fxLayout="column" class="signup-form-container-field">
              <!--Field-->
              <mat-form-field #formFieldStructure fxFlex="40" color="primary" fxFlex.lt-md="80" class="signup-form-field siret"
                            appearance="outline">
                <mat-label></mat-label>
                <input
                  matInput
                  formControlName="structure"
                  placeholder="{{'APP.SIGNUP.STRUCTURE_PLACEHOLDER.' + ((structureTypeFC.value === '' ? 'EMPTY' : structureTypeFC.value) | uppercase) | translate}}"
                  [matAutocomplete]="auto"
                  #triggerAutoComplete="matAutocompleteTrigger">
                <mat-autocomplete #auto="matAutocomplete" class="autocomplete-panel" classlist="autocomplete-panel">
                  <mat-option *ngFor="let option of filteredStructures | async" [value]="option.value">
                    <div class="container-option-structure" fxLayout="row" style="justify-content: space-between" (click)="selectStructure(option.element)">
                      <span class="value" style="margin-right: 25px;">{{ option.value }}</span>
                      <span class="name" style="font-size: 10px;">{{ option.name }}</span>
                    </div>
                  </mat-option>
                  <mat-option disabled *ngIf="noResult" class="option-save">
                    <div class="container-space-save" fxLayout="column">
                      <span class="msg-save">
                        Votre structure est introuvable?<br/>Enregistrer là!
                      </span>
                      <button mat-raised-button color="primary"
                      class="bn-ats-classic"
                      type="button"
                      (click)="part = parts.registrationForm; triggerAutoComplete.closePanel()">Enregistrer</button>
                    </div>
                  </mat-option>
                </mat-autocomplete>

                <!--Error Message-->
                <mat-error class="container-error-msg">
                  <small *ngIf="structureFC.invalid" class="form-error-msg">
                    {{ 'APP.SIGNUP.ERROR.STRUCTUREVALUE' | translate}}
                  </small>
                </mat-error>

                <mat-spinner matSuffix style="margin-right: 5px;" *ngIf="showLoaderStructure | async" mode="indeterminate" [diameter]="25"></mat-spinner>

              </mat-form-field>
            </div>

            <!-- Buttons Search & Clear -->
            <div class="actions">
              <button mat-raised-button color="primary" class="bn-ats-classic m-10"
                      [style.opacity]="structureFC.value.length > 0 ? 1 : 0"
                      style="transition: opacity .2s;"
                      (click)="structureFC.setValue('')"
                      type="button"> {{'APP.BUTTON.CLEAR' | translate}}</button>
            </div>
          </div>
        </div>

        <app-print-structure
        [structure]="structureSelect ? {
          name: structureSelect.nom,
          siret: structureSelect.siret,
          country: structureSelect.pays.name,
          town: structureSelect.commune.nom,
          codePostal: structureSelect.commune.code_postal,
          adress: structureSelect.adresse.num_voie + ' ' + structureSelect.adresse.type_voie + ' ' + structureSelect.adresse.nom_voie,
          codeNAF: structureSelect.code_naf
        } : null"></app-print-structure>

        <!-- Button Next -->
        <div class="float-right">
          <button mat-stroked-button (click)="goNext()" color="primary" class="stroked-bn lg-border-radius" type="button">
            {{'APP.BUTTON.NEXT' | translate}}
          </button>
        </div>
      </mat-step>

      <!---------------- STEP 3: Services list ---------------->
      <mat-step [label]="signupSteps.step3" [completed]="step3Completed()">
        <ng-template matStepLabel><h2 *ngIf="selectedStep === signupSteps.step3"
                                      fxHide.lt-md="true">{{'APP.SIGNUP.STEP_3' | translate}}</h2>
        </ng-template>
        <h2 fxHide.gt-md="true" fxHide.md="true">{{'APP.SIGNUP.STEP_3' | translate}}</h2>

        <!-- Liste Services -->
        <mat-selection-list #myServicesList formControlName="services" fxLayout="row wrap" class="selection-list">
          <mat-list-option *ngFor="let service of servicesList"
                            fxFlex="45"
                            fxFlex.lt-md="100"
                            fxFlex.md="100"
                            class="service-list"
                            [value]="service.nameValueItem.value"
                            [checkboxPosition]="checkboxPosition"
                            color="primary"
                            [selected]="service.selected">
            <div fxLayout="row" fxLayoutAlign="start center" class="service-item">
              <mat-icon svgIcon="{{service.nameValueItem.value}}"></mat-icon>
              <div>
                <h3 [ngClass]="service.nameValueItem.value">{{service.nameValueItem.name | uppercase}}</h3>
                <span>{{ 'APP.HOME.LANDING.SERVICES.' + service.nameValueItem.value | uppercase | translate}}</span>
              </div>
            </div>
          </mat-list-option>
        </mat-selection-list>

        <!-- Error message -->
        <div class="container-error-msg center">
          <small *ngIf="myServicesList.selectedOptions.selected.length <= 0;" class="form-error-msg">
            {{ 'APP.SIGNUP.ERROR.SERVICESLIST' | translate}}
          </small>
        </div>
        
        <!-- Button Finish -->
        <div class="float-right">
          <button mat-stroked-button color="primary" class="stroked-bn lg-border-radius" type="submit">
            {{'APP.BUTTON.FINISH' | translate}}
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>

  <!--RESULT FIND STRUCTURE-->
  <div class="container-result-find-structure" *ngIf="part === parts.resultFindStructure">
    <app-result-find-structure
      id="resulFindStructure"
      [structure]="structureSelect"
      (reponse)="validSearch($event)">
    </app-result-find-structure>
  </div>

  <!--REGISTRATION FORM-->
  <div class="container-registration-form" *ngIf="part === parts.registrationForm">
    <app-registration-form></app-registration-form>
  </div>

</div>
