
<div class="container-certificat container" fxLayout="row nowrap" fxLayout.lt-md="column nowrap" fxLayoutGap="10px" [ngStyle.lt-md]="{ margin: '30px 0 0 0' }">
  <div class="container-sidebar" fxLayout="column" fxLayout.lt-md="row nowrap" fxLayoutAlign.lt-md="start center" fxFlex="20">
    <app-sidebar [overlayOrigin]="overlayOrigin">
      <btn-side-bar *ngFor="let option of options"
        [visible]="!option.fInvisible || option.fInvisible()"
        [routerLink]="[option.etat]"
        routerLinkActive="etat-actived"
        #etatActived="routerLinkActive"
        [selected]="etatActived.isActive">
        {{ option.label | translate  | uppercase }}
      </btn-side-bar>
    </app-sidebar>
    <ng-container *ngIf="mediaObserver.isActive('lt-md')">
      <app-page-title *ngIf="keyTitleAsync | async as keyTitle"
        fxFlex="grow"
        [key]="keyTitle">
      </app-page-title>
    </ng-container>
  </div>
  <div fxFlex="80" fxFlex.lt-md="100" class="container-router-outlet" cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">
    <router-outlet></router-outlet>
  </div>
</div>
