import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {

  @Input()
  isActivate = false;

  @Output()
  isActivated = new EventEmitter<boolean>();

  updateState(e: any): void{
    this.isActivate = e.target.checked;
    this.isActivated.emit(e.target.checked);
  }

}
