import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as comparators from '@atline/core/functions/comparators.functions';

type ParamsScroll = {
  left?: number,
  top?: number,
  smooth?: boolean
};

class ParamsScrollToElement {
  htmlElement!: HTMLElement;
  smooth ? = false;
}

const isParamsScroll = comparators.createChecker<ParamsScroll>({
  left: [_.isNil, _.isNumber],
  top: [_.isNil, _.isNumber],
  smooth: [_.isNil, _.isBoolean],
});

const isParamsScrollToElement = comparators.createChecker<ParamsScrollToElement>({
  htmlElement: comparators.isHTMLElement,
  smooth: [_.isNil, _.isBoolean],
});

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor() { }

  windowsScrollTo(params: ParamsScrollToElement | ParamsScroll, callback?: () => void): void {
    let onlyY = false;
    let left: number | undefined;
    let top: number | undefined;
    if (isParamsScrollToElement(params)) {
      onlyY = true;
      left = params.htmlElement.offsetLeft;
      top = params.htmlElement.offsetTop;
    }
    else if (isParamsScroll(params)) {
      onlyY = false;
      left = params.left;
      top = params.top;
    }

    const XFixedOffset = left?.toFixed();
    const YFixedOffset = top?.toFixed();

    const onScroll = (): void => {
      const boolY = YFixedOffset === undefined || window.pageYOffset.toFixed() === YFixedOffset;
      const boolX = onlyY || XFixedOffset === undefined || window.pageXOffset.toFixed() === XFixedOffset;
      if (boolX && boolY) {
        window.removeEventListener('scroll', onScroll);
        if (callback !== undefined) { callback(); }
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    window.scrollTo({top, left, behavior: params.smooth ? 'smooth' : 'auto'});
  }
}
