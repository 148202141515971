import * as _ from 'lodash';
import { Pagination } from '../../pagination.model';

export interface EntrepriseDepots {
    cle: string;
    cle_annuaire: string;
    denomination: string;
    nom: string;
    adresse_1: string;
    adresse_2: string;
    code_postal: string;
    commune: string;
    pays: string;
    tel: string;
    fax: string;
    siret: string;
    siren: string;
    code_naf: string;
    url: string;
    contact: string;
    email_contact: string;
    email: string;
}

export function isEntrepriseDepots(value: any): value is EntrepriseDepots {
    return _.isString(value.cle_annuaire) &&
    _.isString(value.denomination) &&
    _.isString(value.nom) &&
    _.isString(value.adresse_1) &&
    _.isString(value.adresse_2) &&
    _.isString(value.code_postal) &&
    _.isString(value.commune) &&
    _.isString(value.pays) &&
    _.isString(value.tel) &&
    _.isString(value.fax) &&
    _.isString(value.siret) &&
    _.isString(value.siren) &&
    _.isString(value.code_naf) &&
    _.isString(value.url) &&
    (_.isString(value.email_contact) || _.isString(value.email));
}

// tslint:disable:variable-name
export interface DataCommun {
    cle: string;
    cle_dce: string;
    taille_reponse: string;
    hors_delai: number;
    lots: any[] | string;
    contact: string;
    entreprise: EntrepriseDepots;
}

export function isDataCommun(value: any): value is DataCommun {
    return _.isString(value.cle) &&
    _.isString(value.cle_dce) &&
    _.isString(value.taille_reponse) &&
    _.isNumber(value.hors_delai) &&
    _.isArray(value.lots) &&
    isEntrepriseDepots(value.entreprise);
}

export interface DepotReponse extends DataCommun {
    date_depot: number;
    date_depot_f: string;
    etat_depot: number;
    etat_depot_lbl: string;
    taille_reponse: string;
}

export function isDepotReponse(value: any): value is DepotReponse {
    return _.isNumber(value.date_depot) &&
    _.isString(value.date_depot_f);
    // (isDataCommun(value) as boolean) &&
}

export interface RetraitReponse extends DataCommun {
    nom_document: string;
    date_debut: number;
    date_debut_f: string;
    date_fin: number;
    date_fin_f: string;
    taille_reponse: string;
    date_retrait: number;
    date_retrait_f: string;
}

export function isRetraitReponse(value: any): value is RetraitReponse {
    return (
      _.isNumber(value.date_debut) &&
      _.isString(value.date_debut_f) &&
      _.isNumber(value.date_fin) &&
      _.isString(value.date_fin_f)
    ) || (
      _.isNumber(value.date_retrait) &&
      _.isString(value.date_retrait_f)
    );
}

export class DataListCommun {
    [x: string]: any;
    nb_total = 0;
    pagination: Pagination = new Pagination();
}

export class DepotsListReponse extends DataListCommun{
    depots: DepotReponse[] = [
        {
            cle: '',
            cle_dce: '',
            date_depot: 0,
            date_depot_f: '',
            etat_depot: 0,
            etat_depot_lbl: '',
            hors_delai: 0,
            taille_reponse: '',
            lots: [{dir_fich: ''}],
            contact: '',
            entreprise: {
                cle: '',
                cle_annuaire: '',
                denomination: '',
                nom: '',
                adresse_1: '',
                adresse_2: '',
                code_postal: '',
                commune: '',
                pays: '',
                tel: '',
                fax: '',
                siret: '',
                siren: '',
                code_naf: '',
                url: '',
                contact: '',
                email_contact: '',
                email: '',
            }
        }
    ];
}

export function isDepotsListReponse(value: any): value is DepotsListReponse {
    return value instanceof DepotsListReponse;
}

export class RetraitsListReponse extends DataListCommun{
    retraits: RetraitReponse[] = [
        {
            cle: '',
            cle_dce: '',
            hors_delai: 0,
            nom_document: '',
            taille_reponse: '',
            date_debut: 0,
            date_debut_f: '',
            date_fin: 0,
            date_fin_f: '',
            date_retrait: 0,
            date_retrait_f: '',
            lots: [{dir_fich: ''}],
            contact: '',
            entreprise: {
                cle: '',
                cle_annuaire: '',
                denomination: '',
                nom: '',
                adresse_1: '',
                adresse_2: '',
                code_postal: '',
                commune: '',
                pays: '',
                tel: '',
                fax: '',
                siret: '',
                siren: '',
                code_naf: '',
                url: '',
                contact: '',
                email_contact: '',
                email: '',
            }
        }
    ];
}

export function isRetraitsListReponse(value: any): value is RetraitsListReponse {
    return value instanceof RetraitsListReponse;
}

export interface GroupDepots {
    denomination_ent: string;
    login_user: string;
    cle_rep: string;
    date_depot: string;
    taille_par_lot: string;
    user_contact: string;
    user_email: string;
}

export interface LotsGroupDepots {
    lot: string;
    depots: GroupDepots[];
    nb_depots: number;
}

export class DepotsParLotsListReponse {
    depots_lots: LotsGroupDepots[] = [{
        lot: '',
        depots: [{
            denomination_ent: '',
            login_user: '',
            cle_rep: '',
            date_depot: '',
            taille_par_lot: '',
            user_contact: '',
            user_email: '',
        }],
        nb_depots: 0
    }];
    nb_total = 0;
}

export function isDepotsParLotsListReponse(value: any): value is DepotsParLotsListReponse {
    return value instanceof DepotsParLotsListReponse;
}

// tslint:enable:variable-name
