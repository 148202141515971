import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private readonly title: Title,
    private readonly translateService: TranslateService
  ) { }

  async setTile(key: string): Promise<void> {
    this.translateService.get(`APP.SEO.TITLE.${_.upperCase(key)}`).subscribe(res =>  this.title.setTitle(res));

  }
}
