import { WebSendDocumentItem } from './websendGetdocument.response';

export class WebSendDetailResponse {
  // tslint:disable:variable-name
  response!: WebsendDetailtem;
  files!: WebSendDocumentItem[];
  nb_total = 0;
  // tslint:enable:variable-name
}

export class WebsendDetailtem {
  // tslint:disable:variable-name
  id_l_dc_rep!: string;
  tok_rep_dem!: string;
  cle_reponse!: string;
  cle_dem_ms!: string;
  cle_dce_ms!: string;
  identifiant_dce_ms!: string;
  cle_pa!: string;
  identifiant_pa!: string;
  cle_ent_ms!: string;
  mail_ent!: string;
  date_acces_dem!: string;
  login_user!: string;
  depose!: number;
  annee_dem!: number;
  date_depot_ts!: number;
  date_depot_f!: string;
  // tslint:enable:variable-name
}

export function isWebSendDetailResponse(value: any): value is WebSendDetailResponse {
  return value !== undefined && value !== null && value instanceof WebSendDetailResponse;
}
