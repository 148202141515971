// tslint:disable:variable-name

export class NotificationItem {
    public denomination_pa = '';
    public objet_dce = '';
    public reference_dce = '';
    public reference_interne_dce = '';
    public type_prestation = '';
    public type_marche = '';
    public libelle_passation = ''; // procédure
    public departements_prestation = ''; // Zone géo
    public fichier = '';
    public date_envoi = 0;
    public date_envoi_f = '';
    public date_ouverture = 0; // Notification ouverte
    public date_ouverture_f = ''; // Notification ouverte
    public date_cloture = 0;
    public date_cloture_f = '';
    public nb_lots = 0;
    public extension = '';
    public cle_entreprise = '';
    public cle_demande = '';
    public cle_dce = '';
    public cle_notification = '';

}

// tslint:enable:variable-name
