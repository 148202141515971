import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class ImgHelper {

  async blobToBase64(blob: any): Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = (): void => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

}
