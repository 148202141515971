import { Component, Input, OnInit } from '@angular/core';
import { NameValueItem } from '@atline/core/models/special-types.model';

type PartnersValues = NameValueItem & { fileType?: 'png' | 'jpg' };

interface GroupsPartners {
  label?: string;
  path?: string;
  partners: PartnersValues[];
}

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  @Input()
  public groupsPartners: GroupsPartners[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
