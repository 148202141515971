  <!--  newsletter form-->
  <div class="newsletter landing-section" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-card class="newsletter-title" fxFlex="25" fxFlex.lt-md="100">
      <h1>  {{ 'APP.HOME.LANDING.NEWSLETTER.TITLE' | translate }}
      </h1>
    </mat-card>
    <mat-card class="newsletter-form" fxFlex="25" fxFlex.lt-md="100">
      <mat-card-content>
        <form>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'APP.HOME.LANDING.NEWSLETTER.NAME' | translate | titlecase }}</mat-label>
            <input matInput placeholder="Saisissez votre nom et prénom">
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Saisissez une adresse mail">
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button class="discover-bn" (click)="submit()"> {{'APP.HOME.LANDING.NEWSLETTER.SUBMIT' | translate}}
          <mat-icon svgIcon="right-arrow"></mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
