<div class="container-services-menu-hamburger" [class.open]="isOpenHamburger" fxHide.gt-md="true">

    <div class="container-nav-list">
        <mat-nav-list fxLayout="row wrap" fxLayout.lt-md="column nowrap" fxLayoutAlign="space-around center">
            <div class="container-btn-service-link">
                <a fxLayout="row nowrap"
                fxLayoutAlign="center center"
                (click)="closeMenu()" atlineLink [routerLink]="['./']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{'APP.MARCHES_SECURISES.NAV_LINK.SEARCH' | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                 (click)="closeMenu()" atlineLink [routerLink]="['donnees-essentielles']" routerLinkActive="active">{{'APP.MARCHES_SECURISES.NAV_LINK.MAIN_DATA' | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
               (click)="closeMenu()" atlineLink [routerLink]="['avis-attribution']" routerLinkActive="active">{{'APP.MARCHES_SECURISES.NAV_LINK.AWARD' | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link" *ngIf="!!isLogged">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                 (click)="closeMenu()" atlineLink [routerLink]="['historique']" routerLinkActive="active">{{"APP.MARCHES_SECURISES.NAV_LINK.HISTORY" | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                (click)="closeMenu()" atlineLink [routerLink]="['certificat-signature']" routerLinkActive="active">{{'APP.MARCHES_SECURISES.NAV_LINK.CERTIFICATS' | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link" *ngIf="!isLogged">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                (click)="closeMenu()" atlineLinktarget="_blank" href="https://www.marches-securises.fr/entreprise/?module=inscription">{{'APP.MARCHES_SECURISES.NAV_LINK.REGISTRATION' | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                (click)="closeMenu()" atlineLink [routerLink]="['faq']" routerLinkActive="active">{{'APP.MARCHES_SECURISES.NAV_LINK.FAQ' | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                (click)="closeMenu()" atlineLink [routerLink]="['liens-utiles']" routerLinkActive="active" class="disabled-link">{{'APP.MARCHES_SECURISES.NAV_LINK.USEFUL_LINKS' | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                (click)="closeMenu()" atlineLink [routerLink]="['qui-sommes-nous']" routerLinkActive="active">{{'APP.MARCHES_SECURISES.NAV_LINK.WHO_ARE_WE' | translate | uppercase}}</a>
            </div>
            <div class="container-btn-service-link">
                <a class="btn-service-link"
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                (click)="closeMenu()" atlineLink [routerLink]="['patenaires-references']" routerLinkActive="active">{{'APP.MARCHES_SECURISES.NAV_LINK.PARTNERS' | translate | uppercase}}</a>
            </div>
            
            <div class="container-btn-service-link" *ngIf="activePa">
                <a class="btn-service-link" 
                fxLayout="row nowrap"
                fxLayoutAlign="center center"
                (click)="closeMenu()" atlineLink *ngIf="(isPa | async)" [routerLink]="['pa']" routerLinkActive="active">{{'APP.MARCHES_SECURISES.NAV_LINK.SPACE-PA' | translate | uppercase}}</a>
            </div>
        </mat-nav-list>
    </div>
</div>