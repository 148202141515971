import { ApiErrorResponse } from './api.response';

// tslint:disable:variable-name

export class DcWebSendDepotResponse extends ApiErrorResponse {

    cle!: string;

    cle_dce!: string;

    cle_demande!: string;

    date_depot!: number;

    date_depot_f = null;

    etat_depot!: number;

    etat_depot_lbl!: string;

    taille_reponse!: number;

    cle_entreprise_ms!: string;

    denomination_ent!: string;

    date_validation_cu!: number;

    date_validation_cu_f!: string;

    date_acces_reponse!: number;

    date_acces_reponse_f!: string;

    date_ouverture!: number;

    date_ouverture_f!: string;
}


export class DcWebsendFindResponse extends ApiErrorResponse {

    id!: number;

    tok_rep!: string;

    cle_rep_ms!: string;

    cle_dce!: string;

    cle_demande!: string;

    depose!: number;

    annee_dem!: number;

    date_acces_dem!: string;

}

export class DcUploadFileDepotResponse extends ApiErrorResponse {

    id_upload_dc!: number;

    id_l_dc_rep!: number;

    nom_fich!: string;

    size_fich!: string;

    dir_fich!: string;

    path_fich!: string;

    type_fich!: string;

    date_upload!: string;

    statut!: number;

    file_path!: string;

    // tslint:enable:variable-name
}
