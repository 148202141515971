import { ApiRequest } from './api.request';

export class GetDemandeComplementaireRequest extends ApiRequest {

    // tslint:disable:variable-name

    /**
     * Establishment key
     */
    public cle_etab!: string;

    /**
     * DCE key for a closed consultation
     */
    public cle_dce!: string;

    public cle_demande?: string;

}

export class EditDemandeCompRequest {

  public cle_etab!: string;
  public cle_pa!: string;
  public cle_dce!: string;
  public cle_entreprise_destination!: string;
  public type_echange!: string;
  public date_envoi_notification!: string;
  public date_limit_reponse!: string;
  public fichier!: File;
  public cle_demande?: string;
  public type = 1;

  constructor(args?: { [Key in keyof EditDemandeCompRequest]?: EditDemandeCompRequest[Key] }) {
    this.cle_etab = args?.cle_etab || '';
    this.cle_pa = args?.cle_pa || '';
    this.cle_dce = args?.cle_dce || '';
    this.cle_entreprise_destination = args?.cle_entreprise_destination || '';
    this.type_echange = args?.type_echange || '';
    this.date_envoi_notification = args?.date_envoi_notification || '';
    this.date_limit_reponse = args?.date_limit_reponse || '';
    this.fichier = args?.fichier || new File([], 'none');
    this.cle_demande = args?.cle_demande;
    this.type = args?.type || 1;
  }
}

export class DeleteDemandeCompRequest {

    public cle_etab!: string;

    public cle_dce!: string;

    public cle_demande!: string;

}


export class SendDemandeCompRequest {

    public cle_etab!: string;

    public cle_dce!: string;

    public cle_demande!: string;

    public is_lrar!: number;

}


export class AddEmailDemandeComp {

    public cle_etab!: string;

    public cle_dce!: string;

    public cle_demande!: string;

    public adresse_email!: string;

}

export class DeleteEmailDcRequest {

    public cle_etab!: string;

    public cle_dce!: string;

    public cle_demande!: string;

    public adresse_email!: string;
}

export class GetFileResponseRequest {
    cle_dce!: string;
    cle_demande!: string;
    cle_reponse!: string;
    cle_etab?: string;
    as_pa?: 1 | 0;
}

export class LrarReceiptRequest extends ApiRequest {
  public cle_demande!: string;
  public cle_etab!: string;
  public cle_dce!: string;
  public cle_pa!: string;
}



    // tslint:enable:variable-name
