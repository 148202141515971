import { Component, OnInit } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss']
})
export class ContactItemComponent implements OnInit {

  public Icons = Icons;

  constructor(    private router: Router,
    ) { }

  ngOnInit(): void {
  }

  navigateToContactUs(): void {
    this.router.navigateByUrl('/home/contactUs');
  }

}
