import { AdminUserRequest } from './../../../../../core/models/api/requests/adminUser.request';
import { Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import { AdminUserResponse } from '@atline/core/models/api/responses/adminUser.response';
import { ApiErrorResponse } from '@atline/core/models/api/responses/api.response';
import { AdminUserService } from '@atline/core/services/adminUser.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { AdminUserItem } from '@atline/core/models/adminUser.model';
import { PaService } from '@atline/core/services/pa.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  @Input() cross = true;
  @Input() legendType!: 'user' | 'group';
  userListAdmin!:AdminUserResponse;
  nbUsers: number = 0;
  isLoading: boolean = true;
  cleEtab!: string;
  filteredUsers: AdminUserItem[] = [];
  searchUserTerm = '';
  public show: boolean = false;
  public added: boolean = false;
  public legendVisible = false;
  public paVisible = false;
  public user!: AdminUserItem;
  public paList: any;
  public rights: boolean = false;

  constructor(
    private readonly adminUserService: AdminUserService,
    private readonly utils: UtilsService,
    private readonly paService: PaService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.cleEtab = this.utils.cleEtab || '';
    this.legendType = 'user';
    this.callToWs();
    this.paService.getPAs({
      cle_etab: this.cleEtab
    }).subscribe(
      {
        next: (res) => {
          this.paList= res.pas;
        },
      });
  }

  public callToWs(): void {
    const request: AdminUserRequest = {
      cle_etab: this.cleEtab,
      cle_ent: this.utils.cleEnt ?? '',
    };

    this.adminUserService.adminUser(request).subscribe(
      (res) => {
        this.userListAdmin = res;
        this.nbUsers = this.userListAdmin?.nb_total || 0;
        this.filterUsers();
        this.isLoading = false;
      },
      (err) => {
        const apiError = err.error as ApiErrorResponse;
        if (apiError) {
        }
        this.isLoading = false;
      }
    );
  }

  getPlurial(n: number): string {
    return n > 1 ? 's' : '';
  }

  get users(): AdminUserItem[] {
    return this.userListAdmin?.utilisateurs || [];
  }

  filterUsers(): void {
    this.filteredUsers = this.users.filter((user) =>
      user.firstName.toLowerCase().includes(this.searchUserTerm.toLowerCase()) ||
      user.lastName.includes(this.searchUserTerm.toLowerCase()) ||
      user.login.includes(this.searchUserTerm.toLowerCase())
    );
  }

  searchUsers(searchTerm: string): void {
    this.searchUserTerm = searchTerm;
    this.filterUsers();
  }

  addUser(): void {
    this.show = true;
  }

  close(): void {
    this.added =false;
  }

  legendVisibility(): void {
    this.legendVisible = !this.legendVisible;
  }

  paPart(currentUser : AdminUserItem): void {
    this.user = currentUser;
    this.paVisible = true;
  }

  countPa(currentUser : AdminUserItem): number {
    if (currentUser.paList) {
      return currentUser.paList.length;
    }
    return 0;
  }

  openUserRights(currentUser : AdminUserItem): void {
    this.user = currentUser;
    this.rights=true;
  }
}
