import { EntDemCompItem } from './entDemCompItem.model';

export class DemandeCompItem {

    // tslint:disable:variable-name

    public entreprise = '';

    public date_depot = '';

    public date_depot_f = '';

    public cle_entreprise = '';

    public ent_dem_comp: EntDemCompItem[] = [new EntDemCompItem()];

    // tslint:enable:variable-name
}
