import { Pagination } from '../../pagination.model';
import { AliasItem } from './../../aliasItem.model';
import { ApiErrorResponse } from './api.response';

export class AliasResponse extends ApiErrorResponse {
  // tslint:disable:variable-name
  alias_list!: Array<AliasItem>;
  nb_total!: number;
  pagination!: Pagination;
  // tslint:enable:variable-name
}
