import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  userEmail: string= 'commercial@atline.fr';

  readonly partners = [
    {
      label: 'APP.HOME.LANDING.PARTNER.CLIENTS',
      path: '/assets/images/references/',
      partners: [
        {name: 'CD60', value: 'CD60'},
        {name: 'CD14', value: 'CD14'},
        {name: 'GBM', value: 'GBM'},
        {name: 'CAM', value: 'CAM'},
        {name: 'ANGERS', value: 'ANGERS'},
        {name: 'POITIERS', value: 'POITIERS'},
      ]
    },
    {
      label: 'APP.HOME.LANDING.PARTNER.PARTNERS',
      path: '/assets/images/partners/',
      partners: [
        {name: 'BERGER-LEVRAULT', value: 'BERGER-LEVRAULT'},
        {name: 'ORDIGES', value: 'ORDIGES'},
        {name: 'CERTINOMIS', value: 'CERTINOMIS'},
        {name: 'PMP', value: 'PM-Publicite'},
        {name: 'GROUPE-LES-ECHOS-Le-PARISIEN', value: 'GROUPE-LES-ECHOS-Le-PARISIEN'},
        {name: 'COMPRA', value: 'COMPRA'},
      ]
    }
  ];

  readonly clients = [
    {
      label: 'APP.HOME.LANDING.PARTNER.CLIENTS',
      path: '/assets/images/references/',
      partners: [
        {name: 'CD60', value: 'CD60'},
        {name: 'CD14', value: 'CD14'},
        {name: 'GBM', value: 'GBM'},
        {name: 'CAM', value: 'CAM'},
        {name: 'ANGERS', value: 'ANGERS'},
        {name: 'POITIERS', value: 'POITIERS'},
      ]
    },
    {
      label: 'APP.HOME.LANDING.PARTNER.PARTNERS',
      path: '/assets/images/partners/',
      partners: [
        {name: 'BERGER-LEVRAULT', value: 'BERGER-LEVRAULT'},
        {name: 'ORDIGES', value: 'ORDIGES'},
        {name: 'CERTINOMIS', value: 'CERTINOMIS'},
        {name: 'PMP', value: 'PM-Publicite'},
        {name: 'GROUPE-LES-ECHOS-Le-PARISIEN', value: 'GROUPE-LES-ECHOS-Le-PARISIEN'},
        {name: 'COMPRA', value: 'COMPRA'},
      ]
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  redirectToEmail(emailAddress: string) {
    window.location.href = `mailto:${emailAddress}`;
  }

}
