export class Service {
    constructor(readonly route: string,
                readonly name: string,
                readonly value: string,
                public disabled: boolean) {}
}

export const servicesHeader: Service[] = [
    new Service('/marches-securises', 'Atline Tender', 'tender', false),
    new Service('https://www.atline-formations.com', 'Atline Training', 'training', false),
];

export const services: Service[] = [
    new Service('/home/ourSolutions/marches-securises', 'Atline Tender', 'tender', false),
    new Service('/home/ourSolutions/attestations-securisees', 'Atline Attestation', 'attestations', false),
    new Service('/home/ourSolutions/convocations-securisees', 'Atline Convocation', 'convocations', false),
    new Service('/home/ourSolutions/parapheur-securise', 'Atline Sign off', 'sign', false),
];
