import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetQuestionsAndAnwserPaRequest, QuestionVisibility } from '../models/api/requests/questionsAndAnwsers.request';
import { ApiService } from './api.service';
import { QandAPaResponse } from '../models/api/responses/qradd.response';
import { EditResponseStatusRequest, GetQRAttachement, InformOeRequest, ResponseVisibilityRequest } from '../models/api/requests/qradd.request';
import { MailResponsePaItem } from '../models/mailPaItem.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MethodRequest } from '@atline-shared/enums/method-request.enum';

@Injectable({
  providedIn: 'root'
})
export class QuestionAnwsersService {

  constructor(private readonly api: ApiService, private readonly http: HttpClient) { }

  getPaQuestionsAndAnwsers(request: GetQuestionsAndAnwserPaRequest): Observable<QandAPaResponse> {
    return this.api.post(QandAPaResponse, request, 'pa/qr_list');
  }

  changeQuestionVisibility(request: QuestionVisibility): Observable<QandAPaResponse> {
    return this.api.post(QandAPaResponse, request, 'pa/qr_display_edit');
  }

  informOe(req: InformOeRequest): Observable<QandAPaResponse> {
    const formData = new FormData();
    formData.append('accuse', req.accuse.toString());
    formData.append('cle_dce', req.cle_dce);
    formData.append('cle_etab', req.cle_etab);
    if (req.cle_mail_question)
      formData.append('cle_mail_question', req.cle_mail_question);
    formData.append('cle_pa', req.cle_pa);
    formData.append('contexte', req.contexte);
    formData.append('copie', req.copie.toString());
    formData.append('recipients', req.recipients);
    formData.append('registre_depot', req.registre_depot ? '1' : '0');
    formData.append('registre_retrait', req.registre_retrait ? '1' : '0');
    formData.append('sujet', req.sujet);
    formData.append('texte', req.texte);
    formData.append('visible', req.visible.toString());
    if (req.piece_jointe)
      formData.append('piece_jointe', req.piece_jointe, req.piece_jointe.name);

    if (req.cle_alias)
      formData.append('cle_alias', req.cle_alias);
    return this.api.post(QandAPaResponse, formData, 'inform_oe');
  }

  postReponseVisibility(req: ResponseVisibilityRequest): Observable<MailResponsePaItem> {
    return this.api.post(MailResponsePaItem, req, 'pa/qr_display_edit');
  }

  editResponseStatus(req: EditResponseStatusRequest): Observable<MailResponsePaItem> {
    return this.api.post(MailResponsePaItem, req, 'pa/qr_reponse_edit');
  }

  getAttachment(req: GetQRAttachement): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.api}/qr_piece_get`, req,
      {
        headers: this.api.getHttpOptions(null, MethodRequest.POST).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );

  }
}
