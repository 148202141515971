<div class="container" fxLayout="column">

  <app-page-title [key]="'attribution'" [nbAvalaible]="nbAttributions"></app-page-title>

  <app-search-form (submit)="onSubmit()">
    <form [formGroup]="searchFormGroup">
      <div class="textInput">
        <div fxLayoutAlign="center center" class="input-element">
          <span class="input-prefix-container">
            <mat-icon svgIcon="target" id="icon-target"></mat-icon>
          </span>

          <div class="verticalLine"></div>
          <input formControlName="motscles" type="text"
            [placeholder]="'APP.MARCHES_SECURISES.CONSULTATION.AVIS.SUBJECTS' | translate" class="targetInput" />
        </div>

        <h2 class="pouvoir">
          {{
          "APP.MARCHES_SECURISES.CONSULTATION.ATTRIBUTION.TITRE3"
          | translate
          }}
        </h2>
        <div fxLayoutAlign="center center" class="input-element">
          <span class="input-prefix-container">
            <ng-container *ngIf="isLoadingPa; then prefixPaInputLoader; else prefixPaInputChevron"></ng-container>
            <ng-template #prefixPaInputLoader>
              <app-loading-spinner [size]="26" style="margin-right: 8px;"></app-loading-spinner>
            </ng-template>
            <ng-template #prefixPaInputChevron>
              <mat-icon svgIcon="chevron_grey"
                aria-label="APP.MARCHES_SECURISES.CONSULTATION.SEARCH.ALT.LOC | translate" class="inputIcon"></mat-icon>
            </ng-template>
          </span>


          <div class="verticalLine"></div>
          <div class="input-element">
            <input type="text" name="cle_pa"
              [placeholder]="'APP.MARCHES_SECURISES.CONSULTATION.AVIS.ACHETEUR' | translate"
              [matAutocomplete]="paAutocomplete" formControlName="paSearch" />
            <mat-autocomplete #paAutocomplete="matAutocomplete" panelWidth="700" [displayWith]="displayFn">
              <mat-option *ngFor="let pa of paList" [value]="pa">
                {{ pa.denomination }}
              </mat-option>
            </mat-autocomplete>
            <span id="cross-remove-pas" *ngIf="hasDataOnPaField">
              <mat-icon svgIcon="plus" (click)="clearPaField()"></mat-icon>
            </span>
          </div>
        </div>
      </div>

      <div class="avis-type" fxLayout="row">
        <mat-radio-group formControlName="type" class="type-rb" fxLayout="row wrap" fxLayoutGap="13px" fxLayoutAlign="start center">
          <mat-radio-button *ngFor="let item of criteresList" class="label1" [value]="item.code" color="primary"
            name="radioGroup">
            {{ item.label | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
  </app-search-form>

  <div class="consulation">
    <ng-container *ngIf="isLoading; then thenTemplate; else elseTemplate"></ng-container>
    <ng-template #thenTemplate>
      <div class="loading">
        <mat-spinner [style.margin]="'auto'" [diameter]="50"></mat-spinner>
      </div>
    </ng-template>
    <ng-template #elseTemplate>
      <div class="consultation-container">
        <app-avis-attribution-list [attributions]="attributionArray"></app-avis-attribution-list>

        <app-pagination *ngIf="pagination" [nbConsultation]="nbAttributions" [pagination]="pagination"
          (changePage)="changePage($event)" (changeNbParPage)="changeNbParPage($event)"></app-pagination>

        <div class="not-found" *ngIf="!nbAttributions" fxLayout="column" fxLayoutAlign="center center">
          {{
          "APP.MARCHES_SECURISES.NO_AVIS_ATTRIBUTION_FOUND"
          | translate
          }}
        </div>

      </div>
    </ng-template>

  </div>
