import { EtapeConsultation } from "@atline-shared/enums/EtapeConsultation.emun";
import { TypeMarcheEnum } from "@atline-shared/enums/typeMarche.enum";
import { TypePrestationEnum } from "@atline-shared/enums/typePrestation.enum";
import * as _ from "lodash";


export class DefaultFieldConsultation {

  private identifiant!: string;
  private type_marche!: TypeMarcheEnum;
  private objetCons!: string;
  private ref_interne?: string;
  private departements_prestation!: string;
  private procedureCons!: string;
  private date_publication_f!: string;
  private date_cloture!: number;
  private type_prestation!: TypePrestationEnum;
  etat!: number;


  constructor(consultation: any) {
    this.departements_prestation = consultation.departements_prestation;
    this.identifiant = consultation.identifiant;
    this.ref_interne = consultation.ref_interne;
    this.objetCons = consultation.objet;
    this.type_marche = consultation.type_marche;
    this.procedureCons = consultation.procedure;
    this.date_cloture = consultation.date_cloture;
    this.type_prestation = consultation.type_prestation;
    this.etat = consultation.etat;
  }


  get datePublicationDefault(): DatePublicationDefault {
    return {
      dateFormated: this.date_publication_f,
    }
  }

  get dateClotureDefault(): DateClotureDefault {
    return {
      timeStamp: this.date_cloture,
    }
  }

  get departements(): string {
    return this.departements_prestation
  }

  get id(): string {
    return this.identifiant
  }

  get etape(): EtapeConsultation {
    return this.etape
  }

  get refInterne(): string | null {
    return _.isNil(this.ref_interne) ? null : _.isEmpty(this.ref_interne) ? null : this.ref_interne;
  }

  get objet(): string {
    return this.objetCons;
  }

  get typeMarche(): string {
    return this.type_marche;
  }

  get procedure(): string {
    return this.procedureCons;
  }

  get prestation(): TypePrestationEnum {
    return this.type_prestation;
  }
}

export interface DatePublicationDefault {
  dateFormated: string;
}

export interface DateClotureDefault {
  timeStamp: number;
}
