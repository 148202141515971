<form class="formulaire_contactUs" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <h1 [innerHTML]="'APP.HOME.LANDING.CONTACT_US.DEMANDE_INFO_CONTACT_US' | translate"></h1>
  <div class="container-trace">
    <i class="material-icons trace"></i>
  </div>

  <div fxLayout="column" class="container-fields">
    <!-- 1ere ligne -->
    <div class="line-fields" fxLayout="row nowrap" fxLayout.lt-sm="column nowrap" fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start stretch" fxLayoutGap="10px">
      <!-- NOM -->
      <div fxFlex="45" fxFlex.lt-md="100">
        <mat-form-field appearance="standard"
          [ngClass]="{'red-error': displayError(contactForm, 'firstName', submitted)}">
          <h3 class="input-label">
            {{ "APP.HOME.LANDING.CONTACT_US.FORM_NOM" | translate }}
            <span>*</span>
          </h3>
          <input matInput formControlName="firstName" />
          <mat-error class="matErrorContactUsForm" *ngIf="displayError(contactForm, 'firstName', submitted)">
            {{ "APP.HOME.LANDING.CONTACT_US.ERROR_NOM" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- PRENOM -->
      <div fxFlex="45" fxFlex.lt-sm="100">
        <mat-form-field appearance="standard"
          [ngClass]="{'red-error': displayError(contactForm, 'lastName', submitted)}">
          <h3 class="input-label">
            {{ "APP.HOME.LANDING.CONTACT_US.FORM_PRENOM" | translate }}
            <span>*</span>
          </h3>
          <input matInput formControlName="lastName" />
          <mat-error class="matErrorContactUsForm" *ngIf="displayError(contactForm, 'lastName', submitted)">
            {{ "APP.HOME.LANDING.CONTACT_US.ERROR_PRENOM" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- 2eme ligne -->
    <div class="line-fields" fxLayout="row nowrap" fxLayout.lt-md="column nowrap" fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start stretch">
      <div fxLayout="row nowrap" fxLayout.lt-sm="column nowrap" fxFlex="45" fxFlex.lt-md="100" fxLayout="row nowrap"
        fxLayout.lt-md="column nowrap" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start stretch"
        style="gap: 10px">
        <!-- TYPE -->
        <mat-form-field class="formContactUsMatSelect" appearance="standard" fxFlex="nogrow" fxFlex.lt-sm="100">
          <!-- <h3 class="input-label">{{ "APP.HOME.LANDING.CONTACT_US.TYPE" | translate }}</h3> -->
          <h3>&nbsp;</h3>
          <mat-select [formControl]="typeForm" class="my-autocomplete">
            <mat-option *ngFor="let type of types" [value]="type">
              {{ type.value | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- ID ENTREPRISE -->
        <mat-form-field id="matForFieldSIRET" fxFlex="grow" fxFlex.lt-sm="100"
          [ngClass]="{'red-error': displayError(contactForm, 'companyID', submitted)}">
          <h3 class="input-label">
            {{ "APP.HOME.LANDING.CONTACT_US.FORM_ID_ENTREPRISE" | translate }}
            <span>*</span>
          </h3>
          <input matInput formControlName="companyID" [matAutocomplete]="idAutoComplete" placeholder="" />
          <mat-error class="matErrorContactUsForm" *ngIf="displayError(contactForm, 'companyID', submitted) &&
              contactForm.controls['companyID'].value?.length !== 0
            ">
            {{ "APP.HOME.LANDING.CONTACT_US.ERROR_SIRET" | translate }}
          </mat-error>
          <mat-error class="matErrorContactUsForm" *ngIf="
              contactForm.controls['companyID'].value?.length == 0 &&
              (contactForm.controls['companyID'].touched || submitted)
            ">
            {{ "APP.HOME.LANDING.CONTACT_US.ERROR_SIRET_VIDE" | translate }}
          </mat-error>
          <mat-autocomplete #idAutoComplete="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
              <strong>{{ option.name }}</strong> - {{ option.value }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <!-- E-MAIL -->
      <div fxFlex="45" fxFlex.lt-md="100">
        <mat-form-field appearance="standard" [ngClass]="{'red-error': displayError(contactForm, 'email', submitted)}">
          <h3 class="input-label">
            {{ "APP.HOME.LANDING.CONTACT_US.FORM_EMAIL" | translate }}
            <span>*</span>
          </h3>
          <input matInput formControlName="email" />
          <mat-error class="matErrorContactUsForm" *ngIf="displayError(contactForm, 'email', submitted)
            ">
            {{ "APP.HOME.LANDING.CONTACT_US.ERROR_MAIL" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row nowrap" fxLayout.lt-md="column nowrap" fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start stretch" class="line-fields">
      <!-- TELEPHONE -->
      <div fxFlex="45" fxFlex.lt-md="100">
        <mat-form-field appearance="standard" [ngClass]="{'red-error': displayError(contactForm, 'phone', submitted)}">
          <h3 class="input-label">
            {{ "APP.HOME.LANDING.CONTACT_US.FORM_TEL" | translate }}
            <span>*</span>
          </h3>
          <input matInput formControlName="phone" />
          <mat-error class="matErrorContactUsForm" *ngIf="displayError(contactForm, 'phone', submitted)">
            {{ "APP.HOME.LANDING.CONTACT_US.ERROR_TEL" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="45" fxFlex.lt-md="100">
        <mat-form-field appearance="standard"
          [ngClass]="{'red-error': displayError(contactForm, 'subject', submitted)}">
          <h3 class="input-label">
            {{ "APP.HOME.LANDING.CONTACT_US.FORM_OBJ_DEMANDE" | translate }}
          </h3>

          <input matInput formControlName="subject" />
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center start"
      class="line-fields">
      <mat-form-field class="contact-us-message-input"
        [ngClass]="{'red-error': displayError(contactForm, 'message', submitted)}">
        <h3 class="input-label">
          {{ "APP.HOME.LANDING.CONTACT_US.FORM_MSG" | translate }}<span>*</span>
        </h3>

        <textarea matInput formControlName="message" rows="12" cols="100" cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div fxLayoutAlign="center center" class="btn-send-container">
    <button mat-flat-button type="submit" class="btn_send_form" [disabled]="!contactForm.valid">
      <mat-icon [svgIcon]="Icons.EvelopClosed"></mat-icon>
    </button>
  </div>
</form>
