import { JalResponse } from './annonceList.response';

export class EditAnnonceResponse {
  // tslint:disable:variable-name
  date_creation = 0;
  archive = false;
  cle_annonce = '';
  cle_utilisateur = '';
  cle_annonceur = '';
  cle_compte = '';
  nePasPublierBOAMP = false;
  envoi_joue = false;
  language = '';
  identifiant_annonce = '';
  identifiant_annonce_boamp = '';
  identifiant_annonce_boamp_ref = '';
  identifiant_annonce_TED = '';
  identifiant_envoi_TED = '';
  identifiant_annonce_TED_ref = '';
  nb_ref = 0;
  nb_modif = 0;
  nb_attrib = 0;
  nb_ic = 0;
  annulation = '';
  cle_ref = '';
  pub_ms = false;
  logo_aapc = false;
  suivi_par_type = '';
  suivi_parution = '';
  suivi_num_ann_par = '';
  suivi_date_pub = '';
  suivi_url = '';
  suivi_url_pdf = '';
  suivi_num_annonce_JOUE = '';
  suivi_date_publication_JOUE = '';
  suivi_lienTED_JOUE = '';
  type_formulaire = '';
  lib_form = '';
  erreur_xml = '';
  envoi_xml = '';
  date_envoi = 0;
  date_envoi_JOUE = 0;
  nom_fichier_envoi = '';
  info_erreur_xml = '';
  infos_regles_JOUE = '';
  etat = '';
  etat_TED = '';
  valide = false;
  criteres_environnementaux = false;
  criteres_sociaux = false;
  typeIdentifiantNational = '';
  codeIdentificationNational = '';
  correspondant_nom = '';
  correspondant_pren = '';
  correspondant_fonc = '';
  acheteur_public = '';
  adr_voie_nomvoie = '';
  adr_cp = '';
  adr_ville = '';
  adr_pays = '';
  coord_tel = '';
  coord_fax = '';
  coord_mel = '';
  coord_url = '';
  point_contact = '';
  url_profil_acheteur = '';
  codeNUTS = '';
  classification_organisme = 0;
  classification_organisme_joue = 0;
  classification_organisme_dsp = '';
  classification_organisme_autre = '';
  activ_services_generaux_adm_pub = false;
  activ_defense = false;
  activ_ordre_securite_pub = false;
  activ_environnement = false;
  activ_affaires_ecofi = false;
  activ_sante = false;
  activ_logement_devcol = false;
  activ_protection_sociale = false;
  activ_loisirs_culture_religion = false;
  activ_education = false;
  activ_autre = '';
  activs_gaz_chaleur = false;
  activs_electricite = false;
  activs_gaz_petrole = false;
  activs_combustibles_solides = false;
  activs_eau = false;
  activs_services_postaux = false;
  activs_services_cheminfer = false;
  activs_services_cheminfer_urbains = false;
  activs_activites_portuaires = false;
  activs_activites_aeroportuaires = false;
  agit_pour_autre_compte = false;
  adrPouvoirAdjudicateur_codeIdentificationNational = '';
  adrPouvoirAdjudicateur_personne_morale = '';
  adrPouvoirAdjudicateur_adr_voie_nomvoie = '';
  adrPouvoirAdjudicateur_adr_cp = '';
  adrPouvoirAdjudicateur_adr_ville = '';
  adrPouvoirAdjudicateur_adr_pays = '';
  adrPouvoirAdjudicateur_codeNUTS = '';
  adrPouvoirAdjudicateur_tel = '';
  adrPouvoirAdjudicateur_fax = '';
  adrPouvoirAdjudicateur_mel = '';
  adrPouvoirAdjudicateur_url = '';
  adrPouvoirAdjudicateur_point_contact = '';
  appelOffreConjointLoi = '';
  acheteurCentral = false;
  documentMarche = 0;
  urlDocument = '';
  urlParticipation = '';
  adresseContact = false;
  urlOutilLogiciel = '';
  departement_pub_rappel = '';
  departement_pub = '';
  code_cpf = '';
  fact_num_bon_commande = '';
  fact_code_client = 0;
  is_CHORUS = false;
  fact_Chorus_SiretChorus = false;
  fact_Chorus_noServiceExecutant = false;
  fact_Chorus_noEngagementJuridique = false;
  fact_Chorus_codeServiceOuNumeroEJ = false;
  fact_noServiceExecutant = '';
  fact_noServiceExecutant_manual = '';
  fact_noEngagementJuridique = '';
  fact_denomination = '';
  fact_adr1 = '';
  fact_adr2 = '';
  fact_adr3 = '';
  fact_adr4 = '';
  fact_cp = '';
  fact_ville = '';
  fact_pays = '';
  fact_siret = '';
  fact_tva_inter = '';
  fact_classe_profit = '';
  nature_marche = '';
  nature_marche_travaux = '';
  nature_marche_services = '';
  nature_marche_fournitures = '';
  objet_marche = '';
  titre_marche = '';
  amp = false;
  cpv = '';
  cpv_1 = '';
  cpv_2 = '';
  cpv_3 = '';
  cpv_4 = '';
  exec_liv_voie = '';
  exec_liv_ville = '';
  exec_liv_cp = '';
  liv_voie = '';
  liv_ville = '';
  liv_cp = '';
  lieu_nuts = '';
  carac_variante = false;
  carac_principales = '';
  carac_quantites = '';
  estimation_devise = '';
  estimation_valeur = 0;
  estimation_valeur_basse = 0;
  estimation_valeur_haute = 0;
  carac_options_description = '';
  carac_options = false;
  carac_calendrier_previsionnel_mois = 0;
  carac_calendrier_previsionnel_jours = 0;
  carac_nb_reconductions = 0;
  carac_nb_reconduction_valeur_basse = 0;
  carac_nb_reconduction_valeur_haute = 0;
  reconduction = false;
  reconductionDesc = '';
  carac_calendrier_ulterieurs = '';
  carac_calendrier_ulterieurs_mois = 0;
  carac_calendrier_ulterieurs_jour = 0;
  carac_duree_nb_mois = 0;
  carac_duree_nb_jours = 0;
  carac_duree_debut = 0;
  carac_duree_fin = 0;
  conditions_garanties = '';
  conditions_financement = '';
  conditions_juridique = '';
  conditions_autres = false;
  conditions_autres_conditions = '';
  conditions_langue = '';
  conditions_langue_autre = '';
  eligibleMPS = false;
  situation_forme_juridique = '';
  capaEcoFiDoc = false;
  situation_capa_ecofi = '';
  situation_capa_ecofi_min = '';
  capaTechDoc = false;
  situation_capa_tech = '';
  situation_capa_tech_min = '';
  situation_critereParticipation = '';
  situation_marche_reserve = false;
  situation_ateliers_proteges = false;
  situation_emplois_proteges = false;
  nombre_candidat = 0;
  nombre_candidat_min = 0;
  nombre_candidat_max = 0;
  candidat_criteres_limitation = '';
  candidat_reduction_progessive = 0;
  attribOffresInitiales = false;
  critere_attribution_non = false;
  critere_attribution = '';
  critere_libre = '';
  critere_pondere = '';
  critere_enchere_electronique = false;
  criteres_ee_rens_compl = '';
  criteres_selection = '';
  procedure = '';
  procedure_sous_type = '';
  procedure_ms_cand_selec = false;
  delai_urgence = false;
  delai_urgence_info = '';
  joce_avis = '';
  joce_avis_num = '';
  joce_avis_date = 0;
  joce_autrepub_num1 = '';
  joce_autrepub_date1 = 0;
  joce_autrepub_num2 = '';
  joce_autrepub_date2 = 0;
  joce_avis_as_sad = '';
  joce_avis_as_sad_num = '';
  joce_avis_as_sad_date = 0;
  joce_avis_ex_an_v_num = '';
  joce_avis_ex_an_v_date = 0;
  abandonSAD = 0;
  abandonProcedure = 0;
  delais_reception_cand = 0;
  delais_reception_offres = 0;
  delais_envoi_invitation = 0;
  delais_valide_date_jusquau = 0;
  delais_valide_nbmois = 0;
  delais_valide_nbjours = 0;
  renseignements_complt = '';
  renseignements_idmarche = '';
  adrc_admtech_nom = '';
  adrc_admtech_personne_morale = '';
  adrc_admtech_adr_nomvoie = '';
  adrc_admtech_adr_pays = '';
  adrc_admtech_adr_cp = '';
  adrc_admtech_adr_ville = '';
  adrc_admtech_tel = '';
  adrc_admtech_fax = '';
  adrc_admtech_mel = '';
  adrc_admtech_url = '';
  adrc_admtech = false;
  adrc_tech_nom = '';
  adrc_tech_personne_morale = '';
  adrc_tech_adr_nomvoie = '';
  adrc_tech_adr_pays = '';
  adrc_tech_adr_cp = '';
  adrc_tech_adr_ville = '';
  adrc_tech_tel = '';
  adrc_tech_fax = '';
  adrc_tech_mel = '';
  adrc_tech_url = '';
  adrc_document_nom = '';
  adrc_document_personne_morale = '';
  adrc_document_adr_nomvoie = '';
  adrc_document_adr_pays = '';
  adrc_document_adr_cp = '';
  adrc_document_adr_ville = '';
  adrc_document_tel = '';
  adrc_document_fax = '';
  adrc_document_mel = '';
  adrc_document_url = '';
  adrc_document_point_contact = '';
  adrc_envoi_nom = '';
  adrc_envoi_codeIdentificationNational = '';
  adrc_envoi_personne_morale = '';
  adrc_envoi_adr_nomvoie = '';
  adrc_envoi_adr_pays = '';
  adrc_envoi_codeNUTS = '';
  adrc_envoi_adr_cp = '';
  adrc_envoi_adr_ville = '';
  adrc_envoi_tel = '';
  adrc_envoi_fax = '';
  adrc_envoi_mel = '';
  adrc_envoi_url = '';
  adrc_envoi_point_contact = '';
  adrc_envoi_cond_remise = '';
  adrc_renscomp_nom = '';
  adrc_renscomp_codeIdentificationNational = '';
  adrc_renscomp_personne_morale = '';
  adrc_renscomp_adr_nomvoie = '';
  adrc_renscomp_adr_pays = '';
  adrc_renscomp_codeNUTS = '';
  adrc_renscomp_adr_cp = '';
  adrc_renscomp_adr_ville = '';
  adrc_renscomp_tel = '';
  adrc_renscomp_fax = '';
  adrc_renscomp_mel = '';
  adrc_renscomp_url = '';
  adrc_renscomp_point_contact = '';
  avis_implique = '';
  avis_imp_ac_op_unique = false;
  avis_imp_ac_nb_part = 0;
  avis_imp_ac_max_nb_part = 0;
  avis_imp_ac_duree_an = 0;
  avis_imp_ac_duree_mois = 0;
  avis_imp_ac_justif_4a = '';
  avis_imp_ac_val_est_devise = '';
  avis_imp_ac_val_est_valeur = 0;
  avis_imp_ac_val_est_valeur_basse = 0;
  avis_imp_ac_val_est_valeur_haute = 0;
  avis_imp_ac_fq_val_marche = '';
  is_fonds_communautaire = false;
  is_fonds_communautaire_info = '';
  is_prestation_reservee = false;
  is_prestation_reservee_info = '';
  is_date_limite_obtention = 0;
  is_doc_payants = false;
  is_doc_prix = 0;
  is_doc_payants_devise = '';
  is_cond_mode_paiement = '';
  is_marche_periodique_calendrier = false;
  is_marche_periodique_calendrier_info = '';
  executionMarche = '';
  is_indic_qualif_pers = false;
  is_oodate = 0;
  is_oolieu = '';
  is_oopers_autorisees = false;
  is_oopers_autorisees_info = '';
  webCommande = false;
  webFacturation = false;
  webPaiement = false;
  marche_lot = false;
  repartition_reponse_lot = '';
  nbMaxLotOffre = 0;
  nbMaxLotAttrib = 0;
  lotsReserves = '';
  pr_icr_personne_morale = '';
  pr_icr_adr_nomvoie = '';
  pr_icr_adr_pays = '';
  pr_icr_adr_cp = '';
  pr_icr_adr_ville = '';
  pr_icr_tel = '';
  pr_icr_fax = '';
  pr_icr_mel = '';
  pr_icr_url = '';
  pr_icm_personne_morale = '';
  pr_icm_adr_nomvoie = '';
  pr_icm_adr_pays = '';
  pr_icm_adr_cp = '';
  pr_icm_adr_ville = '';
  pr_icm_tel = '';
  pr_icm_fax = '';
  pr_icm_mel = '';
  pr_icm_url = '';
  pr_irr_personne_morale = '';
  pr_irr_adr_nomvoie = '';
  pr_irr_adr_pays = '';
  pr_irr_adr_cp = '';
  pr_irr_adr_ville = '';
  pr_irr_tel = '';
  pr_irr_fax = '';
  pr_irr_mel = '';
  pr_irr_url = '';
  pr_irr_precisions = '';
  justif_redressement_judiciaire = false;
  justif_article_43 = false;
  justif_articles_L5212 = false;
  justif_articles_L1221_L3243 = false;
  justif_article_L3243 = false;
  justif_chiffres_affaires = false;
  justif_risques_professionels = false;
  justif_bilans = false;
  justif_effectifs = false;
  justif_liste_fournitures_services = false;
  justif_liste_travaux = false;
  justif_indication_titres_operateur = false;
  justif_indication_titres_cadres = false;
  justif_equipement_technique = false;
  justif_equipement_fournitures_services = false;
  justif_qualifications_pro = false;
  justif_qualifications_pro_p = '';
  justif_conformite_fournitures = false;
  justif_conformite_fournitures_p = '';
  justif_detail_fournitures = false;
  justif_defense_nationalite_candidat = false;
  justif_defense_habilitation_secret = false;
  justif_autorisation_specifique = false;
  justif_autorisation_specifique_p = '';
  justif_DC1 = false;
  justif_DC2 = false;
  justif_DC3 = false;
  justif_DC4 = false;
  justif_documents_autre_operateur = false;
  justif_traduction_francaise_candidat = false;
  justif_articles_D8222 = false;
  justif_attestation_obligations_fiscales = false;
  justif_certificat_pays_origine = false;
  justif_traduction_francaise_attributaire = false;
  justif_justification_autre = '';
  justif_justification_comment = '';
  mot_clef = '';
  index_date_fin_diff = 0;
  index_dep_presta = '';
  index_resume_objet = '';
  index_id_service_pub = '';
  tableau_carac = '';
  conditions_euro = false;
  conditions_languefr = false;
  date_debut_prestation = 0;
  date_debut_travaux = 0;
  rectif_info_ted = '';
  rectif_info_original_ao = '';
  rectif_info_proc_imcoplete = '';
  nouvelle_publication = false;
  signature = '';
  motif_annulation = '';
  info_jal = '';
  libre_procedure = '';
  libre_organisme = '';
  libre_duree = '';
  libre_attribution = '';
  libre_critere = '';
  libre_date_limite = '';
  avis_correspond = '';
  cond_sysq_conditions = '';
  cond_sysq_mverif = '';
  recompenses_primes = '';
  recompenses_paiements = '';
  recompenses_laureat = false;
  renseignements_etudes = '';
  moniteur_num_abonnement_mol = '';
  moniteur_num_abonne_mol = '';
  attrib_vt_devise = '';
  attrib_vt_tva = '';
  attrib_vt_valeur = 0;
  attrib_vt_valeur_basse = 0;
  attrib_vt_valeur_haute = 0;
  attrib_informations = '';
  avis_concerne = '';
  JCProc_aj = '';
  JCProc_ca = '';
  JCProc_lc = false;
  JCProc_mc = false;
  JCProc_mp = false;
  JCProc_oi = false;
  JCProc_pd_ra = false;
  JCProc_pd_re = false;
  JCProc_pd_rt = false;
  JCProc_pi = false;
  JCProc_proc = '';
  JCProc_ral = '';
  JCProc_recherche = false;
  JCProc_ui = false;
  JCProc_EA_procedure = '';
  JCProc_EA_recherche = false;
  JCProc_EA_prestataireDetermine_raisonsTechniques = false;
  JCProc_EA_prestataireDetermine_raisonsArtistiques = false;
  JCProc_EA_prestataireDetermine_raisonsExclusivite = false;
  JCProc_EA_urgenceImperieuse = false;
  JCProc_EA_marcheComplementaire = false;
  JCProc_EA_prestationsIdentiques = false;
  JCProc_EA_laureatsConcours = false;
  JCProc_EA_matieresPremieres = false;
  JCProc_EA_conditionsAvantageuses = '';
  JCProc_EA_achatOpportunite = false;
  JCProc_EA_accordCadre = false;
  JCProc_EA_autreJustification = '';
  ref_pub_ent_boamp = '';
  date_attribution = 0;
  type_attribution = '';
  nb_tot_offre = 0;
  nb_part = 0;
  nb_par_et = 0;
  val_prime_att = 0;
  val_prime_att_devise = '';
  attribution_unique = '';
  avisCouvert = '';
  nomsParticipants = '';
  membresJury = '';
  criteresEvaluationProjets = '';
  decisionJuryContraignante = false;
  domaine_delegation = '';
  domaine_delegation_autre = '';
  delegation_texte = '';
  delegation_duree_nbAns = 0;
  delegation_duree_nbMois = 0;
  delegation_duree_dateACompterDu = 0;
  delegation_duree_autre = '';
  delegation_autreCaracteristiques = '';
  delegation_montantPrevisionnel = '';
  delegation_montantPrevisionnel_devise = '';
  procedure_modalitesCandidatures = '';
  procedure_criteres = '';
  dateLancementPassation = 0;
  infosComplementaires = '';
  accordCadre = false;
  autreAcheteurSAD = false;
  adrc_legislationFiscale_urlInformations = '';
  adrc_legislationEnvironnement_urlInformations = '';
  adrc_protectionEmploi_urlInformations = '';
  adrc_legislationFiscale_nom = '';
  adrc_legislationFiscale_personne_morale = '';
  adrc_legislationFiscale_adr_nomvoie = '';
  adrc_legislationFiscale_adr_pays = '';
  adrc_legislationFiscale_adr_cp = '';
  adrc_legislationFiscale_adr_ville = '';
  adrc_legislationFiscale_tel = '';
  adrc_legislationFiscale_fax = '';
  adrc_legislationFiscale_mel = '';
  adrc_legislationFiscale_url = '';
  adrc_legislationFiscale_point_contact = '';
  adrc_legislationEnvironnement_nom = '';
  adrc_legislationEnvironnement_personne_morale = '';
  adrc_legislationEnvironnement_adr_nomvoie = '';
  adrc_legislationEnvironnement_adr_pays = '';
  adrc_legislationEnvironnement_adr_cp = '';
  adrc_legislationEnvironnement_adr_ville = '';
  adrc_legislationEnvironnement_tel = '';
  adrc_legislationEnvironnement_fax = '';
  adrc_legislationEnvironnement_mel = '';
  adrc_legislationEnvironnement_url = '';
  adrc_legislationEnvironnement_point_contact = '';
  adrc_protectionEmploi_nom = '';
  adrc_protectionEmploi_personne_morale = '';
  adrc_protectionEmploi_adr_nomvoie = '';
  adrc_protectionEmploi_adr_pays = '';
  adrc_protectionEmploi_adr_cp = '';
  adrc_protectionEmploi_adr_ville = '';
  adrc_protectionEmploi_tel = '';
  adrc_protectionEmploi_fax = '';
  adrc_protectionEmploi_mel = '';
  adrc_protectionEmploi_url = '';
  adrc_protectionEmploi_point_contact = '';
  VAL_TOTAL_ATP = false;
  fact_noEngagementJuridiqueExist = false;
  origine_request = '';
  id_annonce_complement = '';
  identifiantInterne = '';
  grpCommandes = 0;
  grpCoordonnateur = '';
  communication_documentMarche = '';
  docSecurite = 0;
  mesuresConfidentialite = '';
  urlSecurite = 0;
  categorieAcheteur = '';
  tranches = 0;
  reservationMarche = '';
  visite = 0;
  visiteDetail = '';
  nom_organisme = '';
  date_creation_f = '';
  date_debut_prestation_f = '';
  date_debut_travaux_f = '';
  date_attribution_f = '';
  cle_pa = '';
  xml_ted = false;
  xml_ted_version = '';
  xml_boamp = false;
  xml_boamp_version = '';
  pdf_integral = false;
  pdf_resume = false;
  jals: JalResponse[] = [];
  // tslint:enable:variable-name
}
