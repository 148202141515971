<div class="key-moments" ngClass.xs="key-responsive">
    <!-- <h1 class="title" ngClass.lt-md="mobile">{{ 'Les instants clefs' | translate | uppercase }}</h1> -->
    <h1 class="title" ngClass.lt-md="mobile">{{ 'APP.HOME.LANDING.STORY.KEY' | translate | uppercase }}</h1>
    <div class="container-calendar">
        <header fxLayout="row wrap" fxLayoutAlign="center" ngClass.lt-md="mobile">
            <div *ngFor="let step of steps; let i = index"
            fxLayout="column nowrap"
            fxLayoutAlign="center"
            class="selector"
            [class.selected]="selected === step"
            (click)="goMoment(i)"
            >
                <span>{{ step.label }}</span>
                <div class="circle"></div>
            </div>
        </header>
        <main>
            <ng-container *ngFor="let step of steps; let i = index">
                <div *ngIf="selected === step"
                class="step"
                [attr.tabindex]="selectedIndex === i ? 0 : null"
                [id]="_getStepContentId(i)"
                [attr.aria-labelledby]="_getStepLabelId(i)"
                [attr.aria-expanded]="selectedIndex === i"
                >
                    <ng-container [ngTemplateOutlet]="step.content"></ng-container>
                </div>
            </ng-container>
        </main>
    </div>
</div>