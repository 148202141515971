import { ApiRequest } from './api.request';

// tslint:disable:variable-name
export class Account {
    email = '';
    username = '';
    password = '';
    role = '';
    apps: string[] = [];
    structure_type = '';
    structure_value = '';
}
export class CreateAccountRequest extends ApiRequest{
    email = '';
    username = '';
    password = '';
    role = '';
    apps: string[] = [];
    structure_type = '';
    structure_value = '';
}
// tslint:enable:variable-name
