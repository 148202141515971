<div class="container" fxLayout="column">

  <header fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" class="file-result-header">
    <h1>{{file.file.name}}</h1>
    <nav class="file-signature-nav" *ngIf="file">
      <button mat-icon-button (click)="downloadReport('PDF')" *ngIf="pdfReport">
        <mat-icon [svgIcon]="icons.PDF"></mat-icon>
      </button>

      <button mat-icon-button (click)="downloadReport('XML')" *ngIf="xmlReport">
        <mat-icon [svgIcon]="icons.XML"></mat-icon>
      </button>
    </nav>
  </header>



  <mat-accordion>

    <ng-container *ngIf="hasSignatures; else elseTemplate">
      <mat-expansion-panel *ngFor="let signature of file.signatures; let i = index" #expanse1 hideToggle="true">
        <mat-expansion-panel-header expandedIcon="remove_circle" collapsedIcon="add_circle">
          <mat-panel-title>
            <span fxLayout="row" fxLayoutAlign="start center">{{ "APP.MARCHES_SECURISES.SIGNATURE.SIGN" | translate :
              {number: i+1, signatory:
              signature.signatory} }} <mat-icon class="icon-validity-sign"
                [svgIcon]="hasErrorCode ? icons.Red_Cross : icons.Valid_green"></mat-icon></span></mat-panel-title>
          <mat-icon svgIcon="chevron_grey" class="icon" [class.rotate]="expanse1.expanded"></mat-icon>
        </mat-expansion-panel-header>

        <mat-panel-description fxLayout="column" class="sign-content">

          <div class="sign-content-subtitle">
            <mat-divider></mat-divider>
            <h1 class="section-title">{{"APP.MARCHES_SECURISES.SIGNATURE.PROPRI_SIGNA" | translate | uppercase}}</h1>
            <mat-divider></mat-divider>
          </div>



          <form fxLayout="row" fxFlex="100" fxLayoutAlign="start">
            <div fxFlex="100">
              <ul fxFlex="30">
                <span class="Requestws">
                  {{
                  "APP.MARCHES_SECURISES.SIGNATURE.STATUT_SIGNA" | translate
                  }}</span><br />
              </ul>

              <ul fxFlex="50">
                <span class="Responsews">{{
                  file.signatures[i].signatory
                  }}</span><br />
              </ul>
            </div>
          </form>

          <div class="sign-content-subtitle">
            <mat-divider></mat-divider>
            <h1 class="section-title">{{"APP.MARCHES_SECURISES.SIGNATURE.CERTIF_SIGNA" | translate | uppercase}}</h1>
            <mat-divider></mat-divider>
          </div>
          <!-- <hr class="mt2" />
          <h1 class="TitleSign">


            {{
            "APP.MARCHES_SECURISES.SIGNATURE.CERTIF_SIGNA"
            | translate
            | uppercase
            }}
          </h1>
          <hr /> -->
          <form class="FormSign" fxLayout="row" fxFlex="100" fxLayoutAlign="start">
            <div fxFlex="100">
              <ul fxFlex="30">
                <span class="Requestws">{{
                  "APP.MARCHES_SECURISES.SIGNATURE.REQ38" | translate
                  }}</span><br />
                <span class="Requestws">{{
                  "APP.MARCHES_SECURISES.SIGNATURE.REQ39" | translate
                  }}</span><br />
                <span class="Requestws">{{
                  "APP.MARCHES_SECURISES.SIGNATURE.REQ40" | translate
                  }}</span><br />
                <span class="Requestws">{{
                  "APP.MARCHES_SECURISES.SIGNATURE.REQ41" | translate
                  }}</span><br />
                <span class="Requestws">{{
                  "APP.MARCHES_SECURISES.SIGNATURE.REQ42" | translate
                  }}</span><br />
                <span class="Requestws">{{
                  "APP.MARCHES_SECURISES.SIGNATURE.REQ43" | translate
                  }}</span><br />
                <span class="Requestws">{{
                  "APP.MARCHES_SECURISES.SIGNATURE.REQ44" | translate
                  }}</span><br />
                <span class="Requestws">{{
                  "APP.MARCHES_SECURISES.SIGNATURE.REQ45" | translate
                  }}</span><br />
              </ul>

              <ul fxFlex="50">
                <span class="Responsews">{{
                  file.signatures[i].valid
                  }}</span><br />
                <span class="Responsews">{{
                  file.signatures[i].certificate.format
                  }}</span><br />
                <span class="Responsews">{{
                  file.signatures[i].certificate.type
                  }}</span><br />
                <span class="Responsews">{{
                  file.signatures[i].date
                  }}</span><br />
                <span class="Responsews">{{
                  file.signatures[i].certificate.validityDates.expiration
                  }}</span><br />
                <span class="Responsews">{{
                  file.signatures[i].certificate.revocation
                  }}</span><br />
                <span class="Responsews">{{
                  file.signatures[i].certificate.integrity
                  }}</span><br />
                <span class="Responsews">{{
                  file.signatures[i].authority
                  }}</span><br />
              </ul>
            </div>
          </form>
        </mat-panel-description>
      </mat-expansion-panel>
    </ng-container>
    <ng-template #elseTemplate>
      <p class="no-sign-found">
        {{ "APP.MARCHES_SECURISES.SIGNATURE." + file.errorCode | translate : {filename: file.file.name} }}</p>
    </ng-template>

  </mat-accordion>

</div>