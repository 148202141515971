import { HotlineModule } from './../hotline/hotline.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MaterialModule } from '../../../core/material/material.module';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { CoachItemComponent } from './coach-item/coach-item.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { LoginItemComponent } from './login-item/login-item.component';
import { UserItemComponent } from './user-item/user-item.component';
import { HotlineItemComponent } from './hotline-item/hotline-item.component';
import { ContactItemComponent } from './contact-item/contact-item.component';
import { ServicesItemComponent } from './services-item/services-item.component';
import { DateTimeItemComponent } from './date-time-item/date-time-item.component';
import { ServicesMenuComponent } from './services-menu/services-menu.component';
import { RouterModule } from '@angular/router';
import { MsRedirectionComponent } from './ms-redirection/ms-redirection.component';
import { PaLogoModule } from '../pa-logo/pa-logo.module';
import { ServicesMenuHamburgerComponent } from './services-menu-hamburger/services-menu-hamburger.component';
import { PresentationServiceItemModule } from '../presentation-service-item/presentation-service-item.module';
import { SwitchModule } from '@atline-shared/design/switch/switch.module';
import { LayoutModule } from '@angular/cdk/layout';
import { LoginModule } from '../dialog/login/login.module';

@NgModule({
  declarations: [
    HeaderComponent,
    LangSelectorComponent,
    CoachItemComponent,
    LoginItemComponent,
    UserItemComponent,
    HotlineItemComponent,
    ContactItemComponent,
    ServicesItemComponent,
    DateTimeItemComponent,
    ServicesMenuComponent,
    MsRedirectionComponent,
    ServicesMenuHamburgerComponent,
  ],
  exports: [HeaderComponent, LoginItemComponent],
  imports: [
    CommonModule,
    MaterialModule,
    NgxTranslateModule,
    FlexLayoutModule,
    FlexModule,
    LayoutModule,
    RouterModule,
    HotlineModule,
    PaLogoModule,
    PresentationServiceItemModule,
    SwitchModule,
    LoginModule
  ],
})
export class HeaderModule {}
