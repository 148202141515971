import { PageTitleModule } from './../../shared/components/page-title/page-title.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { DesignModule } from '@atline-shared/design/design.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ShareConsultationOeData } from '@atline/core/services/share-consultations-oe-data.service';
import { FavorisModule } from '@atline-shared/components/favoris/favoris.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    FlexLayoutModule,
    DesignModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDividerModule,
    PageTitleModule,
    FavorisModule,
    MatDatepickerModule,
    MatFormFieldModule
  ],
  providers: [ShareConsultationOeData],
  exports: [SearchComponent]
})
export class SearchModule { }
