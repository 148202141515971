import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Option } from '../custom-select.models';

@Component({
  selector: 'app-custom-option',
  templateUrl: './custom-option.component.html',
  styleUrls: ['./custom-option.component.scss']
})
export class CustomOptionComponent implements OnInit {

  @Input()
  value: any = '';

  @Output()
  selectOption = new EventEmitter<Option>();

  selected = false;

  constructor(private readonly el: ElementRef) { }

  ngOnInit(): void { }

  get option(): Option {
    return new Option(this.value, this.text);
  }

  get text(): string {
    return this.el.nativeElement.innerText;
  }

  select(): void {
    this.selectOption.emit(this.option);
  }

}
