<div class="container-custom-select">
    <div class="container-select" fxLayout="row" fxLayout="row">
        <div containerIcon class="container-icon" fxLayout="row">
            <div class="img">
                <ng-content select="[iconSelect]"></ng-content>
            </div>
            <mat-divider [vertical]="true" *ngIf="spaceBar"></mat-divider>
        </div>
        <div class="select" [class.open]="open" #select (click)="openCloseOptions()" cdkOverlayOrigin  #triggerOverlay="cdkOverlayOrigin">
            <select #selectHTML [(ngModel)]="value" [required]="required" (blur)="onTouched($event)"></select>
            <div class="text">{{text}}</div>
            <i class="arrow"></i>
            <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="triggerOverlay"
            [cdkConnectedOverlayOpen]="open">
                <div #options class="options"
                [style.width.px]="select.getBoundingClientRect().width">
                    <ng-content select="app-custom-option"></ng-content>
                </div>
            </ng-template>
        </div>

    </div>

</div>
