import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {

  readonly links = [
    'ourSolutions',
    'story',
    'certificat',
    'faq',
    'contactUs',
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
