import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationsFindStructure, FindStructureRequest } from '../models/api/requests/find-structure.request';
import { FindStructureReponse, EtablissementReponse, Annuaire, AnnuaireAdd, isAnnuaireAdd} from '../models/api/responses/find-structure.reponse';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';

interface StructureFind {
  annuaire?: Annuaire[];
  etablissement?: EtablissementReponse;
  add?: AnnuaireAdd;
}

@Injectable({
  providedIn: 'root'
})
export class FindStructureService {

  constructor(private readonly apiService: ApiService, private readonly utils: UtilsService) { }

  find(type: string, value: string, application?: ApplicationsFindStructure): Observable<StructureFind> {
    const request = new FindStructureRequest(type, value, this.utils.cleEtab ?? '', application);
    return this.find_structure(request).pipe(
      catchError( err => throwError(err)),
      map((rep: FindStructureReponse) => {
        const annuaire = _.isArray(rep.annuaire) ? rep.annuaire : undefined;
        const etablissement = rep.client_api?.etablissement
          ?? rep.client_api?.etablissement_siege
          ?? rep.client_api?.annuaire.etablissement_siege;
        const add = isAnnuaireAdd(rep.annuaire) ? rep.annuaire : undefined;
        return { annuaire, etablissement, add };
      }),
    );
  }

  // TODO: use this.api.post
  find_structure(request: FindStructureRequest): Observable<FindStructureReponse> {
    return this.apiService.post(FindStructureReponse, request, 'find_structure');
  }
}
