import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-date-time-item',
  templateUrl: './date-time-item.component.html',
  styleUrls: ['./date-time-item.component.scss']
})
export class DateTimeItemComponent implements OnInit {

  date = new Date();

  private monthFr = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  constructor(private readonly translateService: TranslateService) { }

  ngOnInit(): void {
    interval(1000)
    .subscribe(() => { this.date = new Date(); });
  }

  formatDate(): string {
    let dateFormated = '';
    const key = 'APP.TOP_MENU.DATE.' + this.monthFr[this.date.getMonth()];
    this.translateService.get(key).subscribe(res => {dateFormated = this.date.getDate() + ' ' + res; });
    return dateFormated;
  }

}
