import { Injectable } from '@angular/core';
import { DialogsService } from './dialogs.service';
import { UtilsService } from './utils.service';
import { Subject, Subscription, interval } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private readonly startOB = new Subject();
  private readonly stopOB = new Subject();
  private intervalValidity?: Subscription;

  constructor(
    private readonly utilsService: UtilsService,
    private readonly dialogService: DialogsService,
  ) { }

  verifyTokenValidity(): void {
    if (!this.intervalValidity) {
      this.intervalValidity = this.startOB.pipe(
        mergeMap(() => interval(100).pipe(takeUntil(this.stopOB))),
      ).subscribe(() => this.intervalCallback());
    }
    this.stopTokenVerification();
    this.startOB.next();
  }

  stopTokenVerification(): void {
    this.stopOB.next();
  }

  public intervalCallback(): void {
    const isValidToken = this.utilsService.isTokenValid();
    const token = this.utilsService.token;
    if (!token) {
      this.stopTokenVerification();
      return;
    }
    if (!isValidToken || !token) {
      this.stopTokenVerification();
      this.dialogService.openSessionExpiredDialog();
    }
  }
}
