<div class="container">
    <div class="content" fxFlex="100" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayout.lt-md="column nowrap" fxLayoutAlign="space-around center">
            <h1 class="help">
                {{'APP.MARCHES_SECURISES.CONSULTATION.FOOTER.TEXT' | translate | uppercase}}
            </h1>

            <app-hotline></app-hotline>
        </div>

    </div>

</div>
