import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';
import { AvisAttributionItem } from '@atline/core/models/avis-attributions.model';
import { AvisGetService } from '@atline/core/services/avis-get.service';
import { DialogsService } from '@atline/core/services/dialogs.service';

@Component({
  selector: 'app-avis-attribution-item',
  templateUrl: './avis-attribution.component.html',
  styleUrls: ['./avis-attribution.component.scss']
})
export class AvisAttributionItemComponent implements OnInit {

  @Input()
  avis!: AvisAttributionItem;

  public readonly Icons = Icons;

  constructor(
    private readonly downloadavisAttributionsService: AvisGetService,
    private dialogsService: DialogsService,
  ) { }

  ngOnInit(): void {
  }

  goTo(): void {
    this.downloadavisAttributionsService
      .getPiece(this.avis.cle_avis)
      .subscribe(
        (response: HttpResponse<Blob>) => {
          if (response.body !== null) {
            const blob = new Blob([response.body], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = this.avis.fichier_avis;
            link.click();
          }
        },
        () => {
          this.dialogsService.createInfoDialog(
            '',
            'APP.HOME.LANDING.FAQ.ALERT_NOTSUCCESS',
            'APP.CLOSE'
          );
        }
      );
  }

}
