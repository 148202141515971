import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnersComponent } from './partners.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { PartnerModule } from '@atline-shared/components/partner/partner.module';
import { NewsletterModule } from '@atline-shared/components/newsletter/newsletter.module';
import { StudyCaseModule } from '@atline-shared/components/study-case/study-case.module';
import { BlockWhyAtlineModule } from '@atline-shared/components/block-why-atline/block-why-atline.module';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [PartnersComponent],
  imports: [
    CommonModule,
    LayoutModule,
    FlexLayoutModule,
    NgxTranslateModule,
    PartnerModule,
    NewsletterModule,
    StudyCaseModule,
    BlockWhyAtlineModule,
    MatButtonModule
  ],
  exports: [PartnersComponent]
})
export class PartnersModule { }
