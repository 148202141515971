<section class="section-engagement-rse">
  <!-- <h1 fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" class="title-inner"
    [innerHTML]="'APP.HOME.LANDING.ENGAGEMENT.TITLE' | translate | uppercase"></h1> -->

  <app-section-title>
    <div #title>
      {{'APP.HOME.LANDING.ENGAGEMENT.TITLE' | translate | uppercase}}
    </div>
  </app-section-title>

  <h2 class="widthMax" class="subtitle">
    {{ "APP.HOME.LANDING.ENGAGEMENT.TITLE2" | translate | uppercase }}
  </h2>

  <div class="container-rects" fxLayout="column nowrap">

    <section id="growup-green">
      <div class="container-picture picture-tree" fxLayout="row wrap"></div>
      <section class="container-content">
        <h2 class="title-content">
          {{"APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.GROWTH-SHARED.TITLE" | translate | uppercase}}
        </h2>
        <div class="container-text" fxLayout.gt-md="row nowrap" fxLayout.lt-md="column">
          <p class="text" fxFlex.gt-md="60" fxFlex.lt-md="100">
            {{ "APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.GROWTH-SHARED.TEXT" | translate }}
            <br />
            <br />
            {{ "APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.GROWTH-SHARED.TEXT2" | translate }}
          </p>
          <p class="xl-size" fxFlex.gt-md="40" fxFlex.lt-md="100">
            "{{ "APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.GROWTH-SHARED.TEXT3" | translate | uppercase }}"
          </p>
        </div>
      </section>
    </section>




    <div class="container-sections" ngClass.lt-md="mobile" ngClass.xs="container-sections-responsive">

      <section class="purple container-content" fxLayout="column">
        <h2 class="title-content">
          {{ "APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.DIGITAL-SOBRIETY.TITLE" | translate | uppercase }}
        </h2>
        <div class="picture-data-center"></div>
        <div class="container-text" fxLayout.gt-md="row nowrap" fxLayout.lt-md="column">
          <p fxFlex.gt-md="50" fxFlex.lt-md="100">
            {{ "APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.DIGITAL-SOBRIETY.TEXT" | translate }}
          </p>
          <p fxFlex.gt-md="50" fxFlex.lt-md="100">
            {{ "APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.DIGITAL-SOBRIETY.TEXT2" | translate }}
          </p>
        </div>
      </section>
      <section class="blue container-content" fxLayout="column nowrap">
        <h2 class="title-content">
          {{ "APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.SOCIETY-OPEN-INCLUSIVE.TITLE" | translate | uppercase }}
        </h2>
        <div class="container-text" fxLayout="column nowrap">
          <p>
            {{ "APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.SOCIETY-OPEN-INCLUSIVE.TEXT" | translate }}
          </p>
          <div class="last-part" fxLayout="row" fxLayout.lt-md="column">
            <span class="our-philo"
              [innerHTML]="('APP.HOME.LANDING.ENGAGEMENT.ENGAGEMENTS.SOCIETY-OPEN-INCLUSIVE.TEXTHTML' | translate | uppercase)">
            </span>
            <div class="container-lines-color" fxLayout="row nowrap" ngClass.lt-md="mobile">
              <div class="line-color orange" ngClass.lt-md="mobile"></div>
              <div class="line-color purple" ngClass.lt-md="mobile"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>