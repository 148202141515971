// tslint:disable:variable-name

export class DumeItem{
public dateheure_f = '';
public idDumeOE = '';
public ident_dce = '';

}

// tslint:enable:variable-name
