<div class="container" fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="row">
    <img src="/assets/icons/svg/tender.svg" alt="" class="ms_logo">
    <div class="title_ms">
      {{'APP.MARCHES_SECURISES.TITLE_MARCHES' | translate | uppercase}} <br>
      {{'APP.MARCHES_SECURISES.TITLE_SECURISES' | translate | uppercase}}
    </div>
  </div>

  <div class="navLink" fxLayoutAlign="space-around center">
    <mat-nav-list fxLayout="row">
      <ng-container *ngFor="let route of routes">
        <ng-container *ngIf="route.isLink; then withHref; else withRouterLink"></ng-container>

        <ng-template #withHref>
          <a class="link" [id]="route.name" [href]="[route.link]" target="_blank"
            [ngClass]="{active: currentRoute == route.link, 'width-large': checked && widthLarge('text-' + route.name), 'disabled-link': !route.isEnabled}">
            <span [id]="'text-' + route.name" class="text">
              {{'APP.MARCHES_SECURISES.PA.NAV_LINK.' + route.text | translate | uppercase}}
            </span>
          </a>
        </ng-template>
        <ng-template #withRouterLink>
          <a class="link" [id]="route.name" [routerLink]="[route.link]"
            [ngClass]="{active: currentRoute == route.link, 'width-large': checked && widthLarge('text-' + route.name), 'disabled-link': !route.isEnabled}">
            <span [id]="'text-' + route.name" class="text">
              {{'APP.MARCHES_SECURISES.PA.NAV_LINK.' + route.text | translate | uppercase}}
            </span>
          </a>
        </ng-template>
      </ng-container>
      <a class="link" id="space-entreprise" [routerLink]="['../']">
        <span id="text-space-entreprise" class="text">
          {{'APP.MARCHES_SECURISES.PA.NAV_LINK.SPACE-ENTREPRISE' | translate | uppercase}}
        </span>
      </a>
    </mat-nav-list>
  </div>
</div>
