import * as _ from "lodash";
import { createChecker } from "../functions/comparators.functions";

// tslint:disable:variable-name
export class Pagination {
  public page = 1;
  public nbr_par_page = 10;
  public ordre?: string;
  public sens_ordre?: 'DESC' | 'ASC';

  constructor();
  constructor(datas: { [Key in keyof Pagination]?: Pagination[Key] });
  constructor(arg?: { [Key in keyof Pagination]?: Pagination[Key] } | number) {
    if (arg) Object.assign(this, arg);
  }
}
// tslint:enable:variable-name

const checkerPagination = createChecker<Pagination>({
  page: _.isNumber,
  nbr_par_page: _.isNumber,
  ordre: [_.isString, _.isNil],
  sens_ordre: [(arg: any): arg is 'DESC' | 'ASC' => arg === 'DESC' || arg === 'ASC', _.isNil],
});

export function isPagination(arg: any): arg is Pagination {
  return arg instanceof Pagination || checkerPagination(arg);
}
