<div class="home-footer">
  <section class="top-footer">
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="space-around">

        <div class="footer-box address" ngClass.lt-md="mobile" fxFlex.lt-md="100">
          <img class="footer-logo" src="assets/icons/svg/atline-logo_footer.svg" alt="atline-logo" />

          <mat-divider class="horizontal-divider" fxHide.gt-sm="true"></mat-divider>

          <address fxHide.lt-md="true">
            ATLINE Services <br>
            <span> 4 avenue du Recteur Poincaré </span> <br>
            75016 Paris
            <br>
            {{'APP.FOOTER.PHONE' | translate }} <a href="tel: +33155746250">+33 (0)1 55 74 62 50</a> <br>
          </address>
        </div>

        <mat-divider fxHide.lt-md="true" vertical></mat-divider>

        <div class="footer-box pictos" fxFlex.lt-md="100">
          <mat-icon svgIcon="secure"></mat-icon>
          <mat-icon svgIcon="tender"></mat-icon>
          <mat-icon svgIcon="training"></mat-icon>
          <mat-icon svgIcon="sign"></mat-icon><br>
          <mat-icon svgIcon="acte"></mat-icon>
          <mat-icon svgIcon="valid"></mat-icon>
          <mat-icon svgIcon="meeting"></mat-icon>
          <mat-icon svgIcon="helios"></mat-icon>
        </div>

        <mat-divider fxHide.lt-lg="true" vertical></mat-divider>

        <div fxHide.lt-lg="true" class="footer-box textes" fxFlex="31" fxFlex.lt-md="100" fxLayout="row nowrap"
          fxLayoutAlign="space-between center">
          <div class="box">
            <!-- TODO: add a tag for link + scss -->
            <span (click)="openDialogLogin()">{{ 'APP.FOOTER.CREATE_ACCOUNT_COONECT' | translate | uppercase }}</span>
            <span [routerLink]="['/home', 'ourSolutions', 'marches-securises']">{{ 'APP.FOOTER.MS' | translate |
              uppercase }}</span>
            <span [routerLink]="['/home', 'ourSolutions', 'attestations-securisees']">{{ 'APP.FOOTER.AS' | translate |
              uppercase }}</span>
            <span [routerLink]="['/home', 'ourSolutions', 'parapheur-securise']">{{ 'APP.FOOTER.PS' | translate |
              uppercase }}</span>
            <span [routerLink]="['/home', 'ourSolutions', 'convocations-securisees']">{{ 'APP.FOOTER.CS' | translate |
              uppercase }}</span>
          </div>
          <div class="box">
            <span [routerLink]="['/home', 'contactUs']">{{ 'APP.FOOTER.CONTACT_US' | translate | uppercase }}</span>
            <span [routerLink]="['/home', 'ourSolutions']">{{ 'APP.FOOTER.SOLUTIONS' | translate | uppercase }}</span>
            <span [routerLink]="['/home', 'story']">{{ 'APP.FOOTER.STORY' | translate | uppercase }}</span>
            <span [routerLink]="['/home', 'faq']">{{ 'APP.FOOTER.FAQ' | translate | uppercase }}</span>
            <span>
              <a target="_blank" href="https://www.linkedin.com/company/atline-services">
                {{ 'APP.FOOTER.RECRUTEMENT' | translate | uppercase }}
              </a>
            </span>
          </div>
        </div>

        <mat-divider fxHide.lt-md="true" vertical></mat-divider>

        <div fxHide.lt-md="true" class="footer-box dume-trust" fxLayout="column">
          <img src="../../../../assets/icons/svg/Satisfaction_utilisateurs.svg" alt="trustpilot">
        </div>
      </div>
    </div>

  </section>

  <div class="copyright" fxLayout.lt-md="column" fxLayout.gt-md="row" fxLayoutAlign="center center">
    <span>
      {{'APP.FOOTER.COPYRIGHT.YEAR' | translate:{year : year} }}
    </span> -

    <a atlineLink (click)="openLegalNoticeDialog()" class="rgpd">
      {{'APP.FOOTER.COPYRIGHT.LEGAL' | translate }}
    </a>
    -
    <span>
      {{'APP.FOOTER.COPYRIGHT.WEBDESIGN' | translate }}
    </span> -
    <a atlineLink (click)="openRgpdDialog()" class="rgpd">
      {{'APP.FOOTER.COPYRIGHT.RGPD' | translate }}
    </a>
  </div>

  <div class="version">
    Version {{appVersion}}
  </div>
</div>