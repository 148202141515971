import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  TypeStrucutre,
  typesStructure,
} from '@atline/core/models/typesStructure.model';
import { DialogsService } from '@atline/core/services/dialogs.service';
import {
  SearchStructureService,
  structure,
} from '@atline/core/services/search-structure.service';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MailService } from '@atline/core/services/mail.service';
import { Icons } from '@atline-shared/enums/icons.enum';
import { EMAIL_REGEX } from '@atline/core/models/constant-variable/regex.model';
import FormHelper from '@atline/core/helpers/form.helper';

function nameValidator(control: AbstractControl): { [key: string]: any } | null {
  const nameRegex = /^[A-Za-z\s']+$/;
  return nameRegex.test(control.value) ? null : { invalidName: true };
}

function identifiantValidation(typeControl: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (typeControl.value instanceof TypeStrucutre) {
      const { pattern } = typeControl.value;
      return pattern ? Validators.pattern(pattern)(control) : null;
    }
    return null;
  };
}

function phoneValidator(control: AbstractControl): ValidationErrors | null {
  const phonePattern = /^(\+?\d{11,12}|\d{10})$/;
  const phoneValue = control.value;
  if (phoneValue) {
    if (phoneValue.startsWith('0')) {
      return phoneValue.length === 10 ? null : { invalidPhone: true };
    } else {
      return phonePattern.test(phoneValue) ? null : { invalidPhone: true };
    }
  }
  return { required: true };
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [SearchStructureService, MailService],
})
export class ContactFormComponent implements OnInit {

  readonly types = typesStructure;

  readonly Icons = Icons;

  public submitted = false;
  public filteredOptions!: Observable<structure[]>;

  readonly typeForm = new FormControl(this.types[0], Validators.required);
  readonly contactForm = new FormGroup({
    lastName:   new FormControl('', [Validators.required, nameValidator]),
    firstName:  new FormControl('', [Validators.required, nameValidator]),
    companyID:  new FormControl('', [Validators.required, identifiantValidation(this.typeForm),]),
    email:      new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
    phone:      new FormControl('', [Validators.required, phoneValidator]),
    subject:    new FormControl('', []),
    message:    new FormControl('', [Validators.required]),
  });

  displayError = FormHelper.displayError

  constructor(
    private searchStructure: SearchStructureService,
    private dialogsService: DialogsService,
    private mailService: MailService,
  ) { }

  ngOnInit(): void {
    this.searchStructure.type = this.typeForm.value.value;
    this.searchStructure.application = 'all';
    this.searchStructure.MAX_LENGTH = 3;
    this.filteredOptions = this.searchStructure.sync(
      this.typeForm.valueChanges.pipe(map(item => item instanceof TypeStrucutre ? item.value : item)),
      this.contactForm.controls.companyID.valueChanges.pipe(debounceTime(500))
    ).pipe(map((structures) => structures.splice(0, 6)));
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      const data = this.contactForm.value;
      this.mailService.sendMail(data).subscribe({
        next: () => {
          this.contactForm.reset();
          Object.keys(this.contactForm.controls).forEach((key) => {
            const control = this.contactForm.controls[key];
            control.markAsUntouched();
            control.markAsPristine();
          });

          this.dialogsService.createInfoDialog(
            'APP.HOME.LANDING.CONTACT_US.ENVOIE_SUCCES',
            'APP.HOME.LANDING.CONTACT_US.DIALOG_SUCCES',
            'APP.CLOSE',
            true,
            undefined,
            this.contactForm.value
          );
        },
        error: () => this.dialogsService.createInfoDialog(
          'APP.HOME.LANDING.CONTACT_US.ERROR',
          'APP.HOME.LANDING.CONTACT_US.ALERT_NOTSUCCES',
          'APP.CLOSE'
        ),
      });
      return;
    }
    this.dialogsService.createInfoDialog(
      'APP.HOME.LANDING.CONTACT_US.ERROR',
      'APP.HOME.LANDING.CONTACT_US.ALERT_NOTSUCCES',
      'APP.CLOSE'
    );
  }

}
