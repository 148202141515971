<div class="webSign" fxLayout="column" fxLayoutGap="17px">
  <app-page-title *ngIf="!mediaObserver.isActive('lt-md')" key="VERIF_SIGNA"></app-page-title>


  <ng-container *ngIf="isConnected; then thenTemplate; else elseTemplate"></ng-container>
  <ng-template #thenTemplate>
    <app-conf-bar-websign-verify (confChange)="confBarData = $event"></app-conf-bar-websign-verify>

    <section class="upload-file-container" fxLayout="column" fxLayoutGap="30px">
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="30px">
        <div fxFlex="50" class="upload" appDragDrop (files)="uploadFile($event, 'file')">
          <h2>{{ "APP.MARCHES_SECURISES.SIGNATURE.DOC_VERIF" | translate }}</h2>
          <div fxLayout="column" fxLayoutAlign="center center" class="uploading">
            <input class="input-field-hidden" type="file" name="signedFile"
              (change)="uploadFile($event.target, 'file')" />
            <ng-container *ngIf="fileFileName; else elseTemplate">
              <span>{{fileFileName}}</span>
            </ng-container>
            <ng-template #elseTemplate>
              <mat-icon svgIcon="plus"></mat-icon>
            </ng-template>
          </div>
        </div>
        <div *ngIf="confBarData.hasAttachedFile" fxFlex="50" class="upload" appDragDrop
          (files)="uploadFile($event, 'token')">
          <h2>{{ "APP.MARCHES_SECURISES.SIGNATURE.TOKEN_VERIF" | translate }}</h2>
          <div fxLayout="column" fxLayoutAlign="center center" class="uploading">
            <input class="input-field-hidden" type="file" name="tokenFile"
              (change)="uploadFile($event.target, 'token')" />
            <ng-container *ngIf="tokenFileName; else elseTemplate">
              <span>{{tokenFileName}}</span>
            </ng-container>
            <ng-template #elseTemplate>
              <mat-icon svgIcon="plus"></mat-icon>
            </ng-template>
            <!-- <span *ngIf="!checkCoherenceTokenAndFile" class="bad-token-coherence">
              {{ "APP.MARCHES_SECURISES.SIGNATURE.BAD_TOKEN" | translate }}
            </span> -->
          </div>
        </div>
      </div>
      <div class="start-verif-container" fxLayout="row" fxLayoutAlign="end center">
        <app-button (clickButton)="callWsForVerification()" [disabled]="disabledVerifyButton">
          <ng-container *ngIf="isLoading; else labelTemplate">
            <mat-spinner [style.margin]="'auto'" [diameter]="16"></mat-spinner>
          </ng-container>
          <ng-template #labelTemplate>
            {{ "APP.MARCHES_SECURISES.SIGNATURE.START_VERIF" | translate }}
          </ng-template>
        </app-button>
      </div>
    </section>

    <div *ngIf="response" class="upload" fxLayout="column">
      <div class="upload" fxLayout="column">
        <h2>{{ "APP.MARCHES_SECURISES.SIGNATURE.SIGNA" | translate }}</h2>
        <div fxLayout="column" fxLayoutAlign="start">
          <app-file-signtaure [file]="file" *ngFor="let file of response.files"></app-file-signtaure>
        </div>
      </div>
    </div>

    <div *ngIf="hasError">
      {{ "APP.MARCHES_SECURISES.SIGNATURE.ERROR" | translate }}
    </div>
  </ng-template>
  <ng-template #elseTemplate>
    <p class="text-center">{{ "APP.MARCHES_SECURISES.SIGNATURE.NOT_CONNECTED" | translate }}</p>

  </ng-template>

</div>
