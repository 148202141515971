import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { GetAliasRequest } from '../models/api/requests/alias.request';
import { AliasResponse } from '../models/api/responses/Alias.response';

@Injectable({
  providedIn: 'root'
})
export class AliasService {

  constructor(
    private readonly api: ApiService
  ) { }

    getAlias(req: GetAliasRequest): Observable<AliasResponse> {
      return this.api.post(AliasResponse, req, 'pa_alias_entreprises');
    }
}
