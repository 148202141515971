import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConsultationRequest } from '../models/api/requests/consultation.request';
import { ConsultationResponse } from '../models/api/responses/consultations.response';
import { ConsultationItem } from '../models/consultationItem.model';
import { ParamsPaConsultation } from '../models/pa/params/ParamsPaConsultation.model';

@Injectable({
  providedIn: 'root'
})
export class ConsultationsDataService {

  private consultationReponseData = new BehaviorSubject<ConsultationResponse>(new ConsultationResponse());
  consultationReponse = this.consultationReponseData.asObservable();

  private consultationListData = new BehaviorSubject(Array<ConsultationItem>());
  consultationList = this.consultationListData.asObservable();

  private consultationData = new BehaviorSubject(new ConsultationItem());
  consultation = this.consultationData.asObservable();

  private searchData = new BehaviorSubject(new ConsultationRequest());
  searchForm = this.searchData.asObservable();

  private paramsPAConsultationsData = new BehaviorSubject(new ParamsPaConsultation());
  paramsPAConsultations = this.paramsPAConsultationsData.asObservable();

  constructor(
  ) {}

  getConsultationReponse(): Observable<ConsultationResponse> {
    return this.consultationReponse;
  }

  setConsultationReponse(cr: ConsultationResponse): void {
    this.consultationReponseData.next(cr);
  }

  getConsultationList(): Observable<Array<ConsultationItem>>{
    return this.consultationList;
  }

  setConsultationList(a: Array<ConsultationItem>): void {
    this.consultationListData.next(a);
  }

  getConsultation(): Observable<ConsultationItem> {
    return this.consultation;
  }

  setConsultation(c: ConsultationItem): void {
    this.consultationData.next(c);
  }

  getSearchForm(): Observable<ConsultationRequest> {
    return this.searchForm;
  }

  setSearchForm(s: ConsultationRequest): void {
    this.searchData.next(s);
  }

  getParamsPAConsultations(): Observable<ParamsPaConsultation> {
    return this.paramsPAConsultations;
  }

  setParamsPAConsultations(params: ParamsPaConsultation): void {
    this.paramsPAConsultationsData.next(params);
  }

}
