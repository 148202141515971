import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { AccountItem } from '@atline/core/models/accounts.model';
import { AffaireItem } from '@atline/core/models/affaireItem.model';
import { AccountsResponse } from '@atline/core/models/api/responses/annonce/accounts.response';
import { EditAnnonceResponse } from '@atline/core/models/api/responses/annonce/editAnnonce.response';
import { PaToken } from '@atline/core/models/api/responses/authenticate.response';
import { FormulaireItem } from '@atline/core/models/api/responses/formualiresList.response';
import { AffaireService } from '@atline/core/services/affaire.service';
import { AnnoncesService } from '@atline/core/services/annonces.service';
import { GlobalDataService } from '@atline/core/services/data-service.service';
import { UtilsService } from '@atline/core/services/utils.service';
import * as _ from 'lodash';
import { Observable, Subscription, forkJoin, iif } from 'rxjs';
import { filter, map, mergeMap, skip, switchMap, take, tap } from 'rxjs/operators';

interface ForkPa {
  formulaires: FormulaireItem[];
  accounts: AccountsResponse;
  affaires: AffaireItem[];
}

@Component({
  selector: 'app-create-annonce',
  templateUrl: './create-annonce.component.html',
  styleUrls: ['./create-annonce.component.scss']
})
export class CreateAnnonceComponent implements OnInit, OnDestroy {

  readonly listPa: PaToken[] = [];
  readonly listFormulaires: FormulaireItem[] = [];
  readonly listAccounts: AccountItem[] = [];
  readonly listAffaires: AffaireItem[] = [];

  private readonly subs = new Subscription();

  public na = true;

  // CONTROLS
  private readonly paFC = new FormControl('', Validators.required);
  private readonly intituleAffFC = new FormControl('');
  private readonly formFC = new FormControl('fns_simple_initial', Validators.required);
  private readonly compteBoampFC = new FormControl('');

  // FORM
  readonly form = new FormGroup({
    pa: this.paFC,
    intituleAff: this.intituleAffFC,
    typeForm: this.formFC,
    compteBoamp: this.compteBoampFC,
  });

  readonly cleEtab = this.utils.cleEtab || '';

  public loading = false;
  public annonceCreated?: EditAnnonceResponse;

  constructor(
    readonly dialogRef: MatDialogRef<CreateAnnonceComponent>,
    private readonly globalData: GlobalDataService,
    private readonly utils: UtilsService,
    private readonly annonces: AnnoncesService,
    private readonly affaires: AffaireService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.loading = true;
    const endLoading = (): void => {
      this.initChanges();
      this.loading = false;
    };

    this.globalData.listPa.pipe(
      take(1),
      tap(list => this.resetListeType(this.listPa, list.filter(pa => pa.cle_annonceur), this.paFC, 'cle_pa')),
      mergeMap(() => this.asyncGetFormulairesAndAccounts(this.paFC.value || '')),
      tap(({ formulaires, accounts, affaires }) => {
        this.resetListeType(this.listFormulaires, formulaires, this.formFC, 'ref_formulaire');
        this.resetListeType(this.listAccounts, accounts, this.compteBoampFC, 'cle_compte');
        this.resetListeType(this.listAffaires, affaires);
      })
    ).subscribe(endLoading, endLoading);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initChanges(): void {
    const listPAChange = this.globalData.listPa.pipe(
      skip(1),
      filter(res => !_.isEqual(res.sort(), this.listPa.sort()))
    ).subscribe(res => this.resetListeType(this.listPa, res.filter(pa => pa.cle_annonceur), this.paFC, 'cle_pa'));
    this.subs.add(listPAChange);


    const paChange = this.paFC.valueChanges.pipe(
      mergeMap(clePa => this.asyncGetFormulairesAndAccounts(clePa))
    ).subscribe(({ formulaires, accounts, affaires }) => {
      this.resetListeType(this.listFormulaires, formulaires, this.formFC, 'ref_formulaire');
      this.resetListeType(this.listAccounts, accounts, this.compteBoampFC, 'cle_compte');
      this.resetListeType(this.listAffaires, affaires);
    });
    this.subs.add(paChange);
  }

  getCleAnnonceur(clePa: string): string |  undefined {
    return _.find(this.listPa, ['cle_pa', clePa])?.cle_annonceur;
  }

  private resetListeType<T>(array: T[], newArray: T[], control?: FormControl, key?: keyof T): void {
    array.length = 0;
    array.push(...newArray);
    if (control && !(key ?  _.some(array, [key, control.value]) : _.some(array, control.value))) {
      const value: any = array[0];
      control.setValue((key ? (value?.[key]) : value) || '');
    }
  }

  private asyncGetFormulairesAndAccounts(clePa: string): Observable<ForkPa> {
    const formulaires = this.annonces
      .getFormulaires({ cle_etab: this.cleEtab, cle_pa: clePa })
      .pipe(map(res => res.formulaires));

    const accounts = this.annonces
      .getAccounts({ cle_etab: this.cleEtab, cle_pa: clePa, cle_annonceur: this.getCleAnnonceur(clePa) || '' });

    const affaires = this.affaires
      .getAffaire({ cle_etab: this.cleEtab, cle_pa: clePa })
      .pipe(map(res => res.affaires));

    return forkJoin({ formulaires, accounts, affaires });
  }

  submit(): void {
    if (this.form.valid) {
      this.loading = true;
      iif(
        () => this.na && this.intituleAffFC.value,
        this.createAffaireAnnonce(),
        this.createAnnonceObs(this.intituleAffFC.value || undefined)
      ).subscribe((res) => {
        this.annonceCreated = res;
        this.loading = false;
      });
      return;
    }
    this.form.markAllAsTouched();
  }

  naChange(): void {
    this.intituleAffFC.setValue('');
  }

  private createAffaireAnnonce(): Observable<EditAnnonceResponse> {
    if (this.intituleAffFC.value) {
      const affObs = this.affaires.createAffaire({
        cle_etab: this.cleEtab,
        cle_pa: this.paFC.value,
        objet_affaire: this.intituleAffFC.value
      });

      return affObs.pipe(switchMap(({ cle }) => this.createAnnonceObs(cle)));
    }
    return this.createAnnonceObs();
  }

  private createAnnonceObs(cleAffaire?: string): Observable<EditAnnonceResponse> {
    return this.annonces.editAnnonce({
      cle_etab: this.cleEtab,
      cle_pa: this.paFC.value,
      type_formulaire: this.formFC.value,
      lib_form: _.find(this.listFormulaires, ['ref_formulaire', this.formFC.value])?.nom_formulaire,
      cle_affaire: cleAffaire,
      cle_compte: this.compteBoampFC.value,
      cle_annonceur: this.getCleAnnonceur(this.paFC.value) || '',
    });
  }

  redirection(): void {
    if (this.annonceCreated) {
      this.router.navigate([
        RoutingEnum.MARCHES_SECURISES,
        RoutingEnum.PA,
        RoutingEnum.ANNNOUNCE,
        RoutingEnum.EDIT,
        this.annonceCreated.cle_annonce
      ]);
      this.dialogRef.close();
    }
  }

}
