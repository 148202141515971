<div class="modal-container">
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="header">
    <div fxLayout="column">
      <h2 class="title">{{'APP.MARCHES_SECURISES.CONSULTATION.DERIVATION.DIALOG.TITLE' | translate: {consultationId:
        consultationId} }}</h2>
      <h3 class="text-thin">{{objet}}</h3>
    </div>

    <button mat-button mat-dialog-close class="close"></button>
  </div>
  <div mat-dialog-content class="body">
    <div class="option-title">
      {{'APP.MARCHES_SECURISES.CONSULTATION.DERIVATION.DIALOG.OPTIONS' | translate }}
    </div>
    <div class="options" *ngIf="options">
      <div *ngFor="let option of options">
        <ng-container *ngIf="option.visibility">
          <mat-checkbox class="option" [(ngModel)]="option.value" color="primary">
            {{'APP.MARCHES_SECURISES.CONSULTATION.DERIVATION.DIALOG.' + option.key | translate}}
          </mat-checkbox>
        </ng-container>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <button mat-raised-button
      [mat-dialog-close]="options">{{'APP.MARCHES_SECURISES.CONSULTATION.DERIVATION.DIALOG.BUTTON' | translate
      }}</button>
  </div>

</div>
