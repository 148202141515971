export class WebSendReportResponse {

    // tslint:disable:variable-name

    reponse = {
        cle_reponse: '',
        cle_dce: '',
        identifiant_dce: '',
        cle_pa: '',
        identifiant_pa: '',
        date_reponse: ''
      };
      documents = [
        {
          enveloppe: '',
          uploaded_files: [
            {
              id_fichier: '',
              nom_fichier: '',
              repertoire_fichier: '',
              type_fichier: '',
              taille_fichier: '',
              path_fich: ''
            }
          ]
        }
      ];
      nb_total = 0;

      // tslint:enable:variable-name
}

export function isWebSendReportResponse(value: any): value is WebSendReportResponse {
  return value !== undefined && value !== null && value instanceof WebSendReportResponse;
}
