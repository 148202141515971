import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MethodRequest } from '@atline-shared/enums/method-request.enum';
import { LogsExportsExchangeWithOe } from '../models/api/requests/logsExport.request';

@Injectable({
  providedIn: 'root'
})
export class LogsExportService {

  constructor(
    private readonly http: HttpClient,
    private readonly apiService: ApiService
  ) { }

  getExchangeWithOe(req: LogsExportsExchangeWithOe): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}dce_journal_echanges_oe`, req,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }


}
