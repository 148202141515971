<app-dialog [dialogRef]="dialogRef">
  <span header>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.TITLE' | translate }}</span>
  <table *ngIf="users.length" class="users-table" body mat-table [dataSource]="users">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.HEADER_NAME' | translate }}</th>
      <td mat-cell *matCellDef="let user" >{{ user.firstName }}</td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.HEADER_PRENOM' | translate }}</th>
      <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button [disabled]="true" (click)="isDeleteDisabled ? null : deleteUser()">
          <mat-icon svgIcon="bin"></mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let user; columns: displayedColumns;"></tr>
  </table>
  <div body *ngIf="users.length === 0" class="empty-list-message">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.EMPTY_USER' | translate }}</div>
</app-dialog>
