export class WebSendSendResponse {
    // tslint:disable:variable-name

    public cle_rep = '';

    public cle_dce = '';

    public depose = 1;

    public date_envoi_rep = 0;

    public date_envoi_rep_f = '';

    public hors_delai = 0;

    // tslint:enable:variable-name

}
