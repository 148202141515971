<div class="container-navigator" fxLayout="row" fxLayoutAlign="center center">
    <img src="/assets/icons/svg/logo-ats.svg" alt="" class="ats_logo" routerLink="./">

    <div class="navLink" fxLayoutAlign="space-around center">
        <mat-nav-list>
            <a class="button-nav" *ngFor="let link of links" [routerLink]="link" routerLinkActive="in-page">
                {{ 'APP.HOME.NAVIGATION.' + (link | uppercase) | translate | uppercase }}
            </a>
        </mat-nav-list>
    </div>
</div>
