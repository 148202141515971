import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-key-moments',
  templateUrl: './key-moments.component.html',
  styleUrls: ['./key-moments.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: KeyMomentsComponent }],
})
export class KeyMomentsComponent extends CdkStepper implements OnInit {

  constructor(dir: Directionality, cdr: ChangeDetectorRef) {
    super(dir, cdr);
  }

  ngOnInit(): void {
  }

  goMoment(index: number): void {
    this.selectedIndex = index;
  }

}
