export enum PeriodOfYear {
  FULL = 'full',
  ERS1 = '1ers',
  NDS2 = '2nds',
  ERT1 = '1ert',
  NDT2 = '2ndt',
  ET3 = '3et',
  ET4 = '4et'
}

export function getPeriodKey(period: PeriodOfYear): string {
  const o = {
    [PeriodOfYear.FULL]: 'FULL',
    [PeriodOfYear.ERT1]: 'FIRST_TRIMESTRE',
    [PeriodOfYear.ERS1]: 'FIRST_SEMESTRE',
    [PeriodOfYear.ET3]: 'THIRD_TRIMESTRE',
    [PeriodOfYear.ET4]: 'FOURTH_TRIMESTRE',
    [PeriodOfYear.NDS2]: 'SECOND_SEMESTRE',
    [PeriodOfYear.NDT2]: 'SECOND_TRIMESTRE',
  };
  return o[period];
}
