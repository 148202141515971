import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './footer.component';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import {MaterialModule} from '../../../core/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '@atline-shared/directive/directive.module';



@NgModule({
  declarations: [FooterComponent],
  exports: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    NgxTranslateModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    DirectiveModule
  ]
})
export class FooterModule { }
