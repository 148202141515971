<div>
  <ng-container *ngIf="true; then spinner; else list"></ng-container>
  <ng-template #spinner>
    <div fxLayout="row" fxLayoutAlign="center center">
      <app-loading-spinner key="DE_OE_LIST"></app-loading-spinner>
    </div>

  </ng-template>
  <ng-template #list>
    <ng-container *ngIf="hasDe; then listContent; else noDtata"></ng-container>
    <ng-template #listContent>
      <nav class="nav-de" *ngIf="plateformPES">
        <a atlineLink [href]="downloadLink" target="_blank" class="link-btn" fxLayout="row"
          fxLayoutAlign="start center">
          <app-button [size]="'medium'" [icon]="iconFile" *ngIf="plateformPES"
            [title]="'APP.MARCHES_SECURISES.ESSENTIALS_DATA.DOWNLOAD.JSON'"></app-button>
          <span class="label-buton">{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.DOWNLOAD.JSON' | translate}}</span>
        </a>
      </nav>
      <div class="deListComponentItem">
        <app-de *ngFor="let de of deListForDisplay" [de]="de"></app-de>
      </div>

    </ng-template>
    <ng-template #noDtata>
      <p class="no-result">{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.NO_RESULT' | translate}}</p>
    </ng-template>

    <app-pagination *ngIf="pagination" [nbConsultation]="nbEssentialData" [pagination]="pagination"
      (changePage)="changePage($event)" (changeNbParPage)="changeNbParPage($event)"></app-pagination>
  </ng-template>
  <div #endDeList></div>
</div>
