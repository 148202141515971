import {Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface InformationsData {
  [name: string]: string | number;
}

export class InfoDialogData {
  title = '';
  description = '';
  actionTitle = '';
  informations?: InformationsData = undefined;
}

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})

export class InfoDialogComponent {
  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: InfoDialogData) { }
}
