import { Component, OnInit, HostListener } from '@angular/core';

/**
 * Main landing page of Atline-services
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])
  windowsScroll(): void {
    this.isOnTop = window.scrollY === 0;
  }

  isOnTop = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.isOnTop = window.scrollY === 0;
  }

}
