import { FileMS } from '../../file.model';
import { FileItem } from './fileItem.reponse';
export class WebsendStructureInternResponse {
  // tslint:disable:variable-name
  structure!: WebsendStructureInternStructureItem[];
  nb_total!: number;
  structure_info!: string;
  // tslint:enable:variable-name
}

export class WebsendStructureInternStructureItem {
  // tslint:disable:variable-name
  libelle!: string;
  str_env_id!: string;
  cle_lot!: string;
  type!: string;
  code!: string;
  documents!: Array<FileMS | FileItem>;
  selected!: boolean;
  // tslint:enable:variable-name
}
