<ng-container *ngIf="isButtonVersion(); else sidebar">
  <button #btn mat-flat-button class="btn-hamburger" (click)="openClose()">
    <div class="hamburger">
      <div class="hamburger-bar"></div>
      <div class="hamburger-bar"></div>
      <div class="hamburger-bar"></div>
    </div>
  </button>
  <ng-template *ngIf="overlayOrigin" cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayOrigin"
    [cdkConnectedOverlayOpen]="open"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    [cdkConnectedOverlayWidth]="overlayOrigin.elementRef.nativeElement.getBoundingClientRect().width"
    [cdkConnectedOverlayPositions]="[positionOverlay]"
    [cdkConnectedOverlayPanelClass]="'background-sidebar'">
    <ng-container [ngTemplateOutlet]="sidebar"
      [ngTemplateOutletContext]="{ style: {
          backgroundColor: 'white',
          width: '100%',
          padding: '10px 5px',
          border: '1px solid gray'
        }
      }">
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #sidebar let-style="style">
  <div #sidebarElement class="container-sidebar" [class.open]="open"
    fxLayout="column"
    fxLayoutGap="15px"
    fxLayoutAlign="start stretch"
    [ngStyle]="style">
    <ng-content select="btn-side-bar, [content-sidebar]"></ng-content>
  </div>
</ng-template>
