import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvisAttributionComponent } from './avis-attribution.component';

import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { DesignModule } from '@atline-shared/design/design.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HotlineModule } from '@atline-shared/components/hotline/hotline.module';
import { MaterialModule } from '@atline/core/material/material.module';
import { PaginationModule } from "../../shared/components/pagination/pagination.module";

import { RouterModule } from '@angular/router';
import { PartnerModule } from '@atline-shared/components/partner/partner.module';
import { AvisAttributionListComponent } from './avis-attribution-list/avis-attribution-list.component';
import { SearchFormModule } from '@atline-shared/design/search-form/search-form.module';
import { PageTitleModule } from '@atline-shared/components/page-title/page-title.module';
import { AvisAttributionItemComponent } from './avis-attribution/avis-attribution.component';
import { MarchesContainerModule } from '@atline-shared/design/marches-container/marches-container.module';




@NgModule({
    declarations: [AvisAttributionComponent,AvisAttributionListComponent,AvisAttributionItemComponent],
    exports: [
        AvisAttributionComponent
    ],
    imports: [
        CommonModule,
        FlexModule,
        FlexLayoutModule,
        DesignModule,
        FormsModule,
        ReactiveFormsModule,
        PartnerModule,
        HotlineModule,
        TranslateModule,
        RouterModule,
        MaterialModule,
        PaginationModule,
        SearchFormModule,
        PageTitleModule,
        MarchesContainerModule
    ]
})
export class AvisAttributionModule { }
