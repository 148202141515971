import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionEngagementRseComponent } from './section-engagement-rse.component';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/core/material/material.module';
import { GoTopModule } from './../../shared/components/go-top/go-top.module';
import { SectionTitleModule } from '@atline-shared/components/section-title/section-title.module';

@NgModule({
  declarations: [SectionEngagementRseComponent],
  imports: [
    CommonModule,
    NgxTranslateModule,
    FlexLayoutModule,
    MaterialModule,
    GoTopModule,
    SectionTitleModule
  ],
  exports: [SectionEngagementRseComponent],
})
export class SectionEngagementRseModule { }
