import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { environment } from 'src/environments/environment';
import {
  AddEmailDemandeComp,
  DeleteDemandeCompRequest,
  DeleteEmailDcRequest,
  GetFileResponseRequest,
  LrarReceiptRequest,
  SendDemandeCompRequest
} from '../models/api/requests/demandeComplementaire.request';
import { EditDemandeCompRequest, GetDemandeComplementaireRequest } from '../models/api/requests/demandeComplementaire.request';
import { DefaultResponse } from '../models/api/responses/default.response';
import { DeleteDemandeCompResponse } from '../models/api/responses/demandesComps/demandeComplementaire.response';
import { GetDemandeComplementaireResponse } from '../models/api/responses/demandesComps/demandeComplementaire.response';
import { EntDemCompItem } from '../models/entDemCompItem.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DemandeCompService {

  private static BASE_PATH = 'demande_comp_pa';


  constructor(private readonly apiService: ApiService, private http: HttpClient) { }

  /**
   * get list of demande complementaire
   * @param req data
   * @returns observable
   */
  public getDC(req: GetDemandeComplementaireRequest): Observable<GetDemandeComplementaireResponse> {
    return this.apiService.post(GetDemandeComplementaireResponse, req, `${DemandeCompService.BASE_PATH}/demandes_list`);
  }

  /**
   * edit or create a demande completmentaire
   * @param req data
   * @returns observable
   */
  public editDC(req: EditDemandeCompRequest): Observable<EntDemCompItem> {
    const formData: FormData = new FormData();
    formData.append('cle_etab', req.cle_etab);
    formData.append('cle_dce', req.cle_dce);
    formData.append('cle_pa', req.cle_pa);
    formData.append('cle_entreprise_destination', req.cle_entreprise_destination);
    formData.append('date_envoi_notification', req.date_envoi_notification);
    formData.append('date_limit_reponse', req.date_limit_reponse);
    formData.append('fichier', req.fichier, req.fichier.name);
    formData.append('type', req.type ? '1' : '0');
    formData.append('cle_demande', !_.isUndefined(req.cle_demande) ? req.cle_demande : '');
    return this.apiService.post(EntDemCompItem, formData, `${DemandeCompService.BASE_PATH}/demande_edit`);
  }

  /**
   * delete a demande complementaire
   * @param req req data
   * @returns observable
   */
  public deleteDC(req: DeleteDemandeCompRequest): Observable<DeleteDemandeCompResponse> {
    return this.apiService.post(DeleteDemandeCompResponse, req, `${DemandeCompService.BASE_PATH}/demande_remove`);
  }

  /**
   * envoyer les demandes complementaires aux destinataires
   * @param req req data
   * @returns observable
   */
  public sendDC(req: SendDemandeCompRequest): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, req, `${DemandeCompService.BASE_PATH}/demande_send`);
  }

  /**
   * ajout de l'email d'un destinataire
   * @param req req data
   * @returns observable
   */
  public addEmailDC(req: AddEmailDemandeComp): Observable<EntDemCompItem> {
    return this.apiService.post(EntDemCompItem, req, `${DemandeCompService.BASE_PATH}/email_add`);
  }


  /**
   * return email from DC
   * @param req req data
   * @returns observable
   */
  public deleteEmailDC(req: DeleteEmailDcRequest): Observable<EntDemCompItem> {
    return this.apiService.post(EntDemCompItem, req, `${DemandeCompService.BASE_PATH}/email_remove`);
  }

  /**
   * Get file response of DC
   * @param req req data
   * @param report is result is report
   * @returns observable
   */
  public getResponseFile(req: GetFileResponseRequest, report = false): Observable<HttpResponse<any>> {
    const path = report ? 'dc_websend_report_pdf' : `${DemandeCompService.BASE_PATH}/reponse_file_get`;
    return this.http.post(
      environment.api + path, req,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  public getLrarReciept(req: LrarReceiptRequest): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.api}${DemandeCompService.BASE_PATH}/pdf_accuse_reception`, req,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }


  /**
   * get file upload on DC
   * @param req req data
   * @param report is result is report
   * @returns Observable
   */
  public getFileDc(req: GetFileResponseRequest): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.api}${DemandeCompService.BASE_PATH}/demande_piece_get`, req,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
}
