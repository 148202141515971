import { SearchComponent } from './search/search.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AvisAttributionComponent } from './avis-attribution/avis-attribution.component';
import { DonnesEssenciellesComponent } from './donnes-essencielles/donnes-essencielles.component';
import { FormInscriptionComponent } from './form-inscription/form-inscription.component';
import { PaComponent } from './pa/pa.component';
import { UsefulLinkComponent } from './useful-link/useful-link.component';
import { HistoriqueComponent } from './historique/historique.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { ROLE_PA } from '../core/models/constant-variable/role.model';
import { ConsultationComponent } from './consultation/consultation.component';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { PartnersComponent } from './partners/partners.component';
import { ElrarAcceptanceComponent } from './elrar-acceptance/elrar-acceptance.component';
import { StoryComponent } from '@atline/home/story/story.component';
import { FaqComponent } from '@atline-shared/components/faq/faq.component';
import { CertificatSignatureComponent } from '@atline-shared/components/certificat-signature/certificat-signature.component';
import { PageNotFoundComponent } from '@atline-shared/components/page-not-found/page-not-found.component';

const msRoutes: Routes = [
  { path: '', component: SearchComponent },
  { path: 'elrar/:cleDce/:context/:key/:action', component: ElrarAcceptanceComponent },
  {
    path: RoutingEnum.AVIS_ATTRIBUTION,
    component: AvisAttributionComponent,
    loadChildren: async () => (await import('./avis-attribution/avis-attribution.module')).AvisAttributionModule
  },
  {
    path: RoutingEnum.DONNEES_ESSENTIELLES,
    component: DonnesEssenciellesComponent,
    loadChildren: async () => (await import('./donnes-essencielles/donnes-essencielles.module')).DonnesEssenciellesModule
  },
  {
    path: RoutingEnum.PARTENAAIRE_REFERENCE,
    component: PartnersComponent,
    loadChildren: async () => (await import('./partners/partners.module')).PartnersModule
  },
  {
    path: RoutingEnum.FAQ,
    component: FaqComponent,
    loadChildren: async () => (await import('../shared/components/faq/faq.module')).FaqModule
  },
  {
    path: RoutingEnum.WHO_WE_ARE,
    component: StoryComponent,
    loadChildren: async () => (await import('./who-are-we/who-are-we.module')).WhoAreWeModule
  },
  {
    path: RoutingEnum.USEFULL_LINKS,
    component: UsefulLinkComponent,
    loadChildren: async () => (await import('./useful-link/useful-link.module')).UsefulLinkModule
  },
  {
    path: RoutingEnum.INSCRIPTION,
    component: FormInscriptionComponent,
    loadChildren: async () => (await import('./form-inscription/form-inscription.module')).FormInscriptionModule
  },
  {
    path: RoutingEnum.PARTENAAIRE_REFERENCE,
    component: PartnersComponent,
    loadChildren: async () => (await import('./partners/partners.module')).PartnersModule
  },
  {
    path: RoutingEnum.PA,
    component: PaComponent,
    canActivate: [AuthGuard],
    data: { role: ROLE_PA, redirect: [RoutingEnum.MARCHES_SECURISES] },
    loadChildren: async () => (await import('./pa/pa.module')).PaModule
  },
  {
    path: RoutingEnum.SIGNATURE,
    component: CertificatSignatureComponent,
    loadChildren: async () => (await import('../shared/components/certificat-signature/certificat-signature.module')).CertificatSignatureModule
  },
  {
    path: RoutingEnum.DE,
    component: DonnesEssenciellesComponent,
    loadChildren: async () => (await import('./donnes-essencielles/donnes-essencielles.module')).DonnesEssenciellesModule
  },
  {
    path: RoutingEnum.HISTORIQUE,
    canActivate: [AuthGuard],
    data: { redirect: [RoutingEnum.MARCHES_SECURISES], },
    component: HistoriqueComponent,
    loadChildren: async () => (await import('./historique/historique.module')).HistoriqueModule,
  },
  {
    path: RoutingEnum.CONSULTATION,
    component: ConsultationComponent,
    loadChildren: async () => (await import('./consultation/consultation.module')).ConsultationModule
  },
  { path: RoutingEnum.ERROR_404, component: PageNotFoundComponent },
  { path: '**', redirectTo: RoutingEnum.ERROR_404, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(msRoutes)],
  exports: [RouterModule],
})
export class MsRoutingModule { }
