// tslint:disable:variable-name

export class PieceItem {
    nom = '';
    libelle = '';
    type?: string;
    cle = '';
    extension?: string;
    type_mime?: string;
    taille?: string;
    lien?: string;
    is_aapc?: number;
}

export class PieceItem2 {
    cle_piece = '';
    cle_dce = '';
    cle_lot = '';
    date_mise_en_ligne = '';
    date_mise_en_ligne_f = '';
    ordre = 0;
    nom = '';
    libelle = '';
    file_mime_type = '';
    cle = '';
    type_mime = '';
    lien = '';
    file_name = '';
    file_extension = '';
    file_size = 0;
    is_aapc: number | string = 0;
    extention = '';
    taille_f = '';
    taille = '';
    type?: string;
}
// tslint:enable:variable-name
