
import { AfterViewChecked } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ms-pa-nav',
  templateUrl: './ms-pa-nav.component.html',
  styleUrls: ['./ms-pa-nav.component.scss']
})
export class MsPaNavComponent implements AfterViewChecked {

  routesLinks: {
    name: string;
    link: string;
    text: string;
    rights: string;
    isEnabled: boolean,
    isLink?: boolean
  }[] = [
      { name: 'projects', link: 'projets', text: 'PROJECTS', rights: 'E_PROJETS', isEnabled: false },
      { name: 'JBJ', link: 'joue-BOAMP-JAL', text: 'JOUE-BOAMP-JAL', rights: 'D_ANNONCES', isEnabled: true },
      { name: 'consult', link: 'consultation', text: 'CONSULTATIONS', rights: '', isEnabled: true },
      { name: 'res', link: 'ressources', text: 'RESOURCES', rights: '', isEnabled: true },
      { name: 'bdc-doc', link: 'documentation-BDC', text: 'BDC-DOC', rights: '', isEnabled: false },
      {
        name: 'training-agenda',
        link: environment.atlineFormation,
        text: 'TRAINING-AGENDA',
        rights: '',
        isEnabled: true,
        isLink: true
      },
      { name: 'admin', link: 'administration', text: 'ADMIN', rights: 'D_ADMINISTRATEUR', isEnabled: true },
    ];

  routes = this.getRoutesLink();

  checked = false;

  constructor(
    private readonly router: Router,
    private readonly utilsService: UtilsService
  ) { }

  ngAfterViewChecked(): void {
    this.checked = false;
  }

  get currentRoute(): string {
    return this.router.url.split('/')[3] ?? './';
  }

  getRoutesLink(): Array<any> {
    const msRights = this.utilsService.msRights as any;
    const rights: Array<any> = [];
    const rightsMs = Object.keys(msRights);
    this.routesLinks.forEach(elt => {
      if (elt.rights === ''
        || (!_.isUndefined(rightsMs.find(r => r === elt.rights))
          && msRights[elt.rights] === true)) {
        rights.push(elt);
      }
    });
    return rights;
  }

  widthLarge(id: string): boolean {
    const el = document.getElementById(id);
    if (el) {
      return el.clientWidth > 85;
    }
    return false;
  }

}
