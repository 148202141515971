<div [ngClass]="{'display-container' : displayBanner}">
  <div class="cookie-banner" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" >
    <span>
      {{'APP.COOKIES.PARAGRAPH' | translate }}
    </span>
    <div class="container-button" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
      <button class="accept" (click)="setCookies('oui')">{{'APP.COOKIES.VALUE-COOKIES.ACCEPT' | translate| uppercase }}</button>
      <button class="refuse" (click)="setCookies('non')">{{'APP.COOKIES.VALUE-COOKIES.REFUSE' | translate }}</button>
    </div>
  </div>
</div>