import { EssentialDataItem } from '../../essentialDataItem.model';
import { Pagination } from '../../pagination.model';

export class EssentialDataResponse {
  // tslint:disable:variable-name
  donnees!: EssentialDataItem[];
  nb_total!: number;
  pagination!: Pagination;
  // tslint:enable:variable-name
}
