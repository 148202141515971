import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: 'input[type=number][inputNumber]',
})
export class InputNumberDirective {

  @Input()
  min?: number;

  @Input()
  max?: number;

  constructor(readonly elementRef: ElementRef<HTMLInputElement>) { }

  @HostListener('input', ['$event'])
  onInput(): void {
    const input = this.elementRef.nativeElement;
    const value = Number(input.value);
    if (!_.isNil(this.max) && value > this.max) input.value = this.max.toString();
    else if (!_.isNil(this.min) && value < this.min) input.value = this.min.toString();
  }

}
