import { CdkStepper } from '@angular/cdk/stepper';
import { Directionality } from '@angular/cdk/bidi';
import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef, ViewChildren, QueryList} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }],
  animations: [trigger('stepTransition', [
    state('previous', style({transform: 'translate3d(-100%, 0, 0)', visibility: 'hidden'})),
    state('current', style({transform: 'none', visibility: 'visible'})),
    state('next', style({transform: 'translate3d(100%, 0, 0)', visibility: 'hidden'})),
    transition('* => *', animate('500ms cubic-bezier(.38,1.04,.53,.96)'))
  ])],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomStepperComponent extends CdkStepper {
  @Input() titre = '';
  @Input() btnNext = true;

  @ViewChild('buttonNext')
  buttonNext!: ElementRef<HTMLElement>;
  @ViewChild('buttonSubmit')
  buttonSubmit!: ElementRef<HTMLElement>;
  @ViewChildren('buttonStep')
  buttonsSteps!: QueryList<ElementRef<HTMLElement>>;


  constructor(dir: Directionality, changeDetectorRef: ChangeDetectorRef) {
    super(dir, changeDetectorRef);
  }

  goStep(index: number): void {
    this.selectedIndex = index;
  }

  goPrevious(): void {
    this.previous();
  }

  goNext(): void {
    this.next();
  }
}
