import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@atline/core/material/material.module';
import { PaginationModule } from '@atline-shared/components/pagination/pagination.module';
import { PaSearchModule } from '@atline-shared/components/pa-search/pa-search.module';
import { PipeModule } from '@atline/core/pipe/pipe.module';
import { DateMarcheModule } from '@atline-shared/components/date-marche/date-marche.module';
import { SidebarModule } from '@atline-shared/components/sidebar/sidebar.module';
import { CertificatSignatureComponent } from './certificat-signature.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignatureWebsignComponent } from './signature-websign/signature-websign.component';
import { SignatureRoutingModule } from './signature-routing.module';
import { DirectiveModule } from '@atline-shared/directive/directive.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@atline-shared/design/button/button.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { PageTitleModule } from '../page-title/page-title.module';
import { SwitchModule } from '@atline-shared/design/switch/switch.module';
import { ConfBarWebsignVerifyModule } from '../conf-bar-websign-verify/conf-bar-websign-verify.module';
import { FileSigntaureComponent } from './signature-websign/file-signtaure/file-signtaure.component';
import { MarchesContainerModule } from '@atline-shared/design/marches-container/marches-container.module';

@NgModule({
  declarations: [CertificatSignatureComponent, SignatureWebsignComponent, FileSigntaureComponent],
  exports: [CertificatSignatureComponent],
  imports: [
    CommonModule,
    NgxTranslateModule,
    LayoutModule,
    FlexLayoutModule,
    MaterialModule,
    PaginationModule,
    PaSearchModule,
    PipeModule,
    DateMarcheModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    PipeModule,
    SignatureRoutingModule,
    DirectiveModule,
    TranslateModule,
    ButtonModule,
    OverlayModule,
    PageTitleModule,
    SwitchModule,
    ConfBarWebsignVerifyModule,
    MarchesContainerModule
  ],
})
export class CertificatSignatureModule {}
