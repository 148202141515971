export enum TypeConfirmDialog {
  DEFAULT = 'APP.DIALOG.TYPE.DEFAULT',
  DELETE_ALL_LOTS = '',
  DELETE_ALL_PIECES = '',
  DELETE_ALL_LOT_MESSAGES = '',
  DELETE_PIECE = '',
  DELETE_AVENANT = '',
  DELETE_ATTRIBUTAIRE = '',
  DELETE_DE_OE_REMOVE = '',
  CREATE_DUME = '',
  CREATE_ATTRIBUTAIRE_REPRESENTATIVE = '',
  ARCHIVE_MARCHE = ''
}
