import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../core/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NewsletterComponent } from './newsletter.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [NewsletterComponent],
  exports: [
    NewsletterComponent
  ],
  imports: [
    CommonModule,
    NgxTranslateModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule
  ]
})
export class NewsletterModule { }
