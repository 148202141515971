import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateMarcheComponent } from './date-marche.component';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [DateMarcheComponent],
  imports: [
    CommonModule,
    NgxTranslateModule,
    FlexLayoutModule
  ],
  exports: [
    DateMarcheComponent
  ]
})
export class DateMarcheModule { }
