import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationServiceItemComponent } from './presentation-service-item.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    PresentationServiceItemComponent
  ],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    PresentationServiceItemComponent
  ]
})
export class PresentationServiceItemModule { }
