import { createChecker } from "@atline/core/functions/comparators.functions";
import { TypeLotDictionnary, isTypeLotDictionnary } from "../../dictionnaryLotItem.model";
import { Pagination, isPagination } from "../../pagination.model";
import * as _ from "lodash";

export class ParamsDictionnaryLot {
  constructor(
    readonly type: TypeLotDictionnary = 'lot',
    readonly pagination?: Pagination,
    readonly recherche?: string,
  ) { }
}

export interface DictionnaryLotRequest extends ParamsDictionnaryLot {
  cle_etab: string;
  cle_pa: string;
}

const checkerParamsDictionnaryLot = createChecker<ParamsDictionnaryLot>({
  type: isTypeLotDictionnary,
  pagination: [isPagination, _.isNil],
  recherche: [_.isString, _.isNil],
});

export function isParamsDictionnaryLot(arg: any): arg is ParamsDictionnaryLot {
  return arg instanceof ParamsDictionnaryLot || checkerParamsDictionnaryLot(arg);
}

export const isDictionnaryLotRequest = createChecker<DictionnaryLotRequest>({
  cle_etab: _.isString,
  cle_pa: _.isString,
  type: isTypeLotDictionnary,
  pagination: [isPagination, _.isNil],
  recherche: [_.isString, _.isNil],
});
