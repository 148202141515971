export class WebsendFindResponse {

    // tslint:disable:variable-name

    id = '';
    tok_rep = '';
    cle_rep_ms = '';
    cle_dce = 0;
    depose = 0;
    annee_dep = 0;
    date_rep = '';

    // tslint:enable:variable-name

}
