<div class="dateTime" fxLayout="row" fxLayoutAlign="space-around center">
  <div class="clock">
    <img src="/assets/icons/svg/clock.svg" alt="">
  </div>
  <div>
    <div class="date">
      {{formatDate() | uppercase}}
    </div>
    <div class="time">
      {{date | date:'HH.mm.ss'}}
    </div>
  </div>
</div>