import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Account, CreateAccountRequest } from '../models/api/requests/create-account.request';
import { CreateAccountResponse } from '../models/api/responses/create-account.response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CreateAccountService {

  constructor(private readonly apiService: ApiService) { }


  create(formBody: Account): Observable<CreateAccountResponse> {
    if (formBody) {
      const creaRequest = new CreateAccountRequest();
      creaRequest.email = formBody.email;
      creaRequest.username = formBody.username;
      creaRequest.password = formBody.password;
      creaRequest.role = formBody.role;
      creaRequest.structure_type = formBody.structure_type;
      creaRequest.structure_value = formBody.structure_value;
      creaRequest.apps = formBody.apps;
      // creaRequest.locale = this.utilsService.getLocale();

      return this.user_add(creaRequest).pipe(
        catchError(err => {
          return throwError(err);
        })
      );
    }
    return new Observable<CreateAccountResponse>();
  }

  user_add(request: CreateAccountRequest): Observable<CreateAccountResponse> {
    return this.apiService.post(CreateAccountResponse, request, 'user_add');
  }
}
