import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdministrationGroupService } from '@atline/core/services/adminstrationGroup.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { ApiErrorResponse } from '@atline/core/models/api/responses/api.response';
import { EditGroupAdminRequest } from '@atline/core/models/api/requests/edit-group-admin-request.request';

@Component({
  selector: 'app-modify-group',
  templateUrl: './modify-group.component.html',
  styleUrls: ['./modify-group.component.scss']
})
export class ModifyGroupComponent implements OnInit {
  public title = '';
  public description = '';
  public informations?: { [groupName: string]: string | number | undefined};
  public nomGroup = '';
  public groupName = '';
  public cleGroupe = '';

  public editGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModifyGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private administrationGroupService: AdministrationGroupService,
    private utils: UtilsService
  ) {
    this.title = data.title;
    this.description = data.description;
    this.groupName = this.data.informations.groupName;
    this.cleGroupe = this.data.informations.cleGroupe;
    this.editGroup = this.fb.group({
      nomGr: fb.control(['', Validators.required])
    });
  }

  ngOnInit(): void {
    this.editGroup.controls.nomGr.setValue(this.data.informations.groupName);
  }


  onConfirm(): void {
    if (this.editGroup.value) {
      const cleEtab = this.utils.cleEtab || '';
      const nomGroup = this.editGroup.value.nomGr;
      const groupLabel = this.editGroup.value.nomGr;
      const cle_groupe = this.cleGroupe;
      const editRequest = new EditGroupAdminRequest(cleEtab, nomGroup, groupLabel, cle_groupe);

      this.administrationGroupService
        .editGroup(editRequest)
        .subscribe(
          () => {
            this.dialogRef.close(true);
          },
          (err) => {
            const apiError = err.error as ApiErrorResponse;
            if (apiError) {
              this.dialogRef.close(false);
            }
          }
        );
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
