import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent {

  constructor(
    readonly dialogRef: MatDialogRef<LegalNoticeComponent>,
  ) { }

  parts = ['RIGHTS_', 'DIRECTOR_', 'REPRODUCTION_'];

}
