import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectedAnonymousComponent } from './connected-anonymous.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@atline/core/material/material.module';
import { DirectiveModule } from '@atline-shared/directive/directive.module';
import { LoginFormModule } from '@atline-shared/components/login-form/login-form.module';



@NgModule({
  declarations: [ConnectedAnonymousComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    DirectiveModule,
    LoginFormModule
  ],
  exports: [
    ConnectedAnonymousComponent
  ]
})
export class ConnectedAnonymousModule { }
