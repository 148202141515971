import { Injectable } from '@angular/core';
import { PeriodOfYear } from '@atline-shared/enums/periodOfYera.enum';
import * as _ from 'lodash';

interface ExplodedDate {
  date: string;
  time: {
    hours: number;
    minute: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DateHelper {

  constructor() { }

  explodeDateFromWS(date: string): ExplodedDate {
    const splitedString = _.split(date, ' ')[0];
    const splitedTime = _.split(splitedString[1], ':');
    return {
      date: splitedString.replace('-', '/'),
      time: {
        hours: parseInt(splitedTime[0], 10),
        minute: parseInt(splitedString[1], 10)
      }
    };
  }


  /**
   * format date
   * @param date date must be form like dd/mm/yyyy
   */
  public getDateFromDateGettingByWS(date: string): Date {
    return new Date(_.join(_.reverse(_.split(this.explodeDateFromWS(date).date, '/')), '/'));
  }

  /**
   * get hours form date ws fromated
   * @param date date must be form like dd/mm/yyyy
   */
  public hoursFromWsDate(date: string): number {
    return this.explodeDateFromWS(date).time.hours;
  }

  /**
   * get minutes form date ws fromated
   * @param date date must be form like dd/mm/yyyy
   */
  public minutesFromWsDate(date: string): number {
    return this.explodeDateFromWS(date).time.minute;
  }

  /**
   * get minutes form date ws fromated
   * @param date date must be form like dd/mm/yyyy
   */
  public formatTimestampForFE(date: string): number {
    return _.parseInt(date, 10) * 1000;
  }

  /**
   * format date
   * @param date must be a timestamp
   */
  public getDateFromTimestampGettingByWS(date: string | undefined): Date {
    return new Date(_.parseInt(!_.isUndefined(date) ? date : '0', 10) * 1000);
  }

  /**
   * format date for WS
   * @param date date
   */
  public formatdateForWSyyyymmdd(date: Date, time?: any, activeHours = true): string {
    let s = '';
    s += date.getFullYear() + '-';
    s += (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
    s += date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
    if (activeHours && time) {
      s += ' ';
      s += _.toString(time.hours).length === 1 ? '0' + time.hours : time.hours;
      s += ':';
      s += _.toString(time.minutes).length === 1 ? '0' + time.minutes : time.minutes;
    } else if (activeHours) {
      s += ' ';
      s += _.toString(date.getHours()).length === 1 ? '0' + date.getHours() : date.getHours();
      s += ':';
      s += _.toString(date.getMinutes()).length === 1 ? '0' + date.getMinutes() : date.getMinutes();
    }
    return s;
  }

  /**
   * format date for WS
   * @param date date
   * @param time time | undefined
   * @param active boolea
   */
  public formatdateForWS(date: Date, time?: any, activeHours = true): string {
    let s = '';
    s += date.getDate().toString().length === 1 ? '0' + date.getDate() + '-' : date.getDate() + '-';
    s += (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
    s += date.getFullYear();
    if (activeHours && time) {
      s += ' ';
      s += _.toString(time.hours).length === 1 ? '0' + time.hours : time.hours;
      s += ':';
      s += _.toString(time.minutes).length === 1 ? '0' + time.minutes : time.minutes;
    }
    if (activeHours) {
      s += ' ';
      s += _.toString(date.getHours()).length === 1 ? '0' + date.getHours() : date.getHours();
      s += ':';
      s += _.toString(date.getMinutes()).length === 1 ? '0' + date.getMinutes() : date.getMinutes();
    }
    return s;
  }

  /**
   * format date for WS
   * @param date date
   * @param time time | undefined
   * @param active boolea
   */
  public formatdateForWSYYYMMDD(date: Date): string {
    let s = '';
    s += date.getFullYear() + '-';
    s += (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
    s += date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
    return s;
  }

  public formatShortDateTime(date: Date | string | number, timezone: string, lang: string): string {
    const d = new Date(date);
    return Intl.DateTimeFormat(lang, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZone: timezone
    }).format(d);
  }

  public formatDateDDMMYYYY(date: string | Date): string {
    const dateObject = _.isString(date) ? new Date(date) : date;
    return Intl.DateTimeFormat(undefined, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).format(dateObject);
  }

  public formatDateYYYYMMDD(date: string | Date): string {
    const dateObject = _.isString(date) ? new Date(date) : date;
    return Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }).format(dateObject);
  }

  millisecondsToTimeHHmmss(ms: number): { hours: number, minutes: number, seconds: number } {
    let seconds = ms / 1000;
    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = Math.ceil(seconds % 60);
    return { hours, minutes, seconds };
  }

  public millisecondsToHHmmss(ms: number): string {
    const { hours, minutes, seconds } = this.millisecondsToTimeHHmmss(ms);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  public dateSwitchPeriod(period: PeriodOfYear, year: number): any {
    switch (period) {
      case PeriodOfYear.FULL:
        return {
          start: `01/01/${year}`,
          end: `31/12/${year}`
        }

      case PeriodOfYear.ERS1:
        return {
          start: `01/01/${year}`,
          end: `30/06/${year}`
        }

      case PeriodOfYear.NDS2:
        return {
          start: `01/07/${year}`,
          end: `31/12/${year}`
        }

      case PeriodOfYear.ERT1:
        return {
          start: `01/01/${year}`,
          end: `31/03/${year}`
        }

      case PeriodOfYear.NDT2:
        return {
          start: `01/04/${year}`,
          end: `30/06/${year}`
        }

      case PeriodOfYear.ET3:
        return {
          start: `01/07/${year}`,
          end: `30/09/${year}`
        }

      case PeriodOfYear.ET4:
        return {
          start: `01/10/${year}`,
          end: `31/12/${year}`
        }

      default:
        break;
    }
  }
}
