<div class="hotline" fxLayout="column" [ngClass]="{'small': isSmall}">
    <div fxLayout="row" fxLayoutAlign="start center">
        <div class="text" fxLayoutAlign="center center">
            {{'APP.MARCHES_SECURISES.CONSULTATION.FOOTER.HOTLINE' | translate | uppercase}}
        </div>
        <div class="icon">
          <mat-icon [svgIcon]="Icons.Headset"></mat-icon>
        </div>
    </div>

    <span class="hotline-label">HOTLINE</span>

    <div class="number">
        {{'APP.MARCHES_SECURISES.CONSULTATION.FOOTER.TELEPHONE_NUMBER' | translate | uppercase}}
    </div>
</div>
