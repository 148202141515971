import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhoAreWeComponent } from './who-are-we.component';
import { SoonAvalaibleModule } from '@atline-shared/components/soon-avalaible/soon-avalaible.module';



@NgModule({
  declarations: [WhoAreWeComponent],
  imports: [
    CommonModule,
    SoonAvalaibleModule
  ],
  exports: [
    WhoAreWeComponent,
  ]
})
export class WhoAreWeModule { }
