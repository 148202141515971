// tslint:disable:variable-name
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { environment } from 'src/environments/environment';
import { DumeDownloadRequest } from '../../models/api/requests/history/dumeDownload.request';
import { HistoryRequest } from '../../models/api/requests/history/history.request';
import { DumeResponse } from '../../models/api/responses/history/dume.response';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryDumeService {
  private static endPoint = 'dume_oe_list';

  constructor(private readonly apiService: ApiService, private _httpClient: HttpClient) { }

  getDumeList(
    request: HistoryRequest
  ): Observable<DumeResponse> {
    return this.apiService.post(
      DumeResponse,
      request,
      HistoryDumeService.endPoint
    );
  }

  websendDumeFile(dumeDownloadRequest: DumeDownloadRequest): Observable<HttpResponse<any>> {
    return this._httpClient.post(
      environment.api + 'oe_download_dume',
      dumeDownloadRequest,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET)
          .headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

}

// tslint:enable:variable-name
