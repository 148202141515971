<div class="container">
  <div class="content">
    <section class="header" [ngClass]="confirmRefusTranslationKey">
      <h2>{{'APP.MARCHES_SECURISES.ELRAR.HEADER' + confirmRefusTranslationKey | translate}}</h2>
    </section>

    <section class="body">
      <div class="not-found" *ngIf="error">
        {{error}}
      </div>
      <ng-container *ngIf="elrarValidity; then elrarValid; else elrarExpired"></ng-container>
    </section>
    <div class="container-btn-redirect" fxLayoutAlign="center center" *ngIf="elrarValidity && displayResponseMessage && action === 'accepter'">
      <app-button (clickButton)="accessContext()">{{ 'APP.MARCHES_SECURISES.ELRAR.BTN_ACCEPTER.' + getContext().toUpperCase() | translate }}</app-button>
    </div>
  </div>
</div>

<ng-template #elrarValid>
  <div *ngIf="displayRefusedSelect" class="confirm-refus" fxLayout="column" fxFlex="75" fxLayoutAlign="start center">
    <h4>{{'APP.MARCHES_SECURISES.ELRAR.CONFIRM_REFUS' | translate}}</h4>
    <mat-form-field appearance="outline" fxFlex="100">
      <mat-select [(ngModel)]="choice">
        <mat-option [value]="1">
          {{'APP.YES' | translate}}
        </mat-option>
        <mat-option [value]="0">
          {{'APP.NO' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-button [size]="'large'" (click)="refusElrar()" style="margin-top: 30px;">
      {{'APP.MARCHES_SECURISES.ELRAR.VALIDATE' | translate}}
    </app-button>
  </div>
  <div class="response-message" *ngIf="displayResponseMessage"
    [innerHTML]="'APP.MARCHES_SECURISES.ELRAR.CONFIRMATION_' + action.toUpperCase() | translate : {dateSent: dateSent, actionDate: actionDate}">
  </div>
</ng-template>

<ng-template #elrarExpired>
  <div class="expired-message" *ngIf="displayResponseMessage"
    [innerHTML]="'APP.MARCHES_SECURISES.ELRAR.OUT_OF_DATE' | translate : {dateSent: dateSent}">
  </div>
</ng-template>
