import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './api.service';
import { AuthenticateService } from './authenticate.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpResponseTimeLogger } from '../interceptor/httpResponseTimer.interceptor';
import { AuthGuard } from '../guards/auth.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ApiService,
    AuthenticateService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseTimeLogger, multi: true }
  ]
})
export class ServicesModule { }
