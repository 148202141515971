import { Component, OnInit, Input, ContentChild, Output, EventEmitter, HostBinding } from '@angular/core';
import { GroupBtnSideBarComponent } from '../group-btn-side-bar/group-btn-side-bar.component';

@Component({
  selector: 'btn-side-bar',
  templateUrl: './button-side-bar.component.html',
  styleUrls: ['./button-side-bar.component.scss']
})
export class ButtonSideBarComponent implements OnInit {

  @ContentChild(GroupBtnSideBarComponent)
  group?: GroupBtnSideBarComponent;

  @Input()
  type: 'button' | 'submit' | 'reset' = 'button';

  @Input()
  disabled = false;

  @Input()
  name?: string;

  @Input()
  value?: string;

  @Input()
  formtarget?: '_blank' | '_self' | '_parent' | '_top';

  @Input()
  autofocus?: boolean;

  @Input()
  selected = false;

  @Input()
  visible = true;

  @Output()
  readonly btnClicked = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  @HostBinding('style.display')
  get display(): string {
    return this.visible ? 'inline-block' : 'none';
  }
}
