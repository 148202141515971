<div fxLayout="row" fxLayoutAlign="center center" class="label" atlineLink (click)="action()">

  <ng-container *ngIf="isLoading; then loaderTemplate; else starTemplate"></ng-container>
  <ng-template #loaderTemplate>
    <app-loading-spinner class="spinner" [size]="24"></app-loading-spinner>
  </ng-template>
  <ng-template #starTemplate>
    <mat-icon class="favorite-star-icon" [ngClass]="{'fav-added': isFavorite, 'only-star': hasCleDce}"
      svgIcon="star"></mat-icon>
  </ng-template>

  <span *ngIf="!hasCleDce">{{'APP.MARCHES_SECURISES.CONSULTATION.FAVORITES'| translate }}</span>
</div>
