// tslint:disable:variable-name
export class ConsultationLotsListRep {
    cle_dce = '';
    lots: Lot[] = [{cle: '', libelle: '', libelle_ordre: '', agregat: '', taille: 0, taille_f: ''}];
    nb_total = 0;
}

export interface Lot {
    cle: string;
    libelle: string;
    libelle_ordre: string;
    agregat: string;
    taille: number;
    taille_f: string;
}
// tslint:enable:variable-name
