<div class="login-form" ngClass. ngClass.gt-md="large-login-form" fxLayout="column" fxLayoutAlign="start start">
  <h1 mat-dialog-title>{{ "APP.LOGIN.MY_ACCOUNT" | translate }}</h1>
  <form [formGroup]="signInForm" class="mat-bg-card" (ngSubmit)="onSubmit()">

    <div class="username-field">
      <mat-form-field class="full-width form-field " #matForm appearance="outline">
        <label class="labelPosition">{{ "APP.LOGIN.USERNAME" | translate }}</label>
        <input matInput formControlName="username" name="username" />
      </mat-form-field>
      <small *ngIf="usernameFC.invalid && usernameFC.touched" class="form-error-msg">
        {{ "APP.LOGIN.USERNAME_ERROR" | translate }}
      </small>
    </div>

    <div class="password-field">
      <mat-form-field class="full-width form-field" appearance="outline">
        <label class="labelPosition">{{ "APP.LOGIN.PASSWORD" | translate }}</label>
        <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" name="password" />
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
      <small *ngIf="passwordFC.invalid && passwordFC.touched" class="form-error-msg">
        {{ getPasswordErrorMessage() | translate }}
      </small>

      <div class="remember-me">
        <input type="checkbox" id="rememberCheckbox" formControlName="rememberMe" />
        <label for="rememberCheckbox">{{'APP.LOGIN.REMEMBER_ME' | translate}}</label>
      </div>

    </div>
    <div>
      <button type="submit" class="full-width btn" color="primary">
        {{ "APP.LOGIN.CONNECT" | translate | uppercase }}
      </button>
      <div class="error-message" *ngIf="incorrectCredentials">
        {{ "APP.LOGIN.INVALID_CREDENTIALS" | translate }}
      </div>
      <button class="lost-password-btn" type="button">{{'APP.LOGIN.FORGOT_PASSWORD' | translate}}</button>
    </div>
    <button type="button" class="full-width btn create" color="primary" (click)="onSignupClick()">
      {{ "APP.LOGIN.CREATE_ACCOUNT" | translate }}
    </button>
  </form>
</div>
