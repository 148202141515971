import { Component, OnInit } from '@angular/core';
import { AvisAttributionsRequest } from '@atline/core/models/api/requests/avis-attributions.request';
import { ApiErrorResponse } from '@atline/core/models/api/responses/api.response';
import { AvisAttributionItem } from '@atline/core/models/avis-attributions.model';
import { AvisAttributionsService } from '@atline/core/services/avis-attribution.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PAListItem } from '@atline/core/models/search_pa_list.model';
import { PAListRequest } from '@atline/core/models/api/requests/search_pa_list.request';
import { SearchPaListSercice } from '@atline/core/services/search-pa_list.service';
import { PaginationRequest } from '@atline/core/models/api/requests/pagination.request';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-avis-attribution',
  templateUrl: './avis-attribution.component.html',
  styleUrls: ['./avis-attribution.component.scss'],
})
export class AvisAttributionComponent implements OnInit {

  public searchFormGroup!: FormGroup;
  public recherche!: PAListRequest;
  public paList: PAListItem[] = [];
  public nbAttributions = 0;
  public attributionArray: Array<AvisAttributionItem> = [];
  public isLoading = false;
  public isLoadingPa = false;
  public error?: ApiErrorResponse;
  public pagination: PaginationRequest = {
    page: 1,
    nbr_par_page: 10,
  };

  constructor(
    private readonly avisattributionService: AvisAttributionsService,
    private readonly searchPaList: SearchPaListSercice,
    private readonly fb: FormBuilder
  ) { }

  public criteresList = [
    {
      code: 0,
      label: 'APP.MARCHES_SECURISES.CONSULTATION.ATTRIBUTION.T1',
    },
    {
      code: 1,
      label: 'APP.MARCHES_SECURISES.CONSULTATION.ATTRIBUTION.T2',
    },
    {
      code: 2,
      label: 'APP.MARCHES_SECURISES.CONSULTATION.ATTRIBUTION.T3',
    },
  ];
  ngOnInit(): void {
    this.searchFormGroup = this.fb.group({
      motscles: new FormControl(''),
      paSearch: new FormControl(''),
      type: new FormControl(0, [Validators.required])
    });
    this.callToWs();

    this.searchFormGroup.controls['paSearch'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        if (!_.isEmpty(this.searchFormGroup.controls['paSearch'].value)) {
          this.isLoadingPa = true;
          this.searchPaList.getPaList({
            recherche: this.searchFormGroup.controls['paSearch'].value
          }).subscribe(
            {
              next: (res) => {
                this.paList = res.pas;
                this.isLoadingPa = false;
              },
              error: () => {
                this.isLoadingPa = false;
              }
            });
        } else {
          this.searchFormGroup.controls['paSearch'].setValue(undefined);
        }

      })
  }

  callToWs(): void {
    this.isLoading = true;
    const request = new AvisAttributionsRequest();


    if (this.searchFormGroup.value.paSearch)
      request.cle_pa = this.searchFormGroup.value.paSearch.cle;

    if (this.searchFormGroup.value.motscles)
      request.motscles = this.searchFormGroup.value.motscles;

    request.type_avis = this.searchFormGroup.value.type;
    request.pagination = this.pagination;

    this.avisattributionService
      .getAvisAttribution(request)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          const apiError = err.error as ApiErrorResponse;
          if (apiError) {
            this.error = apiError;
          }
          return throwError(err);
        })
      )
      .subscribe((res) => {
        this.attributionArray = res.attributions;
        this.nbAttributions = res.nb_total;
        this.pagination = res.pagination;
        this.isLoading = false;
      });
    window.scroll(0, 0);
  }

  get hasDataOnPaField(): boolean {
    return !_.isEmpty(this.searchFormGroup.controls['paSearch'].value) && !_.isNil(this.searchFormGroup.controls['paSearch'].value)
  }

  onSubmit(): void {
    this.callToWs();
  }

  displayFn(pa: any): string {
    return pa ? pa.denomination : '';
  }
  changePage(pageNumber: number): void {
    this.pagination.page = pageNumber;
    this.callToWs();
  }

  changeNbParPage(nbParPage: number): void {
    this.pagination.nbr_par_page = nbParPage;
    this.pagination.page = 1;
    this.callToWs();
  }

  clearPaField(): void {
    this.searchFormGroup.controls['paSearch'].setValue(undefined);
  }

}
