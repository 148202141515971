<div class="dialog-result-find-structure" [ngSwitch]="status">
    <div class="part-tables">
        <section class=".loader" *ngSwitchCase="statusSearch.progress">
            <mat-spinner [diameter]="75" [strokeWidth]="3"></mat-spinner>
        </section>
        <section class="result" *ngSwitchCase="statusSearch.succees">
            <div class="container-datas">
                <div class="head">
                    <div *ngFor="let item of table.splice(0, 3);" class="data" fxLayout="row nowrap" fxLayoutAlign="left center">
                        <div class="label">{{ 'APP.RESULT-FIND-STRUCT.' + item.label | uppercase | translate }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
                <ng-container *ngFor="let item of table.splice(3);">
                    <div *ngIf="item?.value?.length" class="data" fxLayout="row nowrap" fxLayoutAlign="left center">
                        <div class="label">{{ 'APP.RESULT-FIND-STRUCT.' + item.label | uppercase | translate }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </ng-container>
            </div>

            <div class="container-buttons">
                <button id="btn-cancel" mat-raised-button color="primary" type="button" (click)="clickCancel()">
                    <span style="color: white;">{{ 'APP.RESULT-FIND-STRUCT.BTN-CANCEL' | translate}}</span>
                </button>
                <button id="btn-valid" mat-raised-button color="primary" type="button" (click)="clickValid()">
                    <span style="color: white;">{{ 'APP.RESULT-FIND-STRUCT.BTN-VALID' | translate}}</span>
                </button>
            </div>
        </section>
    </div>
</div>
