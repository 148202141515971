<div class="spinner" *ngIf="!isInitialized">
  <mat-spinner matSuffix style="margin-right: 5px;" mode="indeterminate" [diameter]="50"></mat-spinner>
</div>

<ng-container *ngIf="isInitialized">
  <router-outlet (activate)="onActivate()"></router-outlet>
</ng-container>

<ng-container *ngIf="displayCookiesBanner">
  <app-cookies></app-cookies>
</ng-container>



