import { SwitchComponent } from './switch.component';

import { MaterialModule } from '@atline/core/material/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SwitchComponent],
  exports: [SwitchComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
  ]
})
export class SwitchModule { }
