import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { Observable, of } from 'rxjs';
import { CustomStepperComponent } from '../custom-stepper/custom-stepper.component';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit, AfterViewInit {

  @ViewChild(CustomStepperComponent)
  stepper!: CustomStepperComponent;
  private stepActual = 0;

  formCharged = false;

  nomFC = new FormControl('', [Validators.required, Validators.minLength(3)]);
  prenomFC = new FormControl('', [Validators.required, Validators.minLength(3)]);
  emailFC = new FormControl('', [Validators.required, Validators.email]);
  nomEntrepriseFC = new FormControl('', [Validators.required]);
  idEntrepriseFC = new FormControl('', [Validators.required, Validators.pattern('[0-9]{14}')]);
  adresseFC = new FormControl('', [Validators.required]);
  communeFC = new FormControl('', [Validators.required]);
  paysFC = new FormControl('', [Validators.required]);
  codeNafFC = new FormControl('', [Validators.required]);
  telFC = new FormControl('', [Validators.required]);
  faxFC = new FormControl('');
  idFC = new FormControl('', [Validators.required]);
  passwordFC = new FormControl('', [Validators.required]);
  confPwFC = new FormControl('', [Validators.required, CustomValidators.equalTo(this.passwordFC)]);
  captchaFC = new FormControl();
  infoMonthlyFC = new FormControl(false);
  infoPartenerFC = new FormControl(false);

  public formRegistration!: FormGroup;


  listCountry: Observable<{value: string, name: string}[]> = of([
    {value: 'FR', name: 'France'},
    {value: 'EN', name: 'England'},
    {value: 'US', name: 'United States America'},
    {value: 'ES', name: 'Español'},
    {value: 'POL', name: 'Pologne'},
    {value: 'SC', name: 'Scotland'},
    {value: 'IR', name: 'Irland'},
  ]);

  constructor(private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formRegistration = this.formBuilder.group({
      nom: this.nomFC,
      prenom: this.prenomFC,
      email: this.emailFC,
      nomEntreprise: this.nomEntrepriseFC,
      idEntreprise: this.idEntrepriseFC,
      adresse: this.adresseFC,
      commune: this.communeFC,
      pays: this.paysFC,
      codeNaf: this.codeNafFC,
      tel: this.telFC,
      fax: this.faxFC,
      id: this.idFC,
      password: this.passwordFC,
      confirmationPw: this.confPwFC,
      captcha: this.captchaFC,
      infoMonthly: this.infoMonthlyFC,
      infoPartener: this.infoPartenerFC
    });

    this.formCharged = true;
  }

  ngAfterViewInit(): void {
    this.stepActual = this.stepper.selectedIndex;

    const maskTouched = (): void => {
      if (this.stepActual === this.stepper.selectedIndex) {
        switch (this.stepper.selectedIndex) {
          case 0:
            this.nomFC.markAsTouched();
            this.prenomFC.markAsTouched();
            this.emailFC.markAsTouched();
            break;
          case 1:
            this.nomEntrepriseFC.markAsTouched();
            this.idEntrepriseFC.markAsTouched();
            this.adresseFC.markAsTouched();
            this.communeFC.markAsTouched();
            this.paysFC.markAsTouched();
            this.codeNafFC.markAsTouched();
            this.telFC.markAsTouched();
            this.faxFC.markAsTouched();
            break;
          case 2:
            this.idFC.markAsTouched();
            this.passwordFC.markAsTouched();
            this.confPwFC.markAsTouched();
            this.infoMonthlyFC.markAsTouched();
            this.infoPartenerFC.markAsTouched();
            break;
        }
      }
      else {
        this.stepActual = this.stepper.selectedIndex;
      }
    };

    this.stepper?.buttonNext.nativeElement.addEventListener('click', maskTouched);
    this.stepper?.buttonSubmit.nativeElement.addEventListener('click', maskTouched);
    this.stepper?.buttonsSteps.forEach(el => el.nativeElement.addEventListener('click', maskTouched));
  }

  step1Completed(): boolean {
    return this.nomFC.valid
            && this.prenomFC.valid
            && this.emailFC.valid;
  }

  step2Completed(): boolean {
    return this.nomEntrepriseFC.valid
            && this.idEntrepriseFC.valid
            && this.adresseFC.valid
            && this.communeFC.valid
            && this.paysFC.valid
            && this.codeNafFC.valid
            && this.telFC.valid
            && this.faxFC.valid;
  }

  step3Completed(): boolean {
    return this.idFC.valid
            && this.passwordFC.valid
            && this.confPwFC.valid;
  }

  required(fc: FormControl): boolean {
    if (fc && fc.validator) {
      const val = fc.validator({} as AbstractControl);
      return !!val && (val as ValidationErrors).required;
    }
    return false;
  }

}
