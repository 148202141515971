import { Component, EventEmitter, OnInit, Output } from '@angular/core';

export interface ConfBarSignature {
  pdf: boolean;
  xml: boolean;
  hasAttachedFile: boolean;
}
@Component({
  selector: 'app-conf-bar-websign-verify',
  templateUrl: './conf-bar-websign-verify.component.html',
  styleUrls: ['./conf-bar-websign-verify.component.scss']
})
export class ConfBarWebsignVerifyComponent implements OnInit {

  @Output()
  confChange = new EventEmitter<ConfBarSignature>();

  confData: ConfBarSignature = {
    hasAttachedFile: false,
    pdf: false,
    xml: false
  }

  constructor() { }

  ngOnInit(): void {
    this.confChange.emit(this.confData);
  }

  changeFormValue(type: 'pdf' | 'xml' | 'hasAttachedFile', value: boolean): void {
    this.confData[type] = value;
    this.confChange.emit(this.confData);
  }
}
