import { ConsultationOeItem } from '../../consultation/ConsultationOeItem.model';
import { ConsultationPaItem } from '../../consultation/ConsultationPaItem.model';
import { TypeDume } from '../../Dume.model';
import { LotItem } from '../../lotItem.model';
import { Pagination } from '../../pagination.model';
import { PieceItem } from '../../pieceItem2.model';
import { ApiErrorResponse } from './api.response';

// tslint:disable:variable-name
export class ConsultationResponse {
    consultations: any;
    nb_total = 0;
    pagination: Pagination = new Pagination();
}

export class ConsultationPiecesListResponse extends ApiErrorResponse {
    consultation!: ConsultationOeItem | ConsultationPaItem;
    pieces: Array<PieceItem> = [];
    lots: Array<LotItem> = [];
    show_captcha ?= true;
    nb_total ?= 0;
}

export class ConsultationGetPieceResponse extends ApiErrorResponse {

}

export class DeriverConsultationResponse extends ApiErrorResponse {
  cle_dce!: string;
}

export class CreateEditConsultationResponse extends ApiErrorResponse {
    action = '';
    cle_dce = '';
    cle_groupe = '';
    etat = 0;
    cle_etab = '';
    identifiant = '';
    ref_interne = '';
    objet_dce = '';
    cle_pa = '';
    cle_affaire = '';
    finalite_marche = '';
    type_marche = '';
    type_prestation = '';
    type_pa = '';
    date_creation = 0;
    date_publication: any;
    date_publication_f = '';
    date_cloture: any;
    date_cloture_f = '';
    date_cloture_f_s = '';
    date_cloture_pa = 0;
    date_cloture_pa_f = '';
    date_cloture_pa_f_s = '';
    date_cloture_user = 0;
    date_cloture_user_f = '';
    date_cloture_user_f_s = '';
    departements_prestation = '';
    is_piece_dc = 0;
    emails = '';
    emails_oe = '';
    emails_oe_comp = '';
    mail_auto ?= 0;
    passation = '';
    passation_label = '';
    allotie = 0;
    informatique = 0;
    en_ligne = 0;
    invisible = 0;
    passe = '';
    option_reponse = '';
    option_reponse_f = '';
    format_reponse = '';
    signature_obligatoire = '';
    format_signature = '';
    structure_interne = '';
    criteres_environnementaux ?= 0;
    criteres_sociaux ?= 0;
    publication = 0;
    is_dume?: 0 | 1;
    prix_dce ?= 0;
    libre_ms ?= 0;
    infos_tarifs ?= '';
    pays = '';
    restreinte = 0;
    date_creation_f = '';
    infosList: any;
    denomination_pa = '';
    alreadyPublished!: boolean;
    nb_retrait = 0;
    nb_retrait_papier = 0;
    nb_depot = 0;
    nb_depot_papier = 0;
    cle_dce_published = '';
    errors_consultation: any;
    id_dume ?= '';
    type_dume ?= TypeDume.NO_DUME;
    cle_annonce ?= '';
}

// tslint:enable:variable-name
