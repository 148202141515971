import { LotRemoveRequest } from './../models/api/requests/lotRemove.request';
import { LotResponse } from '../models/api/responses/lot.response';
import { Observable } from 'rxjs';
import { LotRequest } from './../models/api/requests/lot-request';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { MessageResponse } from '../models/api/responses/message.response';
import { DictionnaryLotResponse } from '../models/api/responses/dictionnaryLot.reponse';
import { DictionnaryLotRequest, ParamsDictionnaryLot, isDictionnaryLotRequest } from '../models/api/requests/dictionnaryLot.request';

@Injectable({
  providedIn: 'root'
})
export class LotService {

  constructor(private readonly apiService: ApiService) { }

  editLot(editLotResquest: LotRequest): Observable<LotResponse> {
    return this.apiService.post(LotResponse, editLotResquest, 'consultation_lot_edit');
  }

  deleteLot(removeLotRequest: LotRemoveRequest): Observable<MessageResponse> {
    return this.apiService.post(MessageResponse, removeLotRequest, 'consultation_lot_remove');
  }

  // tslint:disable-next-line: variable-name
  getDictionnary(cle_etab: string, cle_pa: string, params?: ParamsDictionnaryLot): Observable<DictionnaryLotResponse>;
  getDictionnary(request: DictionnaryLotRequest): Observable<DictionnaryLotResponse>;
  getDictionnary(arg0: string | DictionnaryLotRequest, arg1?: string, arg2?: ParamsDictionnaryLot): Observable<DictionnaryLotResponse> {
    if (isDictionnaryLotRequest(arg1)) return this.apiService.post(DictionnaryLotResponse, arg1, 'lots_dictionnaire');
    return this.apiService.post(DictionnaryLotResponse, {
      cle_etab: arg0,
      cle_pa: arg1,
      ...arg2
    }, 'lots_dictionnaire');
  }
}
