<div class="partners" ngClass.xs="partners-responsive">

  <app-section-title>
    <div #title>{{ 'APP.HOME.LANDING.PARTNER.TITLE' | translate | uppercase }}</div>
  </app-section-title>

  <div fxLayout="row wrap" fxLayout.lt-md="column nowrap" fxLayoutAlign="center center">
    <ng-container *ngFor="let group of groupsPartners; let i = index">
      <div class="clients-list" fxFlex="35" fxFlex.lt-md="100">
        <h3>{{ group.label || '' | translate | uppercase }}</h3>
        <div class="grid-partners" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
          <div class="grid-card" *ngFor="let partner of group.partners" fxFlex="33.3" fxFlex.lt-md="50" fxLayoutAlign="center center">
            <img [src]="(group.path || '') + partner.value + '.' + (partner.fileType || 'png')" [alt]="partner.name">
          </div>
        </div>
      </div>
      <mat-divider *ngIf="i + 1 < groupsPartners.length" vertical fxHide.lt-md></mat-divider>
    </ng-container>
  </div>
</div>