import { ApiRequest } from './api.request';

export type ApplicationsFindStructure = 'ms' | 'ts' | 'ats' | 'all';

// tslint:disable:variable-name
export class FindStructureRequest extends ApiRequest {
  constructor(
    readonly type: string,
    readonly value: string,
    readonly cle_etab: string,
    readonly application: ApplicationsFindStructure = 'ms') {
    super();
  }
}
// tslint:enable:variable-name
