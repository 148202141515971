<app-dialog [dialogRef]="dialogRef">
  <h1 mat-dialog-title header>{{ 'APP.DIALOG.DICTIONNARY_LOTS.DICTIONNARY' | translate }}</h1>
  <div mat-dialog-content body fxLayoutAlign="center center" *ngIf="!init">
    <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
  </div>
  <div mat-dialog-content body fxLayout="column nowrap" fxLayoutGap="0px" *ngIf="init" class="container-lot-dictionnary">
    <div class="search-container" fxLayout="row nowrap" fxLayoutAlign="start center" fxFlex="nogrow">
      <mat-form-field appearance="outline" class="search-input">
        <input matInput type="search" [ngModel]="recherche" #searchInput (keyup.enter)="search(searchInput.value)"/>
        <mat-icon matSuffix svgIcon="close" *ngIf="searchInput.value" (click)="search(''); searchInput.value = '';"></mat-icon>
      </mat-form-field>
      <app-button [icon]="iconLoupe" size="medium" (clickButton)="search(searchInput.value)"></app-button>
    </div>
    <div fxLayout="column nowrap" fxFlex="grow" *ngIf="loaded">
      <div *ngFor="let lot of resultat.dictionnaires"
        class="lot-dictionnary"
        fxLayout="row nowrap"
        fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <span fxFlex="grow">{{ lot.libelle }}</span>
        <button type="button" fxFlex="nogrow" class="btn-icon" (click)="dialogRef.close(lot)">
          <mat-icon svgIcon="plus"></mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxFlex="grow" fxLayoutAlign="center center" *ngIf="resultat.nb_total <= 0">
        {{ 'APP.DIALOG.DICTIONNARY_LOTS.EMPTY' | translate }}
      </div>
    </div>
    <div fxLayout="column nowrap" fxLayoutAlign="center center" fxFlex="grow" *ngIf="!loaded">
      <mat-spinner [strokeWidth]="5" [diameter]="40"></mat-spinner>
    </div>
    <div fxFlex="nogrow" class="paginator-container">
      <mat-paginator *ngIf="resultat.nb_total > 0"
        [pageIndex]="page > 0 ? page - 1 : 0"
        [pageSize]="nbParPage"
        [length]="size"
        [pageSizeOptions]="[5, 10, 20]"
        (page)="paginatorChange($event)">
      </mat-paginator>
    </div>
  </div>
</app-dialog>
