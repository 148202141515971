import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { EssentialDataItem } from '@atline/core/models/essentialDataItem.model';
import { ShareDeData } from '../../../core/services/share-de-data.service';
import { Observable, Subscription } from 'rxjs';
import { Pagination } from '@atline/core/models/pagination.model';
import { DePlateformeType } from '@atline-shared/enums/dePlateforms.enum';
import { Icons } from '@atline-shared/enums/icons.enum';
import { environment } from 'src/environments/environment';
import { UtilsService } from '@atline/core/services/utils.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  @ViewChild('endDeList', { static: false }) element!: ElementRef<HTMLElement>;
  isElementScrolledIntoView = false;

  private elementDisplayed = 10;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView(): void {
    if (this.element) {
      const rect = this.element.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isElementScrolledIntoView = topShown && bottomShown;
      if (this.isElementScrolledIntoView) {
        this.elementDisplayed += 10;
        this.setDeList();
        this.isElementScrolledIntoView = false;
      }
    }
  }


  private subs = new Subscription();

  public deList!: EssentialDataItem[];
  public deListForDisplay!: EssentialDataItem[];

  public loading!: boolean;

  loading$!: Observable<boolean>;

  iconFile = Icons.AnyFile;

  constructor(
    private readonly shareDeData: ShareDeData,
    private readonly utilServices: UtilsService
  ) { }

  ngOnInit(): void {
    this.shareDeData.reload();
    const loadingSub = this.shareDeData.searchInProgressChange.subscribe((res) => this.loading = res);
    const deSub = this.shareDeData.essentialDataChange.subscribe(res => {
      this.deList = res;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.shareDeData.params.plateform !== DePlateformeType.MS ? this.setDeList() : this.deListForDisplay = this.deList;
    });
    this.subs.add(deSub);
    this.subs.add(loadingSub);
  }

  ngAfterContentInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get nbEssentialData(): number {
    return this.shareDeData.numberEssentialData;
  }

  get pagination(): Pagination {
    return this.shareDeData.pagination;
  }

  get hasDe(): boolean {
    if (_.isUndefined(this.deList))
      return false;
    return this.deList.length > 0;
  }

  get identifier(): string {
    return this.shareDeData.params.identifier ?? '';
  }

  get plateform(): DePlateformeType {
    return this.shareDeData.params.plateform;
  }

  get plateformAIFE(): boolean {
    return this.plateform === DePlateformeType.AIFE;
  }

  get plateformMS(): boolean {
    return this.plateform === DePlateformeType.MS;
  }

  get plateformPES(): boolean {
    return this.plateform === DePlateformeType.PES;
  }

  get downloadLink(): string {
    return `${environment.api}get_pes_data?plateform=PES_JSON&identifier=${this.identifier}&access_token=${this.utilServices.token}`;
  }

  changePage(pageNumber: number): void {
    this.shareDeData.setParamsPagination(this.pagination.nbr_par_page, pageNumber);
  }

  changeNbParPage(nbParPage: number): void {
    this.shareDeData.setParamsPagination(nbParPage, 1);
  }

  setDeList(): void {
    this.deListForDisplay = this.deList.slice(0, this.elementDisplayed);
  }

}
