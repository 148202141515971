// tslint:disable:variable-name
export class TypeStrucutre {

  private _pattern: string | undefined;
  private _regExp: RegExp;
  private _sousTypesStructure: TypeStrucutre[] = [];

  public constructor(value: string, pattern?: string)
  public constructor(value: string, sousTypesStructure: TypeStrucutre[])
  constructor(readonly value: string, patternOrSoustype?: string | TypeStrucutre[]) {
    if (patternOrSoustype instanceof Array) {
      this._sousTypesStructure = patternOrSoustype;
      this._regExp = new RegExp('');
    }
    else if (typeof patternOrSoustype === 'string') {
      this._pattern = patternOrSoustype;
      this._regExp = new RegExp(this._pattern);
    }
    else {
      this._pattern = '';
      this._regExp = new RegExp('');
    }
  }

  get pattern(): string | undefined {
    return this._pattern;
  }

  get regExp(): RegExp | undefined {
    return this._regExp;
  }

  get sousTypesStructure(): TypeStrucutre[] {
    return [...this._sousTypesStructure];
  }
}

export const TypesStructure = {
  SIRET: new TypeStrucutre('siret', '[0-9]{14}'),
  SIREN: new TypeStrucutre('siren', '[0-9]{9}'),
  RCI: new TypeStrucutre('rci'),
  TVA_INTRACOM: new TypeStrucutre('tva intracom.', []),
  TAHITI: new TypeStrucutre('tahiti'),
  RIDET: new TypeStrucutre('ridet'),
  FRWF: new TypeStrucutre('frwf'),
  IREP: new TypeStrucutre('irep'),
  AUTRE: new TypeStrucutre('autre'),
  HORS_UE: new TypeStrucutre('hors ue'),
  RNA: new TypeStrucutre('rna'),
} as const;

export const typesStructure: readonly TypeStrucutre[] = [
  new TypeStrucutre('siret', '[0-9]{14}'),
  new TypeStrucutre('rci'),
  new TypeStrucutre('tva intracom.', []),
  new TypeStrucutre('tahiti'),
  new TypeStrucutre('ridet'),
  new TypeStrucutre('frwf'),
  new TypeStrucutre('irep'),
  new TypeStrucutre('autre'),
  new TypeStrucutre('hors ue'),
  new TypeStrucutre('rna')
] as const;

export function containsType(value: string): TypeStrucutre | undefined {
  return Object.values(TypesStructure).find(type => type.value === value);
}

export function isTypeStrucutre(arg: any): arg is TypeStrucutre {
  return arg instanceof TypeStrucutre;
}
// tslint:enable:variable-name
