import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalDataService } from 'src/app/core/services/data-service.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ms-nav-menu',
  templateUrl: './ms-nav-menu.component.html',
  styleUrls: ['./ms-nav-menu.component.scss'],
})
export class MsNavMenuComponent implements OnInit {
  @Input()
  public isOpenHamburger = false;

  @Output()
  readonly isOpenHamburgerChange = new EventEmitter<boolean>();

  public isLogged = false;

  currentRoute = '';

  public navElement = [{ url: 'marches-securises' }];

  isPa!: Observable<boolean>;

  activePa = environment.activation.pa;

  constructor(
    private readonly router: Router,
    private readonly globalData: GlobalDataService,
    private readonly utilsService: UtilsService
  ) {
    this.isPa = globalData.isPa;
    this.router.events
      .pipe<any>(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event.url.split('/')[2] === undefined || event.url.split('/')[2] === 'consultation') {
          this.currentRoute = '';
        } else this.currentRoute = event.url.split('/')[2];
      });
  }

  ngOnInit(): void {
    this.globalData.isLogged.subscribe((isLogged) => {
      this.isLogged = isLogged;
    });    
  }

  get isConnected(): boolean {
    return this.utilsService.isLoggedWithAuthorizationCode;
  }

  closeMenu(): void {
    this.isOpenHamburger = false;
    this.isOpenHamburgerChange.emit(this.isOpenHamburger);
  }
}
