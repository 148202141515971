import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { EditAnnonceRequest } from '../models/api/requests/annonce/editAnnonce.request';
import { EditAnnonceResponse } from '../models/api/responses/annonce/editAnnonce.response';
import { Observable } from 'rxjs';
import { AccountsRequest } from '../models/api/requests/annonce/accounts.request';
import { AccountsResponse } from '../models/api/responses/annonce/accounts.response';
import { DeleteAnnonceRequest } from '../models/api/requests/annonce/delete.request';
import { DeleteAnnonceResponse } from '../models/api/responses/annonce/delete.response';
import { RequestForPa } from '../models/api/requests/requestForPa.request';
import { FormulairesAnnonceResponse } from '../models/api/responses/formualiresList.response';
import { AnnonceListRequest } from '../models/api/requests/annonce/annonceList.request';
import { AnnonceListResponse } from '../models/api/responses/annonce/annonceList.response';
import { VerifyRulesRequest } from '../models/api/requests/annonce/verifyRules.request';
import { VerifyRulesResponse } from '../models/api/responses/annonce/verifyRules.response';
import { RequestForPaWithAnnonce } from '../models/api/requests/annonce/requestForPaAnnonce.request';
import { LotAnnonce, LotsListAnnonceResponse } from '../models/api/responses/annonce/lotsList.response';
import { EditLotRequest } from '../models/api/requests/annonce/editLot.request';
import { CurrenciesListRequest } from '../models/api/requests/currenciesList.request';
import { CurrenciesListResponse } from '../models/api/responses/currenciesList.response';
import { AnnonceDetailsRequest } from '../models/api/requests/annonce/annonceDetails.request';
import { AnnonceDetails } from '../models/api/responses/annonce/annonceDetails.response';
import { DeleteLotRequest } from '../models/api/requests/annonce/deleteLot.request';

import { MessageResponse } from '../models/api/responses/message.response';
import { SupportsListRequest } from '../models/api/requests/annonce/supportsList.request';
import { SupportListResponse } from '../models/api/responses/supportsList.response';
@Injectable({
  providedIn: 'root'
})
export class AnnoncesService {

  constructor(private readonly api: ApiService) { }

  getAccounts(request: AccountsRequest): Observable<AccountsResponse> {
    return this.api.post(AccountsResponse, request, `seal/account_list`);
  }

  editAnnonce(request: EditAnnonceRequest): Observable<EditAnnonceResponse> {
    return this.api.post(EditAnnonceResponse, request, 'seal/annonce_edit');
  }

  delete(request: DeleteAnnonceRequest): Observable<DeleteAnnonceResponse> {
    return this.api.delete(DeleteAnnonceResponse, request, 'seal/delete_annonce');
  }

  getFormulaires(request: RequestForPa): Observable<FormulairesAnnonceResponse> {
    return this.api.post(FormulairesAnnonceResponse, request, 'seal/formulaires_list');
  }

  annonces_list(request: AnnonceListRequest): Observable<AnnonceListResponse> {
    return this.api.post(AnnonceListResponse, request, 'seal/annonce_list');
  }

  verifyRules(request: VerifyRulesRequest): Observable<VerifyRulesResponse> {
    return this.api.post(VerifyRulesResponse, request, 'verify_rules');
  }

  lots_list(request: RequestForPaWithAnnonce): Observable<LotsListAnnonceResponse> {
    return this.api.post(LotsListAnnonceResponse, request, 'seal/lots_list');
  }

  editLot(request: EditLotRequest): Observable<LotAnnonce> {
    return this.api.post(LotAnnonce, request, 'seal/lots_edit');
  }

  deleteLot(request: DeleteLotRequest): Observable<MessageResponse> {
    return this.api.delete(MessageResponse, request, 'seal/delete_annonce_lot');
  }

  getCurrencies(request?: CurrenciesListRequest): Observable<CurrenciesListResponse> {
    return this.api.post(CurrenciesListResponse, request, 'seal/currencies_list');
  }

  getAnnonceDetails(request: AnnonceDetailsRequest): Observable<AnnonceDetails> {
    return this.api.post(AnnonceDetails, request, 'seal/details_annonce');
  }

  supportsList(request: SupportsListRequest): Observable<SupportListResponse> {
    return this.api.post(SupportListResponse, request, 'seal/supports_list');
  }
}
