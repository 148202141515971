import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerComponent } from './partner.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { SectionTitleModule } from '../section-title/section-title.module';



@NgModule({
  declarations: [PartnerComponent],
  exports: [PartnerComponent],
  imports: [
    CommonModule,
    LayoutModule,
    FlexLayoutModule,
    NgxTranslateModule,
    MatDividerModule,
    SectionTitleModule
  ]
})
export class PartnerModule { }
