
<app-header [isOnAService]=true></app-header>

<app-ms-nav *ngIf="!inSpacePa" ></app-ms-nav>
<div fxLayout="row" class="hamburger-icon" fxHide.gt-md="true" fxHide.md="true" *ngIf="!inSpacePa">
  <img src="../../../assets/icons/svg/tender.svg" alt="" class="ms_logo" (click)="toggleMobileMenu()"  fxHide.gt-md="true" fxHide.md="true"/>
  <div class="title_ms"  fxHide.gt-md="true" fxHide.md="true">
    {{ "APP.MARCHES_SECURISES.TITLE_MARCHES" | translate | uppercase }} <br />
    {{ "APP.MARCHES_SECURISES.TITLE_SECURISES" | translate | uppercase }}
  </div>
</div>
<app-ms-nav-menu *ngIf="!inSpacePa" [(isOpenHamburger)]="servicesMenuHamburgerIsOpen"></app-ms-nav-menu>
<router-outlet></router-outlet>

<app-hotline-footer></app-hotline-footer>

<app-footer></app-footer>
