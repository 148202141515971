import { Pagination } from '../../pagination.model';

// tslint:disable:variable-name
interface IAttributaire {
  cle_pa: string;
  id: number;
  cle: string;
  cle_dce: string;
  cle_lot: string;
  cle_ent?: string;
  cle_entreprise_ms: string;
  cle_annuaire?: string | null;
  siret: string;
  utilisateur_id_ident?: string;
  email_contact: string;
  date_notification: string | number;
  montant: number;
  date_terminaison: string | number;
  nbr_mois: number;
  nom_ent: string;
  localite: string;
  parent_id: number;
  statut: string | number;
  num_interne?: number;
  num_ordre?: number;
  num_ident_aa?: string;
  type_identification?: number;
  cp: string;
}
export interface Attributaire extends IAttributaire {
  cle_groupe?: string | null;
  push_status: boolean;
  id_marche_atst?: string | null;
}

export class AttributaireListReponse {
  attributaires: Attributaire[] = [
    {
      cle_pa: '',
      id: 0,
      cle: '',
      cle_dce: '',
      cle_lot: '',
      cle_ent: '',
      cle_entreprise_ms: '',
      cle_annuaire: '',
      siret: '',
      utilisateur_id_ident: '',
      email_contact: '',
      date_notification: '',
      montant: 0,
      date_terminaison: '',
      nbr_mois: 0,
      nom_ent: '',
      localite: '',
      parent_id: 0,
      statut: '',
      num_interne: 0,
      num_ordre: 0,
      num_ident_aa: '',
      type_identification: 0,
      cp: '',
      cle_groupe: null,
      push_status: false,
      id_marche_atst: null,
    },
  ];
  nb_total = 0;
  pagination = new Pagination();
}


export class AttributaireEditReponse implements IAttributaire {
  cle_etab = '';
  cle_pa = '';
  id = 0;
  cle = '';
  cle_dce = '';
  cle_lot = '';
  cle_ent = '';
  cle_entreprise_ms = '';
  cle_annuaire = '';
  siret = '';
  utilisateur_id_ident = '';
  email_contact = '';
  date_notification = '';
  montant = 0;
  date_terminaison = '';
  nbr_mois = 0;
  nom_ent = '';
  localite = '';
  parent_id = 0;
  statut = '';
  num_interne = 0;
  num_ordre = 0;
  num_ident_aa = '';
  type_identification = 0;
  cp = '';
  push_status = false;
}

export interface AttributaireTypes {
  id: number;
  libelle: string;
}

export class AttributaireTypesReponse {
  attrib_types: AttributaireTypes[] = [{
    id: 0,
    libelle: '',
  }];
  nb_total = 0;
}

export class AttributaireRemoveReponse {
  success = false;
}

// tslint:enable:variable-name
