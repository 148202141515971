import { Injectable, OnDestroy } from '@angular/core';
import { Pagination } from '@atline/core/models/pagination.model';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounce, mergeMap, take, tap } from 'rxjs/operators';
import { ConsultationRequest } from '../models/api/requests/consultation.request';
import { ConsultationsService } from './consultations.service';
import { ConsultationResponse } from '../models/api/responses/consultations.response';
import RequestHelper from '../helpers/request.helper';
import { UtilsService } from './utils.service';
import { ConsultationOeItem } from '../models/consultation/ConsultationOeItem.model';
import { ConsultationSemItem } from '../models/consultation/ConsultationSem.model';

@Injectable()
export class ShareConsultationOeData implements OnDestroy {

  private readonly emitSubject = new Subject();

  private isLoadingData = false;

  private readonly paramsConsultationOeDataSubject = new BehaviorSubject<ConsultationRequest>(new ConsultationRequest());

  readonly paramsConsultationOeDataChange = this.paramsConsultationOeDataSubject.pipe(debounce(() => this.emitSubject));

  private readonly consultationOeDataSubject = new BehaviorSubject<ConsultationOeItem[]>([]);
  readonly consultationOeDataChange = this.consultationOeDataSubject.pipe();

  private readonly consultationDipslayedSubject = new BehaviorSubject<ConsultationOeItem | ConsultationSemItem | null>(null);
  readonly consultationDipslayedChange = this.consultationDipslayedSubject.pipe();

  private readonly numberConsultationOeDataSubject = new BehaviorSubject<number>(0);
  readonly numberConsultationOeDataChange = this.numberConsultationOeDataSubject.pipe();

  private readonly searchInProgressSubject = new BehaviorSubject<boolean>(false);
  readonly searchInProgressChange = this.searchInProgressSubject.pipe();

  private readonly paginationSubject = new BehaviorSubject<Pagination>(new Pagination());
  readonly paginationChange = this.paginationSubject.pipe();

  private readonly errorSubject = new BehaviorSubject<any>(undefined);
  readonly errorChange = this.errorSubject.pipe();

  private readonly subs = new Subscription();

  constructor(
    private readonly consultationService: ConsultationsService,
    private readonly utilsService: UtilsService
  ) {
    this.initSetParams();
  }

  private initSetParams(): void {
    this.numberConsultationOeDataSubject.next(0);
    const paramsChange = this.paramsConsultationOeDataChange.pipe(
      mergeMap(() => {
        this.searchInProgressSubject.next(true);
        this.isLoadingData = true;
        this.errorSubject.next(undefined);
        return this.searchConsultationList();
      }),
    ).subscribe({
      next: rep => {
        this.consultationOeDataChange.pipe(take(1)).subscribe(() => this.searchInProgressSubject.next(false));
        this.numberConsultationOeDataSubject.next(rep.nb_total < 0 ? 0 : rep.nb_total);
        this.consultationOeDataSubject.next(rep.consultations);
        this.paginationSubject.next(rep.pagination);
      },
      error: err => {
        this.errorSubject.next(err);
        this.searchInProgressSubject.next(false);
      }
    });

    this.subs.add(paramsChange);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get numberConsultationOeData(): number {
    return this.numberConsultationOeDataSubject.value;
  }

  get params(): ConsultationRequest {
    return this.paramsConsultationOeDataSubject.value;
  }

  get pagination(): Pagination {
    return this.paginationSubject.value;
  }

  get consultationOeData(): ConsultationOeItem[] {
    return this.consultationOeDataSubject.value;
  }

  get isLoading(): boolean {
    return this.isLoadingData;
  }

  get consultation(): ConsultationOeItem | ConsultationSemItem | null {
    return this.consultationDipslayedSubject.value;
  }

  setParams(params: ConsultationRequest, emit = true): void {
    if (_.isUndefined(params.demo))
      params = { ...params, demo: this.utilsService.isDemo };

    if (!_.isEqual(RequestHelper.buildParams(params), RequestHelper.buildParams(this.paramsConsultationOeDataSubject.value))) {
      this.consultationOeDataSubject.next([]);
      if (!params.sens_ordre)
        params.sens_ordre = 'ASC';
      this.paramsConsultationOeDataSubject.next(params);
    } else {
      emit = false;
    }
    if (emit) this.emitSubject.next();
  }

  setConsultation(c: ConsultationOeItem | ConsultationSemItem | null): void {
    this.consultationDipslayedSubject.next(c);
  }

  setParamsPagination(nbParPage: number, page: number, emit = true): void {
    this.consultationOeDataSubject.next([]);
    this.paramsConsultationOeDataSubject.next({
      ...this.params,
      nbr_par_page: nbParPage,
      page
    });
    if (emit) this.emitSubject.next();
  }

  get searchInProgress(): boolean {
    return this.searchInProgressSubject.value;
  }


  get error(): any {
    return this.errorSubject.value;
  }

  private searchConsultationList(): Observable<ConsultationResponse> {
    this.consultationOeDataSubject.next([]);
    return this.consultationService.consultationListOe(this.params).pipe(
      tap((response: any) => {
        this.consultationOeDataSubject.next(response?.donnees || []);
        // response = response.consultations.map((c: any) => new ConsultationOeItem(c))
      })
    );
  }

  resetConsultation(): void {
    this.setParamsPagination(10, 1, false);
    this.numberConsultationOeDataSubject.next(0);
    this.consultationOeDataSubject.next([]);
  }

  reload(): void {
    this.emitSubject.next();
  }
}
