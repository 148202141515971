<app-dialog [dialogRef]="dialogRef">

  <div header>
    <a atlineLink>{{'APP.MARCHES_SECURISES.ELRAR.CGU.HEADER' | translate}}</a>
  </div>

  <div body>
    <div [innerHTML]="'APP.MARCHES_SECURISES.ELRAR.CGU.INFO' | translate"></div>

    <div style="margin-top: 25px;" class="text-bl">
      <app-round-checkbox [code]="'accept'" [name]="'accept'" (changeState)="getAcceptCGU($event)" class="full-width">
        <label [for]="'accept'"> {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.ACCEPT' | translate}}
        </label>
      </app-round-checkbox>
    </div>

  </div>

  <div footer fxLayout="align" fxLayoutAlign="end center">
    <app-button [size]="'auto'" [disabled]="!acceptCgu" (click)="validateCgu()">
      {{'APP.BUTTON.VALIDATE' | translate}}
    </app-button>
  </div>

</app-dialog>
