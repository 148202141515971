import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-study-case',
  templateUrl: './study-case.component.html',
  styleUrls: ['./study-case.component.scss']
})
export class StudyCaseComponent implements OnInit {

  private readonly element = document.createElement('a');

  ngOnInit(): void {
    this.element.href = '/assets/pdf/LB.pdf';
    this.element.download = 'Livre blanc.pdf';
  }

  download(): void {
    this.element.click();
  }

}
