<div class="container-story" fxLayout="column nowrap" ngClass.xs="container-story_responsive"
  ngClass.lt-lg="container-story_responsiveTab">
  <div class="part-story-text" fxLayout="column nowrap">


    <div class="part-1" fxLayout="row nowrap" fxLayout.xs="column nowrap" ngClass.xs="respon-part-1"
      fxLayout.lt-lg="column nowrap">

      <!-- Partie 1 -->
      <div class="container-image-ceo" fxFlexOrder.xs="1" fxHide.xs="false">
        <img src="../../../assets/images/home-landing/qui_sommes_nous/RYP_3.png" alt="Roger-Yves" />
        <div class="circle-orange"></div>
      </div>

      <!-- Partie 2 -->
      <div class="container-text container-text-p1">
        <p class="title" [innerHTML]="'APP.HOME.LANDING.STORY.TITLE_HTML' | translate"></p>
        <div class="container-paragraph line" style="margin-top: 20px; margin-bottom: 40px;">
          <p>
            {{ "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.0.TEXT" | translate
            }}<br />
            <span class="key-date">{{
              "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.0.TEXT2" | translate
              }}</span>
            {{ "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.0.TEXT3" | translate }}
          </p>
          <p>
            <span class="key-date">{{
              "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.0.TEXT4" | translate
              }}</span>
            {{ "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.0.TEXT5" | translate }}
          </p>
        </div>
        <div class="container-rect-blue" fxFlexOrder.xs="3" fxHide.xs="true">
          <p>
            "
            {{
            "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.1.TEXT" | translate
            }}
            "
          </p>
        </div>
      </div>
      <div class="container-rect-blue" fxFlexOrder.xs="3" fxHide.xs="false">
        <p>
          "
          {{
          "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.1.TEXT" | translate
          }}
          "
        </p>
      </div>
    </div>




    <div class="part-2" fxLayout="row nowrap" ngClass.xs="respon-part-2" fxLayout.xs="column nowrap">
      <div class="container-text">

        <div class="container-paragraph line">
          <p>
            <span class="key-date">{{
              "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.2.TEXT" | translate
              }}</span>
            {{ "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.2.TEXT2" | translate }}
          </p>
          <p>
            {{ "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.2.TEXT3" | translate }}
          </p>
        </div>

        <div class="container-paragraph line">
          <p>
            <span class="key-date">{{
              "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.2.TEXT4" | translate
              }}</span>
            {{ "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.2.TEXT5" | translate }}
          </p>
        </div>
        <div class="container-paragraph" ngClass.lt-lg="line">
          <p>
            {{ "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.2.TEXT6" | translate }}
          </p>
        </div>

        <div class="container-paragraph container-sign" fxLayout="row" style="align-items: flex-end; margin-top: 10px;">
          <p style="margin: 0">{{'APP.HOME.LANDING.STORY.NAME-DR' |translate}}</p>
          <img src="/assets/images/home-landing/accueil/RYP_Signature.png" alt="signature"
            style="height: 50px; margin-left: 5px;" />
        </div>

      </div>

      <div class="container-round-text">
        <div class="round-text circle-orange">
          <p>
            &laquo;
            {{
            "APP.HOME.LANDING.STORY.PARAGRAPH_HTML.TEXT_ORANGE_CIRCLE"
            | translate
            | uppercase
            }}
            &raquo;
          </p>
        </div>
      </div>
    </div>

  </div>

  <app-key-moments ngClass.xs="point-responsive">
    <ng-container *ngFor="let date of keysDates">
      <cdk-step [label]="date" ngClass.xs="point">
        <p [style]="{ 'min-height': '70px'}" fxLayout="row nowrap" fxLayoutAlign="center center">
          {{
          "APP.HOME.LANDING.KEYS-DATES.DATES." + date | translate
          }}
        </p>
      </cdk-step>
    </ng-container>
  </app-key-moments>

  <div class="numbers" fxLayout="row nowrap" fxLayout.xs="column nowrap">
    <div class="container-lines-color">
      <div class="line-color purple"></div>
      <div class="line-color orange"></div>
      <div class="line-color blue-light"></div>
    </div>
    <div class="line-color blue-light" fxHide.xs="false"></div>
    <div class="container-numbers" fxLayout="row nowrap" fxLayoutAlign="flex-start center">


      <span class="title-numbers" fxFlex.xs="100">{{'APP.HOME.LANDING.STORY.TITEL-NUMBER' |translate}}</span>
      <div class="case-number">
        <p class="data">{{'APP.HOME.LANDING.STORY.NUMBERS.CASE-NUMBERS-1.DATA' |translate}}</p>
        <p class="text">{{'APP.HOME.LANDING.STORY.NUMBERS.CASE-NUMBERS-1.TEXT' |translate}}</p>
      </div>
      <div class="case-number">
        <p class="data">{{'APP.HOME.LANDING.STORY.NUMBERS.CASE-NUMBERS-2.DATA' |translate}}</p>
        <p class="text">{{'APP.HOME.LANDING.STORY.NUMBERS.CASE-NUMBERS-2.TEXT' |translate}}</p>
      </div>
      <div class="case-number">
        <p class="data">{{'APP.HOME.LANDING.STORY.NUMBERS.CASE-NUMBERS-3.DATA' |translate}}</p>
        <p class="text">{{'APP.HOME.LANDING.STORY.NUMBERS.CASE-NUMBERS-3.TEXT' |translate}}</p>
      </div>
      <div class="case-number">
        <p class="data">{{'APP.HOME.LANDING.STORY.NUMBERS.CASE-NUMBERS-4.DATA' |translate}}</p>
        <p class="text">{{'APP.HOME.LANDING.STORY.NUMBERS.CASE-NUMBERS-4.TEXT' |translate}}</p>
      </div>

    </div>
    <div class="line-color orange" fxHide.xs="false"></div>
    <div class="line-color purple" fxHide.xs="false"></div>
  </div>
  <!-- fxHide.gt-sm="true"
  <div class="numbers mobile">
    <div class="line-color blue-light"></div>
    <span class="title-numbers"></span>
    <div class="container-numbers">
      <div class="case-number"></div>
      <div class="case-number"></div>
      <div class="case-number"></div>
      <div class="case-number"></div>
    </div>
    <div class="line-color orange"></div>
    <div class="line-color purple"></div>
  </div> -->
  <app-section-engagement-rse></app-section-engagement-rse>
  <!--<app-section-postulation></app-section-postulation>-->
</div>