import { Pagination } from '../../pagination.model';
import { DceEssentialData } from './essential-data.response';

export class DceEssentialDataList {
  // tslint:disable:variable-name
  cle_dce = '';
  is_concession = 0;
  objet_marche = '';
  dce_donnees_essentielles = [new DceEssentialData()];
  nb_total = 0;
  pagination = new Pagination();
  // tslint:enable:variable-name
}
