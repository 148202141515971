import { Component, ContentChildren, ElementRef, HostBinding, HostListener, Input, OnDestroy, QueryList, ViewChild, AfterContentChecked } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { ButtonSideBarComponent } from './button-side-bar/button-side-bar.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy, AfterContentChecked {

  public open = false;
  readonly mediaChange!: Subscription;

  @ViewChild('sidebarElement')
  public containerSidebarElement?: ElementRef<HTMLDivElement>;

  @ViewChild('btn', { static: false, read: ElementRef })
  public btn?: ElementRef<HTMLButtonElement>;

  @ContentChildren(ButtonSideBarComponent)
  buttonsSidebar!: QueryList<ButtonSideBarComponent>;

  @Input()
  public overlayOrigin?: CdkOverlayOrigin;

  readonly positionOverlay: ConnectedPosition = { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' };
  private btnClicked = false;

  private subs?: Subscription;

  constructor(private readonly media: MediaObserver) {
    this.mediaChange = media.asObservable()
      .pipe(filter(changes => !_.some(changes, ['mqAlias', 'lt-md'])))
      .subscribe(() => this.open = false);
  }

  ngAfterContentChecked(): void {
    this.subs?.unsubscribe();
    if (this.buttonsSidebar.length > 0) {
      this.subs = new Subscription();
      for (const btnSidebar of this.buttonsSidebar.toArray()) {
        const eventClick = (): void => { if (this.open) this.open = false; }
        this.subs.add(btnSidebar.btnClicked.subscribe(eventClick));
        this.subs.add(btnSidebar.group?.subButtonClicked.subscribe(eventClick));
      }
    }
    delete this.subs;
  }

  isButtonVersion(): boolean {
    return this.media.isActive('lt-md');
  }

  @HostBinding('class')
  get clazz(): string {
    return `sidebar ${this.isButtonVersion() ? 'sidebar-button' : 'basic'}`;
  }

  openClose(): void {
    this.btnClicked = true;
    setTimeout(() => {
      this.open = !this.open;
      this.btnClicked = false;
    }, 10);
  }

  @HostListener('document:click', ['$event'])
  outsideClick(event: MouseEvent): void {
    const el = this.containerSidebarElement?.nativeElement;

    if (!this.btnClicked && el && this.isButtonVersion() && this.open) {
      this.open = el.contains(event.target as any);
    }
  }

  ngOnDestroy(): void {
    this.mediaChange.unsubscribe();
  }
}
