<div class="container" fxFlex="100">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <h1 *ngIf="!rights" class="text-bl" fxFlex="45">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.TITLE' | translate: { nbGroups: nbGroups } }}
    </h1>

    <h1 *ngIf="rights" class="text-bl" fxFlex="45">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.RIGHTS_OF' | translate  : { group: group.label} | uppercase}}
    </h1>

    <div class="right" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="55">
      <div fxLayout="row" fxLayoutAlign="start center">
        <label class="search label-input">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.SEARCH_GROUPS' |
          translate }}</label>
        <input mat-input type="search" class="form-control" aria-label="Recherche" aria-describedby="search-addon"
          #searchInput (input)="searchGroups(searchInput.value)" (keyup.enter)="loadGroups()">
        
        <button mat-icon-button class="loupe" (click)="loadGroups()">
          <mat-icon [svgIcon]="icons.loupe" class="search-icon"></mat-icon>
        </button>

        <button mat-icon-button (click)="searchInput.value = ''; searchGroups(''); loadGroups()">
          <mat-icon [svgIcon]="icons.Clear" class="clear-icon"></mat-icon>
        </button>

      </div>

      <div fxLayout="row" class="add-group" fxLayoutAlign="start center">
        <label class="label-input">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.ADD_GROUP_LABEL' |
          translate }}</label>
        <app-button class="btn btn-primary" (click)="addGroup()" class="add-group-btn">+</app-button>
      </div>
    </div>
  </div>

  <app-legend-user-admin [cross]="true" *ngIf="legendVisible" [legendType]="'group'"></app-legend-user-admin>
  <app-rights-procedure-dialog *ngIf="rights"></app-rights-procedure-dialog>
  
  <div *ngIf="!legendVisible && !rights" class="body">

    <header fxLayoutAlign="space-between center">
      <label class="label-list">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.LIST' | translate }}</label>
      <div fxLayout="row" fxLayoutAlign="start center" mat-icon-button>
        <button mat-icon-button (click)="legendVisibility()">
          <mat-icon svgIcon="info" class="mat-icon-legend"></mat-icon>
          <label class="legend">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.LEGEND' | translate }}</label>
        </button>
      </div>
      <label>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.NB_USER_LABEL' | translate: { nbUsers: nbGroups,
          plurial: getPlurial(nbGroups) } }}</label>
    </header>

    <div *ngIf="isLoading" class="loading-spinner">
      <mat-spinner [diameter]="80" color="primary"></mat-spinner>
    </div>

    <div *ngIf="!isLoading">
      <table class="table">
        <thead>
          <tr>
            <th fxFlex="70">{{ 'APP.MARCHES_SECURISES.PA.GROUPS.TABLE.GROUP_NAME' | translate }}</th>
            <th fxFlex="10">
              <div class="icon-number">
                <!-- <mat-icon svgIcon="user"></mat-icon> -->
                <!-- <span class="number">{{ nbUsersTotal }}</span> -->
                <div>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.UTILISATEURS' | translate }}</div>
              </div>
            </th>
            <th fxFlex="10">
              <div class="icon-number">
                <!-- <mat-icon svgIcon="eye"></mat-icon> -->
                <div>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.DROITS' | translate }}</div>
              </div>
            </th>
            <th fxFlex="10"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="groupListAdmin.groups.length === 0">
            <td colspan="4" class="no-groups-found">
              <div class="no-groups-message">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.EMPTY_GRP' | translate }}</div>
            </td>
          </tr>
          <tr *ngFor="let group of groupListAdmin.groups">
            <td class="group-label" fxFlex="70">{{ group.label }}</td>
            <td class="group" fxFlex="10">
              <div class="icon-number">
                <button mat-icon-button (click)="openGroupUsers(group)" style="cursor: pointer;">
                  <mat-icon svgIcon="user"></mat-icon>
                </button>
                <span class="number">{{ group.nbUser }}</span>
              </div>
            </td>
            <td fxFlex="10">
              <div class="icon-number">
                <button mat-icon-button (click)="openGroupRights(group)">
                  <mat-icon svgIcon="eye"></mat-icon>
                </button>
                <span class="number">{{ group.affectedRights.nb_total }}</span>
              </div>
            </td>
            <td fxFlex="10">
              <button mat-icon-button (click)="editGroup(group)">
                <mat-icon svgIcon="editpencil"></mat-icon>
              </button>
              <button mat-icon-button (click)="deleteGroup(group)">
                <mat-icon svgIcon="bin"></mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-pagination 
    *ngIf="!rights && !legendVisible"
    [pagination]="paginationGroups"
    [nbConsultation]="nbGroups"
    (changePage)="changePageGroups($event)"
    [fixedPageSize]="true"
    [appearance]="'square'">
  </app-pagination>
</div>
