<div fxLayout="row" fxLayoutAlign="space-between end" fxFlex="100" class="page-title-container">
  <h1 class="page-title">
    {{'APP.MARCHES_SECURISES.HEADER.TITLE.' + key | uppercase | translate: paramsTitle }}
    <span class="avalaible-data" *ngIf="hasNumberInput">
      {{'APP.MARCHES_SECURISES.HEADER.AVALAIBLE.' + key | uppercase | translate: {nbAvalaible: nbAvalaible, plurial:
      plurial} }}
    </span>
  </h1>
  <div class="custom-content">
    <ng-content select="[customRightPart]"></ng-content>
  </div>

</div>
