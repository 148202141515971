<app-dialog [dialogRef]="dialogRef">
    <div header>
        <h1>{{'APP.LEGALS_NOTICE.TITLE' | translate}}</h1>
    </div>
    <div body>
        <ng-container *ngFor="let part of parts">
            <div fxLayout="column">
                <h2>
                    {{'APP.LEGALS_NOTICE.' + part + 'TITLE' | translate}}
                </h2>
                <p>{{'APP.LEGALS_NOTICE.' + part + 'CONTENT' | translate}}</p>
            </div>

        </ng-container>



    </div>
</app-dialog>