import {Injectable} from '@angular/core';
import {Icons} from '../../shared/enums/icons.enum';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
  }

  /**
   * Load all icons defined in icons.enum list as svgIcon
   * Each icon should be provided in assets/icons/svg folder with the same name as the enum item value
   */
  public registerIcons(): void {
    this.loadIcons(Object.values(Icons), '/assets/icons/svg');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}
