import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { AvisAttributionsRequest } from '../models/api/requests/avis-attributions.request';
import { AvisAttributionsResponse } from '../models/api/responses/avis-attributions.response';
@Injectable({
  providedIn: 'root'
})



export class AvisAttributionsService {
  constructor(private readonly apiService: ApiService) {


  }

  getAvisAttribution(request: AvisAttributionsRequest): Observable<AvisAttributionsResponse> {
    return this.apiService.post<AvisAttributionsResponse>(AvisAttributionsResponse, request, 'avis_attributions');

  }
}
