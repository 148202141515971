<app-dialog class="create-annonce-dialog" [dialogRef]="dialogRef">
  <div body class="loading" *ngIf="isLoading" class="spinner">
    <mat-spinner [style.margin]="'auto'" [diameter]="50"></mat-spinner>
  </div>

  <div header>
    <h1 class="titleDialog">
      {{
        "APP.MARCHES_SECURISES.PA.SEARCH.RESSOURCES_ANNONCES.TITLE_DIALOG"
          | translate
      }}
    </h1>
  </div>

  <div body *ngIf="!isLoading && table.data.length <= 0">
    <h1 class="noData">
      {{
        "APP.MARCHES_SECURISES.PA.SEARCH.RESSOURCES_ANNONCES.NO_DATA"
          | translate
          | uppercase
      }}
    </h1>
  </div>

  <div
    body
    class="dialog-content"
    *ngIf="!noData && !isLoading && table.data.length > 0"
    class="container-table-retraits-sort-by-entreprise"
  >
    <div class="dialog-content-inner">
      <table
        #tableRetrait
        mat-table
        [dataSource]="table"
        class="mat-elevation-z8 demo-table sorted-entreprise"
      >
        <ng-container matColumnDef="operateur_economiques">
          <th mat-header-cell *matHeaderCellDef>
            {{
              "APP.MARCHES_SECURISES.PA.SEARCH.RESSOURCES_ANNONCES.OPERA_ECO"
                | translate
                | uppercase
            }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.operateur_economiques }}
          </td>
        </ng-container>

        <ng-container matColumnDef="siret_siren">
          <th mat-header-cell *matHeaderCellDef>
            {{
              "APP.MARCHES_SECURISES.PA.SEARCH.RESSOURCES_ANNONCES.SIRET_SIREN"
                | translate
                | uppercase
            }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.siret_siren }}</td>
        </ng-container>

        <ng-container matColumnDef="emails">
          <th mat-header-cell *matHeaderCellDef>
            {{
              "APP.MARCHES_SECURISES.PA.SEARCH.RESSOURCES_ANNONCES.EMAILS"
                | translate
                | uppercase
            }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.emails }}</td>
        </ng-container>

        <ng-container matColumnDef="villes">
          <th mat-header-cell *matHeaderCellDef>
            {{
              "APP.MARCHES_SECURISES.PA.SEARCH.RESSOURCES_ANNONCES.CITY"
                | translate
                | uppercase
            }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.villes }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon svgIcon="plus" (click)="onAdd(element)"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          (click)="clickedRows.add(row)"
          [class.demo-row-is-clicked]="clickedRows.has(row)"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>

    <div *ngIf="!noData && !isLoading && table.data.length > 0" footer>
      <mat-paginator
        [pageIndex]="page"
        [length]="length"
        [pageSize]="nbParPage"
        [pageSizeOptions]="itemsPerPage"
        (page)="paginatorEvent($event)"
      >
      </mat-paginator>
    </div>
  </div>
</app-dialog>
