import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { GlobalDataService } from 'src/app/core/services/data-service.service';

interface BtnHistoryBase {
  link: string;
  text: string;
}
interface BtnHistoty extends BtnHistoryBase {
  subBtn?: BtnHistoryBase[];
}

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss'],
})
export class HistoriqueComponent implements OnInit {
  public isLogged = false;

  readonly btns: BtnHistoty[] = [
    {
      link: 'messages-envoyes',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.MESSAGES.SENT_MESSAGE',
      subBtn: [
        {
          link: 'messages-envoyes',
          text: 'APP.MARCHES_SECURISES.HISTORIQUE.MESSAGES.SENT_MESSAGE'
        },
        {
          link: 'messages-recus',
          text: 'APP.MARCHES_SECURISES.HISTORIQUE.MESSAGES.RECEIVED_MESSAGE'
        },
      ]
    },
    {
      link: 'response',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.RESPONSE',
    },
    {
      link: 'retrait-list',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.YOUR_RETRAIT',
    },
    {
      link: 'lrar',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.YOUR_LRAR',
    },
    {
      link: 'dume',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.YOUR_DUME'
    },
    {
      link: 'notification',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.NOTIFICATION',
    },
    {
      link: 'demande-execution',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.DEMANDE_EXECUTION'
    },
    {
      link: 'demande-complementaire',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.YOUR_COMPLEMENTAIRE'
    },
    {
      link: 'favoris',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.FAVORIS'
    },
    {
      link: 'sem',
      text: 'APP.MARCHES_SECURISES.HISTORIQUE.BUTTON.YOUR_SEM'
    },

  ];

  constructor(
    private readonly globalData: GlobalDataService,
    private readonly route: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.globalData.isLogged.subscribe((isLogged) => {
      this.isLogged = isLogged;
      if (!isLogged) { this.route.navigate(['marches-securises']); }
    });
  }

  get currentPath(): string {
    return this.activatedRoute.firstChild?.snapshot.url[0].path ?? '';
  }

  go(path: string): void {
    this.route.navigate([
      RoutingEnum.MARCHES_SECURISES,
      RoutingEnum.HISTORIQUE,
      path
    ]);
  }

  includes(btn: BtnHistoty, path: string): boolean {
    return btn.subBtn?.some(({ link }) => link === path) ?? false;
  }

}
