import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeComponent } from './de.component';
import { ButtonModule } from '../../design/button/button.module';


@NgModule({
    declarations: [DeComponent],
    exports: [
        DeComponent
    ],
    imports: [
        CommonModule,
        NgxTranslateModule,
        FlexLayoutModule,
        ButtonModule
    ]
})
export class DeModule { }
