import { EtapeConsultation } from "@atline-shared/enums/EtapeConsultation.emun";

const ETATS_CONSULTATIONS = [
  'toutes',
  'en_cours',
  'creer',
  'validation',
  'cloture',
  'validation1',
  'validation2',
  'archive',
  'supprimee'
] as const;

export type Sort = 'date_cloture' | 'date_publication';
export type Etat = typeof ETATS_CONSULTATIONS[number];
export type Order = 'ASC' | 'DESC';

// tslint:disable:variable-name
export class ParamsPaConsultation {
    sens_ordre: Order = 'ASC';
    ordre: Sort = 'date_cloture';
    page = 1;
    nbParPage = 10;
    etat: Etat = 'toutes';
    mots_cle = '';
    validationAcces1 = false;
    validationAcces2 = false;
    cleDce?: string;
    etape_consultation?: EtapeConsultation;
    sem?: 1 | 0 | null;

    constructor(params?: {
      sens_ordre?: Order,
      ordre?: Sort,
      page?: number,
      nbParPage?: number,
      etat?: Etat,
      mots_cle?: string;
      validationAcces1?: boolean;
      validationAcces2?: boolean;
      cleDce?: string;
      etape_consultation?: EtapeConsultation;
    }) {
      this.sens_ordre = params?.sens_ordre ?? this.sens_ordre;
      this.ordre = params?.ordre ?? this.ordre;
      this.page = params?.page ?? this.page;
      this.nbParPage = params?.nbParPage ?? this.nbParPage;
      this.etat = params?.etat ?? this.etat;
      this.mots_cle = params?.mots_cle ?? this.mots_cle;
      this.validationAcces1 = params?.validationAcces1 ?? this.validationAcces1;
      this.validationAcces2 = params?.validationAcces2 ?? this.validationAcces2;
      this.cleDce = params?.cleDce ?? this.cleDce;
      this.etape_consultation = params?.etape_consultation ?? this.etape_consultation;
    }
}
// tslint:enable:variable-name

export function isEtat(value: any): value is Etat {
  return ETATS_CONSULTATIONS.includes(value);
}
