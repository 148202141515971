export enum grantType {
  CLIENT_CREDENTIALS = 'client_credentials',
  AUTHORIZATION_CODE = 'authorization_code',
  REFRESH_TOKEN = 'refresh_token'
}

export enum UserType {
  pa = 'pa',
  oe = 'oe'
}

export enum ConsultationRequestCode {
  UPC = 'Unpublished_consultation',
  PCT = 'published_consultation'
}
