<div class="component-container">
  <div class="header-top-nav mat-elevation-z2" ngClass.xs="header-top-nav-responsive">
    <div class="container" ngClass.xs="container-responsive">
      <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
        <!--<a class="logo" routerLink="/home" ngClass.xs ="logo-responsive">
        <img alt="atline services logo" src="assets/icons/svg/atline-logo_footer.svg" width="auto" height="auto">
      </a>-->
        <div fxLayout="row" fxLayoutAlign="start center" style="height: 100%;">
          <div class="navBar" fxLayoutAlign="start center" fxLayout="row">
            <a class="logo" routerLink="/home" [ngClass]="{'isServiceSide': isOnAService}"></a>
            <div class="hamburger-icon" (click)="toggleMobileMenu()" *ngIf="!isOnAService">
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
            </div>
          </div>

          <mat-divider [vertical]="true"></mat-divider>

          <app-date-time-item *ngIf="isOnAService" fxHide.xs="true"></app-date-time-item>

          <app-services-item fxHide.lt-md="true" *ngIf="!isOnAService"
            (click)="openCloseServicesMenu()"></app-services-item>

          <mat-divider [vertical]="true"></mat-divider>

        </div>

        <div fxHide.lt-md="true" class="container-switch-old-version-ms" fxLayoutAlign="start center" fxLayoutGap="5px">
          <app-switch [isActivate]="oldVersion" (isActivated)="oldVersion = $event; $event ? goOldVersion() : false;"></app-switch>
          <span>{{ 'APP.TOP_MENU.REDIRECTION' | translate }}</span>
        </div>


        <mat-nav-list fxLayout="row" fxLayoutAlign="start center" style="height: 100%;">

          <mat-divider [vertical]="true"></mat-divider>


          <a mat-list-item class="hotline-item" [href]="'tel:' + hotlineNumber">
            <app-hotline-item></app-hotline-item>
          </a>


          <a mat-list-item class="contact-item" fxHide.lt-lg="true">
            <app-contact-item></app-contact-item>
          </a>

          <mat-divider [vertical]="true" fxHide.lt-lg="true"></mat-divider>

          <a mat-list-item [href]="atlineFormation" target="_blank" fxHide.lt-lg="true" rel="noopener noreferrer">
            <app-coach-item></app-coach-item>
          </a>

          <mat-divider [vertical]="true"></mat-divider>

          <a mat-list-item class="login">
            <app-login-item *ngIf="!isLoggedIn"></app-login-item>
            <app-user-item *ngIf="isLoggedIn"></app-user-item>
          </a>

          <mat-divider [vertical]="true" *ngIf="isOnAService"></mat-divider>

          <app-lang-selector *ngIf="mediaMatcher.matchMedia('(min-width: 336px)').matches" class="lang-component"></app-lang-selector>

        </mat-nav-list>

      </mat-toolbar>
    </div>

  </div>
  <app-services-menu class="menu" *ngIf="!isOnAService" [isOpen]="servicesMenuIsOpen"></app-services-menu>
  <app-services-menu-hamburger class="menu" *ngIf="!isOnAService"
    [isOpenHamburger]="servicesMenuHamburgerIsOpen"></app-services-menu-hamburger>
</div>
