<!-- <div >
  <mat-menu #langMenu="matMenu">

  </mat-menu>



</div> -->

<div style="position: relative;">
  <button class="lang-bn light-border" [ngClass]="{'active': isMenuOpen}" mat-icon-button
    (click)="isMenuOpen = !isMenuOpen">
    {{currentLang | uppercase}}
  </button>

  <div class="menu-container" [ngClass]="{'hidden': !isMenuOpen}">
    <ng-container *ngFor="let lang of languagesList">
      <div class="menu">
        <button mat-menu-item *ngIf="lang.value !== currentLang" (click)="onSelect(lang.value)">{{lang.value |
          uppercase}}</button>
      </div>
    </ng-container>
  </div>

</div>
