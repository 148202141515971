import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-marches-securises',
  templateUrl: './marches-securises.component.html',
  styleUrls: ['./marches-securises.component.scss']
})
export class MarchesSecurisesComponent implements OnDestroy {

  public cleDce?: string;
  public servicesMenuHamburgerIsOpen = false;
  private readonly routerSubscription!: Subscription;

  constructor(private router: Router) {
    this.getDceFromUrl(this.router.url);
    this.routerSubscription = this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(event => this.getDceFromUrl(event.url));
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  toggleMobileMenu() :void {
    this.servicesMenuHamburgerIsOpen = !this.servicesMenuHamburgerIsOpen;
  }

  get inSpacePa(): boolean {
    return this.router.url.split('/')[2] === 'pa';
  }

  get isOnMsHomePage(): boolean {
    return this.router.url.split('/')[this.router.url.split('/').length - 1] === 'marches-securises';
  }

  private getDceFromUrl(url: string): void{
    this.cleDce = undefined;
    const urlSplit = url.split('/');
    if ((urlSplit[2] !== undefined) || (urlSplit[2] === 'consultation') && urlSplit[3] !== undefined) {
      this.cleDce = urlSplit[3];
    }
  }

}
