import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit, OnChanges {

  @Input()
  key!: string;

  @Input()
  paramsTitle: any = {};

  @Input()
  nbAvalaible?: number;

  @Input()
  loadingData?: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paramsTitle)
      this.paramsTitle = {
        ...this.paramsTitle,
        plurial: this.paramsTitle.nbCons ? this.paramsTitle.nbCons > 1 ? 's' : '' : this.plurial
      }
  }

  get hasNumberInput(): boolean {
    return !_.isUndefined(this.nbAvalaible);
  }

  get plurial(): string {
    if (!_.isUndefined(this.nbAvalaible))
      return this.nbAvalaible > 1 ? 's' : '';
    return '';
  }

}
