import { Component, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { AuthenticateService } from '@atline/core/services/authenticate.service';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

interface Option {
  readonly label: string;
  readonly fInvisible?: () => boolean;
  readonly disabled?: () => boolean;
}

type EtatOption =  'SignatureBoutique' | 'webSign';

interface OptionGroup extends Option {
  etat: EtatOption;
  options?: Option[];
}

@Component({
  selector: 'app-certificat-signature',
  templateUrl: './certificat-signature.component.html',
  styleUrls: ['./certificat-signature.component.scss'],
})
export class CertificatSignatureComponent implements OnInit {
  readonly options: OptionGroup[] = [
    {
      etat: RoutingEnum.SIGNATURE_BOUTIQUE,
      label: 'APP.MARCHES_SECURISES.SIGNATURE.LABEL1',
    },
    {
      etat: RoutingEnum.WEBSIGN,
      label: 'APP.MARCHES_SECURISES.SIGNATURE.LABEL3',
    }
  ];

  readonly keyTitleAsync!: Observable<string | null>;

  constructor(
    private readonly router: Router,
    readonly authenticationService: AuthenticateService,
    readonly mediaObserver: MediaObserver,
    route: ActivatedRoute
  ) {
    this.keyTitleAsync = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(router),
      map(() => route.firstChild?.snapshot.url?.[0]),
      map(url => {
        if (!url) return null;
        if (url.path === RoutingEnum.SIGNATURE_BOUTIQUE) return 'WEBSHOP';
        if (url.path === RoutingEnum.WEBSIGN) return 'WEBSIGN_VERIFIER';
        return null;
      })
    );
  }

  ngOnInit(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  goToEtat(etat: EtatOption): void {
    this.router.navigate([
      '/marches-securises',
      RoutingEnum.CERTIFICAT_SIGNAUTRE_MAIN,
      etat,
    ]);
  }
}
