import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogsService } from 'src/app/core/services/dialogs.service';

@Component({
  selector: 'app-login-item',
  templateUrl: './login-item.component.html',
  styleUrls: ['./login-item.component.scss']
})
export class LoginItemComponent implements OnInit {

  loginDialogIsOpen = false;

  constructor(
    public dialog: MatDialog,
    private readonly dialogService: DialogsService
  ) { }

  ngOnInit(): void {
  }

  openLoginDialog(): void {
    this.loginDialogIsOpen = true;
    this.dialogService.openLoginDialog();
  }

  openSignUpDialog(): void {
    this.dialogService.openSignUpDialog();
  }
}
