<mat-card class="mat-elevation-z3 light-border">
  <mat-card-title fxFlex="row" fxLayoutAlign="center center">
    <ng-container *ngIf="isLogged" class="logo-pa" fxHide.lt-md="true">
      <app-pa-logo></app-pa-logo>
    </ng-container>

    <div fxLayout="column" fxHide.lt-md="true">
      <h5 class="username"> {{username | uppercase}}</h5>
      <h6 *ngIf="!isOnPaSide" class="lib-entity">{{entrepriseName}}</h6>
      <h6 *ngIf="isOnPaSide" class="lib-entity">{{etablissementName}}</h6>
    </div>

    <div class="btns" fxLayout="row" fxLAyoutAlign="start center" fxHide.lt-md="true">
      <a altlineLink>
        <div class="pin" *ngIf="hasPin">{{notificationsLength}}</div>
        <img src="/assets/icons/svg/bell.svg" class="icon-action" [draggable]="false" />
      </a>
      <a altlineLink>
        <img src="/assets/icons/svg/engrenage.svg" class="icon-action" [draggable]="false" />
      </a>
    </div>

    <div class="divider" fxHide.lt-md="true"></div>

    <button mat-button class="logout-bn" (click)="logout()">
      <mat-icon svgIcon="disconnect"></mat-icon>
    </button>
  </mat-card-title>
</mat-card>
