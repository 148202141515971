import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetDirective } from './let.directive';
import { TooltipDirective } from './tooltip.directive';
import { InputPriceDirective } from './input-price.directive';
import { ButtonFileUploadDirective } from './button-file-upload.directive';
import { LinkDirective } from './link.directive';
import { DragDropDirective } from './drag-drop.directive';
import { InputNumberDirective } from './input-number.directive';

const directives = [
  LetDirective,
  TooltipDirective,
  InputPriceDirective,
  ButtonFileUploadDirective,
  LinkDirective,
  DragDropDirective,
  InputNumberDirective,
];

@NgModule({
  declarations: [...directives],
  exports: [...directives],
  imports: [CommonModule]
})
export class DirectiveModule { }
