export class WebSignVerifierRequest {
  // tslint:disable:variable-name
  cle_etab!: string;
  cle_pa!: string;
  processStateAife!: string;
  repKey!: string;
  signed_document!: File;
  attached_document!: File;
  locale!: string;
  reportType!: string;
  // tslint:enable:variable-name
}
