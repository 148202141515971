import {
  NotificationsRequest,
  DeleteNotificationRequest,
  AddRemoveEmailNotificationRequest,
  SendNotificationRequest,
  LrarReceiptRequest,
  GetNotificationPieceRequest,
  AddEditNotificationRequest
} from './../models/api/requests/notifications.request';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationResponse } from '../models/api/responses/notification.response';
import { ApiService } from './api.service';
import { NotificationItem } from '../models/notificationItem.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private static BASE_PATH = 'notifications_pa';

  constructor(private readonly apiService: ApiService, private readonly http: HttpClient) { }

  public getNotifications(notificationsRequest: NotificationsRequest): Observable<NotificationResponse> {
    return this.apiService.post(NotificationResponse, notificationsRequest, `${NotificationsService.BASE_PATH}/notifications_list`);
  }

  public deleteNotification(deleteNotificationRequest: DeleteNotificationRequest): Observable<any> {
    return this.apiService.post(NotificationResponse, deleteNotificationRequest, `${NotificationsService.BASE_PATH}/notification_remove`);
  }

  public addEmail(addEmailRequest: AddRemoveEmailNotificationRequest): Observable<NotificationItem> {
    return this.apiService.post(NotificationItem, addEmailRequest, `${NotificationsService.BASE_PATH}/email_add`);
  }

  public removeEmail(removeEmailRequest: AddRemoveEmailNotificationRequest): Observable<NotificationItem> {
    return this.apiService.post(NotificationItem, removeEmailRequest, `${NotificationsService.BASE_PATH}/email_remove`);
  }

  public sendNotification(sendNotificationRequest: SendNotificationRequest): Observable<NotificationItem> {
    return this.apiService.post(NotificationItem, sendNotificationRequest, `${NotificationsService.BASE_PATH}/notification_send`);
  }

  public getLrarReciept(getLrarReceipt: LrarReceiptRequest): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.api}${NotificationsService.BASE_PATH}/pdf_accuse_reception`, getLrarReceipt,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  public addEditNotification(addEditRequest: AddEditNotificationRequest): Observable<NotificationResponse> {
    const formData = new FormData();
    formData.append('cle_dce', addEditRequest.cle_dce);
    formData.append('cle_entreprise_destination', addEditRequest.cle_entreprise_destination);
    formData.append('cle_etab', addEditRequest.cle_etab);
    formData.append('cle_pa', addEditRequest.cle_pa);
    formData.append('date_envoi_notification', addEditRequest.date_envoi_notification);
    if (addEditRequest.fichier)
      formData.append('fichier', addEditRequest.fichier, addEditRequest.fichier.name);
    formData.append('type', addEditRequest.type ? '1' : '0');
    if (addEditRequest.cle_notification)
      formData.append('cle_notification', addEditRequest.cle_notification);
    return this.apiService.post(NotificationResponse, formData, `${NotificationsService.BASE_PATH}/notification_edit`);
  }

  public getPiece(notificationPIeceRequest: GetNotificationPieceRequest): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.api}${NotificationsService.BASE_PATH}/pa_notification_download`, notificationPIeceRequest,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
}
