import { AdministrationGroupResponse } from '@atline/core/models/api/responses/administrationGroup.response';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from '@atline/core/services/utils.service';
import { AffectedUserItem } from '@atline/core/models/userItem.model';
import { AdministrationGroupService } from '@atline/core/services/adminstrationGroup.service';
import { ApiErrorResponse } from '@atline/core/models/api/responses/api.response';
import { AdministrationGroupItem } from '@atline/core/models/administrationGroupItem.model';
import { DialogsService } from '@atline/core/services/dialogs.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'lodash';
import { Pagination } from '@atline/core/models/pagination.model';
import { GroupListRequest } from '@atline/core/models/api/requests/groupsList-request';
import { Icons } from '@atline-shared/enums/icons.enum';
@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss']
})
export class GroupsListComponent implements OnInit {
  public icons = Icons;
  groupListAdmin!: AdministrationGroupResponse;
  searchGroupName = '';
  filteredGroups: AdministrationGroupItem[] = [];
  isLoading: boolean = true;
  nbGroups: number = 0;
  users: AffectedUserItem[] = [];
  isAddButtonClicked: boolean = false;
  cleEtab: string | undefined;
  public rights: boolean = false;
  public group!: AdministrationGroupItem;
  public legendVisible = false;
  isLegendOpen: boolean = false;


  constructor(
    private readonly administrationGroupService: AdministrationGroupService,
    private readonly utils: UtilsService,
    private readonly dialogService: DialogsService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.cleEtab = this.utils.cleEtab || '';
    this.loadGroups();
  }

  public callToWs(): void {
    this.administrationGroupService.getGroups({cle_etab: this.cleEtab ?? ''}).subscribe(
      (res) => {
        this.groupListAdmin = res;
        this.nbGroups = this.groupListAdmin?.nb_total || 0;
        this.filterGroups();
        this.isLoading = false;
      },
      (err) => {
        const apiError = err.error as ApiErrorResponse;
        if (apiError) {
        }
        this.isLoading = false;
      }
    );
  }

  get groups(): AdministrationGroupItem[] {
    return this.groupListAdmin?.groups || [];
  }

  get nbUsersTotal(): number {
    return this.groups.reduce((accumulator, currentValue) => accumulator + currentValue.nbUser, 0);
  }

  getPlurial(n: number): string {
    return n > 1 ? 's' : '';
  }

  legendVisibility(): void {
    this.legendVisible = !this.legendVisible;
    this.isLegendOpen = this.legendVisible;
  }

  editGroup(group: AdministrationGroupItem): void {
    const idGroup = group.idGroup;
    const groupName = group.label;
    const cleGroupe = group.cle_gr;

    if (idGroup && idGroup.trim() !== '') {
      const dialogRef = this.dialogService.openEditGroupDialog({
        title: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.MODIFY_GROUP',
        description: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.DESC_ADD_GROUP',
        data: { groupName, cleGroupe }
      });

      dialogRef.afterClosed().subscribe(
        (result) => {
        if (result) {
          this.callToWs();
        }
      });
    }
  }

  updateGroupList(group: AdministrationGroupItem): void {
    this.groupListAdmin.groups = this.groupListAdmin.groups.filter(
      (item) => item.idGroup !== group.idGroup
    );
    this.filterGroups();
  }

  deleteGroup(group: AdministrationGroupItem): void {
    const cleEtab = this.utils.cleEtab || '';
    const idGroup = group.idGroup;
    const cleGroupe = group.cle_gr;
    const groupName = group.label;

    if (idGroup && idGroup.trim() !== '') {
      const dialogRef = this.dialogService.openConfirmationDialog({
        title: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.TITLE_REMOVE',
        description: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.REMOVE',
        data: { groupName }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.administrationGroupService.deleteGroup(cleEtab, cleGroupe, idGroup).subscribe(
            () => {
              this.nbGroups--;
              this.updateGroupList(group);
            },
            (err) => {
              const apiError = err.error as ApiErrorResponse;
              if (apiError) {

              }
            }
          );
        }
      });
    }
  }

  filterGroups(): void {
    this.filteredGroups = this.groups.filter((group) =>
      group.label.toLowerCase().includes(this.searchGroupName.toLowerCase())
    );
  }

  searchGroups(searchTerm: string): void {
    this.searchGroupName = searchTerm;
    if (this.searchGroupName.trim() !== '') {
      this.filterGroups();
    } else {
      this.loadGroups();
    }
  }

  addGroup(): void {
    this.dialogService.openCreationGroupDialog({
      title: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.TITLE_ADD_GROUP',
      description: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.DESC_ADD_GROUP',
    }, (res) => { if (res) this.callToWs()});
  }

  openGroupRights(currentGroup: AdministrationGroupItem): void {
    this.group = currentGroup;
    this.rights = true;
    //this.dialogService.openRightsDialog(group);
  }

  openGroupUsers(group: AdministrationGroupItem): void {
    this.dialogService.openGroupUsers(group.users);
  }

  /**
   *
   * Pagination
   *
   * AS-336
   *
   **/
  paginationGroups: Pagination = new Pagination({
    page: 1,
    nbr_par_page: 10,
  });
  totalGroups: number = 0;

  changePageGroups(pageNumber: number): void {
    this.paginationGroups.page = pageNumber;
    this.loadGroups();
  }

  /**
   * 
   * @param searchTerm 
   * AS-335-search-groups
   * Contains the null value when we want to retrieve all the groups 
   * Otherwise the name of the group to search when clicking on the search button
   * 
  */
    public loadGroups(): void {
    let request: GroupListRequest;

    if (this.cleEtab === null) {
      this.isLoading = false;
      return;
    }

    let currentPagination: Pagination;
    currentPagination = this.paginationGroups;

    request = {
      cle_etab: this.cleEtab ?? '',
      recherche: this.searchGroupName, 
      pagination: { ...currentPagination }
    };

    this.administrationGroupService.getGroups(request).subscribe(
      (res) => {

        this.groupListAdmin = res;
        this.nbGroups = this.groupListAdmin?.nb_total || 0;

        this.isLoading = false;
      },
      (err) => {
        const apiError = err.error as ApiErrorResponse;
        if (apiError) { }

        this.isLoading = false;
      }
    );
  }

}
