import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-send-jal-dialog',
  templateUrl: './send-jal-dialog.component.html',
  styleUrls: ['./send-jal-dialog.component.scss']
})
export class SendJalDialogComponent implements OnInit {

  mailArray: Array<string> = [];
  form!: FormGroup;
  mail = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private readonly fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      mail: this.mail
    });
  }

  get canSendMail(): boolean {
    return this.mailArray.length > 0;
  }

  addMail(event: any): void {
    if (this.mail.valid) {
      this.mailArray.push(this.mail.value);
      this.mail.reset();
    }
    event.preventDefault();
  }

  removeMail(mail: string): void {
    const index = this.mailArray.indexOf(mail);
    if (index > -1) {
      this.mailArray.splice(index, 1);
    }
  }

}
