import { Pagination } from '@atline/core/models/pagination.model';

export interface JalResponse {
  id_jal: string;
  cle_jal: string;
  entreprise: string;
  type_jal: string;
  offre_jal?: string | null;
  date_creation: string;
  denomination: string;
  description: string;
  email_envoi: string;
  url_envoi: string;
  url_suivi: string;
  partenaire_interbat: string;
  login: string;
  pass: string;
  logo_ent: string;
  option_lrar_available: boolean;
  option_logo_available: boolean;
  option_formule_available: boolean;
  option_formule_list: { id: string, value: string }[];
  jal_actif: boolean;
  option_lrar_actif: boolean;
  option_logo_actif: boolean;
  option_formule_actif: boolean;
  option_formule_select: string;
}

export interface AnnonceResponse {
  id_annonce_boamp: string;
  date_creation: string;
  archive: boolean;
  cle_annonce: string;
  cle_utilisateur: string;
  cle_annonceur: string;
  cle_compte: string;
  nePasPublierBOAMP: boolean;
  envoi_joue: boolean;
  language: string;
  identifiant_annonce: string;
  identifiant_annonce_boamp: string;
  identifiant_annonce_boamp_ref: string;
  identifiant_annonce_TED: string;
  identifiant_envoi_TED: string;
  identifiant_annonce_TED_ref: string;
  nb_ref: number;
  nb_modif: number;
  nb_attrib: number;
  nb_ic: number;
  annulation: string;
  cle_ref: string;
  pub_ms: boolean;
  logo_aapc: boolean;
  suivi_par_type: string;
  suivi_parution: string;
  suivi_num_ann_par: string;
  suivi_date_pub: string;
  suivi_url: string;
  suivi_url_pdf: string;
  suivi_num_annonce_JOUE: string;
  suivi_date_publication_JOUE: string;
  suivi_lienTED_JOUE: string;
  type_formulaire: string;
  lib_form: string;
  erreur_xml: string;
  envoi_xml: string;
  date_envoi: number;
  date_envoi_JOUE: number;
  nom_fichier_envoi: string;
  info_erreur_xml: string;
  infos_regles_JOUE: string;
  etat: string;
  etat_TED: string;
  valide: boolean;
  criteres_environnementaux: boolean;
  criteres_sociaux: boolean;
  typeIdentifiantNational: string;
  codeIdentificationNational: string;
  correspondant_nom: string;
  correspondant_pren: string;
  correspondant_fonc: string;
  acheteur_public: string;
  adr_voie_nomvoie: string;
  adr_cp: string;
  adr_ville: string;
  adr_pays: string;
  coord_tel: string;
  coord_fax: string;
  coord_mel: string;
  coord_url: string;
  point_contact: string;
  url_profil_acheteur: string;
  codeNUTS: string;
  classification_organisme: number;
  classification_organisme_joue: number;
  classification_organisme_dsp: string;
  classification_organisme_autre: string;
  activ_services_generaux_adm_pub: boolean;
  activ_defense: boolean;
  activ_ordre_securite_pub: boolean;
  activ_environnement: boolean;
  activ_affaires_ecofi: boolean;
  activ_sante: boolean;
  activ_logement_devcol: boolean;
  activ_protection_sociale: boolean;
  activ_loisirs_culture_religion: boolean;
  activ_education: boolean;
  activ_autre: string;
  activs_gaz_chaleur: boolean;
  activs_electricite: boolean;
  activs_gaz_petrole: boolean;
  activs_combustibles_solides: boolean;
  activs_eau: boolean;
  activs_services_postaux: boolean;
  activs_services_cheminfer: boolean;
  activs_services_cheminfer_urbains: boolean;
  activs_activites_portuaires: boolean;
  activs_activites_aeroportuaires: boolean;
  agit_pour_autre_compte: boolean;
  adrPouvoirAdjudicateur_codeIdentificationNational: string;
  adrPouvoirAdjudicateur_personne_morale: string;
  adrPouvoirAdjudicateur_adr_voie_nomvoie: string;
  adrPouvoirAdjudicateur_adr_cp: string;
  adrPouvoirAdjudicateur_adr_ville: string;
  adrPouvoirAdjudicateur_adr_pays: string;
  adrPouvoirAdjudicateur_codeNUTS: string;
  adrPouvoirAdjudicateur_tel: string;
  adrPouvoirAdjudicateur_fax: string;
  adrPouvoirAdjudicateur_mel: string;
  adrPouvoirAdjudicateur_url: string;
  adrPouvoirAdjudicateur_point_contact: string;
  appelOffreConjointLoi: string;
  acheteurCentral: boolean;
  documentMarche: number;
  urlDocument: string;
  urlParticipation: string;
  adresseContact: boolean;
  urlOutilLogiciel: string;
  departement_pub_rappel: string;
  departement_pub: string;
  code_cpf: string;
  fact_num_bon_commande: string;
  fact_code_client: number;
  is_CHORUS: boolean;
  fact_Chorus_SiretChorus: boolean;
  fact_Chorus_noServiceExecutant: boolean;
  fact_Chorus_noEngagementJuridique: boolean;
  fact_Chorus_codeServiceOuNumeroEJ: boolean;
  fact_noServiceExecutant: string;
  fact_noServiceExecutant_manual: string;
  fact_noEngagementJuridique: string;
  fact_denomination: string;
  fact_adr1: string;
  fact_adr2: string;
  fact_adr3: string;
  fact_adr4: string;
  fact_cp: string;
  fact_ville: string;
  fact_pays: string;
  fact_siret: string;
  fact_tva_inter: string;
  fact_classe_profit: string;
  nature_marche: string;
  nature_marche_travaux: string;
  nature_marche_services: string;
  nature_marche_fournitures: string;
  objet_marche: string;
  titre_marche: string;
  amp: boolean;
  cpv: string;
  cpv_1: string;
  cpv_2: string;
  cpv_3: string;
  cpv_4: string;
  exec_liv_voie: string;
  exec_liv_ville: string;
  exec_liv_cp: string;
  liv_voie: string;
  liv_ville: string;
  liv_cp: string;
  lieu_nuts: string;
  carac_variante: boolean;
  carac_principales: string;
  carac_quantites: string;
  estimation_devise: string;
  estimation_valeur: number;
  estimation_valeur_basse: number;
  estimation_valeur_haute: number;
  carac_options_description: string;
  carac_options: boolean;
  carac_calendrier_previsionnel_mois: number;
  carac_calendrier_previsionnel_jours: number;
  carac_nb_reconductions: number;
  carac_nb_reconduction_valeur_basse: number;
  carac_nb_reconduction_valeur_haute: number;
  reconduction: boolean;
  reconductionDesc: string;
  carac_calendrier_ulterieurs: string;
  carac_calendrier_ulterieurs_mois: number;
  carac_calendrier_ulterieurs_jour: number;
  carac_duree_nb_mois: number;
  carac_duree_nb_jours: number;
  carac_duree_debut: number;
  carac_duree_fin: number;
  conditions_garanties: string;
  conditions_financement: string;
  conditions_juridique: string;
  conditions_autres: boolean;
  conditions_autres_conditions: string;
  conditions_langue: string;
  conditions_langue_autre: string;
  eligibleMPS: boolean;
  situation_forme_juridique: string;
  capaEcoFiDoc: boolean;
  situation_capa_ecofi: string;
  situation_capa_ecofi_min: string;
  capaTechDoc: boolean;
  situation_capa_tech: string;
  situation_capa_tech_min: string;
  situation_critereParticipation: string;
  situation_marche_reserve: boolean;
  situation_ateliers_proteges: boolean;
  situation_emplois_proteges: boolean;
  nombre_candidat: number;
  nombre_candidat_min: number;
  nombre_candidat_max: number;
  candidat_criteres_limitation: string;
  candidat_reduction_progessive: number;
  attribOffresInitiales: boolean;
  critere_attribution_non: boolean;
  critere_attribution: string;
  critere_libre: string;
  critere_pondere: string;
  critere_enchere_electronique: boolean;
  criteres_ee_rens_compl: string;
  criteres_selection: string;
  procedure: string;
  procedure_sous_type: string;
  procedure_ms_cand_selec: boolean;
  delai_urgence: boolean;
  delai_urgence_info: string;
  joce_avis: string;
  joce_avis_num: string;
  joce_avis_date: number;
  joce_autrepub_num1: string;
  joce_autrepub_date1: number;
  joce_autrepub_num2: string;
  joce_autrepub_date2: number;
  joce_avis_as_sad: string;
  joce_avis_as_sad_num: string;
  joce_avis_as_sad_date: number;
  joce_avis_ex_an_v_num: string;
  joce_avis_ex_an_v_date: number;
  abandonSAD: number;
  abandonProcedure: number;
  delais_reception_cand: number;
  delais_reception_offres: number;
  delais_envoi_invitation: number;
  delais_valide_date_jusquau: number;
  delais_valide_nbmois: number;
  delais_valide_nbjours: number;
  renseignements_complt: string;
  renseignements_idmarche: string;
  adrc_admtech_nom: string;
  adrc_admtech_personne_morale: string;
  adrc_admtech_adr_nomvoie: string;
  adrc_admtech_adr_pays: string;
  adrc_admtech_adr_cp: string;
  adrc_admtech_adr_ville: string;
  adrc_admtech_tel: string;
  adrc_admtech_fax: string;
  adrc_admtech_mel: string;
  adrc_admtech_url: string;
  adrc_admtech: boolean;
  adrc_tech_nom: string;
  adrc_tech_personne_morale: string;
  adrc_tech_adr_nomvoie: string;
  adrc_tech_adr_pays: string;
  adrc_tech_adr_cp: string;
  adrc_tech_adr_ville: string;
  adrc_tech_tel: string;
  adrc_tech_fax: string;
  adrc_tech_mel: string;
  adrc_tech_url: string;
  adrc_document_nom: string;
  adrc_document_personne_morale: string;
  adrc_document_adr_nomvoie: string;
  adrc_document_adr_pays: string;
  adrc_document_adr_cp: string;
  adrc_document_adr_ville: string;
  adrc_document_tel: string;
  adrc_document_fax: string;
  adrc_document_mel: string;
  adrc_document_url: string;
  adrc_document_point_contact: string;
  adrc_envoi_nom: string;
  adrc_envoi_codeIdentificationNational: string;
  adrc_envoi_personne_morale: string;
  adrc_envoi_adr_nomvoie: string;
  adrc_envoi_adr_pays: string;
  adrc_envoi_codeNUTS: string;
  adrc_envoi_adr_cp: string;
  adrc_envoi_adr_ville: string;
  adrc_envoi_tel: string;
  adrc_envoi_fax: string;
  adrc_envoi_mel: string;
  adrc_envoi_url: string;
  adrc_envoi_point_contact: string;
  adrc_envoi_cond_remise: string;
  adrc_renscomp_nom: string;
  adrc_renscomp_codeIdentificationNational: string;
  adrc_renscomp_personne_morale: string;
  adrc_renscomp_adr_nomvoie: string;
  adrc_renscomp_adr_pays: string;
  adrc_renscomp_codeNUTS: string;
  adrc_renscomp_adr_cp: string;
  adrc_renscomp_adr_ville: string;
  adrc_renscomp_tel: string;
  adrc_renscomp_fax: string;
  adrc_renscomp_mel: string;
  adrc_renscomp_url: string;
  adrc_renscomp_point_contact: string;
  avis_implique: string;
  avis_imp_ac_op_unique: boolean;
  avis_imp_ac_nb_part: number;
  avis_imp_ac_max_nb_part: number;
  avis_imp_ac_duree_an: number;
  avis_imp_ac_duree_mois: number;
  avis_imp_ac_justif_4a: string;
  avis_imp_ac_val_est_devise: string;
  avis_imp_ac_val_est_valeur: number;
  avis_imp_ac_val_est_valeur_basse: number;
  avis_imp_ac_val_est_valeur_haute: number;
  avis_imp_ac_fq_val_marche: string;
  is_fonds_communautaire: boolean;
  is_fonds_communautaire_info: string;
  is_prestation_reservee: boolean;
  is_prestation_reservee_info: string;
  is_date_limite_obtention: number;
  is_doc_payants: boolean;
  is_doc_prix: number;
  is_doc_payants_devise: string;
  is_cond_mode_paiement: string;
  is_marche_periodique_calendrier: boolean;
  is_marche_periodique_calendrier_info: string;
  executionMarche: string;
  is_indic_qualif_pers: boolean;
  is_oodate: number;
  is_oolieu: string;
  is_oopers_autorisees: boolean;
  is_oopers_autorisees_info: string;
  webCommande: boolean;
  webFacturation: boolean;
  webPaiement: boolean;
  marche_lot: boolean;
  repartition_reponse_lot: string;
  nbMaxLotOffre: number;
  nbMaxLotAttrib: number;
  lotsReserves: string;
  pr_icr_personne_morale: string;
  pr_icr_adr_nomvoie: string;
  pr_icr_adr_pays: string;
  pr_icr_adr_cp: string;
  pr_icr_adr_ville: string;
  pr_icr_tel: string;
  pr_icr_fax: string;
  pr_icr_mel: string;
  pr_icr_url: string;
  pr_icm_personne_morale: string;
  pr_icm_adr_nomvoie: string;
  pr_icm_adr_pays: string;
  pr_icm_adr_cp: string;
  pr_icm_adr_ville: string;
  pr_icm_tel: string;
  pr_icm_fax: string;
  pr_icm_mel: string;
  pr_icm_url: string;
  pr_irr_personne_morale: string;
  pr_irr_adr_nomvoie: string;
  pr_irr_adr_pays: string;
  pr_irr_adr_cp: string;
  pr_irr_adr_ville: string;
  pr_irr_tel: string;
  pr_irr_fax: string;
  pr_irr_mel: string;
  pr_irr_url: string;
  pr_irr_precisions: string;
  justif_redressement_judiciaire: boolean;
  justif_article_43: boolean;
  justif_articles_L5212: boolean;
  justif_articles_L1221_L3243: boolean;
  justif_article_L3243: boolean;
  justif_chiffres_affaires: boolean;
  justif_risques_professionels: boolean;
  justif_bilans: boolean;
  justif_effectifs: boolean;
  justif_liste_fournitures_services: boolean;
  justif_liste_travaux: boolean;
  justif_indication_titres_operateur: boolean;
  justif_indication_titres_cadres: boolean;
  justif_equipement_technique: boolean;
  justif_equipement_fournitures_services: boolean;
  justif_qualifications_pro: boolean;
  justif_qualifications_pro_p: string;
  justif_conformite_fournitures: boolean;
  justif_conformite_fournitures_p: string;
  justif_detail_fournitures: boolean;
  justif_defense_nationalite_candidat: boolean;
  justif_defense_habilitation_secret: boolean;
  justif_autorisation_specifique: boolean;
  justif_autorisation_specifique_p: string;
  justif_DC1: boolean;
  justif_DC2: boolean;
  justif_DC3: boolean;
  justif_DC4: boolean;
  justif_documents_autre_operateur: boolean;
  justif_traduction_francaise_candidat: boolean;
  justif_articles_D8222: boolean;
  justif_attestation_obligations_fiscales: boolean;
  justif_certificat_pays_origine: boolean;
  justif_traduction_francaise_attributaire: boolean;
  justif_justification_autre: string;
  justif_justification_comment: string;
  mot_clef: string;
  index_date_fin_diff: string;
  index_dep_presta: string;
  index_resume_objet: string;
  index_id_service_pub: string;
  tableau_carac: string;
  conditions_euro: boolean;
  conditions_languefr: boolean;
  date_debut_prestation: number;
  date_debut_travaux: number;
  rectif_info_ted: string;
  rectif_info_original_ao: string;
  rectif_info_proc_imcoplete: string;
  nouvelle_publication: boolean;
  signature: string;
  motif_annulation: string;
  info_jal: string;
  libre_procedure: string;
  libre_organisme: string;
  libre_duree: string;
  libre_attribution: string;
  libre_critere: string;
  libre_date_limite: string;
  avis_correspond: string;
  cond_sysq_conditions: string;
  cond_sysq_mverif: string;
  recompenses_primes: string;
  recompenses_paiements: string;
  recompenses_laureat: boolean;
  renseignements_etudes: string;
  moniteur_num_abonnement_mol: string;
  moniteur_num_abonne_mol: string;
  attrib_vt_devise: string;
  attrib_vt_tva: string;
  attrib_vt_valeur: number;
  attrib_vt_valeur_basse: number;
  attrib_vt_valeur_haute: number;
  attrib_informations: string;
  avis_concerne: string;
  JCProc_aj: string;
  JCProc_ca: string;
  JCProc_lc: boolean;
  JCProc_mc: boolean;
  JCProc_mp: boolean;
  JCProc_oi: boolean;
  JCProc_pd_ra: boolean;
  JCProc_pd_re: boolean;
  JCProc_pd_rt: boolean;
  JCProc_pi: boolean;
  JCProc_proc: string;
  JCProc_ral: string;
  JCProc_recherche: boolean;
  JCProc_ui: boolean;
  JCProc_EA_procedure: string;
  JCProc_EA_recherche: boolean;
  JCProc_EA_prestataireDetermine_raisonsTechniques: boolean;
  JCProc_EA_prestataireDetermine_raisonsArtistiques: boolean;
  JCProc_EA_prestataireDetermine_raisonsExclusivite: boolean;
  JCProc_EA_urgenceImperieuse: boolean;
  JCProc_EA_marcheComplementaire: boolean;
  JCProc_EA_prestationsIdentiques: boolean;
  JCProc_EA_laureatsConcours: boolean;
  JCProc_EA_matieresPremieres: boolean;
  JCProc_EA_conditionsAvantageuses: string;
  JCProc_EA_achatOpportunite: boolean;
  JCProc_EA_accordCadre: boolean;
  JCProc_EA_autreJustification: string;
  ref_pub_ent_boamp: string;
  date_attribution: number;
  type_attribution: string;
  nb_tot_offre: number;
  nb_part: number;
  nb_par_et: number;
  val_prime_att: number;
  val_prime_att_devise: string;
  attribution_unique: string;
  avisCouvert: string;
  nomsParticipants: string;
  membresJury: string;
  criteresEvaluationProjets: string;
  decisionJuryContraignante: boolean;
  domaine_delegation: string;
  domaine_delegation_autre: string;
  delegation_texte: string;
  delegation_duree_nbAns: number;
  delegation_duree_nbMois: number;
  delegation_duree_dateACompterDu: number;
  delegation_duree_autre: string;
  delegation_autreCaracteristiques: string;
  delegation_montantPrevisionnel?: string | null;
  delegation_montantPrevisionnel_devise?: string | null;
  procedure_modalitesCandidatures: string;
  procedure_criteres: string;
  dateLancementPassation: number;
  infosComplementaires: string;
  accordCadre: boolean;
  autreAcheteurSAD: boolean;
  adrc_legislationFiscale_urlInformations: string;
  adrc_legislationEnvironnement_urlInformations: string;
  adrc_protectionEmploi_urlInformations: string;
  adrc_legislationFiscale_nom: string;
  adrc_legislationFiscale_personne_morale: string;
  adrc_legislationFiscale_adr_nomvoie: string;
  adrc_legislationFiscale_adr_pays: string;
  adrc_legislationFiscale_adr_cp: string;
  adrc_legislationFiscale_adr_ville: string;
  adrc_legislationFiscale_tel: string;
  adrc_legislationFiscale_fax: string;
  adrc_legislationFiscale_mel: string;
  adrc_legislationFiscale_url: string;
  adrc_legislationFiscale_point_contact: string;
  adrc_legislationEnvironnement_nom: string;
  adrc_legislationEnvironnement_personne_morale: string;
  adrc_legislationEnvironnement_adr_nomvoie: string;
  adrc_legislationEnvironnement_adr_pays: string;
  adrc_legislationEnvironnement_adr_cp: string;
  adrc_legislationEnvironnement_adr_ville: string;
  adrc_legislationEnvironnement_tel: string;
  adrc_legislationEnvironnement_fax: string;
  adrc_legislationEnvironnement_mel: string;
  adrc_legislationEnvironnement_url: string;
  adrc_legislationEnvironnement_point_contact: string;
  adrc_protectionEmploi_nom: string;
  adrc_protectionEmploi_personne_morale: string;
  adrc_protectionEmploi_adr_nomvoie: string;
  adrc_protectionEmploi_adr_pays: string;
  adrc_protectionEmploi_adr_cp: string;
  adrc_protectionEmploi_adr_ville: string;
  adrc_protectionEmploi_tel: string;
  adrc_protectionEmploi_fax: string;
  adrc_protectionEmploi_mel: string;
  adrc_protectionEmploi_url: string;
  adrc_protectionEmploi_point_contact: string;
  VAL_TOTAL_ATP: boolean;
  fact_noEngagementJuridiqueExist: boolean;
  origine_request: string;
  denomination_pa: string;
  identifiant_ms_pa: string;
  entreprise: string;
  departements_activite: string;
  direction: string;
  type_marche: string;
  orga_public: string;
  orga_ordo2005: string;
  orga_prive: string;
  demo: string;
  tropic: string;
  eoplus: string;
  eoppp: string;
  non_actif: string;
  aor_invisible: string;
  url: string;
  applet_depot_v2: string;
  mps: string;
  dume: string;
  publication_auto: string;
  archivage: string;
  url_archivage: string;
  guichet_mention_signature: string;
  type_pa: string;
  type_pa_defaut: string;
  ouverture_anticipee: string;
  id_liaison: string;
  cle_pa: string;
  cle_etablissement: string;
  identifiantInterne?: string | null;
  grpCommandes?: string | null;
  grpCoordonnateur?: string | null;
  communication_documentMarche?: string | null;
  docSecurite?: string | null;
  mesuresConfidentialite?: string | null;
  urlSecurite?: string | null;
  categorieAcheteur?: string | null;
  tranches?: string | null;
  reservationMarche?: string | null;
  visite?: string | null;
  visiteDetail?: string | null;
  nb_lots: number;
  nb_attributions: number;
  date_envoi_f: string;
  xml_ted: boolean;
  xml_ted_version: string;
  xml_boamp: boolean;
  xml_boamp_version: string;
  pdf_integral: boolean;
  pdf_resume: boolean;
  cle_dce: string;
  jals?: JalResponse[];
  type_formulaire_label: string;
  procedure_label: string;
  rectifications: { rectifications: any[], nb_total?: string | null };
  attributions: {  attributions: any[], nb_total?: string | null };
  lots: { lots: any[], nb_total?: string | null };
}

export class AnnonceListResponse {
  // tslint:disable:variable-name
  annonces: AnnonceResponse[] = [];
  nb_total = 0;
  pagination = new Pagination();
  // tslint:enable:variable-name
}
