import { AdminUserItem } from "../../adminUser.model";

export class AdminUserResponse {

    // tslint:disable:variable-name

   // retourner tableau de user
   utilisateurs: AdminUserItem[] = [];
   nb_total = 0;


    // tslint:enable:variable-name

}
