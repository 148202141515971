// tslint:disable:variable-name

export class ConsultationItem {

    /**
     * Clé de la consultation
     * type -  string
     * access - Publié et non publié
     */
    public cle = '';

    /**
     * Identifiant de la consultation
     * type -  string
     * access - Publié et non publié
     */
    public identifiant!: string;

    /**
     * Object de la consultation
     * type -  string
     * access - Publié et non publié
     */
    public objet = '';

    /**
     * Clé du pouvoir adjudicateur (PA) en charge de la consultation
     * type -  string
     * access - Publié et non publié
     */
    public cle_pa = '';

    /**
     * Clé de l'entreprise rattachée à la consultation
     * type -  string
     * access - Publié et non publié
     */
    public cle_entreprise = '';

    /**
     * Clé DCE de la consultation
     * type -  string
     * access - Publié et non publié
     */
    public cle_dce = '';

    /**
     * Clé de l'étalissement rattaché à la consultation
     * type -  string
     * access - Publié et non publié
     */
    public cle_etab!: string;

    /**
     * Référence interne au client de la consultation
     * type -  string
     * access - Publié et non publié
     */
    public ref_interne = '';

    /**
     * Objet de l'affaire rattachée à la consultation
     * type -  string
     * access - Publié et non publié
     */
    public objet_affaire = '';

    /**
     * Nom du pouvoir adjudicateur
     * type -  string
     * access - Publié et non publié
     */
    public denomination_pa = '';

    /**
     * Type de marché de la consultation
     * type -  enum
     * access - Publié et non publié
     * default - public
     */
    public type_marche!: 'public' | 'ordonnance2005' | 'prive';

    /**
     * Type de prestation de la consultation
     * type -  enum
     * access - Publié et non publié
     * default - travaux
     */
    public type_prestation!: 'travaux' | 'services' | 'fournitures' | 'autres';

    /**
     * Procédure applicable à la consultation
     * type -  string
     * access - Publié et non publié
     */
    public procedure = '';

    /**
     * Nombre de lot de la consultation
     * type -  number
     * access - Publié et non publié
     * default - 0
     */
    public nb_lots = 0;

    /**
     * Nombre de DCE de la consultation
     * type -  number
     * access - Publié et non publié
     * default - 0
     */
    public nb_dce = 0;


    cle_group = '';
    code_restreinte = '';

    // Ajout Code d'acces pour SEM

    code_acces = '';

    cle_affaire = '';
    date_envoi_publication = 0;
    date_envoi_publication_f = '';
    date_publication = 0;
    date_publication_f = '';
    date_activation_JOUE = 0;
    date_cloture = 0;
    date_cloture_f = '';
    date_cloture_f_s = '';
    signature_obligatoire = 0;
    departements_prestation = '';
    identifiant_passation = '';
    estimation = 0;
    piece_la = '';
    piece_rc = '';
    piece_dc = '';
    piece_pc = '';
    en_ligne = 0;
    restreinte = 0;
    reponse_electronique = 0;
    format_signature = 0;
    invisible = 0;
    passe = '';
    type_pa = '';
    nb_retrait = 0;
    nb_retrait_papier = 0;
    nb_depot = 0;
    nb_depot_papier = 0;
    mail_cloture = 0;
    archive = 0;
    archivage = 0;
    option_mps = 0;
    prix = 0;
    infos_tarifs = 0;
    libre_ms = 0;
    mps = 0;
    mps_id = 0;
    mps_pin = 0;
    criteres_environnementaux = 0;
    criteres_sociaux = 0;
    hubal_envoi = 0;
    hubal_retour = 0;
    is_cloture = 0;
    is_dume!: 0 | 1;
    etape_consultation = '';
    lien_aapc = '';
    lien_rc = '';
    lien_dce = '';
    lien_depot = '';
    allotie = 0;
    favoris?: boolean;
    finalite_marche = '';
    cao_access = 0;
    date_cloture_pa_f?: string
    id_dume?: string
}
// tslint:enable:variable-name
