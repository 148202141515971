<div class="container">
  <div class="corner"></div>
  <div class="content" [fxLayout]="layout" [fxLayoutAlign]="layoutAlign">
    <ng-content select="img, svg"></ng-content>
    <ng-content select="[title-block-status]"></ng-content>
    <ng-content select="*:not([title-block-status])"></ng-content>
  </div>
  <div class="container-button-back" dir="rtl">
    <button mat-button class="discover-bn" (click)="back()" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon svgIcon="right-arrow" style="margin-right: 8px;"></mat-icon>
      <span class="btn-label">{{'APP.SOON.BUTTON' | translate}}</span>
    </button>
  </div>
</div>
