export class LotResponse {

    // tslint:disable:variable-name

    cle_dce = '';

    cle_lot = '';

    libelle = '';

    numero = '0';

    ordre = 0;

    // tslint:enable:variable-name
}
