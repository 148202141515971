import { AdministrationGroupResponse } from '@atline/core/models/api/responses/administrationGroup.response';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { EditGroupAdminRequest } from '@atline/core/models/api/requests/edit-group-admin-request.request';
import { GroupListRequest } from '../models/api/requests/groupsList-request';

@Injectable({
  providedIn: 'root'
})
export class AdministrationGroupService {
  constructor(private readonly apiService: ApiService) {}


  getGroups(groupRequest?: GroupListRequest): Observable<AdministrationGroupResponse> {
    const data = typeof groupRequest === 'string' ? { cle_etab: groupRequest } : groupRequest;
    return this.apiService.post(AdministrationGroupResponse, data, 'administration/group_list');
  }


 deleteGroup(cleEtab: string, cleGroupe: string,id:string): Observable<AdministrationGroupResponse> {
    const data = {
      cle_etab: cleEtab,
      cle_groupe: cleGroupe,
      id_groupe:id
    };
    return this.apiService.post(AdministrationGroupResponse, data, 'group_delete');
  }

  addGroup(cleEtab: string, nomGroupe: string, groupLabel: string): Observable<AdministrationGroupResponse> {
    const data = {
      cle_etab: cleEtab,
      nom_groupe: nomGroupe,
      group_label: groupLabel
    };
    return this.apiService.post(AdministrationGroupResponse, data, 'group_edit');
  }

  editGroup(data : EditGroupAdminRequest): Observable<AdministrationGroupResponse> {
    return this.apiService.post(AdministrationGroupResponse, data, 'group_edit');
  }

}
