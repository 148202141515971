import { Annuaire } from './api/responses/find-structure.reponse';
import { Country } from './country/countrys.model';
// tslint:disable:variable-name
export class Structure {
    date_update = '';
    description = '';
    effectif = 0;

    constructor(readonly id_etab_ident: string,
                readonly siret: string,
                readonly nom: string,
                readonly code_naf: string,
                readonly date_crea_etab: string,
                readonly cedex: any,
                readonly pays: Country,
                readonly adresse: Adresse,
                readonly commune: Commune,
                readonly form_juridique: string,
                readonly annuaire?: Annuaire) {}
}

export class Adresse {
    constructor(readonly num_voie: string,
                readonly type_voie: string,
                readonly nom_voie: string,
                readonly complement: string) { }
}

export class Commune {
    constructor(readonly code_postal: string,
                readonly nom: string) { }
}

export function isStructure(o: any): o is Structure {
  return o instanceof Structure;
}
// tslint:enable:variable-name
