import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AttributaireEditRequest, AttributaireListRequest, AttributaireRemoveRequest, PushMarcheRequest } from '../models/api/requests/Attributaires.request';
import { AttributaireEditReponse, AttributaireListReponse, AttributaireRemoveReponse, AttributaireTypesReponse } from '../models/api/responses/attributaire.reponse';
import { MessageResponse } from '../models/api/responses/message.response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AttributaireService {

  constructor(private api: ApiService) { }

  get types(): Observable<AttributaireTypesReponse> {
    return this.api.post(AttributaireTypesReponse, undefined, 'attributaire_types');
  }

  list(request: AttributaireListRequest): Observable<AttributaireListReponse> {
    return this.api.post(AttributaireListReponse, request, 'attributaires_list');
  }

  edit(request: AttributaireEditRequest): Observable<AttributaireEditReponse> {
    return this.api.post(AttributaireEditReponse, request, 'attributaire_edit');
  }

  remove(request: AttributaireRemoveRequest): Observable<AttributaireRemoveReponse> {
    return this.api.post(AttributaireRemoveReponse, request, 'attributaire_remove');
  }

  pushMarche(request: PushMarcheRequest): Observable<MessageResponse> {
    return this.api.post(MessageResponse, request, 'push_marche');
  }
}
