export class CreateAccountResponse {
    user = {
        id: '',
        user_name: '',
        first_name: '',
        last_name: '',
        scope: '',
        email: '',
        email_verified: '',
        last_connection_date: '',
        timezone: '',
        role: ''
    };
    rights = {
        tender: '',
        compliance: '',
        training: '',
        secure: '',
        sign: ''
    };
}
