import { Component, OnInit } from '@angular/core';
import { ConsultationItem } from 'src/app/core/models/consultationItem.model';
import { Pagination } from 'src/app/core/models/pagination.model';
import { ConsultationsDataService } from 'src/app/core/services/consultations-data.service';
import { ConsultationsService } from 'src/app/core/services/consultations.service';
import { ConstEnum } from 'src/app/shared/enums/const.enum';
import { ConsultationState } from 'src/app/shared/enums/consultation.enum';

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements OnInit {

  private searchForm: any;

  public nbConsulationsInProgress = 0;
  public consultationState: ConsultationState;
  public consultationArray: Array<ConsultationItem> = [];
  public pagination = new Pagination();
  public keyWords = '';
  public isLoading = false;

  public paramsViwComponent = { form: false, qanda: false, pieces: false };


  constructor(
    private readonly consultationService: ConsultationsService,
    private readonly ConsultationDataService: ConsultationsDataService,
  ) {
    this.consultationState = ConsultationState.SEARCH;        // set default step on Search component
  }

  ngOnInit(): void {}


  /**
   * call to WS and update all data on consultation data service
   * update pagination data
   */
  private callWSandUpdateData(): void {
    this.isLoading = true;
    this.consultationService.consultationList(this.consultationState === ConsultationState.SEARCH ? {} : this.searchForm).subscribe(
      res => {
        this.nbConsulationsInProgress = res.nb_total;
        this.ConsultationDataService.setConsultationList(res.consultations);
        this.pagination = res.pagination;
        this.isLoading = false;
      });
    window.scroll(0, 0);       // go to top of the page
  }


  /**
   * change step of consultation research (search, list, details)
   * @param $event state
   */
  changeState($event: any): void {
    this.searchForm = $event.form;
    this.searchForm.page = ConstEnum.DEFAULT_PAGE;
    this.searchForm.nbr_par_page = ConstEnum.DEFAULT_NB_PAR_PAGE;
    this.keyWords = this.searchForm.motscles;
    this.consultationState = $event.state;
    this.callWSandUpdateData();
  }

  /**
   *
   * @param state go to a a step
   */
  goToStep(state: string): void {
    switch (state) {
      case ConsultationState.SEARCH:
        this.consultationState = ConsultationState.SEARCH;
        break;

      case ConsultationState.LIST:
        this.consultationState = ConsultationState.LIST;
        break;

      default:
        break;
    }

    this.callWSandUpdateData();
  }

  public displayConsultation($event: string): void {
    switch ($event) {
      case 'qandanwser':
        this.paramsViwComponent.qanda = true;
        this.paramsViwComponent.pieces = false;
        this.consultationState = ConsultationState.QUESTIONS;
        break;

      default:
        this.paramsViwComponent.qanda = false;
        this.paramsViwComponent.pieces = true;
        this.consultationState = ConsultationState.DETAILS;
        break;
    }
  }

}
