<ng-container *ngIf="visible">
  <ng-container *ngLet="!!group?.liChildren?.length && selected as printSubBtn">
    <button class="btn-side-bar"
      [class.sub-btn-open]="printSubBtn"
      [class.selected]="selected"
      [type]="type"
      [disabled]="disabled"
      [name]="name"
      [value]="value"
      [formTarget]="formtarget"
      [autofocus]="autofocus"
      (click)="btnClicked.emit($event)"
    >
      <ng-content></ng-content>
    </button>

    <ng-container *ngIf="printSubBtn">
      <ng-content select="app-group-btn-side-bar"></ng-content>
    </ng-container>
  </ng-container>
</ng-container>
