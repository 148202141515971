<div class="container-register-form">
    <app-custom-stepper linear [titre]="('APP.REGISTER-FORM.TITLE' | translate )">
        <form [formGroup]="formRegistration">
            <cdk-step label="one" [completed]="step1Completed()" [editable]="true">
                <div class="stepForm">
                    <div class="container-form-fields" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Nom -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="nom">
                                    {{ 'APP.REGISTER-FORM.NAME' | translate }}
                                    <small class="required" *ngIf="required(nomFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]=" nomFC.invalid && nomFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.NAME' | translate }}
                                </div>
                            </div>
                            <input type="text" name="nom" formControlName="nom">
                            <span class="underline" [class.error-input]="nomFC.invalid && nomFC.touched"></span>
                        </div>
                        <!-- Prénom -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="prenom">
                                    {{ 'APP.REGISTER-FORM.FIRSTNAME' | translate }}
                                    <small class="required" *ngIf="required(prenomFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]=" prenomFC.invalid && (prenomFC.touched || prenomFC.dirty)">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.FIRSTNAME' | translate }}
                                </div>
                            </div>
                            <input type="text" name="prenom" formControlName="prenom">
                            <span class="underline" [class.error-input]="prenomFC.invalid && prenomFC.touched"></span>
                        </div>
                    </div>
    
                    <div class="container-form-fields" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Vide -->
                        <div class="container-form-field invisible" fxLayout="column">
                        </div>
                        <!-- Email -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="email">
                                    {{ 'APP.REGISTER-FORM.EMAIL' | translate }}
                                    <small class="required" *ngIf="required(emailFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]=" emailFC.invalid && emailFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.EMAIL' | translate }}
                                </div>
                            </div>
                            <input type="text" name="email" formControlName="email">
                            <span class="underline" [class.error-input]="emailFC.invalid && emailFC.touched"></span>
                        </div>
                    </div>
                </div>
            </cdk-step>
            <cdk-step label="two" [completed]="step2Completed()" [editable]="true">
                <div class="stepForm">
                    <div class="container-form-fields" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Nom entreprise -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="nomEntreprise">
                                    {{ 'APP.REGISTER-FORM.NAMEENTREPRISE' | translate }}
                                    <small class="required" *ngIf="required(nomEntrepriseFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]="nomEntrepriseFC.invalid && nomEntrepriseFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.NAMEENTREPRISE' | translate }}
                                </div>
                            </div>
                            <input type="text" name="nomEntreprise" formControlName="nomEntreprise">
                            <span class="underline" [class.error-input]="nomEntrepriseFC.invalid && nomEntrepriseFC.touched"></span>
                        </div>
                        <!-- Identifiant entreprise -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="idEntreprise">
                                    {{ 'APP.REGISTER-FORM.IDENTREPRISE' | translate }}
                                    <small class="required" *ngIf="required(idEntrepriseFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]="idEntrepriseFC.invalid && idEntrepriseFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.IDENTREPRISE' | translate }}
                                </div>
                            </div>
                            <input type="text" name="idEntreprise" formControlName="idEntreprise">
                            <span class="underline" [class.error-input]="idEntrepriseFC.invalid && idEntrepriseFC.touched"></span>
                        </div>
                    </div>
                    <div class="container-form-fields" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Adresse -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="adresse">
                                    {{ 'APP.REGISTER-FORM.ADRESSE' | translate }}
                                    <small class="required" *ngIf="required(adresseFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]="adresseFC.invalid && adresseFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.ADRESSE' | translate }}
                                </div>
                            </div>
                            <input type="text" name="adresse" formControlName="adresse">
                            <span class="underline" [class.error-input]="adresseFC.invalid && adresseFC.touched"></span>
                        </div>
                        <!-- Commune -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="commune">
                                    {{ 'APP.REGISTER-FORM.COMMUNE' | translate }}
                                    <small class="required" *ngIf="required(communeFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]="communeFC.invalid && communeFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.COMMUNE' | translate }}
                                </div>
                            </div>
                            <input type="text" name="commune" formControlName="commune">
                            <span class="underline" [class.error-input]="communeFC.invalid && communeFC.touched"></span>
                        </div>
                    </div>
                    <div class="container-form-fields" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Pays -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="pays">
                                    {{ 'APP.REGISTER-FORM.PAYS' | translate }}
                                    <small class="required" *ngIf="required(paysFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]="paysFC.invalid && paysFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.PAYS' | translate }}
                                </div>
                            </div>
                            <app-custom-select formControlName="pays">
                                <img iconSelect src="../../../../assets/icons/svg/pin.svg" alt="pin" class=" inputIcon" />
                                <app-custom-option *ngFor="let country of (listCountry | async)" [value]="country.value">
                                    {{country.name | titlecase}}
                                </app-custom-option>
                            </app-custom-select>
                            <span class="underline" [class.error-input]="paysFC.invalid && paysFC.touched"></span>
                        </div>
                        <!-- Code NAF -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="codeNaf">
                                    {{ 'APP.REGISTER-FORM.NAF' | translate }}
                                    <small class="required" *ngIf="required(codeNafFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]="codeNafFC.invalid && codeNafFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.NAF' | translate }}
                                </div>
                            </div>
                            <input type="text" name="codeNaf" formControlName="codeNaf">
                            <span class="underline" [class.error-input]="codeNafFC.invalid && codeNafFC.touched"></span>
                        </div>
                    </div>
                    <div class="container-form-fields" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Téléphone portable ou fixe -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="tel">
                                    {{ 'APP.REGISTER-FORM.TEL' | translate }}
                                    <small class="required" *ngIf="required(telFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]="telFC.invalid && telFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.TEL' | translate }}
                                </div>
                            </div>
                            <input type="text" name="tel" formControlName="tel">
                            <span class="underline" [class.error-input]="telFC.invalid && telFC.touched"></span>
                        </div>
                        <!-- Fax -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="fax">
                                    {{ 'APP.REGISTER-FORM.FAX' | translate }}
                                    <small class="required" *ngIf="required(faxFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]=" faxFC.invalid && faxFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.FAX' | translate }}
                                </div>
                            </div>
                            <input type="text" name="fax" formControlName="fax">
                            <span class="underline" [class.error-input]="faxFC.invalid && faxFC.touched"></span>
                        </div>
                    </div>
                </div>
            </cdk-step>
            <cdk-step label="three" [completed]="step3Completed()" [editable]="true">
                <div class="stepForm">
                    <div class="container-form-fields" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Identifiant -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="id">
                                    {{ 'APP.REGISTER-FORM.ID' | translate }}
                                    <small class="required" *ngIf="required(idFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]=" idFC.invalid && idFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.ID' | translate }}
                                </div>
                            </div>
                            <input type="text" name="id" formControlName="id">
                            <span class="underline" [class.error-input]="idFC.invalid && idFC.touched"></span>
                        </div>
                        <!-- Mot de passe -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="password">
                                    {{ 'APP.REGISTER-FORM.PASSWORD' | translate }}
                                    <small class="required" *ngIf="required(passwordFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]=" passwordFC.invalid && passwordFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.PASSWORD' | translate }}
                                </div>
                            </div>
                            <input type="text" name="password" formControlName="password">
                            <span class="underline" [class.error-input]="passwordFC.invalid && passwordFC.touched"></span>
                        </div>
                    </div>
                    <div class="container-form-fields" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Vide -->
                        <div class="container-form-field invisible" fxLayout="column">
                        </div>
                        <!-- Confirmation du mot de passe -->
                        <div class="container-form-field" fxLayout="column">
                            <div class="text" fxLayout="row">
                                <label for="confirmationPw">
                                    {{ 'APP.REGISTER-FORM.CONDPASSWORD' | translate }}
                                    <small class="required" *ngIf="required(confPwFC)">*</small>
                                </label>
                                <div class="msg-error" [class.print]="confPwFC.invalid && confPwFC.touched">
                                    {{ 'APP.REGISTER-FORM.ERROR-MSG.CONDPASSWORD' | translate }}
                                </div>
                            </div>
                            <input type="text" name="confirmationPw" formControlName="confirmationPw">
                            <span class="underline" [class.error-input]="confPwFC.invalid && confPwFC.touched"></span>
                        </div>
                    </div>
    
                    <div class="container-form-fields check" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
                        <!-- Captcha -->
                        <mat-checkbox class="captcha">Captcha</mat-checkbox>
                        <!-- Checkbox -->
                        <div class="conditions" fxLayout="column" fxLayoutGap="10px">
                            <app-circle-checkbox name="info-monthly">
                                <label for="info-monthly">
                                    {{ 'APP.REGISTER-FORM.VALID-INFO-MONTHLY' | translate}}
                                </label>
                            </app-circle-checkbox>
                            <app-circle-checkbox name="info-partner">
                                <label for="info-partner">
                                    {{ 'APP.REGISTER-FORM.VALID-INFO-PARTNER-OFFER' | translate}}
                                </label>
                            </app-circle-checkbox>
                        </div>
                    </div>
                </div>
            </cdk-step>
        </form>
    </app-custom-stepper>
</div>
