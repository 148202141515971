import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@atline/core/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@atline-shared/design/button/button.module';
import { LoadingSpinnerModule } from '@atline-shared/design/loading-spinner/loading-spinner.module';
import { DeModule } from '../de/de.module';
import { PaginationModule } from '../pagination/pagination.module';



@NgModule({
  declarations: [ListComponent],
  exports: [
    ListComponent
  ],
  imports: [
    CommonModule,
    NgxTranslateModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    LoadingSpinnerModule,
    DeModule,
    PaginationModule
  ]
})
export class ListModule { }
