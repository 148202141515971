import { MailPaItem } from '../../mailPaItem.model';
import { Pagination } from '../../pagination.model';
import { ApiErrorResponse } from './api.response';

// tslint:disable:variable-name
export class QrAddResponse extends ApiErrorResponse {

  info = '';
  message = {
    cle_mail: '',
    sujet: '',
    texte: '',
    date: '',
    expediteur_type_code: 0,
    expediteur_type_lbl: '',
    visibilite_code: 0,
    visibilite_lbl: '',
    avec_accuse_reception: 0,
    piece_jointe: '',
    expediteur: {
      nom: '',
      prenom: '',
      email: ''
    },
    destinataires: [
      {
        etat: 0,
        etat_lbl: '',
        reception_accuse: 0,
        date_reception_accuse: 0,
        date_reception_accuse_f: '',
        email: '',
        nom: '',
        alias: '',
        entreprise: '',
        email_origine: '',
        email_origine_lbl: ''
      }
    ],
    reponses: [
      {
        cle_mail: '',
        sujet: '',
        texte: '',
        date: '',
        expediteur_type_code: 0,
        expediteur_type_lbl: '',
        visibilite_code: 0,
        visibilite_lbl: '',
        avec_accuse_reception: 0,
        piece_jointe: '',
        expediteur: {
          nom: '',
          prenom: '',
          email: ''
        },
        destinataires: [
          {
            etat: 0,
            etat_lbl: '',
            reception_accuse: 0,
            date_reception_accuse: 0,
            date_reception_accuse_f: '',
            email: '',
            nom: '',
            alias: '',
            entreprise: '',
            email_origine: '',
            email_origine_lbl: ''
          }
        ]
      }
    ]
  };
  nb_total = 0;

}

export class QandAPaResponse extends ApiErrorResponse {
  mails_envoyes!: MailPaItem[];
  mails_recus!: MailPaItem[];
  nb_qrs!: number;
  pagination!: Pagination;
}
    // tslint:enable:variable-name
