import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CguComponent } from './cgu.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@atline/core/material/material.module';
import { DesignModule } from '@atline-shared/design/design.module';



@NgModule({
  declarations: [CguComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    DesignModule
  ],
  exports: [
    CguComponent
  ]
})
export class CguModule { }
