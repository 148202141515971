<div class="container-btn-service-link" [class.disabled]="isDisabled(service)">
  <a (click)="goTo()" class="btn-service-link" routeLinkActive="active"
    fxLayout="row nowrap" fxLayoutAlign="start start">
    <mat-icon svgIcon="{{service.value}}" class="icon-service"></mat-icon>
    <div class="container-label-service">
      <h1 [ngClass]="service.value">{{ 'APP.HOME.LANDING.SERVICES.TITLES.' + (service.value | uppercase) | translate |
        uppercase }}</h1>
      <span class="service-description" fxLayout="column" fxLayoutGap="8px grid">{{ 'APP.HOME.LANDING.SERVICES.' +
        service.value | uppercase | translate}}</span>
    </div>
  </a>
</div>