import { DePlateformeType } from '@atline-shared/enums/dePlateforms.enum';
import { Order, Sort } from './pa/params/ParamsPaConsultation.model';

export class EssentialDataOeListParams {

  // tslint:disable:variable-name
  plateform!: DePlateformeType;
  keyword?: string;
  end_date?: string | null;
  start_date?: string | null;
  identifier?: string;
  departments?: string;
  target?: string;
  sens_ordre: Order = 'ASC';
  ordre: Sort = 'date_cloture';
  page = 1;
  nbParPage = 10;
  // tslint:enable:variable-name

  constructor(
    // tslint:disable:variable-name
    plateform: DePlateformeType,
    keyword: string,
    end_date: string | null,
    start_date: string | null,
    identifier: string,
    target: string,
    sens_ordre?: Order,
    page?: number,
    nbParPage?: number
    // tslint:enable:variable-name
  ) {
    this.plateform = plateform;
    this.keyword = keyword;
    this.end_date = end_date;
    this.start_date = start_date;
    this.identifier = identifier;
    this.target = target;
    this.sens_ordre = sens_ordre ?? this.sens_ordre;
    this.page = page ?? this.page;
    this.nbParPage = nbParPage ?? this.nbParPage;
  }
}
