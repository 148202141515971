import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-round-checkbox',
  templateUrl: './round-checkbox.component.html',
  styleUrls: ['./round-checkbox.component.scss']
})
export class RoundCheckboxComponent {

  @Input()
  public name: string;

  @Input()
  public code: string;

  @Input()
  public checked = false;

  @Input()
  title!: string;

  @Output()
  public checkedChange = new EventEmitter<boolean>();

  @Input()
  public img?: string;

  @Input()
  public disabled?: boolean

  @Output()
  public changeState = new EventEmitter<boolean>();


  constructor() {
    this.code = '';
    this.name = '';
  }

  get isActive(): boolean {
    return _.isUndefined(this.disabled) || !this.disabled;
  }

  onChange(): void {
    if (this.isActive) {
      this.checked = !this.checked;
      this.changeState.emit(this.checked);
    }

  }

}
