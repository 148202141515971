import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EssentialDataTitulaireItem } from '@atline/core/models/essentialDataItem.model';

@Component({
  selector: 'app-attributaires',
  templateUrl: './attributaires.component.html',
  styleUrls: ['./attributaires.component.scss']
})
export class AttributairesComponent implements OnInit {

  public displayedColumns: string[] = ['identId', 'ident', 'denomination', 'type_attrib'];

  public dataSource: any = new MatTableDataSource<any>(this.data);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EssentialDataTitulaireItem[],
    readonly dialogRef: MatDialogRef<AttributairesComponent>,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.data.map(d => {
      return {
        type_ident_id: d.type_ident_id,
        ident: d.ident,
        denomination: d.denomination,
        type_attrib: d.type_attrib,
      };
    }));
  }

}
