import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from './button.component';
import { MaterialModule } from '@atline/core/material/material.module';



@NgModule({
  declarations: [
    ButtonComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    FlexModule,
    FormsModule,
    MaterialModule
  ],
  exports: [ButtonComponent]
})
export class ButtonModule { }
