import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdministrationGroupService } from '@atline/core/services/adminstrationGroup.service';
import { UtilsService } from '@atline/core/services/utils.service';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent implements OnInit {
  public title = '';
  public description = '';
  public informations?: { [name: string]: string | number };
  public nomGroup = '';

  public addGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private administrationGroupService: AdministrationGroupService,
    private utils: UtilsService
  ) {
    this.title = data.title;
    this.description = data.description;

    this.addGroup = this.fb.group({
      nomGr: fb.control('', [Validators.required]),
    });
  }

  ngOnInit(): void {}

  onConfirm(): void {
    if (this.addGroup.value) {
      const cleEtab = this.utils.cleEtab || '';
      const nomGroup = this.addGroup.value.nomGr;
      const groupLabel = this.addGroup.value.nomGr;

      this.administrationGroupService
        .addGroup(cleEtab, nomGroup, groupLabel)
        .subscribe();

        this.dialogRef.close(true);
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
