import { DepotRetraitEntrepriseItem } from '../../depotRetraitEntrepriseItem.model';

export class DepotPapierResponse {
  // tslint:disable:variable-name
  cle!: string;
  cle_dce!: string;
  nom_document!: string;
  date_depot!: string;
  date_depot_f!: string;
  lot!: string;
  entreprise!: DepotRetraitEntrepriseItem;
  // tslint:enable:variable-name
}
