import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { environment } from 'src/environments/environment';
import { DumePaEditRequest } from '../models/api/requests/dume/dumePaEdit.request';
import { PaDumeGetRequest } from '../models/api/requests/dume/paDumeGet.request';
import { DumePaEditReponse } from '../models/api/responses/dume/DumePaEdit.response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DumeService {

  constructor(private readonly api: ApiService, private readonly http: HttpClient) { }

  dumePaEdit(request: DumePaEditRequest): Observable<DumePaEditReponse> {
    return this.api.post(DumePaEditReponse, request, 'dume_pa_edit');
  }

  paDumeGet(request: PaDumeGetRequest): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.api}dume_pa_get`,
      request,
      {
        headers: this.api.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
}
