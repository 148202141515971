import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, OperatorFunction } from 'rxjs';
import { UtilsService } from './utils.service';
import { GlobalDataService } from './data-service.service';
import { filter, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
type TypeInstance<T> = new (...args: any[]) => T;

function clone<T>(model: T): T {
  if (model) {
    if (_.isArray(model)) { return model.map(item => clone(item)) as any as T; }
    else if (_.isObject(model)) {
      const toClone: any = model;
      return Object.keys(toClone).reduce((newClone, key) => ({ ...newClone, [key]: clone(toClone[key])  }), {} as T);
    }
    return model;
  }
  return model;
}

function convertToObject<T>(type: TypeInstance<T>, element: any): Observable<T> {
  const typeInstanced = new type();
  Object.assign(typeInstanced as any, clone(element));
  return of(typeInstanced);
}

function mapperHttp<T>(type: TypeInstance<T>): OperatorFunction<any, T> {
  return mergeMap((element: any) => convertToObject(type, element));
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private readonly utilService: UtilsService,
    private readonly dataService: GlobalDataService,
    private http: HttpClient
  ) { }


  /**
   * Simple post request, with http params and a body.
   * @param formBodyParams Data for the body of the request.
   * @param endpoint Endpoint from the api we want to call.
   * @returns Observable<T>
   */
  post<T>(type: TypeInstance<T>, formBodyParams: any, endpoint: string, mapData?: boolean): Observable<T> {
    return this.callWS(endpoint, MethodRequest.POST, formBodyParams, type, mapData);
  }

  /**
   * DON'T USE FOR GENERAL CASE
   * POST request
   * @param type type of request response
   * @param formBody body of request
   * @param endpoint url targeted
   * @returns obserable with response
   */
  postClient<T>(formBody: any, endpoint: string): Observable<T> {
    return this.http.post<T>(
      environment.api + endpoint, formBody,
      {
        headers: this.getHttpOptions(null, MethodRequest.GET).headers,
        reportProgress: true
      }
    );
  }

  /**
   * DON'T USE FOR GENERAL CASE
   * POST request
   * @param type type of request response
   * @param formBody body of request
   * @param endpoint url targeted
   * @returns obserable with response
   */
  getAssetFile(endpoint: string): Observable<any> {
    return this.http.get<any>(endpoint);
  }

  /**
   * Simple get request, with http params and a body.
   * @param formBodyParams Data for the params of the request.
   * @param endpoint Endpoint from the api we want to call.
   * @returns Observable<T>
   */
  get<T>(type: TypeInstance<T>, formBodyParams: any, endpoint: string): Observable<T> {
    return this.callWS(endpoint, MethodRequest.GET, formBodyParams, type);
  }

  /**
   * DELETE request
   * @param type type of request response
   * @param formBodyParams body of request
   * @param endpoint url targeted
   * @returns obserable with response
   */
  delete<T>(type: TypeInstance<T>, formBodyParams: any, endpoint: string): Observable<T> {
    return this.callWS(endpoint, MethodRequest.DELETE, formBodyParams, type);
  }


  /**
   *
   * @param path path of web service to call
   * @param method method http to call
   * @param request body of request
   * @param type type of request response
   * @returns Observable<T>
   */
  callWS<T>(path: string, method: MethodRequest, request: any, type: TypeInstance<T>, mapData?: boolean): Observable<T> {
    const link = `${environment.api}${path}`;
    const httpOptions = this.getHttpOptions(request, method, mapData);

    switch (method) {
      case MethodRequest.GET:
        httpOptions.params = httpOptions.params ;
        return this.http.get(link, httpOptions).pipe(mapperHttp(type));
      case MethodRequest.POST:
        return this.http.post(link, request, httpOptions).pipe(mapperHttp(type));
      case MethodRequest.DELETE:
        return this.http.delete(link, httpOptions).pipe(mapperHttp(type));
      default:
        return of({}).pipe(mapperHttp(type));
    }
  }


  downloadBlob(path: string, request: any): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.api}${path}`, request, {
      headers: this.getHttpOptions(null, MethodRequest.GET).headers,
      responseType: 'blob',
      observe: 'response'
    });
  }

  getDownloadBlob(path: string, request: any): Observable<HttpResponse<any>> {
    const options = {
      headers: this.getHttpOptions(null, MethodRequest.GET).headers,
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };

    return this.http.get(`${environment.api}${path}`, { ...options, params: request }) as Observable<HttpResponse<any>>;
  }


  /**
   * Get basic http options.
   * @param formBody Form data to put in http request body.
   * @returns Http request options.
   */
  getHttpOptions(formBody: any, requestMethod: MethodRequest, mapData = true): any {
    let httpHeaders: any;
    const token = this.utilService.token;
    if (!_.isEmpty(token)) {
      httpHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` });
    } else {
      this.dataService.getToken()
        .pipe(filter(res => _.isEmpty(res)))
        .subscribe(res => httpHeaders = new HttpHeaders({ Authorization: `Bearer ${res}` }));
    }
    if (!(formBody instanceof FormData)) {
      formBody = { ...{ locale: this.utilService.getLocale() }, ...formBody };
    }
    switch (requestMethod) {
      case MethodRequest.GET:
        return {
          reportProgress: true,
          params: formBody,
          headers: httpHeaders,
          body: undefined,
        };
      case MethodRequest.POST:
        return {
          reportProgress: true,
          body: formBody,
          headers: httpHeaders,
          params: undefined,
          mapData,
        };
      case MethodRequest.DELETE:
        return {
          reportProgress: true,
          body: formBody,
          headers: httpHeaders,
          params: undefined,
        };
      default:
        return {};
    }
  }

}
