import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import { AliasService } from '@atline/core/services/alias.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AliasItem } from '@atline/core/models/aliasItem.model';

export interface AliasItemExtends extends AliasItem {
  isSelected: boolean;
}

@Component({
  selector: 'app-annuaire-alias',
  templateUrl: './annuaire-alias.component.html',
  styleUrls: ['./annuaire-alias.component.scss']
})
export class AnnuaireAliasComponent implements OnInit {

  public aliasArr!: Array<AliasItemExtends>;
  public aliasSelectedArr!: Array<AliasItemExtends>;

  constructor(
    public dialogRef: MatDialogRef<AnnuaireAliasComponent>,
    private readonly aliasService: AliasService,
    @Inject(MAT_DIALOG_DATA) public data: {
      clePa: string,
      cleEtab: string
},
  ) { }

  ngOnInit(): void {
    this.aliasService.getAlias({
      cle_etab: this.data.cleEtab,
      cle_pa: this.data.clePa
    }).pipe(
      catchError(err => {
        return throwError(err);
      })
    ).subscribe(res => {
      this.aliasArr = res.alias_list.map(a => ({...a, isSelected: false}));
    });
  }

  toggleSelectAlias(): void {
    this.aliasSelectedArr = this.aliasArr.filter(a => a.isSelected);
  }

  close(): void {
    this.dialogRef.close(this.aliasSelectedArr);
  }

}
