import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaSearchComponent } from './pa-search.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/core/material/material.module';
import { PaLogoModule } from '../pa-logo/pa-logo.module';



@NgModule({
  declarations: [PaSearchComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgxTranslateModule,
    MaterialModule,
    PaLogoModule
  ],
  exports: [PaSearchComponent]
})
export class PaSearchModule { }
