<app-dialog [dialogRef]="dialogRef">
  <div header>
    <h1>{{'APP.DIALOG.ATTRIBUTAIRES.HEADER' | translate}}</h1>
  </div>
  <div body>

    <table mat-table [dataSource]="data" matSort class="mat-elevation-z8 roundedCorners full-width">
      <!-- Id dume Column -->
      <ng-container matColumnDef="identId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{
          "APP.DIALOG.ATTRIBUTAIRES.IDENT_ID"
          | translate
          | uppercase
          }}
        </th>
        <td mat-cell *matCellDef="let item">{{ item.type_ident_id }}</td>
      </ng-container>

      <!-- date Column -->
      <ng-container matColumnDef="ident">
        <th mat-header-cell *matHeaderCellDef>
          {{
          "APP.DIALOG.ATTRIBUTAIRES.NUMBER" | translate | uppercase
          }}
        </th>
        <td mat-cell *matCellDef="let item">{{ item.ident }}</td>
      </ng-container>

      <!-- date Column -->
      <ng-container matColumnDef="denomination">
        <th mat-header-cell *matHeaderCellDef>
          {{
          "APP.DIALOG.ATTRIBUTAIRES.DENOMINATION" | translate | uppercase
          }}
        </th>
        <td mat-cell *matCellDef="let item">{{ item.denomination }}</td>
      </ng-container>

      <!-- date Column -->
      <ng-container matColumnDef="type_attrib">
        <th mat-header-cell *matHeaderCellDef>
          {{
          "APP.DIALOG.ATTRIBUTAIRES.STATUS" | translate | uppercase
          }}
        </th>
        <td mat-cell *matCellDef="let item">{{ item.type_attrib }}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div footer>

  </div>

</app-dialog>
