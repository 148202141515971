export class ElrarResponse {
  // tslint:disable:variable-name
  message!: string;
  date_envoi!: string;
  contexte!: string;
  action!: string;
  date_action!: string;
  // tslint:enable:variable-name
}

export class ElRarValidityAndCheckResponse {
  // tslint:disable:variable-name
  cle!: string;
  contexte!: 'demande' | 'notification';
  date_envoi!: string;
  action!: 'accepted' | 'refused' | null;
  date_action?: string;
  action_validite!: boolean;
  // tslint:enable:variable-name
}
