import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationHistoryRequest } from '@atline/core/models/api/requests/history/notification.request';
import { Observable } from 'rxjs';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { environment } from 'src/environments/environment';
import { WebsendNotificationDownloadFileRequest } from '../../models/api/requests/history/websendNotificationPieceDownload.request';
import { NotificationResponse } from '../../models/api/responses/history/notification.response';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private static endPoint = 'notifications_oe_list';

  constructor(
    private readonly apiService: ApiService,
    private httpClient: HttpClient
  ) {}

  getNotificationList(
    request: NotificationHistoryRequest
  ): Observable<NotificationResponse> {
    return this.apiService.post(
      NotificationResponse,
      request,
      NotificationService.endPoint
    );
  }

  websendNotificationFile(
    websendNotificationDownloadFileRequest: WebsendNotificationDownloadFileRequest
  ): Observable<HttpResponse<any>> {
    return this.httpClient.post(
      environment.api + 'oe_notification_open',
      websendNotificationDownloadFileRequest,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET)
          .headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

}
