import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/core/services/scroll.service';

@Component({
  selector: 'app-go-top',
  templateUrl: './go-top.component.html',
  styleUrls: ['./go-top.component.scss'],
})
export class GoTopComponent implements OnInit {
  constructor(private readonly scroll: ScrollService) {}

  ngOnInit(): void {}

  goTop(): void {
    this.scroll.windowsScrollTo({ top: 0, smooth: true });
  }
}
