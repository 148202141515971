import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './page-title.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerModule } from '@atline-shared/design/loading-spinner/loading-spinner.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';



@NgModule({
  declarations: [PageTitleComponent],
  imports: [
    CommonModule,
    TranslateModule,
    LoadingSpinnerModule,
    FlexModule,
    FlexLayoutModule
  ],
  exports: [PageTitleComponent]
})
export class PageTitleModule { }
