import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoryComponent } from './story.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/core/material/material.module';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { SectionEngagementRseModule } from '../section-engagement-rse/section-engagement-rse.module';
import { SectionPostulationModule } from '../section-postulation/section-postulation.module';
import { KeyMomentsComponent } from './key-moments/key-moments.component';
import { CdkStepperModule } from '@angular/cdk/stepper';



@NgModule({
  declarations: [StoryComponent, KeyMomentsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    NgxTranslateModule,
    SectionEngagementRseModule,
    SectionPostulationModule,
    CdkStepperModule
  ],
  exports: [StoryComponent]
})
export class StoryModule { }
