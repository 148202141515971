export class FormulaireItem {
  // tslint:disable:variable-name
  ref_formulaire = '';
  nom_formulaire = '';
  // tslint:enable:variable-name
}

export class FormulairesAnnonceResponse {
  // tslint:disable:variable-name
  cle_annonceur = '';
  formulaires = [new FormulaireItem()];
  nb_total = 0;
  // tslint:enable:variable-name
}
