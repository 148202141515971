export const DC_CONST = 'demande-complementaire';
export type DC_CONST_TYPE = typeof DC_CONST;

export const DEPOT_CONST = 'depot';
export type DEPOT_CONST_TYPE = typeof DEPOT_CONST;

export const RETRAIT_CONST = 'retrait';
export type RETRAIT_CONST_TYPE = typeof RETRAIT_CONST;

export type DEPOT_RETRAIT_TYPE = DEPOT_CONST_TYPE | RETRAIT_CONST_TYPE;
export type DC_DEPOT_CONST = DC_CONST_TYPE | DEPOT_CONST_TYPE;
