import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaLogoComponent } from './pa-logo.component';



@NgModule({
  declarations: [PaLogoComponent],
  imports: [
    CommonModule
  ],
  exports: [PaLogoComponent]
})
export class PaLogoModule { }
