export class AvisGetResponse {
  cle_avis!: string;
  cle_dce!: string;
  objet_dce!: string;
  identifiant_dce!: string;
  departements_prestation!: string;
  type_marche!: string;
  type_prestation!: string;
  procedure!: string;
  description_avis!: string;
  fichier_avis!: string;
  date_debut!: number;
  date_debut_f!: string;
  date_fin!: number;
  date_fin_f!: string;
  date_creation!: number;
  date_creation_f!: string;
  Duree!:number;
  nb_lots!:number;
  reference_interne!:string;
  type_avis!: string;
  libelle_passation!: string;


}
