import { PieceItem2 } from 'src/app/core/models/pieceItem2.model';

export class PiecesResponse {

    // tslint:disable:variable-name

    // Information relativeà la consultation
    public consultation = {
        cle: '',
        identifiant: '',
        objet: '',
        cle_pa: ''
    };

    // pieces consitutives de la consultation
    public pieces: PieceItem2[] = [new PieceItem2()];

    // pieces constitutives des lots
    public lots = [{
        cle_lot: '',
        libelle_lot: '',
        libelle_ordre_lot: '',
        pieces: [new PieceItem2()]
        // [
        //     {
        //         cle_piece: '',
        //         cle_dce: '',
        //         cle_lot: '',
        //         date_mise_en_ligne: '',
        //         ordre: 0,
        //         nom: '',
        //         libelle: '',
        //         file_mime_type: '',
        //         cle: '',
        //         type_mime: '',
        //         lien: '',
        //         file_name: '',
        //         file_extension: '',
        //         extension: '',
        //         file_size: 0,
        //         is_aapc: 0,
        //         taille_f: '',
        //         taille: ''
        //     }
        // ]
    }];

    // nombre total de pieces (pieces constitutives de la consultation + pieces constitutives des lots)
    public nb_total = 0;

    // tslint:enable:variable-name

}
