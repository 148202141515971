// tslint:disable:variable-name

export interface MailPaItem {
  id: number;
  cle_mail: string;
  cle_mail_question: string;
  question_repondue: number;
  sujet: string;
  texte: string;
  date: number;
  date_f: string;
  cle_etablissement: string;
  cle_utilisateur: string;
  cle_dce: string;
  type: 1 | 2;
  visibilite: number;
  emails_comp: string;
  accuse: number;
  piece_jointe: string;
  i_nom: string;
  i_prenom: string;
  i_email: string;
  reponses: MailResponsePaItem[];
  nb_reponses: number;
}

export class MailResponsePaItem {
  id!: number;
  cle_mail!: string;
  cle_mail_question!: string;
  question_repondue!: 0 | 1;
  sujet!: string;
  texte!: string;
  date!: number;
  date_f!: string;
  cle_etablissement!: string;
  cle_utilisateur!: string;
  type!: 1 | 2;
  visibilite!: 0 | 1;
  emails_comp!: string;
  accuse!: 0 | 1;
  piece_jointe!: string;
  i_nom!: string;
  i_prenom!: string;
  i_email!: string;
  envois!: MalEnvoiPaItem[];
  nb_envois!: number;
}

export class MalEnvoiPaItem {
  id!: number;
  cle_envoi!: string;
  cle_mail!: string;
  cle_alias!: string;
  utilisateur!: string;
  annuaire!: string;
  email!: string;
  date_accuse!: 0 | 1;
  date_accuse_f!: string;
  etat!: number;
  accuse!: number;
}

    // tslint:enable:variable-name
