<app-search-form (submit)="submit()" class="form-container">

  <form [formGroup]="searchDeForm" class="form">

    <section class="section">
      <h1 class="title">
        {{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.PLATEFORM.TITLE'| translate }}
      </h1>
      <mat-radio-group fxLayout="row wrap" class="plateform-type" fxLayoutGap="20px" fxLayoutAlign="space-between center"
        formControlName="plateform" (change)="setFormControl()">
        <mat-radio-button color="primary" *ngFor="let p of plateforms" [value]="p.code" class="plateform-radio"
          [disabled]="!p.isActive">
          {{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.PLATEFORM.' + p.labelKey | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </section>

    <section class="section">
      <h1 class="title">
        {{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.CRITERIAS'| translate }}
      </h1>
      <div fxLayout.lt-md="column" fxLayout.gt-sm="row">
        <div fxLayoutAlign="center center" class="inputElement" fxFlex.gt-sm="50" fxFlex.lt-md="100"
          *ngIf="displaySiretInput">
          <mat-icon>SIRET</mat-icon>
          <mat-divider vertical></mat-divider>
          <input matInput formControlName="identifier" name="motscles"
            placeholder="{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.PLACEHOLDER.SIRET' | translate}}"
            class="targetInput" />
        </div>

        <div fxLayoutAlign="center center" class="inputElement" fxFlex.gt-sm="50" fxFlex.lt-md="100"
          *ngIf="displayDepartmentsInput">
          <mat-icon [svgIcon]="icons.pin"></mat-icon>
          <mat-divider vertical></mat-divider>
          <input matInput formControlName="department" name="motscles"
            placeholder="{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.PLACEHOLDER.DEPARTEMENT' | translate}}"
            class="targetInput" />
        </div>
      </div>

      <div fxLayout.lt-md="column" fxLayout.gt-sm="row">
        <div fxLayoutAlign="center center" class="inputElement" fxFlex.gt-sm="50" fxFlex.lt-md="100"
          *ngIf="displayObject">
          <mat-icon [svgIcon]="icons.target"></mat-icon>
          <mat-divider fxHide.lt-md="true" vertical></mat-divider>
          <input matInput formControlName="target" name="motscles"
            placeholder="{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.PLACEHOLDER.OBJECT' | translate}}" />
        </div>

        <div class="datePickers" fxFlex.gt-sm="50" fxFlex.lt-md="100" fxLayout="row" *ngIf="displayDatePickers">
          <div fxLayoutAlign="center center" class="inputElement datepicker" fxLayout="row">
            <mat-datepicker-toggle class="toggle-datetime-picker" matPrefix [for]="pickerStart">
              <mat-icon matDatepickerToggleIcon [svgIcon]="icons.calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
            <mat-divider fxHide.lt-md="true" vertical></mat-divider>
            <input matInput [matDatepicker]="pickerStart" formControlName="startDate" [placeholder]="'APP.MARCHES_SECURISES.PLACEHOLDER.JJ/MM/AAAA' | translate" />
          </div>

          <div class="arrow">
            <mat-icon [svgIcon]="icons.arrow"></mat-icon>
            <mat-icon [svgIcon]="icons.arrow"></mat-icon>
          </div>

          <div fxLayoutAlign="center center" class="inputElement datepicker" fxLayout="row">
            <mat-datepicker-toggle class="toggle-datetime-picker" matPrefix [for]="pickerEnd">
              <mat-icon matDatepickerToggleIcon [svgIcon]="icons.calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
            <mat-divider fxHide.lt-md="true" vertical></mat-divider>
            <input matInput [matDatepicker]="pickerEnd" formControlName="endDate" [placeholder]="'APP.MARCHES_SECURISES.PLACEHOLDER.JJ/MM/AAAA' | translate" />
          </div>

        </div>

      </div>
      <ng-container *ngIf="isAifePlateform">
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row">
          <div fxLayoutAlign="center center" class="inputElement" fxFlex.gt-sm="50" fxFlex.lt-md="100"
            *ngIf="displaySiretInput">
            <mat-icon>M</mat-icon>
            <mat-divider vertical></mat-divider>
            <input matInput formControlName="natureMarket" name="motscles"
              placeholder="{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.PLACEHOLDER.NATURE' | translate}}"
              class="targetInput" />
          </div>

          <div fxLayoutAlign="center center" class="inputElement" fxFlex.gt-sm="50" fxFlex.lt-md="100"
            *ngIf="displayDepartmentsInput">
            <mat-icon>P</mat-icon>
            <mat-divider vertical></mat-divider>
            <input matInput formControlName="procedure"
              placeholder="{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.PLACEHOLDER.PROCEDURE' | translate}}"
              class="targetInput" />
          </div>
        </div>

        <div fxLayout.lt-md="column" fxLayout.gt-sm="row">
          <div fxLayoutAlign="center center" class="inputElement" fxFlex.gt-sm="50" fxFlex.lt-md="100"
            *ngIf="displaySiretInput">
            <mat-icon>R</mat-icon>
            <mat-divider vertical></mat-divider>
            <input matInput formControlName="rum" name="motscles"
              placeholder="{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.PLACEHOLDER.RUM' | translate}}"
              class="targetInput" />
          </div>

          <div fxLayoutAlign="center center" class="inputElement" fxFlex.gt-sm="50" fxFlex.lt-md="100"
            *ngIf="displayDepartmentsInput">
            <mat-icon>CPV</mat-icon>
            <mat-divider vertical></mat-divider>
            <input matInput formControlName="cpv" name="cpv"
              placeholder="{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.PLACEHOLDER.CPV' | translate}}"
              class="targetInput" />
          </div>
        </div>

        <div fxLayout.lt-md="column" fxLayout.gt-sm="row">
          <div fxLayoutAlign="center center" class="inputElement" fxFlex.gt-sm="50" fxFlex.lt-md="100"
            *ngIf="displaySiretInput">
            <mat-icon>S</mat-icon>
            <mat-divider vertical></mat-divider>
            <input matInput formControlName="rasionSociale" name="motscles"
              placeholder="{{'APP.MARCHES_SECURISES.ESSENTIALS_DATA.SEARCH.INPUTS.PLACEHOLDER.RAiSON_SOCIAL' | translate}}"
              class="targetInput" />
          </div>
        </div>
      </ng-container>
    </section>
  </form>
</app-search-form>