import { Injectable, OnDestroy } from '@angular/core';
import { DePlateformeType } from '@atline-shared/enums/dePlateforms.enum';
import { DateHelper } from '@atline/core/helpers/date.helper';
import { EssentialDataOeListRequest } from '@atline/core/models/api/requests/essentialData.request';
import { EssentialDataResponse } from '@atline/core/models/api/responses/essentialDataOeList.response';
import { ConsultationItem } from '@atline/core/models/consultationItem.model';
import { EssentialDataItem } from '@atline/core/models/essentialDataItem.model';
import { EssentialDataOeListParams } from '@atline/core/models/essentialDataItemParams.model';
import { Pagination } from '@atline/core/models/pagination.model';
import { EssentialDataService } from '@atline/core/services/essential-data.service';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounce, mergeMap, take, tap } from 'rxjs/operators';

@Injectable()
export class ShareDeData implements OnDestroy {

  private readonly emitSubject = new Subject();

  private deList: EssentialDataItem[] = [];
  private isLoadingData = false;

  private readonly paramsEssentialDataSubject = new BehaviorSubject<EssentialDataOeListParams>(new EssentialDataOeListParams(
    DePlateformeType.MS,
    '',
    null,
    null,
    '', ''
  ));
  readonly paramsEssentialDataChange = this.paramsEssentialDataSubject.pipe(debounce(() => this.emitSubject));

  private readonly essentialDataSubject = new BehaviorSubject<any[]>([]);


  readonly essentialDataChange = this.essentialDataSubject.pipe();

  private readonly numberEssentialDataSubject = new BehaviorSubject<number>(0);
  readonly numberEssentialDataChange = this.numberEssentialDataSubject.pipe();

  private readonly searchInProgressSubject = new BehaviorSubject<boolean>(false);
  readonly searchInProgressChange = this.searchInProgressSubject.pipe();

  private readonly paginationSubject = new BehaviorSubject<Pagination>(new Pagination());
  readonly paginationChange = this.paginationSubject.pipe();

  private readonly errorSubject = new BehaviorSubject<any>(undefined);
  readonly errorChange = this.errorSubject.pipe();

  private readonly subs = new Subscription();

  constructor(
    private readonly essentialDataService: EssentialDataService,
    private readonly dateHelper: DateHelper
  ) {
    this.initSetParams();
  }

  private initSetParams(): void {
    this.numberEssentialDataSubject.next(0);
    const paramsChange = this.paramsEssentialDataChange.pipe(
      mergeMap(() => {
        this.searchInProgressSubject.next(true);
        this.isLoadingData = true;
        this.errorSubject.next(undefined);
        return this.searchEssentialDataOeList();
      }),
    ).subscribe({
        next: rep => {
          this.essentialDataChange.pipe(take(1)).subscribe(() => this.searchInProgressSubject.next(false));
          this.numberEssentialDataSubject.next(rep.nb_total < 0 ? 0 : rep.nb_total);
          this.essentialDataSubject.next(rep.donnees);
          this.paginationSubject.next(rep.pagination);
        },
        error: err => {
          this.errorSubject.next(err);
          this.searchInProgressSubject.next(false);
        }
      }

    );
    this.subs.add(paramsChange);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get essentialData(): ConsultationItem[] {
    return this.essentialDataSubject.value;
  }

  get numberEssentialData(): number {
    return this.numberEssentialDataSubject.value;
  }

  get params(): EssentialDataOeListParams {
    return this.paramsEssentialDataSubject.value;
  }

  get pagination(): Pagination {
    return this.paginationSubject.value;
  }

  get essentialsDatas(): EssentialDataItem[] {
    return this.deList;
  }

  get isLoading(): boolean {
    return this.isLoadingData;
  }

  setParams(params: any, emit = true): void {
    this.essentialDataSubject.next([]);
    this.paramsEssentialDataSubject.next({
      plateform: params.plateform,
      end_date: params.endDate ? this.dateHelper.formatdateForWSYYYMMDD(params.endDate) : null,
      identifier: params.identifier,
      keyword: params.keyword,
      departments: params.department,
      target: params.target,
      start_date: params.startDate ? this.dateHelper.formatdateForWSYYYMMDD(params.startDate) : null,
      nbParPage: this.params.nbParPage,
      ordre: this.params.ordre,
      sens_ordre: this.params.sens_ordre,
      page: this.params.page,
    });

    if (emit) this.emitSubject.next();
  }

  setParamsPagination(nbParPage: number, page: number, emit = true): void {
    this.essentialDataSubject.next([]);
    this.paramsEssentialDataSubject.next({
      ...this.params,
      nbParPage,
      ordre: this.params.ordre,
      sens_ordre: this.params.sens_ordre,
      page,
    });
    if (emit) this.emitSubject.next();
  }

  get searchInProgress(): boolean {
    return this.searchInProgressSubject.value;
  }

  get error(): any {
    return this.errorSubject.value;
  }

  private searchEssentialDataOeList(): Observable<EssentialDataResponse> {
    const paramsBuilder: EssentialDataOeListRequest = {
      plateform: this.params.plateform,
      end_date: !_.isNull(this.params.end_date) ? this.params.end_date : undefined,
      identifier: this.params.identifier,
      departments: this.params.departments,
      keyword: this.params.target,
      search: this.params.target,
      start_date: !_.isNull(this.params.start_date) ? this.params.start_date : undefined,
      pagination: {
        page: this.params.page,
        nbr_par_page: this.params.nbParPage,
        ordre: this.params.ordre,
        sens_ordre: this.params.sens_ordre,
      },
    };

    return this.essentialDataService.getDeOeList(paramsBuilder).pipe(
      tap((response: any) => {
        this.essentialDataSubject.next(response?.donnees || []);
      })
    );
  }

  resetDe(): void {
    this.setParamsPagination(10, 1, false);
    this.numberEssentialDataSubject.next(0);
    this.essentialDataSubject.next([]);
  }

  reload(): void {
    this.emitSubject.next();
  }
}
