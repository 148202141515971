import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../../core/services/utils.service';
import {NameValueItem} from '../../../../core/models/special-types.model';
import {SessionStorage} from '../../../enums/session-storage.enum';
import { MatMenuTrigger } from '@angular/material/menu';
@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorComponent implements OnInit {

  readonly languagesList: NameValueItem[] = [
    { name: 'Français', value: 'fr' },
    { name: 'English', value: 'en' },
  ];

  public currentLang = 'fr';
  public isMenuOpen = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly utilsService: UtilsService
  ) { this.currentLang  = translate.currentLang; }

  ngOnInit(): void {
  }

  /**
   * Change the application language
   * Translate to the selected language and update the localStorage
   * @param lang selected language
   * If needed farther in the code, add a Service with BehaviorSubject to notify other components with language refresh
   */
  onSelect(lang: string): void {
    this.utilsService.setSessionStorage(SessionStorage.LANG, lang);
    this.translate.use(lang);
    this.currentLang = lang;
    this.isMenuOpen = false;
  }

  toggleMenu(menu: MatMenuTrigger): void {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      menu.openMenu();
    } else {
      menu.closeMenu();
    }
  }
}
