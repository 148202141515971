/**
 * for more information, see
 * source https://www.meziantou.net/upload-files-and-directories-using-an-input-drag-and-drop-or-copy-and-paste-with.htm
 */

import { Directive, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';

interface FileForDepot {
  file: File;
  fullPath?: string;
}

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {

  @HostBinding('style.background') background = 'transparent';
  @HostBinding('style.z-index') zIndex = '10';

  @Output()
  files = new EventEmitter<{ file: File, fullPath?: string }[]>();


  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent';
  }

  @HostListener('drop', ['$event']) public async onDrop(evt: DragEvent): Promise<any> {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent';
    if (evt.dataTransfer) {
      const files = await this.getFilesAsync(evt.dataTransfer);
      this.files.emit(files);
    }
  }

  private isDirectory(entry: any): entry is any {
    return entry.isDirectory;
  }

  private isFile(entry: any): entry is any {
    return entry.isFile;
  }

  private async getFilesAsync(dataTransfer: DataTransfer): Promise<FileForDepot[]> {
    const files: FileForDepot[] = [];
    const entries = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < dataTransfer.items.length; i++) {
      const item = dataTransfer.items[i];
      if (item.kind === 'file') {
        if (typeof item.webkitGetAsEntry === 'function') {
          const entry = item.webkitGetAsEntry();
          if (entry) {
            entries.push(this.readEntryContentAsync(entry));
            continue;
          }
          continue;
        }

        const file = item.getAsFile();
        if (file) {
          files.push({ file });
        }
      }
    }
    await Promise.all(entries).then(array => array.forEach(v => {
      v.forEach(file => files.push(file));
    }));
    return files;
  }

  private async readEntryContentAsync(entry: any): Promise<FileForDepot[]> {
    return new Promise<{ file: File, fullPath?: string }[]>((resolve) => {
      let reading = 0;
      const contents: { file: File, fullPath?: string }[] = [];
      const readEntry = (entryToRead: any): void => {
        if (this.isFile(entryToRead as any)) {
          reading++;
          entryToRead.file((file: File) => {
            reading--;
            contents.push({ file, fullPath: entryToRead.fullPath });

            if (reading === 0) {
              resolve(contents);
            }
          });
        } else if (this.isDirectory(entryToRead)) {
          readReaderContent(entryToRead.createReader());
        }
      };

      const readReaderContent = (reader: any): void => {

        reading++;

        reader.readEntries((entries: any[]) => {
          reading--;
          for (const item of entries) {
            readEntry(item);
          }

          if (reading === 0) {
            resolve(contents);
          }
        });
      };

      readEntry(entry);

    });
  }

}
