import { EnveloppeFileGetRequest } from './../models/api/requests/enveloppeFileGet.request';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EnveloppeGetRequest } from './../models/api/requests/enveloppeGet.request';
import { EnveloppeResponse } from '../models/api/responses/enveloppe.response';
import { EnveloppeRequest } from '../models/api/requests/enveloppe.request';
import { Observable } from 'rxjs';
import { RequestForPaWithDCE } from 'src/app/core/models/api/requests/requestForPa.request';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { UtilsService } from './utils.service';

// tslint:disable:variable-name
@Injectable({
  providedIn: 'root'
})
export class EnveloppeService implements RequestForPaWithDCE {
  cle_etab!: string;
  cle_pa!: string;
  cle_dce!: string;

  constructor(
    private readonly apiService: ApiService,
    private http: HttpClient,
    private utilService: UtilsService
  ) { }

  getEnvelopeList(req: EnveloppeRequest): Observable<EnveloppeResponse> {
    return this.apiService.post(EnveloppeResponse, req, 'envelopes_list');
  }

  getEnvelopeGet(req: EnveloppeGetRequest): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.api + 'envelope_get', req,
    {
      headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
      responseType: 'blob',
      observe: 'response'
    });
  }


  getEnveloppeFileGet(req: EnveloppeFileGetRequest): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.api + 'envelope_file_get', req,
    {
      headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
      responseType: 'blob',
      observe: 'response'
    });
  }

  getEnvelopeFileLink(req: EnveloppeFileGetRequest): string {
    const variableString = `${req.id_file ? '&id_file=' + req.id_file : '' }${req.repertoire_fichier ? '&repertoire_fichier=' + req.repertoire_fichier : '' }`;
    return `${environment.api}envelope_file_get?cle_dce=${req.cle_dce}&cle_reponse=${req.cle_reponse}&cle_enveloppe=${req.cle_enveloppe}&cle_etab=${req.cle_etab}&cle_pa=${req.cle_pa}&access_token=${this.utilService.token}${variableString}`;
  }

  getEnvelopeLink(req: EnveloppeGetRequest): string {
    return `${environment.api}envelope_get?type=zip&cle_dce=${req.cle_dce}&cle_reponse=${req.cle_reponse}&cle_enveloppe=${req.cle_enveloppe}&cle_etab=${req.cle_etab}&cle_pa=${req.cle_pa}&access_token=${this.utilService.token}`;
  }

}
// tslint:enable:variable-name
