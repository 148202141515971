import { Component, OnInit, ContentChildren, QueryList, Output, EventEmitter, AfterContentChecked } from '@angular/core';
import { SubButtonSideBarDirective } from '../sub-button-side-bar.directive';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-group-btn-side-bar',
  templateUrl: './group-btn-side-bar.component.html',
  styleUrls: ['./group-btn-side-bar.component.scss']
})
export class GroupBtnSideBarComponent implements OnInit, AfterContentChecked {

  @ContentChildren(SubButtonSideBarDirective)
  liChildren?: QueryList<SubButtonSideBarDirective>;

  @Output()
  readonly subButtonClicked = new EventEmitter<MouseEvent>();

  private subs?: Subscription;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.subs?.unsubscribe();
    if (this.liChildren) {
      this.subs = new Subscription();
      for (const subBtn of this.liChildren.toArray()) {
        this.subs.add(subBtn.buttonClicked.subscribe((event: MouseEvent) => this.subButtonClicked.emit(event)));
      }
      return;
    }
    delete this.subs;
  }

}
