import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class CustomMatPaginatorIntlService extends MatPaginatorIntl implements OnDestroy {

  private langChangeSub!: Subscription;
  OF_LABEL = 'of';

  readonly linkText = {
    OF_LABEL: 'APP.MAT_PAGINATOR.OF_LABEL',
    ITEM_PAR_PAGE: 'APP.MAT_PAGINATOR.ITEMS_PER_PAGE',
    NEXT_PAGE: 'APP.MAT_PAGINATOR.NEXT_PAGE',
    PREVIOUS_PAGE: 'APP.MAT_PAGINATOR.PREVIOUS_PAGE',
  } as const;

  constructor(private translate: TranslateService) {
    super();
    this.langChangeSub = translate.onLangChange.subscribe(() => this.getAndInitTranslations());
    this.getAndInitTranslations();
  }

  ngOnDestroy(): void { this.langChangeSub.unsubscribe(); }

  getAndInitTranslations(): void {
    this.translate.get(Object.values(this.linkText)).pipe(take(1))
      .subscribe(translate => {
        this.itemsPerPageLabel = translate[this.linkText.ITEM_PAR_PAGE];
        this.nextPageLabel = translate[this.linkText.NEXT_PAGE];
        this.previousPageLabel = translate[this.linkText.PREVIOUS_PAGE];
        this.OF_LABEL = translate[this.linkText.OF_LABEL];
        this.changes.next();
      });
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) { return `0 ${this.OF_LABEL} ${length}`; }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1}-${endIndex} ${this.OF_LABEL} ${length}`;
  }
}
