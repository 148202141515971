<div class="container-historique container" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap="30px">
  <app-sidebar [overlayOrigin]="overlayOrigin">
    <btn-side-bar *ngFor="let btn of btns"
      (btnClicked)="go(btn.link)"
      [selected]="currentPath == btn.link || includes(btn, currentPath)"
    >
    {{ btn.text | translate }}
    <app-group-btn-side-bar *ngIf="btn.subBtn">
      <li sub-btn-side-bar *ngFor="let sub of btn.subBtn"
        (click)="go(sub.link)"
        [selected]="currentPath == sub.link"
      >
      {{ sub.text | translate }}
      </li>
    </app-group-btn-side-bar>
  </btn-side-bar>
  </app-sidebar>
  <div fxFlex="100" cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">
    <router-outlet></router-outlet>
  </div>
</div>
