import { Component, Input, OnInit } from '@angular/core';
import { AvisAttributionItem } from '@atline/core/models/avis-attributions.model';

@Component({
  selector: 'app-avis-attribution-list',
  templateUrl: './avis-attribution-list.component.html',
  styleUrls: ['./avis-attribution-list.component.scss'],
})
export class AvisAttributionListComponent implements OnInit {
  @Input()
  attributions!: AvisAttributionItem[];

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
