import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { PipeModule } from '@atline/core/pipe/pipe.module';



@NgModule({
  declarations: [
    DialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FlexModule,
    PipeModule
  ],
  exports: [
    DialogComponent
  ]
})
export class DialogModule { }
