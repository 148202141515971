export class LotsListAnnonceResponse {
  // tslint:disable:variable-name
  public lots: LotAnnonce[] = [];
  public nb_total = 0;
  // tslint:enable:variable-name
}

export class LotAnnonce {
  // tslint:disable:variable-name
  public id_lot_boamp = 0;

  public cle_lot_boamp = '';

  public cle_annonce = '';

  public numero_lot = 0;

  public intitule_lot = '';

  public description = '';

  public quantite: number | null = null;

  public devise = '';

  public valeur = 0;

  public valeur_basse = 0;

  public valeur_haute = 0;

  public date_execution = 0;

  public date_livraison = 0;

  public mot_clef: string | null = null;

  public cpv = '';

  public cpv_1 = '';

  public cpv_2 = '';

  public cpv_3 = '';

  public cpv_4 = '';

  public infos_comp = '';

  public duree_nb_mois = 0;

  public duree_nb_jours = 0;

  public duree_fin = 0;

  public duree_debut = 0;

  public dateLancementPassation: string | null = null;

  public lieu_nuts: string | null = null;

  public lieuExecutionLivraison = '';

  public critereDoc = 0;

  public criteres: string | null = null;

  public criterePrix = '';

  public critereCout = '';

  public critereQualite = '';

  public reconduction = 0;

  public reconductionDesc = '';

  public nombre_candidat: string | null = null;

  public nombre_candidat_min: string | null = null;

  public nombre_candidat_max: string | null = null;

  public candidat_criteres_limitation: string | null = null;

  public carac_variante: string | null = null;

  public carac_options = '';

  public carac_options_description: string | null = null;

  public catalogueElec: string | null = null;

  public is_fonds_communautaire: string | null = null;

  public is_fonds_communautaire_info: string | null = null;

  public AC_ATP: number | null = null;

  public origine_request: string | null = null;
  // tslint:enable:variable-name
}
