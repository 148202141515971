import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticateService } from '@atline/core/services/authenticate.service';
import { DialogsService } from '@atline/core/services/dialogs.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public incorrectCredentials = false;

  constructor(
    private readonly authenticator: AuthenticateService,
    public dialogsService: DialogsService,
    public loginDialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { before: () => any, error: (err: any) => any, after: (result: any) => any },
  ) { }

  ngOnInit(): void {
  }

  /**
   * Submit the form by logging in the user
   * Redirect to dashboard when is success
   */
  onSubmit(formData: FormGroup): void {
    const { before, after } = this.data;
    if (formData.valid) {
      before();
      this.authenticator.login(formData.value).subscribe({
        next: result => {
          this.loginDialogRef.close();
          after(result);
        },
        error: () => {
          this.incorrectCredentials = true;
          this.dialogsService.closeLoader();
        }
      });
    }
  }
}
