import { Component, Input, OnInit } from '@angular/core';
import { menuItems } from '../../../../core/models/service-hamburger.model';

@Component({
  selector: 'app-services-menu-hamburger',
  templateUrl: './services-menu-hamburger.component.html',
  styleUrls: ['./services-menu-hamburger.component.scss']
})
export class ServicesMenuHamburgerComponent implements OnInit {

  @Input() isOpenHamburger = false;

  readonly listMenuItems = menuItems;

  constructor() { }

  ngOnInit(): void { }

  closeMenu(): void {
    this.isOpenHamburger = false;
  }

}
