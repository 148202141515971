import { TYPE_COMMANDE_PUBLIQUE } from '../../constant-variable/attributaire.model';

export class DceEssentialData {
  // tslint:disable:variable-name
  cle_lot = '';
  libelle_lot = '';
  libelle_lot_ordre = '';
  donnees_lot: LotData | null = null;
  donnees_finance: FinanceData | null = null;
  id_acheteur = '';
  nom_acheteur = '';
  // is_demo = 0;
  donnees_oe_lot: OeLotData[] = [];
  nb_oe = 0;
  donnees_concession ? = new ConcessionData();
  donnees_avenants_lot: AvenantLotData[] = [];
  nb_avenants = 0;
  // tslint:enable:variable-name
}

export function isDceEssentialData(value: any): value is DceEssentialData {
  return value instanceof DceEssentialData;
}

export class ConcessionData {
  // tslint:disable:variable-name
  id_concess = 0;
  id_marche = 0;
  cle_lot = '';
  donnees_exec = '';
  date_deb = '';
  date_sign = '';
  date_exec = '';
  mtt_subv = 0;
  mtt_invest = 0;
  mtt_usager = 0;
  lib_tarif = '';
  version = 0;
  // tslint:enable:variable-name
}

export class LotData {
  // tslint:disable:variable-name
  id_dma = 0;
  niump = '';
  num_lot = '';
  is_concession: 0 | 1 = 0;
  nature_marche = '';
  objet_marche = '';
  code_cpv = '';
  procedure = '';
  lieux = '';
  code_lieux = '';
  type_code_lieux = '';
  nom_lieux = '';
  duree = 0;
  date_notif = '';
  date_publi_donnee = '';
  version = 0;
  etat_marche: TYPE_COMMANDE_PUBLIQUE = 0;
  date_creation = '';
  user_nom = '';
  user_prenom = '';
  date_aife = '';
  ref_fonct_aife = '';
  date_update_aife = '';
  date_modification = '';
  // tslint:enable:variable-name
}

export class FinanceData {
  // tslint:disable:variable-name
  id_finance = 0;
  id_marche = 0;
  mtt_estime = 0;
  forme_prix = '';
  version = 0;
  // tslint:enable:variable-name
}

export class OeLotData {
  // tslint:disable:variable-name
  id_doe = 0;
  id_marche = 0;
  niump = '';
  cle_lot = '';
  ident = '';
  type_ident = '';
  type_ident_id = 0;
  denomination = '';
  statut = 0;
  etat = 0;
  version = 0;
  suppr = 0;
  type_attrib = 0;
  // tslint:enable:variable-name
}

export function isOeLotData(arg: any): arg is OeLotData {
  return arg instanceof OeLotData;
}

export class EndorsementAttribute {
  // tslint:disable:variable-name
  id = 0;
  type_attrib = 0;
  ident = '';
  type_ident_id = 0;
  denomination = '';

  constructor(args?: { [Key in keyof EndorsementAttribute]?: EndorsementAttribute[Key] }) {
    this.id = args?.id ?? this.id;
    this.type_attrib = args?.type_attrib ?? this.type_attrib;
    this.ident = args?.ident ?? this.ident;
    this.type_ident_id = args?.type_ident_id ?? this.type_ident_id;
    this.denomination = args?.denomination ?? this.denomination;
  }
  // tslint:enable:variable-name
}

export class EndorsementAttributeLot extends EndorsementAttribute {
  // tslint:disable:variable-name
  id_dmodif = 0;
  update_type = '';

  constructor(args?: { [Key in keyof EndorsementAttributeLot]?: EndorsementAttributeLot[Key] }) {
    super(args);
    this.id_dmodif = args?.id_dmodif ?? this.id_dmodif;
    this.update_type = args?.update_type ?? this.update_type;
  }
  // tslint:enable:variable-name
}

export function isEndorsementAttributeLot(arg: any): arg is EndorsementAttributeLot {
  return arg instanceof EndorsementAttributeLot;
}

export class AvenantLotData {
  // tslint:disable:variable-name
  id_dmodif = 0;
  id_marche = 0;
  num_lot = '';
  id_dpa = 0;
  objet_modif = '';
  date_modif = '';
  version = 0;
  user_nom = '';
  user_prenom = '';
  dateheure = '';
  date_pub_donnee_modif = '';
  date_sign_donnee_modif = '';
  duree = 0;
  mtt_estime = 0;
  num_avenant = 0;
  deleted = 0;
  donnees_modif_oe: EndorsementAttributeLot[] = [];
  nb_oe_modif = 0;
  // tslint:enable:variable-name
}

export class OeDeEditReponse extends OeLotData { }
export function isOeDeEditReponse(value: any): value is OeDeEditReponse {
  return value instanceof OeDeEditReponse;
}


export class PublishDeReponse {
  // tslint:disable:variable-name
  response?: string = undefined;
  accessible?: boolean = undefined;
  id_dma = 0;
  date_aife = '';
  date_update_aife: string | null = null;
  ref_fonct_aife = '';
  // tslint:enable:variable-name
}

export class AvenantEditReponse {
  // tslint:disable:variable-name
  id_dmodif = 0;
  id_marche = 0;
  num_lot = '';
  id_dpa = 0;
  objet_modif = '';
  date_modif = '';
  version = 0;
  user_nom = '';
  user_prenom = '';
  dateheure = '';
  date_pub_donnee_modif = '';
  date_sign_donnee_modif = '';
  duree = 0;
  mtt_estime = 0;
  num_avenant = 0;
  deleted = 0;
  avenant_entreprises: EndorsementAttribute[] = [];
  nb_avenant_oe = 1;
  // tslint:enable:variable-name
}
