import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListComponent } from '../../shared/components/list/list.component';
import { SearchFormComponent } from './search-form/search-form.component';

const routes: Routes = [
  {path: '', component: SearchFormComponent},
  {path: 'list', component: ListComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DonnesEssenciellesRoutingModule { }
