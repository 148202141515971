<div class="container-print-structure" *ngIf="print">
    <fieldset class="print-structure">
        <legend>{{ 'APP.PRINT-STRUCT.LEGEND' | translate }}</legend>
        <!--name-->
        <div id="name" class="container-data">
            <span class="label-data">{{ 'APP.PRINT-STRUCT.LABELS.NAME' | translate }}</span>
            <span class="data">{{ structure ? structure.name : '' }}</span>
        </div>
        <!--siret-->
        <div id="siret" class="container-data">
            <span class="label-data">{{ 'APP.PRINT-STRUCT.LABELS.SIRET' | translate }}</span>
            <span class="data">{{ structure ? structure.siret : '' }}</span>
        </div>
        <!--Code NAF-->
        <div class="container-data">
            <span class="label-data">{{ 'APP.PRINT-STRUCT.LABELS.NAF' | translate }}</span>
            <span class="data">{{ structure ? structure.codeNAF : '' }}</span>
        </div>
    </fieldset>
</div>
