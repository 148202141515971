<div class="dialog-container">
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <ng-container>
      <ng-content select="[header]"></ng-content>
    </ng-container>


    <a *ngIf="cross" atlineLink (click)="close()" class="close"></a>
  </div>
  <div mat-dialog-content class="body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>

</div>
